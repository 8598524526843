export const INITIATE_SESSION_FLOW = 'session/INITIATE_SESSION_FLOW';
export const SESSION_FLOW_EXERCISE_SUBMIT = 'session/SESSION_FLOW_EXERCISE_SUBMIT';
export const SESSION_FLOW_CHALLENGE_SUBMIT = 'session/SESSION_FLOW_CHALLENGE_SUBMIT';
export const SESSION_FLOW_SESSION_SUBMIT = 'session/SESSION_FLOW_SESSION_SUBMIT';
export const SESSION_FLOW_SESSION_FEEDBACK = 'session/SESSION_FLOW_SESSION_FEEDBACK';
export const SESSION_FLOW_GET_SESSION_DATA = 'session/SESSION_FLOW_GET_SESSION_DATA';

export const SESSION_FLOW_GET_NEW_USER_SESSION = 'session/SESSION_FLOW_GET_NEW_USER_SESSION';

export const FETCH_PHYSICAL_TEST_BY_LEVEL = 'session/FETCH_PHYSICAL_TEST_BY_LEVEL';
export const GET_PHYSICAL_TEST_TO_USER = 'session/GET_PHYSICAL_TEST_TO_USER';
export const ASSIGN_PHYSICAL_TEST_TO_USER = 'session/ASSIGN_PHYSICAL_TEST_TO_USER';
export const SUBMIT_PHYSICAL_TEST_FOR_USER = 'session/SUBMIT_PHYSICAL_TEST_FOR_USER';

export const GET_SUGGESTED_SESSIONS = 'session/GET_SUGGESTED_SESSIONS';
export const CACHE_UPDATE_SUGGESTED_SESSIONS = 'session/CACHE_UPDATE_SUGGESTED_SESSIONS';

export const GET_ALL_SESSIONS = 'session/GET_ALL_SESSIONS';
export const GET_ALL_SESSIONS_MIN = 'session/GET_ALL_SESSIONS_MIN';
export const GET_SINGLE_SESSION = 'session/GET_SINGLE_SESSION';
export const GET_SESSION_USER = 'session/GET_SESSION_USER';

export const GET_SESSIONS_SDS = 'session/GET_SESSIONS_SDS';

export const GET_PHYSICAL_TEST_LEVELS = 'session/GET_PHYSICAL_TEST_LEVELS';
export const GET_PHYSICAL_TEST_WELCOME_MESSAGE = 'session/GET_PHYSICAL_TEST_WELCOME_MESSAGE';
export const GET_SESSION_INTRO_MESSAGE = 'session/GET_SESSION_INTRO_MESSAGE';

export const LIKE = 'session/LIKE';
export const DISLIKE = 'session/DISLIKE';

export const GET_USER_FAVORITE_SESSIONS_HISTORY = 'session/GET_USER_FAVORITE_SESSIONS_HISTORY';
export const GET_USER_SESSIONS_HISTORY = 'session/GET_USER_SESSIONS_HISTORY';

export const CACHE_UPDATE_SESSIONS = 'session/CACHE_UPDATE_SESSIONS';
export const CACHE_UPDATE_SESSION_LIKE = 'session/CACHE_UPDATE_SESSION_LIKE';
export const CACHE_UPDATE_SESSION_USER = 'session/CACHE_UPDATE_SESSION_USER';
export const CACHE_UPDATE_SESSIONS_SET = 'session/CACHE_UPDATE_SESSIONS_SET';
export const CACHE_UPDATE_DONE_SESSION_DATA = 'session/CACHE_UPDATE_DONE_SESSION_DATA';
export const UPDATE_CACHE_SET_SESSION_END_DATA = 'session/UPDATE_CACHE_SET_SESSION_END_DATA';
export const UPDATE_CACHE_SET_USER_SESSION = 'session/UPDATE_CACHE_SET_USER_SESSION';
export const CACHE_UPDATE_SDS_SESSIONS = 'session/CACHE_UPDATE_SDS_SESSIONS';

export const CACHE_UPDATE_PHYSICAL_TEST_LEVELS = 'session/CACHE_UPDATE_PHYSICAL_TEST_LEVELS';
export const CACHE_UPDATE_PHYSICAL_TEST_WELCOME_MESSAGE =
  'session/CACHE_UPDATE_PHYSICAL_TEST_WELCOME_MESSAGE';
export const CACHE_UPDATE_SESSION_INTRO_MESSAGE = 'session/CACHE_UPDATE_SESSION_INTRO_MESSAGE';
export const UPDATE_SESSION_SEARCH_RESULTS = 'session/UPDATE_SESSION_SEARCH_RESULTS';
export const UPDATE_FAVORITE_SESSIONS = 'session/UPDATE_FAVORITE_SESSIONS';
export const UPDATE_HISTORY_SESSIONS = 'session/UPDATE_HISTORY_SESSIONS';

export function initiateSessionFlowCreator(payload) {
  return { type: INITIATE_SESSION_FLOW, payload };
}

export function sessionFlowExerciseSubmit(payload) {
  return { type: SESSION_FLOW_EXERCISE_SUBMIT, payload };
}

export function sessionFlowChallengeSubmit(payload) {
  return { type: SESSION_FLOW_CHALLENGE_SUBMIT, payload };
}

export function sessionFlowSessionSubmitCreator(payload) {
  return { type: SESSION_FLOW_SESSION_SUBMIT, payload };
}

export function sessionFlowFeedbackCreator(payload) {
  return { type: SESSION_FLOW_SESSION_FEEDBACK, payload };
}

export function sessionFlowGetSessionDataCreator(payload) {
  return { type: SESSION_FLOW_GET_SESSION_DATA, payload };
}

export function sessionFlowGetNewUserSession(payload) {
  return { type: SESSION_FLOW_GET_NEW_USER_SESSION, payload };
}

export function fetchPhysicalTestByLevel(payload) {
  return { type: FETCH_PHYSICAL_TEST_BY_LEVEL, payload };
}

export function assignPhysicalTestToUser(payload) {
  return { type: ASSIGN_PHYSICAL_TEST_TO_USER, payload };
}

export function getPhysicalTestToUser(payload) {
  return { type: GET_PHYSICAL_TEST_TO_USER, payload };
}

export function submitPhysicalTestForUser(payload) {
  return { type: SUBMIT_PHYSICAL_TEST_FOR_USER, payload };
}

export function getSuggestedSessions(payload) {
  return { type: GET_SUGGESTED_SESSIONS, payload };
}

export function getAllSessionsCreator(payload) {
  return { type: GET_ALL_SESSIONS, payload };
}

export function getAllSessionsMinCreator(payload) {
  return { type: GET_ALL_SESSIONS_MIN, payload };
}

export function getSingleSession(payload) {
  return { type: GET_SINGLE_SESSION, payload };
}

export function getSessionUser(payload) {
  return { type: GET_SESSION_USER, payload };
}

export function getSessionsSDS(payload) {
  return { type: GET_SESSIONS_SDS, payload };
}

export function getPhysicalTestLevels(payload) {
  return { type: GET_PHYSICAL_TEST_LEVELS, payload };
}

export function getPhysicalTestWelcomeMessage(payload) {
  return { type: GET_PHYSICAL_TEST_WELCOME_MESSAGE, payload };
}

export function getSessionIntroMessage(payload) {
  return { type: GET_SESSION_INTRO_MESSAGE, payload };
}

export function likeSession(payload) {
  return { type: LIKE, payload };
}

export function dislikeSession(payload) {
  return { type: DISLIKE, payload };
}

export function getUserFavoriteSessionsHistory(payload) {
  return { type: GET_USER_FAVORITE_SESSIONS_HISTORY, payload };
}

export function getUserSessionsHistory(payload) {
  return { type: GET_USER_SESSIONS_HISTORY, payload };
}
