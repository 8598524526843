// @flow

import { PATH_NAMES } from '@core/constants';

import type { Tab, Tabs } from './types';

const homeTab: Tab = {
  name: PATH_NAMES.HOME,
  translationKey: 'NavItems.home',
  isActive: false,
};

const whoWeAreTab: Tab = {
  name: PATH_NAMES.ABOUT,
  translationKey: 'NavItems.whoWeAre',
  isActive: false,
};

const ourPartnersTab: Tab = {
  name: PATH_NAMES.PARTNERS,
  translationKey: 'NavItems.ourPartners',
  isActive: false,
};

const blogTab: Tab = {
  name: PATH_NAMES.BLOG,
  translationKey: 'NavItems.blog',
  isActive: false,
};

const contactTab: Tab = {
  name: PATH_NAMES.CONTACT,
  translationKey: 'NavItems.contact',
  isActive: false,
};

const groupClassesTab = {
  name: 'myGroupClasses',
  translationKey: 'NavItems.myGroupClasses',
  isActive: false,
  link: '/group-classes',
};

const coachingTab = {
  name: 'individualCoaching',
  translationKey: 'NavItems.individualCoaching',
  isActive: false,
  link: '/individual-coaching',
};

export const allTabs: Tabs = [homeTab, whoWeAreTab, ourPartnersTab, blogTab, contactTab];

// export const ipfTabs: Tabs = [homeTab, whoWeAreTab, ourPartnersTab, contactTab];

export const ipfTabs: Tabs = [];

export const allianzTabs: Tabs = [];

export const privateTabs = [groupClassesTab, coachingTab];
