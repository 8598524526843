import { select, put, take, call, takeEvery } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';

import * as ApiService from 'services/api';
import { getCurrentRole } from 'services/roles';
import { socketConnect, socketDisconnect } from 'services/socket';

import { getFriendsCreator } from 'store/modules/User/actions';
import { messageReceived } from 'store/modules/Chat/actions';
import { incrementStatusCreator, decrementStatusCreator } from 'store/modules/Status/actions';
import * as socketActions from 'store/modules/Socket/actions';

/** ********************************************** */
/** Selectors * */
const notificationsCounterSelector = (state) => {
  return JSON.parse(JSON.stringify(state.Socket.notifications_counter));
};

/** ********************************************** */
/** Sagas * */
function* initiateWebsocketSaga(data) {
  try {
    if (!data || !data.payload) return;

    if (!data.payload.userId) return;

    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Initiating websocket' })
    );

    if (
      getCurrentRole() === 'admin' ||
      getCurrentRole() === 'manager' ||
      getCurrentRole() === 'coach'
    ) {
      yield put(socketActions.getNotificationsCounter({}));
    }

    const channel = eventChannel((emitter) => {
      socketConnect(data.payload.userId, emitter);

      return () => {
        // Perform any cleanup you need here
      };
    });

    // Process events until operation completes
    while (true) {
      const emitted = yield take(channel);

      if (!emitted) {
        break;
      }

      switch (emitted.emitType) {
        case 'MessageSent': {
          yield put(messageReceived(emitted.data));
          break;
        }

        case 'FriendUpdate': {
          yield put(getFriendsCreator({ user_id: 'me' }));
          break;
        }

        case 'PageToDoCounter': {
          yield put(socketActions.updatePageTodoCounter(emitted.data));
          break;
        }

        case 'CommunityRequestForCoachCounters': {
          yield put(socketActions.updateCommunityRequestForCoachCounters(emitted.data));
          break;
        }

        case 'CoachAssignToUser': {
          yield put(socketActions.updateTotalNewUsersForCoach(emitted.data));
          break;
        }

        default:
          break;
      }
    }

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Initiating websocket finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Initiating websocketerror',
        state: 'error',
      })
    );
  }
}

function* disconnectWebsocketSaga() {
  yield socketDisconnect();
}

function* getNotificationsCounterSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'get notifications counter start',
      })
    );

    const results = yield call(ApiService.GetNotificationsCounter, data.payload);

    yield put({ type: socketActions.SET_NOTIFICATIONS_COUNTER, payload: results.data.data });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'get notifications counter finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'get notifications counter error',
        state: 'error',
      })
    );
  }
}

function* updatePageTodoCounterSaga(data) {
  if (!data || !data.payload) return;

  const notificationsCounter = yield select(notificationsCounterSelector) || {};
  const todoCounter = notificationsCounter.page_todo;

  if (data.payload.increment) {
    notificationsCounter.page_todo = todoCounter + 1;
  } else {
    notificationsCounter.page_todo = todoCounter - 1;
  }

  yield put({ type: socketActions.SET_NOTIFICATIONS_COUNTER, payload: notificationsCounter });
}

function* updateCommunityRequestForCoachCountersSaga(data) {
  if (!data || !data.payload) return;

  const notificationsCounter = yield select(notificationsCounterSelector) || {};
  const communitiesCounters = Object.values(data.payload);
  let totalCommunitiesCounter = 0;

  communitiesCounters.forEach((communityCounter) => {
    if ((communityCounter || {}).requests_count) {
      totalCommunitiesCounter += communityCounter.requests_count;
    }
  });

  notificationsCounter.communities_requests.by_community = communitiesCounters;
  notificationsCounter.communities_requests.total = totalCommunitiesCounter;

  yield put({ type: socketActions.SET_NOTIFICATIONS_COUNTER, payload: notificationsCounter });
}

function* updateTotalNewUsersForCoachSaga(data) {
  if (!data || !data.payload) return;

  const notificationsCounter = yield select(notificationsCounterSelector) || {};
  const pageAdherents = notificationsCounter.page_adherents || {};
  const newUsersForCoach = data.payload.new_student_id;

  if (newUsersForCoach) {
    (pageAdherents.new || []).push(newUsersForCoach);
    pageAdherents.total += 1;
  }

  if (data.payload.users_ids) {
    const newUsers = pageAdherents.new.filter((user) => !data.payload.users_ids.includes(user));
    pageAdherents.total -= data.payload.users_ids.length;
    pageAdherents.new = newUsers;
    yield call(ApiService.UpdateCoachViewNewAdherents, data.payload);
  }

  yield put({ type: socketActions.SET_NOTIFICATIONS_COUNTER, payload: notificationsCounter });
}

export default function* sagaWatcher() {
  yield takeEvery(socketActions.INITIATE_WEBSOCKET, initiateWebsocketSaga);
  yield takeEvery(socketActions.DISCONNECT_WEBSOCKET, disconnectWebsocketSaga);

  yield takeEvery(socketActions.GET_NOTIFICATIONS_COUNTER, getNotificationsCounterSaga);

  yield takeEvery(socketActions.UPDATE_PAGE_TODO_COUNTER, updatePageTodoCounterSaga);
  yield takeEvery(
    socketActions.UPDATE_COMMUNITY_REQUEST_FOR_COACH_COUNTERS,
    updateCommunityRequestForCoachCountersSaga
  );
  yield takeEvery(socketActions.UPDATE_TOTAL_NEW_USERS_FOR_COACH, updateTotalNewUsersForCoachSaga);
}
