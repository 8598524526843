// @flow
import * as React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import usePrevious from '@shared/hooks/use-previous/usePrevious';
import HeaderContainer from '@containers/HeaderContainer';
import FooterPrivate from 'components/FooterPrivate/index';
import NavBar from '@components/NavBar';
import PromotingBanner from '@components/PromotingBanner';
import { getSeoPages } from '@store/modules/Seo/actions';
import * as promoteAction from '@store/modules/Promote/actions';
import * as prescriberAction from '@store/modules/Prescriber/actions';
import { updateUserLanguage } from '@store/modules/Account/actions';
import { switchLanguage } from '@store/modules/App/actions';
import { getCountries } from '@store/modules/Resource/actions';
import { getCurrentRole } from '@services/roles';
import { getLocale } from '@services/localisation';
import { getLanguageById } from '@shared/i18n/helpers';
import hasLanguageChanged from '@helpers/props-watcher';
import { isUserAllianz } from 'app/user/helpers';

import { ALL_LANGUAGES } from '@core/constants';
import {
  statusRefPrescriptorCode,
  statusRefChangeLanguage,
  statusRefSeoPages,
} from '@layout/constants';

type Props = {
  children: React.Node,
  showNavBar?: boolean,
};

const PrivateLayout = ({ children, showNavBar = true }: Props) => {
  const [unsubscribeEmail, setUnsubscribeEmail] = React.useState(false);
  const [routes] = React.useState({
    routes: [],
    homeRoute: '/',
  });
  const [languageChanged, setLanguageChanged] = React.useState(false);

  const location = useLocation();

  const { seoPagesLoaded } = useSelector((state) => state.Seo);
  const { role, isLoggedIn, user } = useSelector((state) => state.Account);
  const counter = useSelector((state) => state.Notifications);
  const promotion = useSelector((state) => state.Promote);
  const statuses = useSelector((state) => state.Status);

  const prevUser = usePrevious(user);

  const dispatch = useDispatch();

  React.useEffect(() => {
    const { pathname } = location;

    dispatch(getCountries({}));

    if (pathname.includes('/unsubscribe/email')) {
      setUnsubscribeEmail(true);
    }
  }, []);

  React.useEffect(() => {
    dispatch(
      getSeoPages({
        statusRef: statusRefSeoPages,
      })
    );
  }, []);

  React.useEffect(() => {
    if (hasLanguageChanged(user, prevUser) || languageChanged) {
      dispatch(getSeoPages({}));
      setLanguageChanged(false);
    }
  }, [user, prevUser, languageChanged]);

  const isBeneficiariesPerson = () => {
    const { pathname } = location;
    const newPathName = pathname.split('/');
    if (
      (newPathName[1] === 'beneficiaries' && newPathName[2] && newPathName[2].length > 0) ||
      (newPathName[1] === 'task' && newPathName[2] && newPathName[2].length > 0)
    ) {
      return true;
    }
    return false;
  };

  const handleClosePromotingBanner = () => {
    dispatch(promoteAction.hideEcoSantePromotion());
  };

  const handleSubmitPromotingBanner = () => {
    dispatch(
      prescriberAction.linkUserToPrescriberWithCode({
        code: promotion.action.prescriptorProgramCode,
        statusRef: statusRefPrescriptorCode,
      })
    );
  };

  const handleChangeLanguage = (languageId) => {
    dispatch(switchLanguage(getLanguageById(languageId).code || getLocale()));
    if (role) {
      setLanguageChanged(true);
      dispatch(
        updateUserLanguage({
          language_id: languageId,
          statusRef: statusRefChangeLanguage,
        })
      );
    }
  };

  const availableLanguages = isUserAllianz(user) ? [] : ALL_LANGUAGES;

  return (
    <>
      {seoPagesLoaded && (
        <HeaderContainer
          homeRoute={routes.homeRoute}
          user={user}
          className={role || getCurrentRole()}
          unsubscribeEmail={unsubscribeEmail}
          handleCloseUnsubscribeEmail={() => {
            setUnsubscribeEmail(false);
          }}
        />
      )}
      {!isBeneficiariesPerson() && role && showNavBar && (
        <NavBar
          isLoggedIn={isLoggedIn}
          role={role}
          user={user}
          totalNewAdherents={((counter || {}).page_adherents || {}).total}
          totalTodoRequests={(counter || {}).page_todo}
          totalCommunityRequest={((counter || {}).communities_requests || {}).total}
        />
      )}
      <div style={{ minHeight: '70vh' }}>{children}</div>
      {promotion.display && (
        <PromotingBanner
          promotionText={promotion.text}
          promotionActionText={promotion.action.text}
          onClose={handleClosePromotingBanner}
          onSubmit={handleSubmitPromotingBanner}
        />
      )}
      {seoPagesLoaded && (
        <FooterPrivate
          user={user}
          inputLanguageChange={handleChangeLanguage}
          statusChangeLanguage={statuses[statusRefChangeLanguage]}
          availableLanguages={availableLanguages}
        />
      )}
    </>
  );
};

export default PrivateLayout;
