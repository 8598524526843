/* eslint-disable camelcase */
import React, { Component } from 'react';
import ReactModal from 'react-modal';

import { withTranslation } from 'react-i18next';

import { getLocale } from 'services/localisation';

import CONTACT_EMAIL_ADDRESS from 'services/contact';

import FormModal from 'components/FormModal';
import Button from 'components/Button';
import CloseButton from 'components/CloseButton';
import ErrorMessage from 'components/ErrorMessage';

import './style.css';

class ModalDatasConsent extends Component {
  constructor() {
    super();

    this.state = {};

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  }

  handleSubmit() {
    const { handleSubmit } = this.props;
    const { authorize_data } = this.state;

    handleSubmit({
      authorize_data,
    });
  }

  render() {
    const { t, handleCloseModal, status } = this.props;
    const { authorize_data } = this.state;

    return (
      <ReactModal
        className="modal-content modal-datas-consent"
        overlayClassName="modal-overlay"
        isOpen
        onRequestClose={handleCloseModal}
        shouldCloseOnOverlayClick
      >
        <CloseButton onClick={handleCloseModal} />

        <FormModal
          id="register-datas-consent-form"
          className="register-datas-consent transparent"
          onSubmit={this.handleSubmit}
        >
          <h1>{t('ModalRegister.ModalDatasConsent.title')}</h1>

          <p>
            {t('ModalRegister.ModalDatasConsent.text-1')}
            <br />
            <br />
            {t('ModalRegister.ModalDatasConsent.text-2')}
            <br />
            <br />
            {t('ModalRegister.ModalDatasConsent.text-3')}
            <br />
            <br />
            {t('ModalRegister.ModalDatasConsent.text-4', { email: CONTACT_EMAIL_ADDRESS })}
            <br />
            <br />
          </p>

          <div>
            <a
              href={`/pdf/${getLocale()}/privacy_consent.pdf`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('ModalRegister.ModalDatasConsent.show_more')}
            </a>
          </div>

          <div className="row-container check-row">
            <div className="radio-group transparent no-border">
              <div className="radio-field wide no-height">
                <input
                  type="checkbox"
                  id="authorize_data"
                  name="authorize_data"
                  value={authorize_data}
                  onChange={this.handleInputChange}
                  required
                />

                <label htmlFor="authorize_data">{t('ModalRegister.ModalDatasConsent.label')}</label>
              </div>
            </div>
          </div>

          <ErrorMessage status={status} />

          <div className="row-container row-button">
            <Button className="cancel-button" onClick={handleCloseModal}>
              {t('GLOBAL.cancel')}
            </Button>

            <Button
              type="submit"
              className="signup-button"
              status={status}
              disabled={!authorize_data}
            >
              {t('GLOBAL.submit')}
            </Button>
          </div>
        </FormModal>
      </ReactModal>
    );
  }
}

ModalDatasConsent.propTypes = {};

ModalDatasConsent.defaultProps = {};

export default withTranslation()(ModalDatasConsent);
