import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import { getAdminTabs, getCoachTabs, getManagerTabs, getUserTabs } from '@user/helpers';

import { getGetphyExpressVideos } from 'store/modules/GetphyExpress/actions';
import NavItem from './components/NavItem';
import NavExternalItem from './components/NavExternalItem';
import withSlugs from '../../layouts/withSlugs';

import './style.css';

class NavBar extends Component {
  constructor() {
    super();

    this.state = {};

    this.navOptions = this.navOptions.bind(this);
    this.getTotalRequest = this.getTotalRequest.bind(this);
  }

  componentDidMount() {
    if (this.props.isLoggedIn) {
      this.props.dispatch(getGetphyExpressVideos({}));
    }
  }

  navOptions() {
    const { role, user, isLoggedIn, getphyExpress } = this.props;
    switch (role) {
      case 'admin':
        return getAdminTabs();

      case 'manager':
        return getManagerTabs();

      case 'coach':
        return getCoachTabs();

      case 'user':
        return getUserTabs(user, isLoggedIn, getphyExpress);

      default:
        return [];
    }
  }

  getTotalRequest(item) {
    let toReturn;

    switch (item.value) {
      case 'todo':
        toReturn = this.props.totalTodoRequests;
        break;

      case 'courses':
        toReturn = this.props.totalCommunityRequest;
        break;

      case 'communities':
        toReturn = this.props.totalNewAdherents;
        break;

      default:
        toReturn = null;
        break;
    }

    return toReturn;
  }

  render() {
    return (
      <div className="nav-bar">
        {this.props.isLoggedIn &&
          this.navOptions().map((item, index) => {
            if (item.external) {
              // eslint-disable-next-line react/no-array-index-key
              return <NavExternalItem item={item} key={`navExternalItem_${index}`} />;
            }

            return (
              <NavItem
                item={item}
                role={this.props.role}
                // eslint-disable-next-line react/no-array-index-key
                key={`navItem_${index}`}
                totalRequests={this.getTotalRequest(item)}
              />
            );
          })}
      </div>
    );
  }
}

NavBar.propTypes = {
  role: PropTypes.string,
};

NavBar.defaultProps = {
  role: '',
};

const mapStateToProps = (state) => ({
  getphyExpress: state.GetphyExpress.getphy_express,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withSlugs(NavBar)));
