import React, { Component } from 'react';
import './style.css';

class CloseButton extends Component {
  render() {
    return (
      <div className={`close-button ${this.props.className || ''}`} onClick={this.props.onClick}>
        X
      </div>
    );
  }
}

export default CloseButton;
