import React from 'react';
import { withTranslation } from 'react-i18next';

import { Card, CardActions, CardContent, CardHeader, Typography } from '@material-ui/core';
import Button from 'components/Button';
import CloseButton from 'components/CloseButton';

import './style.css';

const baseClass = 'promoting-banner';

const PromotingBanner = ({ t, promotionText, promotionActionText, onClose, onSubmit }) => {
  return (
    <Card className={baseClass}>
      <CardHeader action={<CloseButton onClick={onClose} />} />
      <CardContent>
        <Typography variant="h6" component="p" gutterBottom>
          {t(promotionText)}
        </Typography>
      </CardContent>
      <CardActions>
        <Button className="button--yellow button--bold" onClick={onSubmit}>
          {t(promotionActionText)}
        </Button>
      </CardActions>
    </Card>
  );
};

export default withTranslation()(PromotingBanner);
