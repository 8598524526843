import { takeLatest, call, put, takeEvery, select } from 'redux-saga/effects';

import * as ApiService from 'services/api';
import ProtectedCall from 'services/protected.api';

import { incrementStatusCreator, decrementStatusCreator } from 'store/modules/Status/actions';
import { GET_RESET_OPTIONS_RESULTS } from 'store/modules/SysAdmin/actions';
import { UPDATE_PROFILE as updateProfileCache } from 'store/modules/Account/actions';
import * as userActions from 'store/modules/User/actions';

/** ********************************************** */
/** Selectors * */
const allUsersSelector = (state) => {
  return JSON.parse(JSON.stringify(state.User.users));
};

/** ********************************************** */
/** Sagas * */
function* getUserPaymentsSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Get User Payments' })
    );

    const result = yield ProtectedCall(ApiService.GetUserPayments, data.payload.user_id);
    yield put({ type: userActions.GET_USER_PAYMENTS_RESULTS, payload: result.data.data });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Get User Payments finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Get User Payments error',
        state: 'error',
      })
    );
  }
}

function* userSearchSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'User search' })
    );

    const result = yield ProtectedCall(ApiService.UserSearch, data.payload);
    yield put({ type: userActions.USER_SEARCH_RESULTS, payload: result.data.data });

    yield put(
      decrementStatusCreator({ statusRef: data.payload.statusRef, message: 'User search finished' })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'User search error',
        state: 'error',
      })
    );
  }
}

function* getUserByIdSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Getting user by id' })
    );

    const result = yield ProtectedCall(ApiService.GetUser, data.payload.user_id);

    yield put({ type: userActions.UPDATE_CACHE, payload: [result.data.data.user] });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting user by id finished',
        data: result.data.data.user,
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting user by id error',
        state: 'error',
      })
    );
  }
}

function* deleteUserByIdSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Deleting user by id' })
    );

    yield ProtectedCall(ApiService.DeleteUser, data.payload.userId, data.payload);

    // TODO: SHOULD REMOVE USER FROM STORE ???

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Deleting user by id finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Deleting user by id error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* updateUserByIdSaga(data) {
  const { callback = { success: () => null } } = data.payload;
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Updating user by id' })
    );

    const result = yield ProtectedCall(ApiService.UpdateUser, data.payload.id, data.payload);

    yield put({ type: userActions.UPDATE_CACHE, payload: [result.data.data.user] });
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Updating user by id finished',
      })
    );
    callback.success();
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Updating user by id error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* updateUsersSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Updating users' })
    );

    let { users } = data.payload.form;
    users = users.map((user) => {
      return { id: user.value };
    });
    data.payload.form.users = users;

    const result = yield ProtectedCall(ApiService.UpdateUsers, data.payload.form);

    yield put({ type: userActions.UPDATE_CACHE, payload: [result.data.data.users] });
    yield put({ type: GET_RESET_OPTIONS_RESULTS, payload: [] });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Updating user by id finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Updating user by id error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* getManagerSectorsSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting sectors of a manager',
      })
    );

    const result = yield ProtectedCall(ApiService.getSectorsFromUser, data.payload);
    yield put({ type: userActions.UPDATE_CACHE_SECTORS, payload: result.data.data });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting sectors of a manager finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting sectors of a manager error',
        state: 'error',
      })
    );
  }
}

function* getUserCoachSaga(data) {
  try {
    if (!data || !data.payload || !data.payload.user_id) return;

    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Getting user coach' })
    );

    const result = yield ProtectedCall(ApiService.GetUserCoach, data.payload.user_id);

    const users = yield select(allUsersSelector) || {};
    const user = users[data.payload.user_id] || { id: data.payload.user_id };

    user.coaches = result.data.data.coaches;

    yield put({ type: userActions.UPDATE_CACHE, payload: [user] });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting user coach finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting user coach error',
        state: 'error',
      })
    );
  }
}

function* getAllRolesSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Gettting all roles' })
    );

    const result = yield ProtectedCall(ApiService.RoleGetAll);

    yield put({ type: userActions.UPDATE_CACHE_ROLES, payload: result.data.data.roles });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Gettting all roles finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Gettting all roles error',
        state: 'error',
      })
    );
  }
}

function* assignRoleToUserSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Assigning role to user',
      })
    );

    yield ProtectedCall(ApiService.AddUserRole, data.payload.id, { role_id: data.payload.role_id });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Assigning role to user finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Assigning role to user error',
        state: 'error',
      })
    );
  }
}

function* assignRolesToUserSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Assigning role to user',
      })
    );

    yield ProtectedCall(ApiService.AddUserRoles, data.payload.id, { roles: data.payload.roles });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Assigning role to user finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Assigning role to user error',
        state: 'error',
      })
    );
  }
}

function* getUserJourneySaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Gettting user journey',
      })
    );

    const result = yield ProtectedCall(
      ApiService.GetUserJourney,
      data.payload.userId,
      data.payload
    );

    yield put({ type: userActions.UPDATE_USER_JOURNEY, payload: result.data.data });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Gettting user journey finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Gettting user journey error',
        state: 'error',
      })
    );
  }
}

function* getUserWeekleReportSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Gettting user weekly report',
      })
    );

    const result = yield ProtectedCall(
      ApiService.GetUserWeeklyReport,
      data.payload.user_id,
      data.payload
    );

    yield put({ type: userActions.UPDATE_USER_WEEKLY_REPORT, payload: result.data.data });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Gettting user weekly report finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Gettting user weekly report error',
        state: 'error',
      })
    );
  }
}

function* fillUserWeekleReportSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Submitting user weekly report',
      })
    );

    const result = yield ProtectedCall(ApiService.FillUserWeeklyReport, data.payload);

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Submitting user weekly report finished',
        data: { result: result.data.data },
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Submitting user weekly report error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* setUserAvatarSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Setting user avatar' })
    );

    const { userId } = data.payload;
    const imageData = {
      filename: `avatar_${userId}.png`,
      imageData: data.payload.dataUrl,
    };

    yield ProtectedCall(ApiService.setUserAvatar, userId, imageData);

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Setting user avatar finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Setting user avatar error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* mesAmisSearchSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Mes amis search' })
    );

    const results = yield ProtectedCall(ApiService.MesAmisSearch, data.payload);

    yield put({ type: userActions.UPDATE_MES_AMIS_SEARCH_RESULTS, payload: results.data.data });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Mes amis search finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Mes amis search error',
        state: 'error',
      })
    );
  }
}

function* sendFriendInviteSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Sending friend invite',
      })
    );

    // TODO: Update state ?
    yield ProtectedCall(ApiService.SendFriendInvite, data.payload.user_id, {
      target_id: data.payload.target_id,
    });

    // yield put({type: UPDATE_MES_AMIS_SEARCH_RESULTS, payload: results.data.data});

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Sending friend invite finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Sending friend invite error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* refuseFriendInviteSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Refusing friend invite',
      })
    );

    // TODO: Update state ?
    yield ProtectedCall(ApiService.RefuseFriendInvite, data.payload.user_id, {
      sender_id: data.payload.sender_id,
    });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Refusing friend invite finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Refusing friend invite error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* acceptFriendInviteSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Accepting friend invite',
      })
    );

    // TODO: Update state ?
    yield ProtectedCall(ApiService.AcceptFriendInvite, data.payload.user_id, {
      sender_id: data.payload.sender_id,
    });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Accepting friend invite finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Accepting friend invite error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* removeFriendSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Removing friend' })
    );

    // TODO: Update state ?
    const results = yield ProtectedCall(
      ApiService.RemoveFriend,
      data.payload.user_id,
      data.payload.friend_id
    );

    yield put({ type: userActions.UPDATE_CACHE, payload: [results.data.user] });
    // yield put({type: UPDATE_MES_AMIS_SEARCH_RESULTS, payload: results.data.data});allFriendsSelector
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Removing friendfinished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Removing friend error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* getFriendsSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Getting friends' })
    );

    const results = yield ProtectedCall(ApiService.GetFriends, data.payload.user_id);

    yield put({ type: userActions.UPDATE_CACHE, payload: results.data.data.friends });
    yield put({ type: userActions.UPDATE_FRIENDS_RESULTS, payload: results.data.data.friends });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting friends finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting friends error',
        state: 'error',
      })
    );
  }
}

function* getFriendSuggestionsSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting friend suggestions',
      })
    );

    const results = yield ProtectedCall(ApiService.GetFriendSuggestions, data.payload);

    yield put({ type: userActions.UPDATE_CACHE, payload: results.data.data.suggestions });
    yield put({
      type: userActions.UPDATE_MES_AMIS_SEARCH_RESULTS,
      payload: { non_friends: results.data.data.suggestions },
    });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting friend suggestions finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting friend suggestions error',
        state: 'error',
      })
    );
  }
}

function* getGroupSuggestionsSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting group suggestions',
      })
    );

    const results = yield ProtectedCall(ApiService.GetGroupSuggestions, data.payload);

    yield put({ type: userActions.UPDATE_CACHE, payload: results.data.data.suggestions });
    yield put({
      type: userActions.UPDATE_MES_AMIS_SEARCH_RESULTS,
      payload: { non_groups: results.data.data.suggestions },
    });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting group suggestions finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting group suggestions error',
        state: 'error',
      })
    );
  }
}

function* sendEmailInviteSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Sending email invite' })
    );

    yield ProtectedCall(ApiService.SendEmailInvite, data.payload.user_id, data.payload);

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Sending email invite finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Sending email invite error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* submitPrimaryQuestionnaireSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Submitting primary questionnaire',
      })
    );

    const result = yield ProtectedCall(ApiService.SubmitPrimaryQuestionnaire, data.payload.params);

    const user = yield ProtectedCall(ApiService.GetUser, 'me');
    yield put({ type: updateProfileCache, payload: user.data.data.user });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Submitting primary questionnaire finished',
        data: { results: result.data.data },
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Submitting primary questionnaire error',
        state: 'error',
      })
    );
  }
}

function* getPrimaryQuestionnaireSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Get primary questionnaire',
      })
    );

    const result = yield ProtectedCall(ApiService.GetPrimaryQuestionnaire);

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Get primary questionnaire finished',
        data: { results: result.data.data },
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Get primary questionnaire error',
        state: 'error',
      })
    );
  }
}

function* saveDeclarationPrimaryQuestionnaireSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Save declaration primary questionnaire',
      })
    );

    const result = yield ProtectedCall(
      ApiService.SaveDeclarationPrimaryQuestionnaire,
      data.payload.userId,
      data.payload
    );

    yield put({ type: userActions.UPDATE_CACHE, payload: [result.data.data.user] });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Save declaration primary questionnaire finished',
        data: { results: result.data.data },
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Save declaration primary questionnaire error',
        state: 'error',
      })
    );
  }
}

function* changeQuestionnaireStateSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Changing questionnaire state',
      })
    );

    const results = yield ProtectedCall(
      ApiService.ChangeQuestionnaireState,
      data.payload.userId,
      data.payload.params
    );

    yield put({ type: userActions.UPDATE_CACHE, payload: [results.data.data.user] });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Changing questionnaire state finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Changing questionnaire state error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* bilansInfoSubmitSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Submitting Info Bilans',
      })
    );

    const results = yield ProtectedCall(
      ApiService.BilansInfoSubmit,
      data.payload.userId,
      data.payload
    );

    const allUsers = yield select(allUsersSelector) || {};

    const user = allUsers[data.payload.userId] || {};

    user.infoQuestionnaireData = results.data.data.information_questionnaires;

    yield put({ type: userActions.UPDATE_CACHE, payload: [user] });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Submitting Info Bilans finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Submitting Info Bilans error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* bilansInfoGetSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Getting Info Bilans' })
    );

    const results = yield ProtectedCall(
      ApiService.BilansInfoGet,
      data.payload.userId,
      data.payload
    );

    const allUsers = yield select(allUsersSelector) || {};

    const user = allUsers[data.payload.userId] || {};

    user.infoQuestionnaireData = results.data.data.information_questionnaire;

    yield put({ type: userActions.UPDATE_CACHE, payload: [user] });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting Info Bilans finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting Info Bilans error',
        state: 'error',
      })
    );
  }
}

function* bilansInitialSubmitSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Submitting initial Bilans',
      })
    );

    yield ProtectedCall(ApiService.BilansInitialSubmit, data.payload.userId, data.payload);

    // yield put({type: UPDATE_CACHE, payload: [results.data.data.user]});

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Submitting initial Bilans finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Submitting initial Bilans error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* bilansInitialGetSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting initial Bilans',
      })
    );

    const results = yield ProtectedCall(
      ApiService.BilansInitialGet,
      data.payload.userId,
      data.payload
    );

    const allUsers = yield select(allUsersSelector) || {};

    const user = allUsers[data.payload.userId] || {};

    user.initialQuestionnaireData = results.data.data.initial_balance_sheets;

    yield put({ type: userActions.UPDATE_CACHE, payload: [user] });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting initial Bilans finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting initial Bilans error',
        state: 'error',
      })
    );
  }
}

function* bilansInitialCommentSubmitSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Submiting initial Bilans comment',
      })
    );

    yield ProtectedCall(
      ApiService.BilansInitialSubmitComment,
      data.payload.initialId,
      data.payload
    );

    yield put(userActions.bilansInitialGet({ userId: data.payload.userId }));

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Submiting initial Bilans comment finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Submiting initial Bilans comment error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* bilansIntermediateSubmitSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Submitting Intermedaite Bilans',
      })
    );

    yield ProtectedCall(ApiService.BilansIntermediateSubmit, data.payload.userId, data.payload);

    // yield put({type: UPDATE_CACHE, payload: [results.data.data.user]});

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Submitting Intermedaite Bilans finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Submitting Intermedaite Bilans error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* bilansIntermediateGetSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting Intermediate Bilans',
      })
    );

    const results = yield ProtectedCall(
      ApiService.BilansIntermediateGet,
      data.payload.userId,
      data.payload
    );

    const allUsers = yield select(allUsersSelector) || {};

    const user = allUsers[data.payload.userId] || {};

    user.intermediateQuestionnaireData = results.data.data.intermediary_balance_sheets;

    yield put({ type: userActions.UPDATE_CACHE, payload: [user] });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting Intermediate Bilans finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting Intermediate Bilans error',
        state: 'error',
      })
    );
  }
}

function* bilansFinalSubmitSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Submitting Final Bilans',
      })
    );

    yield ProtectedCall(ApiService.BilansFinalSubmit, data.payload.userId, data.payload);

    // yield put({type: UPDATE_CACHE, payload: [results.data.data.user]});

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Submitting Final Bilans finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Submitting Final Bilans error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* bilansFinalGetSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Getting Final Bilans' })
    );

    const results = yield ProtectedCall(
      ApiService.BilansFinalGet,
      data.payload.userId,
      data.payload
    );

    const allUsers = yield select(allUsersSelector) || {};

    const user = allUsers[data.payload.userId] || {};

    user.finalQuestionnaireData = results.data.data.final_balance_sheets;

    yield put({ type: userActions.UPDATE_CACHE, payload: [user] });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting Final Bilans finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting Final Bilans error',
        state: 'error',
      })
    );
  }
}

function* bilansSatisfactionSubmitSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Submitting Bilans satisfaction',
      })
    );

    const results = yield ProtectedCall(
      ApiService.BilansSatisfactionSubmit,
      data.payload.userId,
      data.payload
    );

    const allUsers = yield select(allUsersSelector) || {};

    const user = allUsers[data.payload.userId] || {};

    user.satisfaction_questionnaire = results.data.data.satisfaction_questionnaire;

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Submitting Bilans satisfaction finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Submitting Bilans satisfaction error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* bilansSatisfactionGetSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting Bilans satisfaction',
      })
    );

    const results = yield ProtectedCall(
      ApiService.BilansSatisfactionGet,
      data.payload.userId,
      data.payload
    );

    const allUsers = yield select(allUsersSelector) || {};

    const user = allUsers[data.payload.userId] || {};

    user.satisfactionData = results.data.data.satisfaction_questionnaires;

    yield put({ type: userActions.UPDATE_CACHE, payload: [user] });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting Bilans satisfaction finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting Bilans satisfaction error',
        state: 'error',
      })
    );
  }
}

function* bilansAnalysisGetResourcesSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting Bilans analysis resources',
      })
    );

    const results = yield ProtectedCall(
      ApiService.BilansAnalysisGetResources,
      data.payload.userId,
      data.payload
    );

    const allUsers = yield select(allUsersSelector) || {};
    const user = allUsers[data.payload.userId] || {};

    user.bilansAnalysisResources = results.data.data;

    yield put({ type: userActions.UPDATE_CACHE, payload: [user] });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting Bilans analysis resources finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting Bilans analysis resources error',
        state: 'error',
      })
    );
  }
}

function* bilansAnalysisGetSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting Bilans analysis',
      })
    );

    const results = yield ProtectedCall(
      ApiService.BilansAnalysisGet,
      data.payload.userId,
      data.payload
    );

    const allUsers = yield select(allUsersSelector) || {};

    const user = allUsers[data.payload.userId] || {};

    const questionnaireAnalyses = {
      initial: ((results.data.data || {}).initial || []).reverse(),
      intermediary: ((results.data.data || {}).intermediary || []).reverse(),
      final: ((results.data.data || {}).final || []).reverse(),
    };

    user.questionnaireAnalyses = questionnaireAnalyses;

    yield put({ type: userActions.UPDATE_CACHE, payload: [user] });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting Bilans analysis finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting Bilans analysis error',
        state: 'error',
      })
    );
  }
}

function* bilansAnalysisCreateSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Create Bilans analysis',
      })
    );

    yield ProtectedCall(ApiService.BilansAnalysisCreate, data.payload.userId, data.payload);

    yield put(userActions.bilansAnalysisGet({ userId: data.payload.userId }));

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Create Bilans analysis finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Create Bilans analysis error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* exportUsersSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Exporting users' })
    );

    yield ProtectedCall(ApiService.ExportUsers, data.payload);

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Exporting users finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Exporting users error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* sendGetphyInvitationsSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Send getphy mail invitation',
      })
    );

    yield call(ApiService.SendGetphyInvitations, data.payload);

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Send getphy mail invitation finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Send getphy mail invitation error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* createUserSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Create user' })
    );

    yield ProtectedCall(ApiService.CreateUser, data.payload);

    yield put(
      decrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Create user finished' })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Create user error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* updateCacheSaga(data) {
  if (!data || !data.payload || data.payload.length === 0) {
    return;
  }

  const users = yield select(allUsersSelector) || {};

  data.payload.map((item) => {
    users[item.id] = item;

    return null;
  });

  yield put({ type: userActions.UPDATE_CACHE_SET, payload: users });
}

export default function* sagaWatcher() {
  yield takeEvery(userActions.GET_USER_PAYMENTS, getUserPaymentsSaga);

  yield takeLatest(userActions.USER_SEARCH, userSearchSaga);
  yield takeEvery(userActions.GET_USER_BY_ID, getUserByIdSaga);
  yield takeEvery(userActions.DELETE_USER_BY_ID, deleteUserByIdSaga);
  yield takeEvery(userActions.UPDATE_USER_BY_ID, updateUserByIdSaga);
  yield takeEvery(userActions.UPDATE_USERS, updateUsersSaga);

  yield takeEvery(userActions.GET_MANAGER_SECTORS, getManagerSectorsSaga);

  yield takeEvery(userActions.GET_USER_COACH, getUserCoachSaga);
  yield takeLatest(userActions.SET_USER_AVATAR_REQUEST, setUserAvatarSaga);
  yield takeLatest(userActions.MES_AMIS_SEARCH, mesAmisSearchSaga);
  yield takeLatest(userActions.SEND_FRIEND_INVITE, sendFriendInviteSaga);
  yield takeEvery(userActions.REFUSE_FRIEND_INVITE, refuseFriendInviteSaga);
  yield takeEvery(userActions.ACCEPT_FRIEND_INVITE, acceptFriendInviteSaga);
  yield takeEvery(userActions.REMOVE_FRIEND, removeFriendSaga);
  yield takeEvery(userActions.SEND_EMAIL_INVITE, sendEmailInviteSaga);
  yield takeLatest(userActions.GET_ALL_ROLES, getAllRolesSaga);
  yield takeLatest(userActions.ADD_USER_ROLE, assignRoleToUserSaga);
  yield takeLatest(userActions.ADD_USER_ROLES, assignRolesToUserSaga);
  yield takeEvery(userActions.GET_USER_JOURNEY, getUserJourneySaga);
  yield takeEvery(userActions.GET_USER_WEEKLY_REPORT, getUserWeekleReportSaga);
  yield takeEvery(userActions.FILL_USER_WEEKLY_REPORT, fillUserWeekleReportSaga);

  yield takeEvery(userActions.GET_FRIENDS, getFriendsSaga);
  yield takeEvery(userActions.GET_FRIEND_SUGGESTIONS, getFriendSuggestionsSaga);
  yield takeEvery(userActions.GET_GROUP_SUGGESTIONS, getGroupSuggestionsSaga);

  yield takeEvery(userActions.SUBMIT_PRIMARY_QUESTIONNAIRE, submitPrimaryQuestionnaireSaga);
  yield takeEvery(userActions.GET_PRIMARY_QUESTIONNAIRE, getPrimaryQuestionnaireSaga);
  yield takeEvery(
    userActions.SAVE_DECLARATION_PRIMARY_QUESTIONNAIRE,
    saveDeclarationPrimaryQuestionnaireSaga
  );

  yield takeEvery(userActions.CHANGE_QUESTIONNAIRE_STATE, changeQuestionnaireStateSaga);

  yield takeEvery(userActions.BILANS_INFO_SUBMIT, bilansInfoSubmitSaga);
  yield takeEvery(userActions.BILANS_INFO_GET, bilansInfoGetSaga);

  yield takeEvery(userActions.BILANS_INITIAL_SUBMIT, bilansInitialSubmitSaga);
  yield takeEvery(userActions.BILANS_INITIAL_GET, bilansInitialGetSaga);
  yield takeEvery(userActions.BILANS_INITIAL_SUBMIT_COMMENT, bilansInitialCommentSubmitSaga);

  yield takeEvery(userActions.BILANS_INTERMEDIATE_SUBMIT, bilansIntermediateSubmitSaga);
  yield takeEvery(userActions.BILANS_INTERMEDIATE_GET, bilansIntermediateGetSaga);

  yield takeEvery(userActions.BILANS_FINAL_SUBMIT, bilansFinalSubmitSaga);
  yield takeEvery(userActions.BILANS_FINAL_GET, bilansFinalGetSaga);

  yield takeEvery(userActions.BILANS_SATISFACTION_SUBMIT, bilansSatisfactionSubmitSaga);
  yield takeEvery(userActions.BILANS_SATISFACTION_GET, bilansSatisfactionGetSaga);

  yield takeEvery(userActions.BILANS_ANALYSIS_GET_RESOURCES, bilansAnalysisGetResourcesSaga);
  yield takeEvery(userActions.BILANS_ANALYSIS_GET, bilansAnalysisGetSaga);
  yield takeEvery(userActions.BILANS_ANALYSIS_CREATE, bilansAnalysisCreateSaga);

  yield takeEvery(userActions.EXPORT_USERS, exportUsersSaga);

  yield takeEvery(userActions.CREATE_USER, createUserSaga);

  yield takeEvery(userActions.SEND_GETPHY_INVITATIONS, sendGetphyInvitationsSaga);

  yield takeEvery(userActions.UPDATE_CACHE, updateCacheSaga);
}
