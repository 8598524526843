import { takeLatest, put, select, takeEvery } from 'redux-saga/effects';
import * as ApiService from 'services/api';
import ProtectedCall from 'services/protected.api';
import { incrementStatusCreator, decrementStatusCreator } from 'store/modules/Status/actions';
import { UPDATE_CACHE as updateUserCache } from 'store/modules/User/actions';
import * as coachActions from 'store/modules/Coach/actions';
// import ModalData from './data'
/** ********************************************** */
/** Selectors * */
const allCoachesSelector = (state) => {
  return JSON.parse(JSON.stringify(state.Coach.coaches));
};
/** ********************************************** */
function* coachRequestSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Requesting coach',
      })
    );
    // TODO: update store?
    // TODO: Hard coded id is VERY bad. Find a better way
    yield ProtectedCall(ApiService.CoachRequestSubmit, data.payload.userId, data.payload);
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Requesting coach finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Requesting coach error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}
function* getAllCoachesSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Gettting all coaches',
      })
    );
    const result = yield ProtectedCall(ApiService.CoachGetAll, data.payload);
    yield put({
      type: coachActions.UPDATE_CACHE,
      payload: result.data.data.coaches,
    });
    yield put({
      type: coachActions.COACH_SEARCH_RESULTS,
      payload: result.data.data,
    });
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Gettting all coaches finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Gettting all coaches error',
        state: 'error',
      })
    );
  }
}
function* assignCoachToUserSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Assigning coach to user',
      })
    );
    yield ProtectedCall(ApiService.AssignCoachToUser, data.payload.user_id, {
      coach_id: data.payload.coach_id,
      call_id: data.payload.call_id,
    });
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Assigning coach to user finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Assigning coach to user error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}
function* getAdherentsSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting adherents',
      })
    );
    const result = yield ProtectedCall(ApiService.GetAdherents, data.payload.coach_id, {
      search: data.payload.search,
    });
    yield put({
      type: coachActions.MY_ADHERENTS_SET,
      payload: result.data.data.users || [],
    });
    yield put({ type: updateUserCache, payload: result.data.data.users || [] });
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting adherents finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting adherents error',
        state: 'error',
      })
    );
  }
}
function* updateCacheSaga(data) {
  if (!data || !data.payload || data.payload.length === 0) {
    return;
  }
  const coaches = yield select(allCoachesSelector) || {};
  data.payload.map((item) => {
    coaches[item.id] = item;
    return null;
  });
  yield put({ type: coachActions.UPDATE_CACHE_SET, payload: coaches });
}
function* getCoachesUsersSaga(data) {
  const result = yield ProtectedCall(ApiService.GetCoachesUsers, data.payload);
  yield put({
    type: coachActions.COACHES_USERS_SET,
    payload: result.data.data,
  });
}
function* getCoachesUsersMinSaga(data) {
  const result = yield ProtectedCall(ApiService.GetCoachesUsersMin, data.payload);
  yield put({
    type: coachActions.COACHES_USERS_MIN_SET,
    payload: result.data.data.users,
  });
}
function* getCoachesUpcomingTaskSaga(data) {
  const result = yield ProtectedCall(ApiService.GetCoachesUpcomingTask, data.payload);
  yield put({
    type: coachActions.COACHES_UPCOMING_TASK_SET,
    payload: result.data.data.tasks,
  });
}

function* getCoachDetailSaga(data) {
  const result = yield ProtectedCall(ApiService.GetCoachDetail, data.payload);
  yield put({
    type: coachActions.COACH_DETAIL_SET,
    payload: result.data.data.user,
  });
}

function* getCoachTableTaskSaga(data) {
  const result = yield ProtectedCall(ApiService.GetCoachTableTask, data.payload);
  yield put({
    type: coachActions.COACH_TABLE_TASK_SET,
    payload: result.data.data.tasks,
  });
}

function* getCoachAssesmentTaskSaga(data) {
  const result = yield ProtectedCall(ApiService.GetCoachAssesmentTask, data.payload);
  yield put({
    type: coachActions.COACH_ASSESMENT_TASK_SET,
    payload: result.data.data.task,
  });
}

function* UpdateCoachAssesmentTaskSaga(data) {
  yield ProtectedCall(ApiService.UpdateCoachAssesmentTask, data.taskId, data.payload);
  yield put({
    type: coachActions.SET_SUCCESS_UPDATE_COACH_ASSESMENT_TASK,
  });
}

function* getCoachAssesmentCallbackSaga(data) {
  const result = yield ProtectedCall(ApiService.GetCoachAssesmentCallback, data.payload);
  yield put({
    type: coachActions.COACH_ASSESMENT_CALLBACK_SET,
    payload: result.data.data.call,
  });
}

function* getCoachCalendarDataSaga(data) {
  const result = yield ProtectedCall(ApiService.GetCoachCalendarDate, data.payload, data.data);
  yield put({
    type: coachActions.SET_CAOCH_CALENDAR_DATA,
    payload: result.data.data,
  });
}

function* getCoachSessionUserSaga(data) {
  const result = yield ProtectedCall(ApiService.GetCoachSessionUser, data.payload);
  yield put({
    type: coachActions.SET_COACH_SESSION_USER,
    payload: result.data.data.session_users,
  });
}

function* ChangeCoachAssesmentTaskDateSaga(data) {
  yield ProtectedCall(ApiService.ChangeCoachAssesmentTaskDate, data.taskId, data.payload);
  yield put({
    type: coachActions.SET_SUCCESS_CHANGE_COACH_ASSESMENT_TASK_DATE,
  });
}

function* getCoachNotesSaga(data) {
  const result = yield ProtectedCall(ApiService.GetCoachNotes, data.payload);
  yield put({
    type: coachActions.SET_COACH_NOTES,
    payload: result.data.data.notes,
  });
}

function* getPhysicalEvolutionTeskSaga(data) {
  const result = yield ProtectedCall(ApiService.GetPhysicalEvolutionTest, data.payload);
  yield put({
    type: coachActions.SET_PHYSICAL_EVOLUTION_TEST,
    payload: result.data.data,
  });
}

function* putPhysicalEvolutionTeskSaga(data) {
  const result = yield ProtectedCall(
    ApiService.PutPhysicalEvolutionTest,
    data.payload,
    data.answers
  );
  yield put({
    type: coachActions.UPDATE_PHYSICAL_EVOLUTION_TEST,
    payload: result.data.data,
  });
}

function* addUserSessionSaga(data) {
  const result = yield ProtectedCall(
    ApiService.AddUserSession,
    data.userId,
    data.sessionId,
    data.start_date
  );
  yield put({
    type: coachActions.RESULT_ADD_USER_SESSION,
    payload: result.data.message,
  });
  yield put({
    type: coachActions.GET_COACH_CALENDAR_DATA,
    payload: data.userId,
  });
}

function* addUserProgramSaga(data) {
  const result = yield ProtectedCall(
    ApiService.AddUserProgram,
    data.userId,
    data.programId,
    data.start_date
  );
  yield put({
    type: coachActions.RESULT_ADD_USER_PROGRAM,
    payload: result.data.message,
  });
  yield put({
    type: coachActions.GET_COACH_CALENDAR_DATA,
    payload: data.userId,
  });
}

function* getProgramUserSaga(data) {
  const result = yield ProtectedCall(ApiService.GetProgramUser, data.payload);
  yield put({
    type: coachActions.SET_PROGRAM_USER,
    payload: result.data.data.programs,
  });
}

function* getSessionUserSaga(data) {
  const result = yield ProtectedCall(ApiService.GetSessionsUser, data.payload);
  yield put({
    type: coachActions.SET_SESSIONS_USER,
    payload: result.data.data.sessions,
  });
}

/** Sagas * */
export default function* sagaWatcher() {
  yield takeLatest(coachActions.COACH_REQUEST, coachRequestSaga);
  yield takeLatest(coachActions.GET_ALL_COACHES, getAllCoachesSaga);
  yield takeLatest(coachActions.ASSIGN_COACH_TO_USER, assignCoachToUserSaga);
  yield takeEvery(coachActions.GET_ADHERENTS, getAdherentsSaga);
  yield takeEvery(coachActions.UPDATE_CACHE, updateCacheSaga);
  yield takeLatest(coachActions.GET_COACHES_USERS, getCoachesUsersSaga);
  yield takeLatest(coachActions.GET_COACHES_USERS_MIN, getCoachesUsersMinSaga);
  yield takeLatest(coachActions.GET_COACHES_UPCOMING_TASK, getCoachesUpcomingTaskSaga);
  yield takeLatest(coachActions.GET_COACH_TABLE_TASK, getCoachTableTaskSaga);
  yield takeLatest(coachActions.GET_COACH_DETAIL, getCoachDetailSaga);
  yield takeLatest(coachActions.GET_COACH_ASSESMENT_TASK, getCoachAssesmentTaskSaga);
  yield takeLatest(coachActions.UPDATE_COACH_ASSESMENT_TASK, UpdateCoachAssesmentTaskSaga);
  yield takeLatest(coachActions.GET_COACH_ASSESMENT_CALLBACK, getCoachAssesmentCallbackSaga);
  yield takeLatest(coachActions.GET_COACH_CALENDAR_DATA, getCoachCalendarDataSaga);
  yield takeLatest(coachActions.GET_COACH_SESSION_USER, getCoachSessionUserSaga);
  yield takeLatest(coachActions.GET_COACH_NOTES, getCoachNotesSaga);
  yield takeLatest(coachActions.CHANGE_COACH_ASSESMENT_TASK_DATE, ChangeCoachAssesmentTaskDateSaga);
  yield takeLatest(coachActions.GET_PHYSICAL_EVOLUTION_TEST, getPhysicalEvolutionTeskSaga);
  yield takeLatest(coachActions.PUT_PHYSICAL_EVOLUTION_TEST, putPhysicalEvolutionTeskSaga);
  yield takeLatest(coachActions.ADD_USER_SESSION, addUserSessionSaga);
  yield takeLatest(coachActions.ADD_USER_PROGRAM, addUserProgramSaga);
  yield takeLatest(coachActions.GET_PROGRAM_USER, getProgramUserSaga);
  yield takeLatest(coachActions.GET_SESSIONS_USER, getSessionUserSaga);
}
