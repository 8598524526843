import { put, select, takeEvery } from 'redux-saga/effects';

import * as statusActions from 'store/modules/Status/actions';

/** ********************************************** */
/** Selectors * */
const allStatusesSelector = (state) => {
  return JSON.parse(JSON.stringify(state.Status.statuses));
};

/** ********************************************** */
/** Sagas * */
function* setStatusesSaga(data) {
  if (!data || !data.payload || data.payload.length === 0) {
    return;
  }

  if (!data.payload.statusRef) {
    return;
  }

  const statuses = yield select(allStatusesSelector) || [];

  let status = statuses[data.payload.statusRef];

  if (!status) {
    status = {
      statusRef: data.payload.statusRef,
      count: 0,
    };
  }

  status.message = data.payload.message || '';

  if (data.type === statusActions.INCREMENT) {
    status.count += 1;
  }

  if (data.type === statusActions.DECREMENT) {
    status.count -= 1;
  }

  if (status.count > 0) {
    status.state = data.payload.state || 'busy';
  }

  if (status.count <= 0) {
    status.state = data.payload.state || 'finished';
  }

  status.data = data.payload.data;

  statuses[data.payload.statusRef] = status;

  yield put({ type: statusActions.SET_STATUSES, payload: statuses });
}

function* clearStatusSaga(data) {
  if (!data.payload.statusRef) {
    return;
  }

  const statuses = yield select(allStatusesSelector) || [];

  // statuses.splice(data.payload.statusRef, 1);
  delete statuses[data.payload.statusRef];

  yield put({ type: statusActions.SET_STATUSES, payload: statuses });
}

export default function* sagaWatcher() {
  yield takeEvery(statusActions.INCREMENT, setStatusesSaga);
  yield takeEvery(statusActions.DECREMENT, setStatusesSaga);
  yield takeEvery(statusActions.CLEAR, clearStatusSaga);
}
