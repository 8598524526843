/* eslint-disable no-underscore-dangle */
import { takeLatest, call, put, take, cancelled } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';

import * as ApiService from 'services/api';

import { APP_INIT } from 'store/modules/Account/actions';
import * as notificationActions from 'store/modules/Notification/actions';

/** ********************************************** */
/** Selectors * */

/** ********************************************** */
/** Sagas * */
function countdown(secs) {
  return eventChannel((emitter) => {
    const iv = setInterval(() => {
      emitter(secs);
    }, 1000 * 60 * 15);
    return () => {
      clearInterval(iv);
    };
  });
}

function* infiniteLoop() {
  const chan = yield call(countdown, 50);

  try {
    while (true) {
      yield take(chan);
      const version = window._build_hash || '';
      let { data } = yield call(ApiService.GetAppVersion);
      data = data.toString();

      if (data && version && data.trim() !== version.trim()) {
        yield put(notificationActions.openNewVersionPopup());
        chan.close();
        break;
      }
    }
  } finally {
    if (yield cancelled()) {
      chan.close();
    }
  }
}

export default function* sagaWatcher() {
  yield takeLatest(APP_INIT, infiniteLoop);
}
