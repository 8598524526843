import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { publicTheme } from '@layout/theme';
import { MAIN_TEXT_COLOR } from '@shared/constants';

const useStyles = makeStyles(() => ({
  header: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginBottom: '3rem',
    [publicTheme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  title: {
    color: MAIN_TEXT_COLOR,
    margin: 'auto 0',
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '2rem',
    [publicTheme.breakpoints.up('sm')]: {
      marginTop: '0',
    },
    [publicTheme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  edit: {
    marginBottom: '1rem',
    '&:hover': {
      filter: 'brightness(95%)',
    },
    [publicTheme.breakpoints.up('md')]: {
      marginBottom: '0',
      marginRight: '1rem',
    },
  },
}));

const GroupClassInformationHeader = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.header}>
      <Typography variant="h4" className={classes.title}>
          {t(`GroupClasses.communityInformation.title`)}
      </Typography>

      <div className={classes.actions}>
        <Button
          key={`group-class-information-edit`}
          variant="contained"
          lg="large"
          style={publicTheme.palette.accentuated}
          className={classes.edit}
          onClick={() => {}}
        >
          <Typography variant="h6">
            {t(`GroupClasses.communityInformation.editButton`)}
          </Typography>
        </Button>

        <Button
          key={`group-class-information-archive`}
          variant="contained"
          color="primary"
          lg="large"
          onClick={() => {}}
        >
          <Typography variant="h6">
            {t(`GroupClasses.communityInformation.archiveButton`)}
          </Typography>
        </Button>
      </div>
    </div>
  );
};

export default GroupClassInformationHeader;
