// @flow
import React, { useEffect } from 'react';
import { useLocation } from 'react-router';

import { PublicLayout } from '@layout/components';

import { PATH_NAMES } from '@core/constants';

import { getLocale, setLocale } from 'services/localisation';
import withSlugs from '../../../layouts/withSlugs';

import BannerSection from './BannerSection';
import ReinsuranceSection from './ReinsuranceSection';
import ReinsuranceMobileSection from './ReinsuranceMobileSection';
import OnlineProgramsSection from './OnlineProgramsSection';
import GroupLessonsSection from './GroupLessonsSection';
import SielBleuSection from './SielBleuSection';
import ContentFrSection from './ContentFrSection';

const LandingPageContent = () => {
  const locale = getLocale();
  const location = useLocation();

  useEffect(() => {
    const urlLangSlug = location ? location.pathname.split('/')[1] : '';
    const currentLang = getLocale();
    if (urlLangSlug !== currentLang) {
      setLocale(urlLangSlug);
    }
  }, []);

  return (
    <PublicLayout
      activeTab={PATH_NAMES.HOME}
      availableLanguages={[]}
      showMenu={false}
      isOnLandingPage
    >
      <main>
        {locale !== 'fr' ? (
          <>
            <BannerSection />
            <ReinsuranceSection />
            <OnlineProgramsSection />
            <GroupLessonsSection />
            <ReinsuranceMobileSection />
            <SielBleuSection />
          </>
        ) : (
          <>
            <ContentFrSection />
            <SielBleuSection />
          </>
        )}
      </main>
    </PublicLayout>
  );
};

export default withSlugs(LandingPageContent);
