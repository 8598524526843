import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/it';
import 'moment/locale/de';
import 'moment/locale/pt';
import 'moment/locale/nl';
import i18next from 'i18next';
import store from 'store';

import { ALL_LANGUAGES } from '@core/constants';

const localStorageName = 'getphy_locale';
const statusRefChangeLanguage = 'App-changeLanguage';

export function isLanguageUpdated(prevProps, props) {
  if (
    props.statuses[statusRefChangeLanguage] &&
    (props.statuses[statusRefChangeLanguage] || {}).state === 'finished'
  ) {
    if (
      !prevProps.statuses[statusRefChangeLanguage] ||
      (prevProps.statuses[statusRefChangeLanguage] || {}).state !== 'finished'
    ) {
      return true;
    }
  }
  return false;
}

export function setLocale(locale) {
  localStorage.setItem(localStorageName, locale);
  moment.locale(locale);
  document.documentElement.lang = locale;
  i18next.changeLanguage(locale);
}

export function getLocale() {
  try {
    // start by using the browser locale
    const languages = 'fr';
    let navLang = ALL_LANGUAGES.includes(languages) ? languages : languages.split('-')[0];

    const currentLang = window.location?.pathname?.split('/')[1] || '';
    if (ALL_LANGUAGES.includes(currentLang)) {
      navLang = currentLang;
    }

    // if user has set then use that
    const fromStorage = localStorage.getItem(localStorageName);
    if (fromStorage) {
      navLang = fromStorage;
    }

    // if user is logged in and has settings the use that
    if (store) {
      const state = store?.getState?.() || {};
      if (state?.Account?.user) {
        // TODO
        const { user } = state.Account;
        if (user?.language?.code) {
          navLang = user.language.code;
        }
      }
    }

    // check if the language exist
    if (!ALL_LANGUAGES.includes(navLang)) {
      navLang = 'fr';
    }
    return navLang;
  } catch (e) {
    return 'fr';
  }
}

export function getLocaleLanguageAndRegion() {
  const locale = getLocale();
  const localeArray = locale.split('-');
  const language = localeArray[0];
  const region = localeArray[1] ?? null;

  return {
    language,
    region,
  };
}
