import * as resourceActions from 'store/modules/Resource/actions';

const initialState = {
  questionnaires: {
    body_parts: [],
    dietician_reasons: [],
    motivations: [],
    personal_sentences: [],
  },
  communities: {
    days: [],
    objectives: [],
    equipment: [],
    themes: [],
    types: [],
    temporalities: [],
    countries: [],
    custom_price_reasons: [],
  },
  programs: {
    levels: [],
  },
  articles: {
    categories: [],
  },
  coaches: {
    days: [],
  },
  exercises: {
    objectives: [],
    counts: [],
    positions: [],
    levels: [],
    muscles: [],
    equipment: [],
    interests: [],
  },
  sessions: {
    target_audiences: [],
    levels: [],
    objectives: [],
    prescribers: [],
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case resourceActions.UPDATE_RESOURCE_CACHE_SET:
      return {
        ...state,
        [action.resource]: action.payload,
      };

    default:
      return state;
  }
};
