import React, { Component } from 'react';

import { NavLink } from 'react-router-dom';

import './style.css';

class DropdownMenu extends Component {
  constructor() {
    super();

    this.state = {};
  }

  render() {
    return (
      <div className={`header-help-dropdown ${this.props.isActive ? 'active' : ''}`}>
        {this.props.dropdownButtons.map((item) => {
          return (
            <div className={`dropdown-item`} key={item.text}>
              <NavLink to={item.link || ''}>{item.text}</NavLink>
            </div>
          );
        })}
      </div>
    );
  }
}

export default DropdownMenu;
