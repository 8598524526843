// @flow
import React from 'react';
import clsx from 'clsx';

import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import { publicTheme } from '@layout/theme';

import { MAIN_TEXT_COLOR, PRIMARY_COLOR } from '@shared/constants';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontFamily: 'Roboto, Georgia, serif',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '2rem',
    lineHeight: '2.25rem',
    color: PRIMARY_COLOR,
    paddingBottom: '2vh',
  },
  text: {
    fontFamily: 'Roboto, Georgia, serif',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '1.25rem',
    lineHeight: '1.75rem',
    color: MAIN_TEXT_COLOR,
    [publicTheme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}));

const GroupLessonsTexts = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div className={clsx(classes.root)}>
      <p className={clsx(classes.title)}>{t('LandingPage.groupLessonsTextsTitle')}</p>
      <p className={clsx(classes.text)}>{t('LandingPage.groupLessonsTextsPart1')}</p>
      <p className={clsx(classes.text)}>{t('LandingPage.groupLessonsTextsPart2')}</p>
    </div>
  );
};

export default GroupLessonsTexts;
