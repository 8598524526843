import { takeEvery, takeLatest, call, put } from 'redux-saga/effects';

import * as ApiService from 'services/api';
import ProtectedCall from 'services/protected.api';

import { incrementStatusCreator, decrementStatusCreator } from 'store/modules/Status/actions';
import * as helpActions from 'store/modules/Help/actions';

/** ********************************************** */
/** Selectors * */

/** ********************************************** */
/** Sagas * */

/** Tutorials * */
function* getTutorialsSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Getting tutorials' })
    );

    const result = yield call(ApiService.GetTutorials, data.payload.data);

    yield put({
      type: helpActions.UPDATE_CACHE_TUTORIALS_SET,
      payload: result.data.data.items,
    });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting tutorials finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting tutorials error',
        state: 'error',
        data: e.response.data.error,
      })
    );
  }
}

function* createTutorialSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Creating tutorial' })
    );

    yield ProtectedCall(ApiService.CreateTutorial, data.payload.tutorial);

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Creating tutorial finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Creating tutorial error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* updateTutorialSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Updating tutorial' })
    );

    yield ProtectedCall(ApiService.UpdateTutorial, data.payload.tutorial.id, data.payload.tutorial);

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Updating tutorial finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Updating tutorial error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* deleteTutorialSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Deleting tutorial' })
    );

    yield ProtectedCall(ApiService.DeleteTutorial, data.payload.id);

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Deleting tutorial finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Deleting tutorial error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* deleteTutorialPreviewSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Deleting preview tutorial',
      })
    );

    yield ProtectedCall(ApiService.DeleteTutorialPreview, data.payload.id);

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Deleting preview tutorial finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Deleting preview tutorial error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* getTutorialsCategoriesSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting tutorials categories',
      })
    );

    const result = yield call(ApiService.GetTutorialsCategories, data.payload);

    yield put({
      type: helpActions.UPDATE_CACHE_TUTORIALS_CATEGORIES_SET,
      payload: result.data.data.items,
    });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting tutorials categories finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting tutorials categories error',
        state: 'error',
        data: e.response.data.error,
      })
    );
  }
}

function* createTutorialCategorySaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Creating tutorial category',
      })
    );

    yield ProtectedCall(ApiService.CreateTutorialCategory, data.payload.tutorialCategory);

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Creating tutorial category finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Creating tutorial category error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* updateTutorialCategorySaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Updating tutorial category',
      })
    );

    yield ProtectedCall(
      ApiService.UpdateTutorialCategory,
      data.payload.tutorialCategory.id,
      data.payload.tutorialCategory
    );

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Updating tutorial category finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Updating tutorial category error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* deleteTutorialCategorySaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Deleting tutorial category',
      })
    );

    yield ProtectedCall(ApiService.DeleteTutorialCategory, data.payload.id);

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Deleting tutorial category finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Deleting tutorial category error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* getTutorialsTreeSaga(data) {
  yield* getTutorialsCategoriesSaga({ payload: { statusRef: '' } });
  yield* getTutorialsSaga(data);
}

/** Faqs * */
function* getFaqsSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Getting faqs' })
    );

    const result = yield call(ApiService.GetFaqs, data.payload.data);

    yield put({
      type: helpActions.UPDATE_CACHE_FAQ_SET,
      payload: result.data.data.items,
    });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting faqs finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting faqs error',
        state: 'error',
        data: e.response.data.error,
      })
    );
  }
}

function* createFaqSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Creating faq' })
    );

    yield ProtectedCall(ApiService.CreateFaq, data.payload.faq);

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Creating faq finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Creating faq error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* updateFaqSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Updating faq' })
    );

    yield ProtectedCall(ApiService.UpdateFaq, data.payload.faq.id, data.payload.faq);

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Updating faq finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Updating faq error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* deleteFaqSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Deleting faq' })
    );

    yield ProtectedCall(ApiService.DeleteFaq, data.payload.id);

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Deleting faq finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Deleting faq error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* getFaqCategoriesSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting faq categories',
      })
    );

    const result = yield call(ApiService.GetFaqCategories, data.payload);

    yield put({
      type: helpActions.UPDATE_CACHE_FAQ_CATEGORIES_SET,
      payload: result.data.data.items,
    });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting faq categories finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting faq categories error',
        state: 'error',
        data: e.response.data.error,
      })
    );
  }
}

function* createFaqCategorySaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Creating faq category',
      })
    );

    yield ProtectedCall(ApiService.CreateFaqCategory, data.payload.faqCategory);

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Creating faq category finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Creating faq category error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* updateFaqCategorySaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Updating faq category',
      })
    );

    yield ProtectedCall(
      ApiService.UpdateFaqCategory,
      data.payload.faqCategory.id,
      data.payload.faqCategory
    );

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Updating faq category finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Updating faq category error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* deleteFaqCategorySaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Deleting faq category',
      })
    );

    yield ProtectedCall(ApiService.DeleteFaqCategory, data.payload.id);

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Deleting faq category finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Deleting faq category error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* getFaqsTreeSaga(data) {
  yield* getFaqCategoriesSaga({ payload: { statusRef: '' } });
  yield* getFaqsSaga(data);
}

/** Glossary * */
function* getGlossariesSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Getting glossaries' })
    );

    const result = yield call(ApiService.GetGlossaries, data.payload.data);

    yield put({
      type: helpActions.UPDATE_CACHE_GLOSSARY_SET,
      payload: result.data.data.items,
    });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting glossaries finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting glossaries error',
        state: 'error',
        data: e.response.data.error,
      })
    );
  }
}

function* createGlossarySaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Creating glossary' })
    );

    yield ProtectedCall(ApiService.CreateGlossary, data.payload.glossary);

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Creating glossary finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Creating glossary error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* updateGlossarySaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Updating glossary' })
    );

    yield ProtectedCall(ApiService.UpdateGlossary, data.payload.glossary.id, data.payload.glossary);

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Updating glossary finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Updating glossary error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* deleteGlossarySaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Deleting glossary' })
    );

    yield ProtectedCall(ApiService.DeleteGlossary, data.payload.id);

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Deleting glossary finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Deleting glossary error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* getGlossaryCategoriesSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting glossary categories',
      })
    );

    const result = yield call(ApiService.GetGlossaryCategories, data.payload);

    yield put({
      type: helpActions.UPDATE_CACHE_GLOSSARY_CATEGORIES_SET,
      payload: result.data.data.items,
    });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting glossary categories finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting glossary categories error',
        state: 'error',
        data: e.response.data.error,
      })
    );
  }
}

function* createGlossaryCategorySaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Creating glossary category',
      })
    );

    yield ProtectedCall(ApiService.CreateGlossaryCategory, data.payload.glossaryCategory);

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Creating glossary category finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Creating glossary category error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* updateGlossaryCategorySaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Updating glossary category',
      })
    );

    yield ProtectedCall(
      ApiService.UpdateGlossaryCategory,
      data.payload.glossaryCategory.id,
      data.payload.glossaryCategory
    );

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Updating glossary category finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Updating glossary category error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* deleteGlossaryCategorySaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Deleting glossary category',
      })
    );

    yield ProtectedCall(ApiService.DeleteGlossaryCategory, data.payload.id);

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Deleting glossary category finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Deleting glossary category error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* getGlossariesTreeSaga(data) {
  yield* getGlossaryCategoriesSaga({ payload: { statusRef: '' } });
  yield* getGlossariesSaga(data);
}

/** Contact Us * */
function* getContactUsSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Getting contact us' })
    );

    const result = yield call(ApiService.GetContactUs, data.payload.data);

    yield put({
      type: helpActions.UPDATE_CACHE_CONTACT_US_SET,
      payload: result.data.data.items,
    });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting contact us finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting contact us error',
        state: 'error',
        data: e.response.data.error,
      })
    );
  }
}

function* createContactUsSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Creating contact us' })
    );

    yield ProtectedCall(ApiService.CreateContactUs, data.payload.contactUs);

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Creating contact us finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Creating contact us error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* updateContactUsSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Updating contact us' })
    );

    yield ProtectedCall(
      ApiService.UpdateContactUs,
      data.payload.contactUs.id,
      data.payload.contactUs
    );

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Updating contact us finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Updating contact us error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* deleteContactUsSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Deleting contact us' })
    );

    yield ProtectedCall(ApiService.DeleteContactUs, data.payload.id);

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Deleting contact us finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Deleting contact us error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* getContactUsCategoriesSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting contact us categories',
      })
    );

    const result = yield call(ApiService.GetContactUsCategories, data.payload);

    yield put({
      type: helpActions.UPDATE_CACHE_CONTACT_US_CATEGORIES_SET,
      payload: result.data.data.items,
    });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting contact us categories finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting contact us categories error',
        state: 'error',
        data: e.response.data.error,
      })
    );
  }
}

function* createContactUsCategorySaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Creating contact us category',
      })
    );

    yield ProtectedCall(ApiService.CreateContactUsCategory, data.payload.contactUsCategory);

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Creating contact us category finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Creating contact us category error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* updateContactUsCategorySaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Updating contact us category',
      })
    );

    yield ProtectedCall(
      ApiService.UpdateContactUsCategory,
      data.payload.contactUsCategory.id,
      data.payload.contactUsCategory
    );

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Updating contact us category finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Updating contact us category error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* deleteContactUsCategorySaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Deleting contact us category',
      })
    );

    yield ProtectedCall(ApiService.DeleteContactUsCategory, data.payload.id);

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Deleting contact us category finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Deleting contact us category error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

/** Procedures * */
function* getProceduresSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Getting procedures' })
    );

    const result = yield call(ApiService.GetProcedures, data.payload.data);

    yield put({
      type: helpActions.UPDATE_CACHE_PROCEDURE_SET,
      payload: result.data.data.items,
    });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting procedures finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting procedures error',
        state: 'error',
        data: e.response.data.error,
      })
    );
  }
}

function* createProcedureSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Creating procedure' })
    );

    yield ProtectedCall(ApiService.CreateProcedure, data.payload.procedure);

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Creating procedure finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Creating procedure error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* updateProcedureSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Updating procedure' })
    );

    data.payload.procedure = {
      ...data.payload.procedure,
      _method: 'put',
    };

    yield ProtectedCall(
      ApiService.UpdateProcedure,
      data.payload.procedure.id,
      data.payload.procedure
    );

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Updating procedure finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Updating procedure error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* deleteProcedureSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Deleting procedure' })
    );

    yield ProtectedCall(ApiService.DeleteProcedure, data.payload.id);

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Deleting procedure finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Deleting procedure error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* deleteProcedureDocumentSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Deleting procedure document',
      })
    );

    yield ProtectedCall(ApiService.DeleteProcedureDocument, data.payload.id);

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Deleting procedure document finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Deleting procedure document error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* getProcedureCategoriesSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting procedure categories',
      })
    );

    const result = yield call(ApiService.GetProcedureCategories, data.payload);

    yield put({
      type: helpActions.UPDATE_CACHE_PROCEDURE_CATEGORIES_SET,
      payload: result.data.data.items,
    });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting procedure categories finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting procedure categories error',
        state: 'error',
        data: e.response.data.error,
      })
    );
  }
}

function* createProcedureCategorySaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Creating procedure category',
      })
    );

    yield ProtectedCall(ApiService.CreateProcedureCategory, data.payload.procedureCategory);

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Creating procedure category finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Creating procedure category error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* updateProcedureCategorySaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Updating procedure category',
      })
    );

    yield ProtectedCall(
      ApiService.UpdateProcedureCategory,
      data.payload.procedureCategory.id,
      data.payload.procedureCategory
    );

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Updating procedure category finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Updating procedure category error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* deleteProcedureCategorySaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Deleting procedure category',
      })
    );

    yield ProtectedCall(ApiService.DeleteProcedureCategory, data.payload.id);

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Deleting procedure category finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Deleting procedure category error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* getProceduresTreeSaga(data) {
  yield* getProcedureCategoriesSaga({ payload: { statusRef: '' } });
  yield* getProceduresSaga(data);
}

/** Communication tools * */
function* getCommunicationToolsSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting communicationTools',
      })
    );

    const result = yield call(ApiService.GetCommunicationTools, data.payload.data);

    yield put({
      type: helpActions.UPDATE_CACHE_COMMUNICATION_TOOL_SET,
      payload: result.data.data.items,
    });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting communication tools finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting communication tools error',
        state: 'error',
        data: e.response.data.error,
      })
    );
  }
}

function* createCommunicationToolSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Creating communication tool',
      })
    );

    yield ProtectedCall(ApiService.CreateCommunicationTool, data.payload.communicationTool);

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Creating communication tool finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Creating communication tool error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* updateCommunicationToolSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Updating communication tool',
      })
    );

    data.payload.communicationTool = {
      ...data.payload.communicationTool,
      _method: 'put',
    };

    yield ProtectedCall(
      ApiService.UpdateCommunicationTool,
      data.payload.communicationTool.id,
      data.payload.communicationTool
    );

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Updating communication tool finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Updating communication tool error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* deleteCommunicationToolSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Deleting communication tool',
      })
    );

    yield ProtectedCall(ApiService.DeleteCommunicationTool, data.payload.id);

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Deleting communication tool finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Deleting communication tool error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* deleteCommunicationToolPreviewSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Deleting communication tool preview',
      })
    );

    yield ProtectedCall(ApiService.DeleteCommunicationToolPreview, data.payload.id);

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Deleting communication tool preview finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Deleting communication tool preview error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* deleteCommunicationToolDocumentSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Deleting communication tool document',
      })
    );

    yield ProtectedCall(ApiService.DeleteCommunicationToolDocument, data.payload.id);

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Deleting communication tool document finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Deleting communication tool document error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* getCommunicationToolCategoriesSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting communication tool categories',
      })
    );

    const result = yield call(ApiService.GetCommunicationToolCategories, data.payload);

    yield put({
      type: helpActions.UPDATE_CACHE_COMMUNICATION_TOOL_CATEGORIES_SET,
      payload: result.data.data.items,
    });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting communication tool categories finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting communication tool categories error',
        state: 'error',
        data: e.response.data.error,
      })
    );
  }
}

function* createCommunicationToolCategorySaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Creating communication tool category',
      })
    );

    yield ProtectedCall(
      ApiService.CreateCommunicationToolCategory,
      data.payload.communicationToolCategory
    );

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Creating communication tool category finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Creating communication tool category error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* updateCommunicationToolCategorySaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Updating communication tool category',
      })
    );

    yield ProtectedCall(
      ApiService.UpdateCommunicationToolCategory,
      data.payload.communicationToolCategory.id,
      data.payload.communicationToolCategory
    );

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Updating communication tool category finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Updating communication tool category error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* deleteCommunicationToolCategorySaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Deleting communication tool category',
      })
    );

    yield ProtectedCall(ApiService.DeleteCommunicationToolCategory, data.payload.id);

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Deleting communication tool category finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Deleting communication tool category error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* getCommunicationToolsTreeSaga(data) {
  yield* getCommunicationToolCategoriesSaga({ payload: { statusRef: '' } });
  yield* getCommunicationToolsSaga(data);
}

export default function* sagaWatcher() {
  /** Tutorials * */
  yield takeLatest(helpActions.GET_TUTORIALS_TREE, getTutorialsTreeSaga);
  yield takeLatest(helpActions.GET_TUTORIALS, getTutorialsSaga);

  yield takeEvery(helpActions.CREATE_TUTORIAL, createTutorialSaga);
  yield takeEvery(helpActions.UPDATE_TUTORIAL, updateTutorialSaga);
  yield takeEvery(helpActions.DELETE_TUTORIAL, deleteTutorialSaga);
  yield takeEvery(helpActions.DELETE_TUTORIAL_PREVIEW, deleteTutorialPreviewSaga);

  yield takeLatest(helpActions.GET_TUTORIALS_CATEGORIES, getTutorialsCategoriesSaga);

  yield takeEvery(helpActions.CREATE_TUTORIAL_CATEGORY, createTutorialCategorySaga);
  yield takeEvery(helpActions.UPDATE_TUTORIAL_CATEGORY, updateTutorialCategorySaga);
  yield takeEvery(helpActions.DELETE_TUTORIAL_CATEGORY, deleteTutorialCategorySaga);

  /** Faqs * */
  yield takeLatest(helpActions.GET_FAQS_TREE, getFaqsTreeSaga);
  yield takeLatest(helpActions.GET_FAQS, getFaqsSaga);

  yield takeEvery(helpActions.CREATE_FAQ, createFaqSaga);
  yield takeEvery(helpActions.UPDATE_FAQ, updateFaqSaga);
  yield takeEvery(helpActions.DELETE_FAQ, deleteFaqSaga);

  yield takeLatest(helpActions.GET_FAQ_CATEGORIES, getFaqCategoriesSaga);

  yield takeEvery(helpActions.CREATE_FAQ_CATEGORY, createFaqCategorySaga);
  yield takeEvery(helpActions.UPDATE_FAQ_CATEGORY, updateFaqCategorySaga);
  yield takeEvery(helpActions.DELETE_FAQ_CATEGORY, deleteFaqCategorySaga);

  /** Glossary * */
  yield takeLatest(helpActions.GET_GLOSSARIES_TREE, getGlossariesTreeSaga);
  yield takeLatest(helpActions.GET_GLOSSARIES, getGlossariesSaga);

  yield takeEvery(helpActions.CREATE_GLOSSARY, createGlossarySaga);
  yield takeEvery(helpActions.UPDATE_GLOSSARY, updateGlossarySaga);
  yield takeEvery(helpActions.DELETE_GLOSSARY, deleteGlossarySaga);

  yield takeLatest(helpActions.GET_GLOSSARY_CATEGORIES, getGlossaryCategoriesSaga);

  yield takeEvery(helpActions.CREATE_GLOSSARY_CATEGORY, createGlossaryCategorySaga);
  yield takeEvery(helpActions.UPDATE_GLOSSARY_CATEGORY, updateGlossaryCategorySaga);
  yield takeEvery(helpActions.DELETE_GLOSSARY_CATEGORY, deleteGlossaryCategorySaga);

  /** Contact Us * */
  yield takeLatest(helpActions.GET_CONTACT_US, getContactUsSaga);

  yield takeEvery(helpActions.CREATE_CONTACT_US, createContactUsSaga);
  yield takeEvery(helpActions.UPDATE_CONTACT_US, updateContactUsSaga);
  yield takeEvery(helpActions.DELETE_CONTACT_US, deleteContactUsSaga);

  yield takeLatest(helpActions.GET_CONTACT_US_CATEGORIES, getContactUsCategoriesSaga);

  yield takeEvery(helpActions.CREATE_CONTACT_US_CATEGORY, createContactUsCategorySaga);
  yield takeEvery(helpActions.UPDATE_CONTACT_US_CATEGORY, updateContactUsCategorySaga);
  yield takeEvery(helpActions.DELETE_CONTACT_US_CATEGORY, deleteContactUsCategorySaga);

  /** Procedures * */
  yield takeLatest(helpActions.GET_PROCEDURES_TREE, getProceduresTreeSaga);
  yield takeLatest(helpActions.GET_PROCEDURES, getProceduresSaga);

  yield takeEvery(helpActions.CREATE_PROCEDURE, createProcedureSaga);
  yield takeEvery(helpActions.UPDATE_PROCEDURE, updateProcedureSaga);
  yield takeEvery(helpActions.DELETE_PROCEDURE, deleteProcedureSaga);
  yield takeEvery(helpActions.DELETE_PROCEDURE_DOCUMENT, deleteProcedureDocumentSaga);

  yield takeLatest(helpActions.GET_PROCEDURE_CATEGORIES, getProcedureCategoriesSaga);

  yield takeEvery(helpActions.CREATE_PROCEDURE_CATEGORY, createProcedureCategorySaga);
  yield takeEvery(helpActions.UPDATE_PROCEDURE_CATEGORY, updateProcedureCategorySaga);
  yield takeEvery(helpActions.DELETE_PROCEDURE_CATEGORY, deleteProcedureCategorySaga);

  /** Communication tools * */
  yield takeLatest(helpActions.GET_COMMUNICATION_TOOLS_TREE, getCommunicationToolsTreeSaga);
  yield takeLatest(helpActions.GET_COMMUNICATION_TOOLS, getCommunicationToolsSaga);

  yield takeEvery(helpActions.CREATE_COMMUNICATION_TOOL, createCommunicationToolSaga);
  yield takeEvery(helpActions.UPDATE_COMMUNICATION_TOOL, updateCommunicationToolSaga);
  yield takeEvery(helpActions.DELETE_COMMUNICATION_TOOL, deleteCommunicationToolSaga);
  yield takeEvery(
    helpActions.DELETE_COMMUNICATION_TOOL_PREVIEW,
    deleteCommunicationToolPreviewSaga
  );
  yield takeEvery(
    helpActions.DELETE_COMMUNICATION_TOOL_DOCUMENT,
    deleteCommunicationToolDocumentSaga
  );

  yield takeLatest(
    helpActions.GET_COMMUNICATION_TOOL_CATEGORIES,
    getCommunicationToolCategoriesSaga
  );

  yield takeEvery(
    helpActions.CREATE_COMMUNICATION_TOOL_CATEGORY,
    createCommunicationToolCategorySaga
  );
  yield takeEvery(
    helpActions.UPDATE_COMMUNICATION_TOOL_CATEGORY,
    updateCommunicationToolCategorySaga
  );
  yield takeEvery(
    helpActions.DELETE_COMMUNICATION_TOOL_CATEGORY,
    deleteCommunicationToolCategorySaga
  );
}
