import { takeEvery, call, put, select, all, takeLatest } from 'redux-saga/effects';

import * as ApiService from 'services/api';

import { incrementStatusCreator, decrementStatusCreator } from 'store/modules/Status/actions';
import * as communityActions from 'store/modules/Community/actions';

import omit from 'lodash/omit';

/** ********************************************** */
/** Selectors * */

const allCommunitiesSelector = (state) => {
  return JSON.parse(JSON.stringify(state.Community.communities));
};

const allGroupsSelector = (state) => {
  return JSON.parse(JSON.stringify(state.Community.groups));
};

const allEventsSelector = (state) => {
  return JSON.parse(JSON.stringify(state.Community.events));
};

const allJoinRequestsSelector = (state) => {
  return JSON.parse(JSON.stringify(state.Community.joinRequests));
};

const allParticipantsSelector = (state) => {
  return JSON.parse(JSON.stringify(state.Community.participants));
};

const allManagersSelector = (state) => {
  return JSON.parse(JSON.stringify(state.Community.managers));
};

const allCommunityPaymentStatus = (state) => {
  return JSON.parse(JSON.stringify(state.Community.communityPaymentStatus));
};

/** ********************************************** */
function* createEventRequestSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Create event' })
    );

    const result = yield call(ApiService.CreateEventRequest, data.payload);

    yield put({ type: communityActions.CACHE_UPDATE_EVENTS, payload: result.data.data.events });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Create event finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Create event error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* createGroupRequestSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Create group' })
    );

    const result = yield call(ApiService.CreateGroupRequest, data.payload);

    yield put({ type: communityActions.CACHE_UPDATE_GROUPS, payload: [result.data.data.group] });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Create group finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Create group error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* getCommunitySaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Getting community' })
    );

    const result = yield call(ApiService.GetCommunity, data.payload.communityId);

    yield put({
      type: communityActions.CACHE_UPDATE_COMMUNITIES,
      payload: [result.data.data.community],
    });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting community finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting community error',
        state: 'error',
      })
    );
  }
}

function* getCommunitiesUserSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting community user',
      })
    );

    const result = yield call(ApiService.GetCommunityUser, data.payload.userId);

    yield put({
      type: communityActions.CACHE_UPDATE_COMMUNITIES_USER,
      payload: result.data.data.communities,
    });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting community user finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting community user error',
        state: 'error',
      })
    );
  }
}

function* getEditCommunitySaga(data) {
  const { callback = { success: () => null } } = data.payload;
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting edit community',
      })
    );

    const result = yield call(ApiService.GetEditCommunity, data.payload.communityId);

    yield put({
      type: communityActions.CACHE_UPDATE_COMMUNITIES,
      payload: [result.data.data.community],
    });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting edit community finished',
      })
    );
    callback.success();
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting edit community error',
        state: 'error',
      })
    );
  }
}

function* communitySearchSaga(data) {
  const { callback = { success: () => null, error: () => null } } = data.payload;
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Searching communities',
      })
    );

    const searchParams = omit(data.payload, ['statusRef', 'callback']);

    const result = yield call(ApiService.CommunitySearch, searchParams);

    yield put({ type: communityActions.SET_COMMUNITIES_SEARCH_RESULTS, payload: result.data.data });

    yield put({
      type: communityActions.CACHE_UPDATE_COMMUNITIES,
      payload: result.data.data.communities,
    });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Searching communities finished',
      })
    );
    callback.success();
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Searching communities error',
        state: 'error',
      })
    );
    callback.error();
  }
}

function* communityMapSearchSaga(data) {
  const { callback = { success: () => null, error: () => null } } = data.payload;
  try {
    yield put({
      type: communityActions.SET_COMMUNITIES_SEARCH_RESULTS,
      payload: {},
    });
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Searching map communities',
      })
    );

    const searchParams = omit(data.payload, ['statusRef', 'callback']);

    const result = yield call(ApiService.CommunityMapSearch, searchParams);

    yield put({ type: communityActions.SET_COMMUNITIES_SEARCH_RESULTS, payload: result.data.data });

    yield put({
      type: communityActions.CACHE_UPDATE_COMMUNITIES,
      payload: result.data.data.communities,
    });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Searching communities finished',
      })
    );
    callback.success();
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Searching communities error',
        state: 'error',
      })
    );
    callback.error();
  }
}

function* myCommunitiesFetchSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Fetching my communities',
      })
    );

    const userId = yield select((state) => state.Account.user.id);

    const result = yield call(ApiService.CommunitySearchByUserId, userId);

    yield put({ type: communityActions.SET_MY_COMMUNITIES, payload: result.data.data });

    yield put({
      type: communityActions.CACHE_UPDATE_COMMUNITIES,
      payload: result.data.data.communities,
    });
    yield put({ type: communityActions.CACHE_UPDATE_EVENTS, payload: result.data.data.events });
    yield put({ type: communityActions.CACHE_UPDATE_GROUPS, payload: result.data.data.groups });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Fetching my  communities finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Fetching my  communities error',
        state: 'error',
      })
    );
  }
}

function* getCommunityJoinRequestsSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting community join requests',
      })
    );

    const result = yield call(
      ApiService.GetCommunityJoinRequests,
      data.payload.communityId,
      data.payload
    );

    yield put({
      type: communityActions.CACHE_UPDATE_JOIN_REQUESTS,
      payload: {
        communityId: data.payload.communityId,
        requests: result.data.data.users,
      },
    });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting community join requests finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting community join requests error',
        state: 'error',
      })
    );
  }
}

function* joinCommunitySaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Joining community' })
    );

    yield call(ApiService.CommunityJoin, data.payload.communityId, data.payload);

    yield put(
      communityActions.getCommunityCreator({
        communityId: data.payload.communityId,
        statusRef: data.payload.statusRef,
      })
    );

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Joining community finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Joining community error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* leaveCommunitySaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Leave community' })
    );

    yield call(
      ApiService.CommunityLeave,
      data.payload.communityId,
      data.payload.userId,
      data.payload
    );

    yield put(
      communityActions.getCommunityCreator({
        communityId: data.payload.communityId,
        statusRef: data.payload.statusRef,
      })
    );

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Leave community finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Leave community error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* acceptCommunityJoinRequestSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Accepting community join request',
      })
    );

    yield call(
      ApiService.CommunityAcceptJoinRequest,
      data.payload.communityId,
      data.payload.user_id
    );

    const joinRequestsByCommunities = yield select(allJoinRequestsSelector) || {};
    const joinRequests = joinRequestsByCommunities[data.payload.communityId] || [];

    const indexJoinRequestToDelete = joinRequests.findIndex(
      (joinRequest) => joinRequest.id === data.payload.user_id
    );

    if (indexJoinRequestToDelete >= 0) {
      joinRequests.splice(indexJoinRequestToDelete, 1);
    }

    yield put({
      type: communityActions.CACHE_UPDATE_JOIN_REQUESTS,
      payload: {
        communityId: data.payload.communityId,
        requests: joinRequests,
      },
    });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Accepting community join request finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Accepting community join request error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* refuseCommunityJoinRequestSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Refusing or cancelling community join request',
      })
    );

    yield call(
      ApiService.CommunityRefuseJoinRequest,
      data.payload.communityId,
      data.payload.user_id
    );

    const joinRequestsByCommunities = yield select(allJoinRequestsSelector) || {};
    const joinRequests = joinRequestsByCommunities[data.payload.communityId] || [];

    const indexJoinRequestToDelete = joinRequests.findIndex(
      (joinRequest) => joinRequest.id === data.payload.user_id
    );

    if (indexJoinRequestToDelete >= 0) {
      joinRequests.splice(indexJoinRequestToDelete, 1);
    }

    yield put({
      type: communityActions.CACHE_UPDATE_JOIN_REQUESTS,
      payload: {
        communityId: data.payload.communityId,
        requests: joinRequests,
      },
    });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Refusing or cancelling community join request finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Refusing or cancelling community join request error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* updateCommunitySaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Updating community' })
    );

    const result = yield call(ApiService.UpdateCommunity, data.payload.id, data.payload);

    yield put({
      type: communityActions.CACHE_UPDATE_COMMUNITIES,
      payload: [result.data.data.community],
    });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Updating community finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Updating community error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* validatePaymentPeriodSaga(data) {
  const { callback = { success: () => null } } = data.payload;
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Validating community payment period',
      })
    );

    const result = yield call(ApiService.ValidatePaymentPeriod, data.payload.periodId);
    yield put({
      type: communityActions.CACHE_UPDATE_COMMUNITIES,
      payload: [result.data.community],
    });

    // Update community payment status cache
    const resultPaymentStatus = yield call(
      ApiService.GetCommunityPaymentStatus,
      result.data.community.id,
      data.payload
    );
    yield put({
      type: communityActions.SET_COMMUNITY_PAYMENT_STATUS,
      payload: resultPaymentStatus.data.data.users,
    });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Validating community payment period finished',
        data: result.data.community,
      })
    );
    callback.success();
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Validating community payment period error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* updateGroupSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Updating group' })
    );

    const result = yield call(ApiService.UpdateGroup, data.payload.id, data.payload);

    yield put({
      type: communityActions.CACHE_UPDATE_COMMUNITIES,
      payload: [result.data.data.group],
    });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Updating group finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Updating group error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* updateEventSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Updating event' })
    );

    const result = yield call(ApiService.UpdateEvent, data.payload.id, data.payload);

    yield put({
      type: communityActions.CACHE_UPDATE_COMMUNITIES,
      payload: [result.data.data.event],
    });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Updating event finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Updating event error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* getCommunityPaymentStatusSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting community payment status',
      })
    );

    const { communityId } = data.payload;

    const result = yield call(ApiService.GetCommunityPaymentStatus, communityId, data.payload);

    yield put({
      type: communityActions.SET_COMMUNITY_PAYMENT_STATUS,
      payload: result.data.data.users,
    });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting community payment status finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting community payment status error',
        state: 'error',
      })
    );
  }
}

function* deletePendingPaymentSaga(data) {
  const { callback = { success: () => null } } = data.payload;
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Deleting community pending payment',
      })
    );

    const { communityId } = data.payload;
    const { pendingPaymentId } = data.payload;

    yield call(
      ApiService.CommunityRemovePendingPayment,
      communityId,
      pendingPaymentId,
      data.payload
    );

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Deleting community pending payment finished',
      })
    );
    callback.success();
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Deleting community pending payment error',
        state: 'error',
      })
    );
  }
}

function* addCustomPriceSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Adding custom price' })
    );

    const { communityId } = data.payload;

    yield call(ApiService.AddCustomPrice, communityId, data.payload);

    yield put(communityActions.getCommunityPaymentStatus({ communityId }));

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Adding custom price finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Adding custom price error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* updateCustomPriceSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Updating custom price',
      })
    );

    const { communityId } = data.payload;
    const { customPriceId } = data.payload;

    yield call(ApiService.UpdateCustomPrice, communityId, customPriceId, data.payload);

    yield put(communityActions.getCommunityPaymentStatus({ communityId }));

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Updating custom price finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Updating custom price error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* addPaymentsSaga(data) {
  const { callback = { success: () => null } } = data.payload;
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Adding community payments',
      })
    );

    const { communityId } = data.payload;

    if (data.payload.payments && data.payload.payments.length) {
      yield call(ApiService.AddCommunityPayment, communityId, { payments: data.payload.payments });
    }

    yield put(communityActions.getCommunityPaymentStatus({ communityId }));

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Adding community payments finished',
      })
    );
    callback.success();
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Adding community payments error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* updatePaymentsSaga(data) {
  const { callback = { success: () => null } } = data.payload;
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Updating community payments',
      })
    );

    const { communityId } = data.payload;

    yield all(
      data.payload.payments.map((payment) => {
        const p = payment;

        return call(ApiService.UpdateCommunityPayment, communityId, p.paymentId, p);
      })
    );

    yield put(communityActions.getCommunityPaymentStatus({ communityId }));

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Updating community payments finished',
      })
    );
    callback.success();
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Updating community payments error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* transferPaymentSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'transfer community payment',
      })
    );

    yield call(ApiService.TransferPayment, data.payload.paymentId, {
      payment_period_id: data.payload.payment_period_id,
    });

    // @TODO this call should be removed after refactoring
    // Don't forget to remove communityId in payload object
    yield put(
      communityActions.getCommunityPaymentStatus({ communityId: data.payload.communityId })
    );

    // @TODO should update communityPaymentStatus in this way after refactoring
    // let communityPaymentStatus = yield select(allCommunityPaymentStatus) || {}

    // Object.keys(communityPaymentStatus).forEach((key) => {
    //   const user = communityPaymentStatus[key]

    //   if (user.id === result.id) {
    //     communityPaymentStatus[key] = result
    //   }
    // })

    // yield put({
    //   type: communityActions.SET_COMMUNITY_PAYMENT_STATUS,
    //   payload: communityPaymentStatus,
    // })

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'transfer community payment finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'transfer community payment error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* updateUserOnlinePaymentSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Updating community user online payment',
      })
    );

    const { communityId } = data.payload;

    const result = yield call(ApiService.UpdateUsersOnlinePayment, communityId, data.payload);

    const communityPaymentStatus = yield select(allCommunityPaymentStatus) || {};
    const usersUpdated = result.data.users_updated;

    Object.keys(communityPaymentStatus).map((key) => {
      const user = communityPaymentStatus[key];

      Object.keys(usersUpdated).map((key2) => {
        const userUpdated = usersUpdated[key2];

        if (user.id === userUpdated.id) {
          user.online_payment = userUpdated.online_payment;
        }

        return null;
      });
      return null;
    });

    yield put({
      type: communityActions.SET_COMMUNITY_PAYMENT_STATUS,
      payload: communityPaymentStatus,
    });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Updating community user online payment finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Updating community user online payment error',
        state: 'error',
      })
    );
  }
}

function* initiateCommunityPaymentSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Initiating community payment',
      })
    );

    const { communityId } = data.payload;

    const results = yield call(ApiService.InitiateCommunityPayment, communityId, data.payload);

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Initiating community payment finished',
        data: results.data.data,
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Initiating community payment error',
        state: 'error',
      })
    );
  }
}

function* submitFailedPaymentSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Submitting failed community payment',
      })
    );

    const results = yield call(ApiService.SubmitFailedPayment, data.payload);

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Submitting failed community payment finished',
        data: results.data.data,
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Submitting failed community payment error',
        state: 'error',
      })
    );
  }
}

function* submitSuccessPaymentSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Submitting successful community payment',
      })
    );

    const results = yield call(ApiService.SubmitPaymentPending, data.payload);

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Submitting successful community payment finished',
        data: results.data.data,
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Submitting successful community payment error',
        state: 'error',
      })
    );
  }
}

function* removeExpiredInitiatedPaymentsSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Remove expired initiate payment',
      })
    );

    const results = yield call(ApiService.RemoveExpiredInitiatedPayments, data.payload);

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Remove expired initiate payment finished',
        data: results.data.data,
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Remove expired initiate payment error',
        state: 'error',
      })
    );
  }
}

function* getCommunityParticipantsSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting community participants',
      })
    );

    const result = yield call(
      ApiService.CommunityGetParticipants,
      data.payload.communityId,
      data.payload
    );

    yield put({
      type: communityActions.CACHE_UPDATE_PARTICIPANTS,
      payload: {
        communityId: data.payload.communityId,
        participants: result.data.data.users,
      },
    });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting community participants finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting community participantserror',
        state: 'error',
      })
    );
  }
}

function* addInactiveSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Add inactive user' })
    );

    const participantsByCommunities = yield select(allParticipantsSelector) || {};
    const participants = participantsByCommunities[data.payload.communityId] || [];

    const result = yield call(
      ApiService.CommunityAddInactive,
      data.payload.communityId,
      data.payload
    );

    participants.push(result.data.data.user);

    yield put({
      type: communityActions.CACHE_UPDATE_PARTICIPANTS,
      payload: {
        communityId: data.payload.communityId,
        participants,
      },
    });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Add inactive user finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Add inactive user error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* addParticipantSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Community add participant',
      })
    );

    const participantsByCommunities = yield select(allParticipantsSelector) || {};
    const participants = participantsByCommunities[data.payload.communityId] || [];

    const result = yield call(
      ApiService.CommunityAddParticipant,
      data.payload.communityId,
      data.payload.user_id || data.payload.id,
      data.payload.addInGroup
    );

    participants.push(result.data.data.user);

    yield put({
      type: communityActions.CACHE_UPDATE_PARTICIPANTS,
      payload: {
        communityId: data.payload.communityId,
        participants,
      },
    });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Community add participant finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Community add participant error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* editParticipantSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Community edit participant',
      })
    );

    const participantsByCommunities = yield select(allParticipantsSelector) || {};
    const participants = participantsByCommunities[data.payload.communityId] || [];
    const statusRefAddBeneficiary = 'SectionBeneficiaries-addBeneficiary';

    const result = yield call(
      ApiService.CommunityEditParticipant,
      data.payload.communityId,
      data.payload.id,
      data.payload
    );

    participants.forEach((participant, index) => {
      const user = result.data.data.user || {};
      if (participant.id === user.id) {
        participants[index] = user;
      }
    });

    yield put({
      type: communityActions.CACHE_UPDATE_PARTICIPANTS,
      payload: {
        communityId: data.payload.communityId,
        participants,
      },
    });

    if (result && data.payload.addParticipant) {
      yield put({
        type: communityActions.ADD_PARTICIPANT,
        payload: {
          ...data.payload,
          statusRef: statusRefAddBeneficiary,
        },
      });
    } else {
      yield put(
        decrementStatusCreator({
          statusRef: data.payload.statusRef,
          message: 'Community edit participant finished',
        })
      );
    }
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Community edit participant error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* removeParticipantSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Community remove participant',
      })
    );

    const participantsByCommunities = yield select(allParticipantsSelector) || {};
    const participants = participantsByCommunities[data.payload.communityId] || [];

    yield call(ApiService.CommunityRemoveParticipant, data.payload.communityId, data.payload.id);

    const indexParticipantToDelete = participants.findIndex(
      (participant) => participant.id === data.payload.id
    );

    if (indexParticipantToDelete >= 0) {
      participants.splice(indexParticipantToDelete, 1);
    }

    yield put({
      type: communityActions.CACHE_UPDATE_PARTICIPANTS,
      payload: {
        communityId: data.payload.communityId,
        participants,
      },
    });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Community remove participant finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Community remove participant error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* sendSmsToParticipantSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Community send SMS to participant',
      })
    );

    const results = yield call(
      ApiService.CommunitySendSmsToParticipant,
      data.payload.communityId,
      data.payload
    );

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Community send SMS to participant finished',
        data: results.data.data,
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Community send SMS to participant error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* sendMailToParticipantSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Community send Mail to participant',
      })
    );

    const results = yield call(ApiService.CommunitySendMailToParticipant, data.payload);

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Community send Mail to participant finished',
        data: results.data.data,
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Community send Mail to participant error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* sendChatToParticipantSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Community send chat message to participant',
      })
    );

    const results = yield call(ApiService.CommunitySendChatToParticipant, data.payload);

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Community send chat message to participant finished',
        data: results.data.data,
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Community send chat message to participant error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* archiveCommunitySaga(data) {
  const { callback = { success: () => null } } = data.payload;
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Community archive start',
      })
    );

    const results = yield call(ApiService.CommunityArchive, data.payload.communityId, data.payload);

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Community archive finished',
        data: results.data.data,
      })
    );
    callback.success();
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Community archive error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* dearchiveCommunitySaga(data) {
  const { callback = { success: () => null } } = data.payload;
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Community dearchive start',
      })
    );

    const results = yield call(
      ApiService.CommunityDearchive,
      data.payload.communityId,
      data.payload
    );

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Community dearchive finished',
        data: results.data.data,
      })
    );
    callback.success();
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Community dearchive error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* getCommunityRequestSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'get community request start',
      })
    );

    const results = yield call(ApiService.CommunityGetRequest, data.payload);

    yield put({ type: communityActions.SET_COMMUNITY_REQUEST, payload: results.data.data });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'get community request finished',
        data: results.data.data,
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'get community request error',
        state: 'error',
      })
    );
  }
}

function* acceptCommunityRequestSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'accept community request start',
      })
    );

    const results = yield call(
      ApiService.CommunityAcceptRequest,
      data.payload.communityId,
      data.payload
    );

    yield put(communityActions.getCommunityRequest({}));

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'accept community request finished',
        data: results.data.data,
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'accept community request error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* refuseCommunityRequestSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'refuse community request start',
      })
    );

    const results = yield call(
      ApiService.CommunityRefuseRequest,
      data.payload.communityId,
      data.payload
    );

    yield put(communityActions.getCommunityRequest({}));

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'refuse community request finished',
        data: results.data.data,
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'refuse community request error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* likeSaga(data) {
  const result = yield call(ApiService.Like, data.payload);

  yield put({
    type: communityActions.CACHE_UPDATE_COMMUNITY_LIKE,
    payload: {
      community_id: data.payload.object_id,
      like_id: result.data.data.like_id,
    },
  });
}

function* dislikeSaga(data) {
  yield call(ApiService.Dislike, data.payload.id, data.payload);

  yield put({
    type: communityActions.CACHE_UPDATE_COMMUNITY_LIKE,
    payload: {
      community_id: data.payload.object_id,
      like_id: null,
    },
  });
}

function* exportCommunitiesSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Exporting communities start',
      })
    );

    yield call(ApiService.ExportCommunities, data.payload);

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Exporting communities finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Exporting communities error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* exportCommunitySaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Exporting community start',
      })
    );

    yield call(ApiService.ExportCommunity, data.payload.communityId, data.payload);

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Exporting community finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Exporting community error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* shareCommunityByEmailSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Share community by mail start',
      })
    );

    yield call(ApiService.ShareCommunityByMail, data.payload.communityId, data.payload);

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Share community by mail finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Share community by mail error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* updateCacheCommunityLikeSaga(data) {
  const communities = yield select(allCommunitiesSelector) || {};

  communities[data.payload.community_id] = {
    ...communities[data.payload.community_id],
    ...{ liked: data.payload.like_id },
  };

  yield put({ type: communityActions.CACHE_UPDATE_COMMUNITIES_SET, payload: communities });
}

function* updateCacheCommunitiesSaga(data) {
  if (!data || !data.payload || data.payload.length === 0) {
    return;
  }

  const communities = yield select(allCommunitiesSelector) || {};

  (data.payload || []).map((item) => {
    communities[item.id] = { ...(communities[item.id] || {}), ...item };

    return null;
  });

  yield put({ type: communityActions.CACHE_UPDATE_COMMUNITIES_SET, payload: communities });
}

function* updateCacheEventsSaga(data) {
  if (!data || !data.payload || data.payload.length === 0) {
    return;
  }

  const events = yield select(allEventsSelector) || {};

  (data.payload || []).map((item) => {
    events[item.id] = item;

    return null;
  });

  yield put({ type: communityActions.CACHE_UPDATE_EVENTS_SET, payload: events });
}

function* updateCacheGroupsSaga(data) {
  if (!data || !data.payload || data.payload.length === 0) {
    return;
  }

  const groups = yield select(allGroupsSelector) || {};

  (data.payload || []).map((item) => {
    groups[item.id] = item;

    return null;
  });

  yield put({ type: communityActions.CACHE_UPDATE_GROUPS_SET, payload: groups });
}

function* updateCacheJoinRequestsSaga(data) {
  if (!data || !data.payload || data.payload.length === 0) {
    return;
  }

  if (!data.payload.communityId) {
    return;
  }

  const joinRequests = yield select(allJoinRequestsSelector) || {};

  joinRequests[data.payload.communityId] = data.payload.requests || [];

  yield put({ type: communityActions.CACHE_UPDATE_JOIN_REQUESTS_SET, payload: joinRequests });
}

function* updateCacheParticipantsSaga(data) {
  if (!data || !data.payload || data.payload.length === 0) {
    return;
  }

  if (!data.payload.communityId) {
    return;
  }

  const participants = yield select(allParticipantsSelector) || {};

  participants[data.payload.communityId] = data.payload.participants || [];

  yield put({ type: communityActions.CACHE_UPDATE_PARTICIPANTS_SET, payload: participants });
}

function* updateCacheManagersSaga(data) {
  if (!data || !data.payload || data.payload.length === 0) {
    return;
  }

  if (!data.payload.communityId) {
    return;
  }

  const managers = yield select(allManagersSelector) || {};

  managers[data.payload.communityId] = data.payload.managers || [];

  yield put({ type: communityActions.CACHE_UPDATE_MANAGERS_SET, payload: managers });
}

/** Sagas * */
export default function* sagaWatcher() {
  yield takeEvery(communityActions.CREATE_EVENT_REQUEST, createEventRequestSaga);
  yield takeEvery(communityActions.CREATE_GROUP_REQUEST, createGroupRequestSaga);
  yield takeEvery(communityActions.GET_COMMUNITY, getCommunitySaga);
  yield takeEvery(communityActions.GET_COMMUNITIES_USER, getCommunitiesUserSaga);
  yield takeEvery(communityActions.GET_EDIT_COMMUNITY, getEditCommunitySaga);
  yield takeLatest(communityActions.COMMUNITY_SEARCH_SUBMIT, communitySearchSaga);
  yield takeLatest(communityActions.COMMUNITY_MAP_SEARCH_SUBMIT, communityMapSearchSaga);
  yield takeEvery(communityActions.REQUEST_MY_COMMUNITIES_FETCH, myCommunitiesFetchSaga);

  yield takeEvery(communityActions.GET_COMMUNITY_JOIN_REQUESTS, getCommunityJoinRequestsSaga);
  yield takeEvery(communityActions.REQUEST_JOIN_COMMUNITY, joinCommunitySaga);
  yield takeEvery(communityActions.REQUEST_LEAVE_COMMUNITY, leaveCommunitySaga);
  yield takeEvery(communityActions.REFUSE_COMMUNITY_JOIN_REQUEST, refuseCommunityJoinRequestSaga);
  yield takeEvery(communityActions.ACCEPT_COMMUNITY_JOIN_REQUEST, acceptCommunityJoinRequestSaga);

  yield takeEvery(communityActions.UPDATE_COMMUNITY, updateCommunitySaga);
  yield takeEvery(communityActions.UPDATE_GROUP, updateGroupSaga);
  yield takeEvery(communityActions.UPDATE_EVENT, updateEventSaga);

  yield takeLatest(communityActions.GET_COMMUNITY_PAYMENT_STATUS, getCommunityPaymentStatusSaga);

  yield takeEvery(communityActions.DELETE_PENDING_PAYMENT, deletePendingPaymentSaga);

  yield takeEvery(
    communityActions.REMOVE_EXPIRED_INITIATE_PAYMENTS,
    removeExpiredInitiatedPaymentsSaga
  );

  yield takeEvery(communityActions.ADD_CUSTOM_PRICE, addCustomPriceSaga);
  yield takeEvery(communityActions.UPDATE_CUSTOM_PRICE, updateCustomPriceSaga);

  yield takeEvery(communityActions.ADD_PAYMENTS, addPaymentsSaga);
  yield takeEvery(communityActions.UPDATE_PAYMENTS, updatePaymentsSaga);
  yield takeEvery(communityActions.TRANSFER_PAYMENT, transferPaymentSaga);

  yield takeEvery(communityActions.UPDATE_USER_ONLINE_PAYMENT, updateUserOnlinePaymentSaga);
  yield takeEvery(communityActions.INITIATE_COMMUNITY_PAYMENT, initiateCommunityPaymentSaga);
  yield takeEvery(communityActions.SUBMIT_FAILED_PAYMENT, submitFailedPaymentSaga);
  yield takeEvery(communityActions.SUBMIT_SUCCESS_PAYMENT, submitSuccessPaymentSaga);

  yield takeEvery(communityActions.VALIDATE_PERIOD, validatePaymentPeriodSaga);

  yield takeEvery(communityActions.GET_COMMUNITY_PARTICIPANTS, getCommunityParticipantsSaga);
  yield takeEvery(communityActions.ADD_INACTIVE, addInactiveSaga);
  yield takeEvery(communityActions.ADD_PARTICIPANT, addParticipantSaga);
  yield takeEvery(communityActions.EDIT_PARTICIPANT, editParticipantSaga);
  yield takeEvery(communityActions.REMOVE_PARTICIPANT, removeParticipantSaga);
  yield takeEvery(communityActions.SEND_SMS_TO_PARTICIPANT, sendSmsToParticipantSaga);
  yield takeEvery(communityActions.SEND_MAIL_TO_PARTICIPANT, sendMailToParticipantSaga);
  yield takeEvery(communityActions.SEND_CHAT_TO_PARTICIPANT, sendChatToParticipantSaga);

  yield takeEvery(communityActions.ARCHIVE_COMMUNITY, archiveCommunitySaga);
  yield takeEvery(communityActions.DEARCHIVE_COMMUNITY, dearchiveCommunitySaga);

  yield takeEvery(communityActions.GET_COMMUNITY_REQUEST, getCommunityRequestSaga);
  yield takeEvery(communityActions.ACCEPT_COMMUNITY_REQUEST, acceptCommunityRequestSaga);
  yield takeEvery(communityActions.REFUSE_COMMUNITY_REQUEST, refuseCommunityRequestSaga);

  yield takeEvery(communityActions.LIKE, likeSaga);
  yield takeEvery(communityActions.DISLIKE, dislikeSaga);

  yield takeEvery(communityActions.EXPORT_COMMUNITIES, exportCommunitiesSaga);
  yield takeEvery(communityActions.EXPORT_COMMUNITY, exportCommunitySaga);

  yield takeEvery(communityActions.SHARE_COMMUNITY_BY_MAIL, shareCommunityByEmailSaga);

  yield takeEvery(communityActions.CACHE_UPDATE_COMMUNITY_LIKE, updateCacheCommunityLikeSaga);
  yield takeEvery(communityActions.CACHE_UPDATE_COMMUNITIES, updateCacheCommunitiesSaga);
  yield takeEvery(communityActions.CACHE_UPDATE_GROUPS, updateCacheGroupsSaga);
  yield takeEvery(communityActions.CACHE_UPDATE_EVENTS, updateCacheEventsSaga);
  yield takeEvery(communityActions.CACHE_UPDATE_JOIN_REQUESTS, updateCacheJoinRequestsSaga);

  yield takeEvery(communityActions.CACHE_UPDATE_PARTICIPANTS, updateCacheParticipantsSaga);

  yield takeEvery(communityActions.CACHE_UPDATE_MANAGERS, updateCacheManagersSaga);
}
