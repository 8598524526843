// @flow
import React, { useState, useCallback, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import clsx from 'clsx';

import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import ModalRegister from '@containers/modals/ModalRegister';

import {
  ONLINE_PROGRAMS_CARDS_FR,
  ONLINE_PROGRAMS_CARDS_EN,
  ONLINE_PROGRAMS_CARDS_ES,
  ONLINE_PROGRAMS_CARDS_DE,
  ONLINE_PROGRAMS_CARDS_NL,
  ONLINE_PROGRAMS_CARDS_PT,
  ONLINE_PROGRAMS_CARDS_IT,
} from '../constants';

import OnlineProgramsCardItem from './OnlineProgramsCardItem';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    paddingTop: '5vh',
  },
}));

const OnlineProgramsCard = () => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const [signupModalActive, setSignupModalActive] = useState(false);
  const [cardItems, setCardItems] = useState([]);

  const history = useHistory();
  const { language } = i18n;

  useEffect(() => {
    switch (language) {
      case 'fr':
        setCardItems(ONLINE_PROGRAMS_CARDS_FR);
        break;
      case 'en':
        setCardItems(ONLINE_PROGRAMS_CARDS_EN);
        break;
      case 'es':
      case 'es-AR':
        setCardItems(ONLINE_PROGRAMS_CARDS_ES);
        break;
      case 'de':
        setCardItems(ONLINE_PROGRAMS_CARDS_DE);
        break;
      case 'nl':
        setCardItems(ONLINE_PROGRAMS_CARDS_NL);
        break;
      case 'pt':
        setCardItems(ONLINE_PROGRAMS_CARDS_PT);
        break;
      case 'it':
        setCardItems(ONLINE_PROGRAMS_CARDS_IT);
        break;
      default:
        break;
    }
  }, [cardItems, language, setCardItems]);

  const handleSubscribe = useCallback(() => {
    setSignupModalActive(true);
  }, [setSignupModalActive]);

  const handleCloseSignup = useCallback(() => {
    setSignupModalActive(false);
  }, [setSignupModalActive]);

  const handleRedirect = (redirectUrl) => {
    if (redirectUrl) history.push(redirectUrl);
  };

  return (
    <>
      <div className={clsx(classes.root)}>
        {cardItems.map((item) => (
          <OnlineProgramsCardItem
            key={item.id}
            title={t(item.title)}
            text={t(item.text)}
            imgSrc={item.imgSrc}
            imgAlt={t(item.imgAlt)}
            handleSubscribe={item.redirect ? () => handleRedirect(item.redirect) : handleSubscribe}
          />
        ))}
      </div>
      {signupModalActive && <ModalRegister handleClose={handleCloseSignup} />}
    </>
  );
};

export default OnlineProgramsCard;
