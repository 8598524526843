// @flow
import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';

import { publicTheme } from '@layout/theme';

import { NEUTRAL_COLOR } from '@shared/constants';

import OnlineProgramsTexts from './OnlineProgramsTexts';
import OnlineProgramsCards from './OnlineProgramsCards';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: NEUTRAL_COLOR,
    paddingTop: '5vh',
    paddingBottom: '5vh',
    width: '100%',
    [publicTheme.breakpoints.down('xs')]: {
      paddingTop: '0',
    },
  },
  content: {
    paddingLeft: '24px',
    paddingRight: '24px',
    [publicTheme.breakpoints.down('md')]: {
      width: '100vw',
    },
    [publicTheme.breakpoints.up('lg')]: {
      width: `${publicTheme.breakpoints.values.lg}px`,
      margin: '0 auto',
    },
  },
}));

const OnlineProgramsSection = () => {
  const classes = useStyles();
  return (
    <section className={clsx(classes.root)}>
      <div className={clsx(classes.content)}>
        <OnlineProgramsTexts />
        <OnlineProgramsCards />
      </div>
    </section>
  );
};

export default OnlineProgramsSection;
