// @flow
import type { State, Action } from '@core/models';
import * as appActions from '@store/modules/App/actions';

const initialState: State = {
  language: '',
  languages: [],
};

export default (state: State = initialState, action: Action) => {
  switch (action.type) {
    case appActions.SWITCH_LANGUAGE_SET:
      return {
        ...state,
        language: action.code,
      };
    case appActions.SET_LANGUAGES:
      return {
        ...state,
        languages: action.payload,
      };
    default:
      return state;
  }
};
