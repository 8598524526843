import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';

// @flow
import { publicTheme } from '@layout/theme';

import { WHITE } from '@shared/constants';

import GroupLessonsTexts from './GroupLessonsTexts';
import GroupLessonsCards from './GroupLessonsCards';
import GroupLessonsMobileCards from './GroupLessonsMobileCards';
import GroupLessonSubscribe from './GroupLessonSubscribe';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: WHITE,
    paddingTop: '5vh',
    paddingBottom: '5vh',
    width: '100%',
  },
  content: {
    paddingLeft: '24px',
    paddingRight: '24px',
    [publicTheme.breakpoints.down('md')]: {
      width: '100vw',
    },
    [publicTheme.breakpoints.up('lg')]: {
      width: `${publicTheme.breakpoints.values.lg}px`,
      margin: '0 auto',
    },
  },
}));

const GroupLessonsSection = () => {
  const classes = useStyles();
  return (
    <section className={clsx(classes.root)}>
      <div className={clsx(classes.content)}>
        <GroupLessonsTexts />
        <GroupLessonsCards />
        <GroupLessonsMobileCards />
        <GroupLessonSubscribe />
      </div>
    </section>
  );
};

export default GroupLessonsSection;
