/* eslint-disable camelcase */
import axios from 'axios';
// const objectToFormData = require('object-to-formdata');
import objectToFormData from 'object-to-formdata';

import { getToken } from 'services/token';
import { getCurrentRole } from 'services/roles';
import { getLocale } from 'services/localisation';

const apiUrl = process.env.REACT_APP_API_URL;
// const apiUrl = 'http://localhost/api/'

function axiosConfig(params, headers = {}) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Accept: 'application/json',
      'X-localization': getLocale(),
      ...headers,
    },
  };

  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  const role = getCurrentRole();
  if (role) {
    config.headers['x-role'] = role;
  }

  config.params = {};

  if (params) {
    config.params = params;

    Object.keys(config.params).map((key) => {
      if (config.params[key] === 'true' || config.params[key] === true) {
        config.params[key] = 1;
      }
      if (config.params[key] === 'false' || config.params[key] === false) {
        config.params[key] = 0;
      }

      return null;
    });
  }

  return config;
}

function axiosConfigForm(params, headers = {}) {
  const config = axiosConfig(params, headers);
  // config.headers['Content-Type'] = `multipart/form-data; boundary=${data._boundary}`;
  return config;
}

function post(url, data, headers = {}) {
  const config = axiosConfigForm(data, headers);

  const configToSend = { headers: config.headers };

  return axios.post(apiUrl + url, config.params, configToSend);
}

function postForm(url, data, headers = {}) {
  const config = axiosConfigForm(data, headers);

  const configToSend = { headers: config.headers };
  // const body = config.params;

  const options = {
    /**
     * whether or not to include array indices in FormData keys
     * defaults to false
     */
    indices: true,

    /**
     * whether or not to include null values as empty strings in FormData instance
     * defaults to true
     */
    nulls: true,
  };

  const formData = objectToFormData(config.params, options);
  // config.headers['Content-Type'] = `multipart/form-data; boundary=${data._boundary}`;
  config.headers['Content-Type'] = `application/x-www-form-urlencoded`;

  return axios.post(apiUrl + url, formData, configToSend);
}

function put(url, data) {
  const config = axiosConfig(data);

  const configToSend = { headers: config.headers };

  return axios.put(apiUrl + url, config.params, configToSend);

  // return axios.put(
  //   apiUrl + url,
  //   data,
  //   axiosConfig(data)
  // );
}

function deleteIt(url, data) {
  return axios.delete(apiUrl + url, axiosConfig(data));
}

function get(url, data) {
  return axios.get(apiUrl + url, axiosConfig(data));
}

function getBlob(url, data, _headers = { responseType: 'arraybuffer' }, openPdf = false) {
  const headers = { ..._headers };

  return axios.get(apiUrl + url, axiosConfig(data, headers)).then((response) => {
    if (openPdf) {
      const separator = 'base64,';
      const splited = response.data.file.split(separator);

      const byteCharacters = atob(splited[1]);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i += 1) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      // eslint-disable-next-line no-undef
      const byteArray = new Uint8Array(byteNumbers);
      const file = new Blob([byteArray], { type: 'application/pdf;base64' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    }

    const link = document.createElement('a');
    link.href = response.data.file;
    link.setAttribute('download', response.data.file_name);
    document.body.appendChild(link);
    link.click();
    link.remove();
  });
}

export const Login = (data) => {
  const url = 'signin';
  return post(url, data);
};

export const LogAs = (userId) => {
  const url = `signin/${userId}`;
  return get(url);
};

export const ForgotPasswordRequest = (data) => {
  const url = 'password';
  return get(url, data);
};

export const GetAppVersion = () => {
  return axios.get(`/versionfile?q=${new Date().getTime()}`);
};

export const SubmitNewPassword = (data) => {
  const url = 'password';
  return post(url, data);
};

export const Logout = (data) => {
  const url = 'logout';
  return get(url, data);
};

export const Register = (formData) => {
  const url = 'signup';
  return post(url, formData);
};

export const Unsubscribe = (data) => {
  const url = 'unsubscribe';
  return post(url, data);
};

export const ConfirmEmail = (data) => {
  const url = 'confirm';
  return get(url, data.payload);
};

export const ResendConfirmEmail = (data) => {
  const url = 'confirm';
  return post(url, data);
};

export const RestoreAccount = (data) => {
  const url = 'restore';
  return get(url, data.payload);
};

export const ResendRestoreAccountEmail = (data) => {
  const url = 'restore';
  return post(url, data);
};

export const RefreshToken = () => {
  const url = 'refreshToken';
  return get(url);
};

export const UpdateUserLanguage = (data) => {
  const url = 'language';
  return put(url, data);
};

export const GetLanguages = () => {
  const url = `languages`;
  return get(url);
};

export const GetLevels = () => {
  const url = `levels`;
  return get(url);
};

export const GetUser = (id) => {
  const url = `users/${id}`;
  return get(url);
};

export const DeleteUser = (id, data) => {
  const url = `users/${id}`;
  return deleteIt(url, data);
};

export const GetUserCoach = (id) => {
  const url = `users/${id}/coaches`;
  return get(url);
};

export const UserSearch = (data) => {
  const url = `users`;
  return get(url, data);
};

export const CreateUser = (data) => {
  const url = `users`;
  return post(url, data);
};

export const UpdateProfile = (data) => {
  const url = `users/me`;
  return put(url, data);
};

export const UpdatePassword = (data) => {
  const url = `users/password/${data.id}`;
  return put(url, data);
};

export const UpdateUser = (userId, data) => {
  const url = `users/${userId}`;
  return put(url, data);
};

export const UpdateUsers = (data) => {
  const url = `users/batch_update`;
  return post(url, data);
};

export const GetResetOptions = (data) => {
  const url = `users/sys_admin/options`;
  return get(url, data);
};

export const getSectorsFromUser = (userId) => {
  const url = `users/${userId}/sectors`;
  return get(url);
};

export const RoleGetAll = (data) => {
  const url = `roles`;
  return get(url, data);
};

export const AddUserRole = (userId, data) => {
  const url = `users/${userId}/roles`;
  return post(url, data);
};

export const AddUserRoles = (userId, data) => {
  const url = `users/${userId}/roles`;
  return put(url, data);
};
export const GetUserJourney = (userId, data) => {
  const url = `users/${userId}/journey`;
  return get(url, data);
};

export const setUserAvatar = (userId, data) => {
  const url = `users/${userId}/photo`;
  return postForm(url, data);
};

export const SendFriendInvite = (userId, data) => {
  const url = `users/${userId}/friends_requests`;
  return post(url, data);
};

export const RefuseFriendInvite = (userId, data) => {
  const url = `users/${userId}/friends_requests`;
  return deleteIt(url, data);
};

export const AcceptFriendInvite = (userId, data) => {
  const url = `users/${userId}/friends_requests`;
  return put(url, data);
};

export const RemoveFriend = (userId, friendId) => {
  const url = `users/${userId}/friends/${friendId}`;
  return deleteIt(url);
};

export const GetFriends = (userId, data) => {
  const url = `users/${userId}/friends`;
  return get(url, data);
};

export const GetFriendSuggestions = (data) => {
  const url = `friend_suggestions`;
  return get(url, data);
};

export const GetGroupSuggestions = (data) => {
  const url = `group_suggestions`;
  return get(url, data);
};

export const SendEmailInvite = (userId, data) => {
  const url = `users/${userId}/invitations`;
  return post(url, data);
};

export const SendGetphyInvitations = (data) => {
  const url = `invitations`;
  return post(url, data);
};

export const SubmitPrimaryQuestionnaire = (data) => {
  const url = `primary_questionnaires`;
  return post(url, data);
};

export const GetPrimaryQuestionnaire = () => {
  const url = `primary_questionnaires`;
  return get(url);
};

export const SaveDeclarationPrimaryQuestionnaire = (userId, data) => {
  const url = `users/${userId}/declaration`;
  return post(url, data);
};

export const ChangeQuestionnaireState = (userId, data) => {
  const url = `users/${userId}/questionnaires`;
  return put(url, data);
};

export const GetUserWeeklyReport = (userId, data) => {
  const url = `users/${userId}/weekly_report`;
  return get(url, data);
};
export const FillUserWeeklyReport = (data) => {
  const url = `weekly_report`;
  return put(url, data);
};

export const BilansInfoSubmit = (userId, data) => {
  const url = `users/${userId}/information_questionnaire`;
  return put(url, data);
};

export const BilansInfoGet = (userId, data) => {
  const url = `users/${userId}/information_questionnaire`;
  return get(url, data);
};

export const BilansInitialSubmit = (userId, data) => {
  const url = `users/${userId}/initial_balance_sheets`;
  return post(url, data);
};

export const BilansInitialGet = (userId, data) => {
  const url = `users/${userId}/initial_balance_sheets`;
  return get(url, data);
};

export const BilansInitialSubmitComment = (initialId, data) => {
  const url = `initial_balance_sheets/${initialId}`;
  return put(url, data);
};

export const BilansIntermediateSubmit = (userId, data) => {
  const url = `users/${userId}/intermediary_balance_sheets`;
  return post(url, data);
};

export const BilansIntermediateGet = (userId, data) => {
  const url = `users/${userId}/intermediary_balance_sheets`;
  return get(url, data);
};

export const BilansFinalSubmit = (userId, data) => {
  const url = `users/${userId}/final_balance_sheets`;
  return post(url, data);
};

export const BilansFinalGet = (userId, data) => {
  const url = `users/${userId}/final_balance_sheets`;
  return get(url, data);
};

export const BilansSatisfactionSubmit = (userId, data) => {
  const url = `users/${userId}/satisfaction_questionnaires`;
  return post(url, data);
};

export const BilansSatisfactionGet = (userId, data) => {
  const url = `users/${userId}/satisfaction_questionnaires`;
  return get(url, data);
};

export const BilansAnalysisGetResources = (userId, data) => {
  const url = `users/${userId}/questionnaire_analyses_resources`;
  return get(url, data);
};

export const BilansAnalysisCreate = (userId, data) => {
  const url = `users/${userId}/questionnaire_analyses`;
  return post(url, data);
};

export const BilansAnalysisGet = (userId, data) => {
  const url = `users/${userId}/questionnaire_analyses`;
  return get(url, data);
};

export const ExportUsers = (data) => {
  const url = `exports/users`;
  const headers = { responseType: 'blob' };

  return getBlob(url, data, headers);
};

export const CreateEventRequest = (data) => {
  const url = `events`;
  return post(url, data);
};

export const UpdateEvent = (eventId, data) => {
  const url = `events/${eventId}`;
  return put(url, data);
};

export const CreateGroupRequest = (data) => {
  const url = `groups`;
  return post(url, data);
};

export const UpdateGroup = (groupId, data) => {
  const url = `groups/${groupId}`;
  return put(url, data);
};

export const GetCommunity = (communityId, data) => {
  const url = `communities/${communityId}`;
  return get(url, data);
};

export const GetCommunityUser = (userId) => {
  const url = `users/${userId}/communities-min`;
  return get(url);
};

export const GetEditCommunity = (communityId, data) => {
  const url = `communities/${communityId}/edit`;
  return get(url, data);
};

export const CommunitySearch = (data) => {
  const url = `communities`;
  return get(url, data);
};

export const CommunityMapSearch = (data) => {
  const url = `communities-map`;
  return get(url, data);
};

export const CommunitySearchByUserId = (id) => {
  const url = `users/${id}/communities`;
  return get(url);
};

export const CommunityGetParticipants = (id, data) => {
  const url = `communities/${id}/users`;
  return get(url, data);
};

export const CommunityAddInactive = (communityId, data) => {
  const url = `communities/${communityId}/users`;
  return post(url, data);
};

export const CommunityAddParticipant = (communityId, userId, addInGroup) => {
  const url = `communities/${communityId}/users/${userId}?addInGroup=${addInGroup ? 1 : 0}`;
  return post(url);
};

export const CommunityEditParticipant = (communityId, usedId, data) => {
  const url = `communities/${communityId}/users/${usedId}`;
  return put(url, data);
};

export const CommunityRemoveParticipant = (communityId, userId) => {
  const url = `communities/${communityId}/users/${userId}`;
  return deleteIt(url);
};

export const CommunityRemovePendingPayment = (communityId, paymentId, data) => {
  const url = `communities/${communityId}/payments/${paymentId}`;
  return deleteIt(url, data);
};

export const CommunitySendSmsToParticipant = (communityId, data) => {
  const url = `communities/${communityId}/sms`;
  return post(url, data);
};

export const CommunitySendMailToParticipant = (data) => {
  const url = `emails`;
  return post(url, data);
};

export const CommunitySendChatToParticipant = (data) => {
  const url = `messages`;
  return post(url, data);
};

export const SetFileToMessage = (messageId, data) => {
  const url = `messages/${messageId}/file`;
  return postForm(url, data);
};

export const GetCommunityJoinRequests = (id, data) => {
  const url = `communities/${id}/join_requests`;
  return get(url, data);
};

export const CommunityJoin = (id, data) => {
  const url = `communities/${id}/join_requests`;
  return post(url, data);
};

export const CommunityLeave = (communityId, userId, data) => {
  const url = `users/${userId}/communities/${communityId}`;
  return deleteIt(url, data);
};

export const CommunityAcceptJoinRequest = (communityId, userId) => {
  const url = `communities/${communityId}/join_requests/${userId}`;
  return put(url);
};

export const CommunityRefuseJoinRequest = (communityId, userId) => {
  const url = `communities/${communityId}/join_requests/${userId}`;
  return deleteIt(url);
};

export const CommunityArchive = (communityId, data) => {
  const url = `communities/${communityId}/archives`;
  return put(url, data);
};

export const CommunityDearchive = (communityId, data) => {
  const url = `communities/${communityId}/archives`;
  return deleteIt(url, data);
};

export const FetchCommunitiesRequestsSearch = (data) => {
  const url = `communities_requests`;
  return get(url, data);
};

export const AcceptCommunityRequest = (id) => {
  const url = `communities_requests/${id}`;
  return put(url);
};

export const RefuseCommunityRequest = (id) => {
  const url = `communities_requests/${id}`;
  return deleteIt(url);
};

export const parseCommunityCSV = (data) => {
  const url = `parseCSV/communities`;
  return postForm(url, data);
};

export const uploadCommunityCSV = (data) => {
  const url = `import/communities`;
  return post(url, data);
};

export const UpdateCommunity = (communityId, data) => {
  const url = `communities/${communityId}`;
  return put(url, data);
};

export const setCommunityFeatureImage = (communityId, data) => {
  const url = `communities/${communityId}/photo`;
  return postForm(url, data);
};

export const GetCommunityPaymentStatus = (communityId, data) => {
  const url = `communities/${communityId}/payments`;
  return get(url, data);
};

export const ValidatePaymentPeriod = (periodId) => {
  const url = `communities/payment_period/${periodId}`;
  return put(url);
};

export const AddCustomPrice = (communityId, data) => {
  const url = `communities/${communityId}/custom_prices`;
  return post(url, data);
};

export const UpdateCustomPrice = (communityId, customPriceId, data) => {
  const url = `communities/${communityId}/custom_prices/${customPriceId}`;
  return put(url, data);
};

export const AddCommunityPayment = (communityId, data) => {
  const url = `communities/${communityId}/payments`;
  return post(url, data);
};

export const UpdateCommunityPayment = (communityId, paymentId, data) => {
  const url = `communities/${communityId}/payments/${paymentId}`;
  return put(url, data);
};

export const TransferPayment = (paymentId, data) => {
  const url = `payments/${paymentId}/transfer`;
  return put(url, data);
};

export const UpdateUsersOnlinePayment = (communityId, data) => {
  const url = `communities/${communityId}/users_online_payment`;
  return put(url, data);
};

export const InitiateCommunityPayment = (communityId, data) => {
  const url = `communities/${communityId}/online_payment`;
  return get(url, data);
};

export const ShareCommunityByMail = (communityId, data) => {
  const url = `communities/${communityId}/share`;
  return post(url, data);
};

export const SubmitFailedPayment = (data) => {
  const url = `payments_refused`;
  return post(url, data);
};

export const SubmitPaymentPending = (data) => {
  const url = `payments_pending`;
  return post(url, data);
};

export const RemoveExpiredInitiatedPayments = (data) => {
  const url = `payments_initiated`;
  return get(url, data);
};

export const MesAmisSearch = (data) => {
  const url = `search`;
  return get(url, data);
};

// Community request
export const CommunityGetRequest = (data) => {
  const url = `communities_requests`;
  return get(url, data);
};

export const CommunityAcceptRequest = (communityId, data) => {
  const url = `communities_requests/${communityId}`;
  return put(url, data);
};

export const CommunityRefuseRequest = (communityId, data) => {
  const url = `communities_requests/${communityId}`;
  return deleteIt(url, data);
};

export const ExportCommunities = (data) => {
  const url = `exports/communities`;
  const headers = { responseType: 'blob' };

  return getBlob(url, data, headers);
};

export const ExportCommunity = (id, data) => {
  const url = `exports/communities/${id}`;
  const headers = { responseType: 'blob' };

  return getBlob(url, data, headers);
};

export const PdfReceipt = (id) => {
  const url = `payment/pdf/${id}`;
  const headers = { responseType: 'blob' };

  return getBlob(url, {}, headers, true);
};

export const AssessmentDocument = (id) => {
  const url = `assessment_document/${id}`;
  const headers = { responseType: 'blob' };

  return getBlob(url, {}, headers, true);
};

// Calendar
export const CalendarSearch = (userId, data) => {
  const url = `calendar/${userId}`;
  return get(url, data);
};

export const AddSessionToCalendar = (userId, data) => {
  const url = `calendar/${userId}/sessions`;
  return post(url, data);
};

export const UpdateSessionInCalendar = (userId, sessionUserId, data) => {
  const url = `calendar/sessions/${sessionUserId}`;
  return put(url, data);
};

export const DeleteSessionFromCalendar = (userId, sessionUserId, data) => {
  const url = `calendar/sessions/${sessionUserId}`;
  return deleteIt(url, data);
};

export const AddProgramToCalendar = (userId, data) => {
  const url = `calendar/${userId}/programs`;
  return post(url, data);
};

export const MakeInactivityChoice = (userId, data) => {
  const url = `calendar/${userId}/inactivity_choice`;
  return post(url, data);
};

// Session

export const GetUserSessionsHistory = (userId, data) => {
  const url = `users/${userId}/sessions`;
  return get(url, data);
};

export const GetUserFavoriteSessionsHistory = (userId, data) => {
  const url = `users/${userId}/favorite_sessions`;
  return get(url, data);
};

export const SessionFlowExerciseSubmit = (sessionUserId, sessionExerciseId, data) => {
  const url = `done/sessions/${sessionUserId}/exercises/${sessionExerciseId}`;
  return post(url, data);
};

export const SessionFlowChallengeSubmit = (sessionUserId, challengeExerciseId, data) => {
  const url = `done/sessions/${sessionUserId}/challenges/${challengeExerciseId}`;
  return post(url, data);
};

export const SessionFlowSessionSubmit = (sessionUserId) => {
  const url = `done/sessions/${sessionUserId}`;
  return post(url);
};

export const SessionFlowFeedback = (sessionUserId, data) => {
  const url = `session/feedback/${sessionUserId}`;
  return post(url, data);
};

export const InitiateSessionFlow = (data) => {
  const url = `initiate/session/${data.session_id}`;
  return post(url, data);
};

export const SessionFlowGetSessionData = (sessionId) => {
  const url = `done/sessions/${sessionId}`;
  return get(url);
};

export const GetAllSessions = (data) => {
  const url = `sessions`;
  return get(url, data);
};

export const GetAllSessionsMin = (data) => {
  const url = `sessions-search`;
  return get(url, data);
};

export const GetSingleSession = (sessionId, data) => {
  const url = `sessions/${sessionId}`;
  return get(url, data);
};

export const GetSessionUser = (sessionUserId) => {
  const url = `calendar/session_user/${sessionUserId}`;
  return get(url);
};

export const GetPhysicalTestLevels = (data) => {
  const url = `physical_test/levels`;
  return get(url, data);
};

export const GetPhysicalTestWelcomeMessage = () => {
  const url = `assessments/configurations/pt_welcome_message`;
  return get(url);
};

export const GetSessionIntroMessage = () => {
  const url = `assessments/configurations/session_intro_message`;
  return get(url);
};

export const GetPhysicalTestByPrescriber = (prescriberId, data) => {
  const url = `prescribers/${prescriberId}/physical_tests`;
  return get(url, data);
};

export const FetchPhysicalTestByLevel = (levelId, data) => {
  const url = `levels/${levelId}/physical_test`;
  return get(url, data);
};

export const GetPhysicalTestToUser = (userId, data) => {
  const url = `users/${userId}/physical_test_users`;
  return get(url, data);
};

export const AssignPhysicalTestToUser = (userId, data) => {
  const url = `users/${userId}/physical_test_users`;
  return post(url, data);
};

export const SubmitPhysicalTestForUser = (userId, physicalTestUserId, data) => {
  const url = `users/${userId}/physical_test_users/${physicalTestUserId}`;
  return put(url, data);
};

export const GetSuggestedSessions = (data) => {
  const url = `sessions_suggestions`;
  return get(url, data);
};

export const startSuggestedPrograms = (data) => {
  const url = `suggestions`;
  return put(url, data);
};

// Program
export const GetAllPrograms = (data) => {
  const url = `programs`;
  return get(url, data);
};

// Prescriber
export const GetAllPrescribers = (data) => {
  const url = `prescribers`;
  return get(url, data);
};

export const GetListPrescribers = (data) => {
  const url = `prescribers/list`;
  return get(url, data);
};

export const PrescriberDashboardDataGet = (prescriberId, dataType, data) => {
  const url = `prescribers/${prescriberId}/${dataType}`;
  return get(url, data);
};

export const GetPrescriberInviteCodes = (data) => {
  const url = `codes`;
  return get(url, data);
};

export const CreatePrescriberInviteCodes = (data) => {
  const url = `codes`;
  return post(url, data);
};

export const EditPrescriberInviteCodes = (data, codeId) => {
  const url = `codes/${codeId}`;
  return put(url, data);
};

export const CheckInviteCode = (code, data) => {
  const url = `check_code/${code}`;
  return get(url, data);
};

export const ExportPrescriberInviteCode = (prescriberId, data) => {
  const url = `exports/prescriber_codes/${prescriberId}`;
  return getBlob(url, data);
};

export const CreateNewPrescriber = (data) => {
  const url = `prescribers`;
  return post(url, data);
};

export const EditPrescriber = (prescriberId, data) => {
  const url = `prescribers/${prescriberId}`;
  return put(url, data);
};

export const LinkUserToPrescriber = (data) => {
  const url = `use_code`;
  return post(url, data);
};

export const UnlinkUserToPrescriber = (userId, data) => {
  const url = `users/${userId}/unlinkPrescriber`;
  return deleteIt(url, data);
};

// Coach
export const CoachRequestSubmit = (userId, data) => {
  const url = `users/${userId}/calls`;
  return post(url, data);
};

export const CoachGetAll = (data) => {
  const url = `coaches`;
  return get(url, data);
};

export const AssignCoachToUser = (userId, data) => {
  const url = `users/${userId}/coaches`;
  return post(url, data);
};

export const GetAdherents = (coachId, data) => {
  const url = `coaches/${coachId}/users`;
  return get(url, data);
};

export const GetCoachesUsers = ({ id, search, offset, limit, minUser }) => {
  const url = `coaches/users${id ? `/${id}` : ''}?limit=${limit}&${
    search ? `search=${search}&` : ''
  }${offset ? `offset=${offset}` : ''}${minUser ? `userId=${minUser}` : ''}`;
  return get(url);
};

export const GetCoachesUsersMin = (coachId) => {
  const url = `coaches/users-min${coachId ? `/${coachId}` : ''}`;
  return get(url);
};

export const GetCoachesUpcomingTask = ({ search, minUser }) => {
  const url = `coach/tasks?${search ? `search=${search}&` : ''}${
    minUser ? `userId=${minUser}` : ''
  }`;
  return get(url);
};

export const GetCoachTableTask = (userId) => {
  const url = `users/${userId}/tasks`;
  return get(url);
};

export const GetCoachesQuestionAnswer = (userId) => {
  const url = `users/${userId}/primary_questionnaire_answers`;
  return get(url);
};

export const GetCoachDetail = (userId) => {
  const url = `coaches/users/${userId}`;
  return get(url);
};

export const GetCoachAssesmentTask = (taskId) => {
  const url = `users/tasks/${taskId}`;
  return get(url);
};

export const UpdateCoachAssesmentTask = (taskId, data) => {
  const url = `users/tasks/${taskId}`;
  return put(url, data);
};

export const GetCoachAssesmentCallback = (taskId) => {
  const url = `users/tasks/${taskId}`;
  return get(url);
};

export const GetCoachCalendarDate = (userId, data) => {
  const url = `calendar/${userId}`;
  return get(url, { month: data });
};

export const GetCoachSessionUser = (userId) => {
  const url = `users/${userId}/session_users`;
  return get(url);
};

export const ChangeCoachAssesmentTaskDate = (taskId, data) => {
  const url = `users/tasks/${taskId}/initial_callbacks`;
  return put(url, data);
};

export const GetCoachNotes = (userId) => {
  const url = `users/${userId}/tasks/notes`;
  return get(url);
};

export const GetPhysicalEvolutionTest = (userId) => {
  const url = `users/tasks/${userId}/physical_test_users`;
  return get(url);
};

export const PutPhysicalEvolutionTest = (userId, data) => {
  const url = `users/tasks/${userId}/physical_test_users`;
  return put(url, data);
};

export const AddUserSession = (userId, sessionId, start_date) => {
  const url = `users/${userId}/sessions/${sessionId}`;
  return post(url, { start_date });
};

export const AddUserProgram = (userId, programId, start_date) => {
  const url = `users/${userId}/programs/${programId}`;
  return post(url, { start_date });
};

export const GetProgramUser = (value) => {
  const url = 'programs-search';
  return get(url, { search: value });
};

export const GetSessionsUser = (value) => {
  const url = 'sessions-search';
  return get(url, { search: value });
};

// Calls
export const CallsGet = (data) => {
  const url = `calls`;
  return get(url, data);
};

export const CallDone = (callId, data) => {
  const url = `calls/${callId}`;
  return put(url, data);
};

// Goals
export const GetAllGoals = (data) => {
  const url = `goals`;
  return get(url, data);
};

export const GetUserGoals = (userId, data) => {
  const url = `users/${userId}/goals`;
  return get(url, data);
};

export const UpdateGoalAnswer = (userId, goalUserId, data) => {
  const url = `users/${userId}/goals/${goalUserId}`;
  return put(url, data);
};

export const AddGoalToUser = (userId, data) => {
  const url = `users/${userId}/goals`;
  return post(url, data);
};

export const AddCommonGoal = (data) => {
  const url = `goals`;
  return post(url, data);
};

export const DeleteGoal = (goalId, data) => {
  const url = `goals/${goalId}`;
  return deleteIt(url, data);
};

export const RemoveUserGoal = (userId, goalUserId, data) => {
  const url = `users/${userId}/goals/${goalUserId}`;
  return deleteIt(url, data);
};

// Discussions
export const GetUserUnreads = (userId, data) => {
  const url = `users/${userId}/unreads`;
  return get(url, data);
};

export const GetUserDiscussion = (userId, data) => {
  const url = `users/${userId}/discussion`;
  return get(url, data);
};

export const GetCommunityDiscussion = (communityId, data) => {
  const url = `communities/${communityId}/discussion`;
  return get(url, data);
};

export const GetDiscussionById = (discussionId, data) => {
  const url = `discussions/${discussionId}`;
  return get(url, data);
};

export const GetLatestMessages = (userId, data) => {
  const url = `users/${userId}/latest_messages`;
  return get(url, data);
};

export const SendUserMessage = (userId, data) => {
  const url = `users/${userId}/discussion`;
  return post(url, data);
};

export const SendCommunityMessage = (communityId, data) => {
  const url = `communities/${communityId}/discussion`;
  return post(url, data);
};

export const DeleteUserMessage = (messageId, data) => {
  const url = `messages/${messageId}`;
  return deleteIt(url, data);
};
// Resources

export const GetQuestionannireResources = (data) => {
  const url = `questionnaires_resources`;
  return get(url, data);
};

export const GetCommunityResources = (data) => {
  const url = `communities_resources`;
  return get(url, data);
};

export const GetCountries = () => {
  const url = `countries`;
  return get(url);
};

export const GetExercisesResources = () => {
  const url = `exercises_resources`;
  return get(url);
};

export const GetSessionsResources = () => {
  const url = `sessions_resources`;
  return get(url);
};

export const GetProgramsResources = (data) => {
  const url = `programs_resources`;
  return get(url, data);
};

export const GetArticlesResources = (data) => {
  const url = `articles_resources`;
  return get(url, data);
};

export const GetCoachesResources = (data) => {
  const url = `coaches_resources`;
  return get(url, data);
};

// Articles
export const GetArticles = (data) => {
  const url = `articles`;
  return get(url, data);
};

export const GetArticleToEdit = (articleId, data) => {
  const url = `articles/${articleId}/edit`;
  return get(url, data);
};

export const CreateArticle = (data) => {
  const url = `articles`;
  return postForm(url, data);
};

export const UpdateArticle = (articleId, data) => {
  const url = `articles/${articleId}`;
  return postForm(url, data);
};

export const TogglePublishedArticle = (articleId, data) => {
  const url = `articles/toggle-published/${articleId}`;
  return put(url, data);
};

export const DeleteArticle = (articleId) => {
  const url = `articles/${articleId}`;
  return deleteIt(url);
};

// Blog
export const GetBlog = (data) => {
  const url = `blog`;
  return get(url, data);
};

export const GetArticleSlugs = (data) => {
  const url = `blog/${data}/slugs`;
  return get(url);
};

export const GetArticleToShow = (slug) => {
  const url = `blog/${slug}`;
  return get(url);
};

// Help
// Tutorials
export const GetTutorials = (data) => {
  const url = `help/tutorials`;
  return get(url, data);
};

export const CreateTutorial = (data) => {
  const url = `help/tutorials`;
  return postForm(url, data);
};

export const UpdateTutorial = (tutorialId, data) => {
  const url = `help/tutorials/${tutorialId}`;
  return put(url, data);
};

export const DeleteTutorial = (tutorialId) => {
  const url = `help/tutorials/${tutorialId}`;
  return deleteIt(url);
};

export const DeleteTutorialPreview = (tutorialId) => {
  const url = `help/tutorials/${tutorialId}/preview`;
  return deleteIt(url);
};

export const GetTutorialsCategories = (data) => {
  const url = `help/tutorials/categories`;
  return get(url, data);
};

export const CreateTutorialCategory = (data) => {
  const url = `help/tutorials/categories`;
  return postForm(url, data);
};

export const UpdateTutorialCategory = (tutorialCategoryId, data) => {
  const url = `help/tutorials/categories/${tutorialCategoryId}`;
  return put(url, data);
};

export const DeleteTutorialCategory = (tutorialCategoryId) => {
  const url = `help/tutorials/categories/${tutorialCategoryId}`;
  return deleteIt(url);
};

// Faq
export const GetFaqs = (data) => {
  const url = `help/faq`;
  return get(url, data);
};

export const CreateFaq = (data) => {
  const url = `help/faq`;
  return postForm(url, data);
};

export const UpdateFaq = (faqId, data) => {
  const url = `help/faq/${faqId}`;
  return put(url, data);
};

export const DeleteFaq = (faqId) => {
  const url = `help/faq/${faqId}`;
  return deleteIt(url);
};

export const GetFaqCategories = (data) => {
  const url = `help/faq/categories`;
  return get(url, data);
};

export const CreateFaqCategory = (data) => {
  const url = `help/faq/categories`;
  return postForm(url, data);
};

export const UpdateFaqCategory = (faqCategoryId, data) => {
  const url = `help/faq/categories/${faqCategoryId}`;
  return put(url, data);
};

export const DeleteFaqCategory = (faqCategoryId) => {
  const url = `help/faq/categories/${faqCategoryId}`;
  return deleteIt(url);
};

// Glossary
export const GetGlossaries = (data) => {
  const url = `help/glossary`;
  return get(url, data);
};

export const CreateGlossary = (data) => {
  const url = `help/glossary`;
  return postForm(url, data);
};

export const UpdateGlossary = (glossaryId, data) => {
  const url = `help/glossary/${glossaryId}`;
  return put(url, data);
};

export const DeleteGlossary = (glossaryId) => {
  const url = `help/glossary/${glossaryId}`;
  return deleteIt(url);
};

export const GetGlossaryCategories = (data) => {
  const url = `help/glossary/categories`;
  return get(url, data);
};

export const CreateGlossaryCategory = (data) => {
  const url = `help/glossary/categories`;
  return postForm(url, data);
};

export const UpdateGlossaryCategory = (glossaryCategoryId, data) => {
  const url = `help/glossary/categories/${glossaryCategoryId}`;
  return put(url, data);
};

export const DeleteGlossaryCategory = (glossaryCategoryId) => {
  const url = `help/glossary/categories/${glossaryCategoryId}`;
  return deleteIt(url);
};

// Contact us
export const GetContactUs = (data) => {
  const url = `help/contact_us`;
  return get(url, data);
};

export const CreateContactUs = (data) => {
  const url = `help/contact_us`;
  return postForm(url, data);
};

export const UpdateContactUs = (contactUsId, data) => {
  const url = `help/contact_us/${contactUsId}`;
  return put(url, data);
};

export const DeleteContactUs = (contactUsId) => {
  const url = `help/contact_us/${contactUsId}`;
  return deleteIt(url);
};

export const GetContactUsCategories = (data) => {
  const url = `help/contact_us/categories`;
  return get(url, data);
};

export const CreateContactUsCategory = (data) => {
  const url = `help/contact_us/categories`;
  return postForm(url, data);
};

export const UpdateContactUsCategory = (contactUsCategoryId, data) => {
  const url = `help/contact_us/categories/${contactUsCategoryId}`;
  return put(url, data);
};

export const DeleteContactUsCategory = (contactUsCategoryId) => {
  const url = `help/contact_us/categories/${contactUsCategoryId}`;
  return deleteIt(url);
};

// Procedures
export const GetProcedures = (data) => {
  const url = `help/procedures`;
  return get(url, data);
};

export const CreateProcedure = (data) => {
  const url = `help/procedures`;
  return postForm(url, data);
};

export const UpdateProcedure = (procedureId, data) => {
  const url = `help/procedures/${procedureId}`;
  return postForm(url, data);
};

export const DeleteProcedure = (procedureId) => {
  const url = `help/procedures/${procedureId}`;
  return deleteIt(url);
};

export const DeleteProcedureDocument = (procedureId) => {
  const url = `help/procedures/${procedureId}/document`;
  return deleteIt(url);
};

export const GetProcedureCategories = (data) => {
  const url = `help/procedures/categories`;
  return get(url, data);
};

export const CreateProcedureCategory = (data) => {
  const url = `help/procedures/categories`;
  return postForm(url, data);
};

export const UpdateProcedureCategory = (procedureCategoryId, data) => {
  const url = `help/procedures/categories/${procedureCategoryId}`;
  return put(url, data);
};

export const DeleteProcedureCategory = (procedureCategoryId) => {
  const url = `help/procedures/categories/${procedureCategoryId}`;
  return deleteIt(url);
};

// Communication tools
export const GetCommunicationTools = (data) => {
  const url = `help/communication_tools`;
  return get(url, data);
};

export const CreateCommunicationTool = (data) => {
  const url = `help/communication_tools`;
  return postForm(url, data);
};

export const UpdateCommunicationTool = (communicationToolId, data) => {
  const url = `help/communication_tools/${communicationToolId}`;
  return postForm(url, data);
};

export const DeleteCommunicationTool = (communicationToolId) => {
  const url = `help/communication_tools/${communicationToolId}`;
  return deleteIt(url);
};

export const DeleteCommunicationToolPreview = (communicationToolId) => {
  const url = `help/communication_tools/${communicationToolId}/preview`;
  return deleteIt(url);
};

export const DeleteCommunicationToolDocument = (communicationToolId) => {
  const url = `help/communication_tools/${communicationToolId}/document`;
  return deleteIt(url);
};

export const GetCommunicationToolCategories = (data) => {
  const url = `help/communication_tools/categories`;
  return get(url, data);
};

export const CreateCommunicationToolCategory = (data) => {
  const url = `help/communication_tools/categories`;
  return postForm(url, data);
};

export const UpdateCommunicationToolCategory = (communicationToolCategoryId, data) => {
  const url = `help/communication_tools/categories/${communicationToolCategoryId}`;
  return put(url, data);
};

export const DeleteCommunicationToolCategory = (communicationToolCategoryId) => {
  const url = `help/communication_tools/categories/${communicationToolCategoryId}`;
  return deleteIt(url);
};

// Socket
export const UpdateCoachViewNewAdherents = (data) => {
  const url = `coaches/users`;
  return put(url, data);
};

// Stats

export const GetNotificationsCounter = (data) => {
  const url = `notification-counter`;
  return get(url, data);
};

export const GetStatistics = (data) => {
  const url = `statistics`;
  return get(url, data);
};

export const GetPublicSessionsStatistics = (data) => {
  const url = `statistics/public_sessions`;
  return get(url, data);
};

export const GetPayments = (data) => {
  const url = `payments`;
  return get(url, data);
};
export const GetUserPayments = (userId) => {
  const url = `users/${userId}/payments`;
  return get(url);
};

export const RecoverPayment = (id, data) => {
  const url = `payments/${id}/recoveries`;
  return post(url, data);
};

export const ExportPayments = (data) => {
  const url = `exports/payments`;
  const headers = { responseType: 'blob' };

  return getBlob(url, data, headers);
};

export const ExportPrescriberBilling = (data, prescriberId) => {
  const url = `exports/prescriber_billing/${prescriberId}`;
  const headers = { responseType: 'blob' };

  return getBlob(url, data, headers);
};

export const ExportUserSessionFeedbacks = (data) => {
  const url = `exports/sessions-stats`;
  const headers = { responseType: 'blob' };

  return getBlob(url, data, headers);
};

// Sac de sport
export const GetFolders = (data) => {
  const url = `folders`;
  return get(url, data);
};

export const GetFolder = (folderId) => {
  const url = `folders/${folderId}`;
  return get(url);
};

// Getphy Express
export const GetGetphyExpressVideos = (data) => {
  const url = `getphy_express_videos`;
  return get(url, data);
};

export const CreateGetphyExpressVideo = (data) => {
  const url = `getphy_express_videos`;
  return post(url, data);
};

export const UpdateGetphyExpressVideo = (getphyExpressVideoId, data) => {
  const url = `getphy_express_videos/${getphyExpressVideoId}`;
  return put(url, data);
};

export const DeleteGetphyExpressVideo = (getphyExpressVideoId, data) => {
  const url = `getphy_express_videos/${getphyExpressVideoId}`;
  return deleteIt(url, data);
};

// Like - dislike
export const Like = (data) => {
  const url = `likes`;
  return post(url, data);
};

export const Dislike = (likeId, data) => {
  const url = `likes/${likeId}`;
  return deleteIt(url, data);
};

// Contact
export const Contact = (data) => {
  const url = `contact`;
  return post(url, data);
};

// Assessments
export const AssessmentsGet = (data) => {
  const url = `assessments`;
  return get(url, data);
};

export const LandingsGet = (data) => {
  const url = `landings`;
  return get(url, data);
};

// Prescriber Dashboard
export const PrescriberDashboardOverviewGet = (data) => {
  const url = `prescribers/charts`;
  // TODO: this should be a get really but due to needing to use nested json params post is used for ease
  return post(url, data);
};

export const PrescriberDashboardOverviewGetWithToken = (token, data) => {
  const url = `prescribers/${token}/charts`;
  // TODO: this should be a get really but due to needing to use nested json params post is used for ease
  return post(url, data);
};

export const DashboardOverviewGet = (data) => {
  const url = `dashboard/charts/${data.prescriberId || ''}`;
  return post(url, data);
};

export const PrescriberDashboardActivitiesGet = () => {
  const url = `prescribers/social`;
  return get(url);
};

export const PrescriberDashboardActivitiesGetWithToken = (token) => {
  const url = `prescribers/${token}/social`;
  return get(url);
};

export const DashboardActivitiesGet = (prescriberId) => {
  const url = `dashboard/social/${prescriberId || ''}`;
  return get(url);
};

// Seo
export const SeoPagesGet = (data) => {
  const url = `seo_pages`;
  return get(url, data);
};

export const SeoPageCreate = (data) => {
  const url = `seo_page`;
  return post(url, data);
};

export const SeoPageUpdate = (data, seoId) => {
  const url = `seo_page/${seoId}`;
  return put(url, data);
};

export const SeoPageDelete = (seoId) => {
  const url = `seo_page/${seoId}`;
  return deleteIt(url);
};
