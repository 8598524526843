// @flow

import React from 'react';

import clsx from 'clsx';

import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import { publicTheme } from '@layout/theme';

import { GROUP_LESSONS_CARDS } from '../constants';

import GroupLessonsCardsItem from './GroupLessonsCardsItem';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    paddingTop: '5vh',
    paddingBottom: '5vh',
    [publicTheme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}));

const GroupLessonsCards = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={clsx(classes.root)}>
      {GROUP_LESSONS_CARDS.map((item) => (
        <GroupLessonsCardsItem
          key={item.id}
          name={t(item.name)}
          place={t(item.place)}
          coach={t(item.coach)}
          imgSrc={item.imgSrc}
          imgAlt={t(item.imgAlt)}
        />
      ))}
    </div>
  );
};

export default GroupLessonsCards;
