import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { getAddressGeocoded, getGeocodeInformationsFormated } from 'services/geocode';
import { COMMUNITY_COUNTRIES } from 'app/core/constants';

import { isEqual, debounce } from 'lodash';

class ZipcodeAutocomplete extends Component {
  constructor(props) {
    super(props);

    this.state = {
      location: {
        address: props.data.address,
        city: props.data.city,
        zip_code: props.data.zip_code,
        country_id: props.data.country_id,
      },
      inputValue: props.data.zip_code,
      errorZipCode: { state: '', data: {} },
    };

    this.getLocationByZipcode = debounce(this.getLocationByZipcode.bind(this), 500);
  }

  componentDidMount() {
    if (this.props.defaultCountryRestriction) {
      this.setDefaultCountryRestriction();
    }
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.data, this.props.data)) {
      this.setState({
        location: {
          address: this.props.data.address,
          city: this.props.data.city,
          zip_code: this.props.data.zip_code,
          country_id: this.props.data.country_id,
        },
        inputValue: this.props.data.zip_code,
      });
    }

    if (!isEqual(prevProps.data, this.props.data) && this.props.defaultCountryRestriction) {
      this.setDefaultCountryRestriction();
    }
  }

  setDefaultCountryRestriction = () => {
    const { data, countries, defaultCountryRestriction } = this.props;

    if (!data) return;

    const countryObject =
      countries.find((country) => country.code === defaultCountryRestriction) || {};
    this.setState({
      location: {
        ...this.state.location,
        country_id: countryObject.id,
      },
    });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    const { location } = this.state;
    location[name] = value;
    if (name === 'zip_code') {
      this.setState({ location, inputValue: value });
    } else {
      this.setState({ location });
    }
    this.props.onChange(location);
  };

  handleChangeCountryRestriction = (e) => {
    const { value } = e.target;
    this.setState({
      location: {},
      inputValue: '',
      selectValue: '',
      errorZipCode: { state: '', data: {} },
    });
    this.handlePlaceChange({
      address: '',
      cityValue: '',
      country_id: parseInt(value, 10),
      postcode_localities: [],
      zip_code: '',
    });
  };

  handleZipcodeChange = (e) => {
    const { value } = e.target;
    const countries = this.props.countries;
    const { location } = this.state;

    const countryOptions =
      countries.find((country) => Number(location.country_id) === Number(country.id)) || {};

    this.handleInputChange({ target: { name: 'zip_code', value } });

    if (value && countryOptions.code) {
      this.getLocationByZipcode(value, countryOptions.code);
    } else {
      this.handlePlaceChange({
        postcode_localities: [],
        zip_code: '',
      });
    }
  };

  getLocationByZipcode(value, countryCode) {
    getAddressGeocoded(value, countryCode, this.setGeocodeInformations);
  }

  setGeocodeInformations = (results, status) => {
    if (status === 'OK' && results.length >= 1) {
      let result = results[0];
      let place = getGeocodeInformationsFormated(result);

      if (place.types.includes('postal_code')) {
        this.setState({ errorZipCode: { state: '', data: {} } });
        this.handlePlaceChange({ zip_code: place.zip_code });
      } else {
        const errorZipCode = {
          state: 'error',
          data: { message: this.props.t('Location.ZipcodeAutocomplete.error') },
        };

        this.handlePlaceChange({
          postcode_localities: [],
          zip_code: '',
          errorZipCode,
        });
        this.setState({ errorZipCode });
      }
    } else {
      const errorZipCode = {
        state: 'error',
        data: { message: this.props.t('Location.ZipcodeAutocomplete.error') },
      };
      this.handlePlaceChange({
        postcode_localities: [],
        zip_code: '',
        errorZipCode,
      });
      this.setState({ errorZipCode });
    }
  };

  handlePlaceChange = (location) => {
    if (!location) return;
    let {
      address,
      cityValue,
      zip_code: zipCode,
      lat: latitude,
      long: longitude,
      country_id: countryId,
      country_short: countryShort,
      postcode_localities: postcodeLocalities,
      errorZipCode,
    } = location || {};

    if (!countryId && countryShort) {
      const countryObject = this.props.countries.find((country) => country.code === countryShort);
      countryId = countryObject?.id;
    } else if (!countryId && !countryShort) {
      countryId = this.state.location.country_id;
    }
    const locationToUpdate = {
      ...this.state.location,
      address: address === '' ? '' : this.state.location.address,
      city: cityValue === '' ? '' : this.state.location.city,
      zip_code: zipCode,
      latitude,
      longitude,
      country_id: countryId,
      postcode_localities: postcodeLocalities,
      errorZipCode,
    };
    this.setState({
      location: locationToUpdate,
    });
    this.props.onChange(locationToUpdate);
  };

  render() {
    const { t, data, countries, required, displayAddress, user } = this.props;
    const { location, inputValue } = this.state;
    const country = location.country_id || data.country_id || data.country?.id;

    const countriesFiltered =
      user?.prescribers?.length === 0
        ? countries?.filter((item) => COMMUNITY_COUNTRIES.includes(item.code))
        : countries;

    return (
      <div className={`autocomplete-only-zipcode`}>
        <div className="data-row">
          <label>{t('Location.label-country')}</label>
          <select
            name="search_place_country"
            value={country}
            onChange={this.handleChangeCountryRestriction}
            required={required}
          >
            <option value="">{t('Location.placeholder-country')}</option>
            {countriesFiltered.map((item) => {
              return (
                <option value={item.id} key={item.id}>
                  {item.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className={`data-row data-row-half`}>
          <label>{t('Location.ZipcodeAutocomplete.label-zipcode')}</label>
          <input
            autoComplete="postal-code"
            name="zip_code"
            type="text"
            value={inputValue}
            onChange={this.handleZipcodeChange}
            maxLength={191}
            required
            data-cy="zip-code"
          />
        </div>

        <div className={`data-row data-row-half`}>
          <label>{t('Location.ZipcodeAutocomplete.label-city')}</label>
          <input
            autoComplete="address-level2"
            name="city"
            onChange={this.handleInputChange}
            required
            value={location.city}
            data-cy="city"
          />
        </div>

        {displayAddress && (
          <div className="data-row">
            <label>{t('Location.ZipcodeAutocomplete.label-address')}</label>
            <input
              autoComplete="street-address"
              name="address"
              type="text"
              value={location.address}
              onChange={this.handleInputChange}
              placeholder={t('Location.ZipcodeAutocomplete.placeholder-address')}
              required
              maxLength={191}
            />
          </div>
        )}
      </div>
    );
  }
}

ZipcodeAutocomplete.propTypes = {
  countries: PropTypes.array,
  data: PropTypes.object,
  user: PropTypes.object,
  required: PropTypes.bool,
  displayAddress: PropTypes.bool,
  defaultCountryRestriction: PropTypes.string,
  onChange: PropTypes.func,
};

ZipcodeAutocomplete.defaultProps = {
  countries: [],
  data: {},
  user: {},
  required: false,
  displayAddress: false,
  onChange: () => {},
};

export default withTranslation()(ZipcodeAutocomplete);
