// @flow
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { publicTheme } from '@layout/theme';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';

import { PRIMARY_COLOR } from '@shared/constants';
import { SECTIONS } from '../constants';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    flexWrap: 'wrap',
    [publicTheme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
    [publicTheme.breakpoints.up('lg')]: {
      flexWrap: 'nowrap',
    },
  },
  button: {
    borderColor: PRIMARY_COLOR,
    border: 'solid 2px',
    fontSize: '1.4rem',
    fontWeight: 'bold',
    textTransform: 'none',
    margin: '0.5rem auto',
    width: '90%',

    '&:hover': {
      border: 'solid 2px',
    },

    [publicTheme.breakpoints.up('sm')]: {
      width: '45%',
      margin: '0.5rem',
    },

    [publicTheme.breakpoints.up('lg')]: {
      width: 'auto',
    },
  },
  active: {
    backgroundColor: PRIMARY_COLOR,
  },
}));

type Props = {
  onActiveSection: Function,
};

const GroupClassesMenu = ({ onActiveSection }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [activeSection, setActiveSection] = useState(SECTIONS[0]);

  const handleClick = (section) => {
    setActiveSection(section);
    onActiveSection(section);
  };

  return (
    <div className={classes.root}>
      {SECTIONS.map((section) => {
        return (
          <Button
            key={section}
            variant={`${activeSection === section ? 'contained' : 'outlined'}`}
            color="primary"
            className={clsx(activeSection === section ? 'contained' : 'outlined', classes.button)}
            onClick={() => handleClick(section)}
          >
            {t(`GroupClasses.menu.${section}`)}
          </Button>
        );
      })}
    </div>
  );
};

GroupClassesMenu.defaultProps = {
  onActiveSection: () => {},
};

export default GroupClassesMenu;
