// @flow
import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

import { NEUTRAL_COLOR, MAIN_TEXT_COLOR } from '@shared/constants';
import GroupClassInformationHeader from './GroupClassInformationHeader';
import GroupClassInformationDisplay from './GroupClassInformationDisplay';

import type { Community } from '../models';

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: NEUTRAL_COLOR,
    width: '75%',
    margin: '1rem auto',
    padding: '3rem',
    color: MAIN_TEXT_COLOR,
  },
}));

type Props = {
  groupClass: Community,
};

const GroupClassInformation = ({ groupClass }: Props) => {
  const classes = useStyles();

  return (
    <Box className={classes.container} borderRadius="borderRadius" boxShadow="5">

      <GroupClassInformationHeader/>

      {groupClass && (
        <GroupClassInformationDisplay groupClass={groupClass}/>
      )}

    </Box>
  );
};

export default GroupClassInformation;
