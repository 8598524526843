import * as seoActions from 'store/modules/Seo/actions';

const initialState = {
  seoPages: {},
  searchResults: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case seoActions.CACHE_SEO_PAGES_SEARCH_RESULTS:
      return {
        ...state,
        searchResults: action.payload,
        seoPagesLoaded: true,
      };

    case seoActions.CACHE_SEO_PAGES_SET:
      return {
        ...state,
        seoPages: action.payload,
      };

    default:
      return state;
  }
};
