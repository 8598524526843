export const AG2RPrescriberId = 13611;

/**
 * Return true if the user is linked to the AG2R prescriber
 *
 * @param {Object} user
 * @returns {bool}
 */

export const isAG2RPrescriber = (user) => {
  return ((user || {}).prescribers || []).find((prescriber) => prescriber.id === AG2RPrescriberId);
};
