import { itContains } from '@services/utils';

export function saveToLocalStorage(data) {
  localStorage.setItem('getphy_role', data);
}

export function getCurrentRole() {
  return localStorage.getItem('getphy_role');
}

export function canBeUser(roles) {
  return itContains(roles, 'user');
}

export function canBePrescriber(roles) {
  return itContains(roles, 'prescriber');
}

export function canBeCoach(roles) {
  return itContains(roles, 'coach');
}

export function canBeManager(roles) {
  return itContains(roles, 'manager');
}

export function canBeAdmin(roles) {
  return itContains(roles, 'admin');
}

export function getDefaultRole(roles) {
  let toReturn = null;

  if (roles && roles.length > 0) {
    if (canBeUser(roles)) {
      toReturn = 'user';
    }
    if (canBeCoach(roles)) {
      toReturn = 'coach';
    }
    if (canBePrescriber(roles)) {
      toReturn = 'prescriber';
    }
    if (canBeManager(roles)) {
      toReturn = 'manager';
    }
    if (canBeAdmin(roles)) {
      toReturn = 'admin';
    }
  }

  return toReturn;
}

export function clearRole() {
  localStorage.removeItem('getphy_role');
}

export const ROLE_ADMIN = 1;

export const ROLE_MANAGER = 2;

export const ROLE_COACH = 3;

export const ROLE_PRESCRIBER = 4;

export const ROLE_USER = 5;

export const ROLE_ADMIN_REFERENSIEL = 6;
