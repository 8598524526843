import 'react-app-polyfill/ie11'; // For IE 11 support
import smoothscroll from 'smoothscroll-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import App from 'containers/App';

import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

import store from './store';

import './index.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const el = document.getElementById('root');

smoothscroll.polyfill();

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </I18nextProvider>,

  el
);
