import DefaultCommunityImage from 'components/Image/assets/default-community.jpg';
import DefaultCommunityImage1 from 'components/Image/assets/default-community-category-1.jpg';
import DefaultCommunityImage2 from 'components/Image/assets/default-community-category-2.jpg';
import DefaultCommunityImage3 from 'components/Image/assets/default-community-category-3.jpg';
import DefaultCommunityImage4 from 'components/Image/assets/default-community-category-4.jpg';
import DefaultCommunityImage5 from 'components/Image/assets/default-community-category-5.jpg';

export function getGroupClassesDefaultImageBasedOnActivity(themeId) {
  let image = DefaultCommunityImage;

  switch (themeId) {
    case 1:
    case 2:
    case 15:
    case 16:
    case 17:
      image = DefaultCommunityImage1;
      break;
    case 3:
    case 4:
    case 5:
    case 6:
    case 13:
      image = DefaultCommunityImage2;
      break;
    case 7:
    case 8:
    case 9:
      image = DefaultCommunityImage3;
      break;
    case 10:
      image = DefaultCommunityImage4;
      break;
    case 11:
    case 12:
      image = DefaultCommunityImage5;
      break;
    default:
      break;
  }

  return image;
}

export default {};
