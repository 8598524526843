import { createTheme } from '@material-ui/core/styles';

export const publicTheme = createTheme({
  palette: {
    primary: {
      main: '#0273bb',
    },
    secondary: {
      main: '#ffc000',
    },
    accentuated: {
      backgroundColor: '#ffc000',
      color: '#000',
    },
    outlinedPrimary: {
      backgroundColor: '#0273bb',
      color: '#fff',
      border: '1px solid #fff',
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
  },
});

export const privateTheme = createTheme({
  palette: {
    primary: {
      main: '#0273bb',
    },
    secondary: {
      main: '#ffc000',
    },
    accentuated: {
      backgroundColor: '#ffc000',
      color: '#000',
    },
    outlinedPrimary: {
      backgroundColor: '#0273bb',
      color: '#fff',
      border: '1px solid #fff',
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
  },
});
