import { getLocale } from '@services/localisation';

export const getSlugByName = (slugs, name) => {
  const slug = slugs.find((slug) => slug.name === name);
  if (slug) {
    return `/${getLocale()}${slug.translations[getLocale()]?.url}`;
  }
  return '/not-found';
};

export default { getSlugByName };
