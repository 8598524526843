import { takeEvery, call, put, select } from 'redux-saga/effects';

import * as ApiService from 'services/api';

import { incrementStatusCreator, decrementStatusCreator } from 'store/modules/Status/actions';
import * as landingActions from 'store/modules/Landing/actions';

/** ********************************************** */
/** Selectors * */
const allSelector = (state) => {
  return JSON.parse(JSON.stringify(state.Landing.landings));
};

/** ********************************************** */
/** Sagas * */
function* getLandingsSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Get landings start' })
    );

    const result = yield call(ApiService.LandingsGet, data.payload);

    yield put({ type: landingActions.UPDATE_LANDINGS_CACHE, payload: result.data.data.landings });

    yield put({ type: landingActions.UPDATE_LANDING_SEARCH_RESULTS, payload: result.data.data });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Get landings finsihed',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Get landings start error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* updateCacheSaga(data) {
  if (!data || !data.payload || data.payload.length === 0) {
    return;
  }

  const landings = yield select(allSelector) || [];

  if (data.delete) {
    data.payload.map((item) => {
      let id = item;
      if (typeof id === 'object') {
        id = id.id;
      }

      delete landings[id];

      return null;
    });
  } else {
    data.payload.map((item) => {
      if (!item.id) return null;

      landings[item.id] = item;

      return null;
    });
  }

  yield put({ type: landingActions.UPDATE_LANDINGS_CACHE_SET, payload: landings });
}

export default function* sagaWatcher() {
  yield takeEvery(landingActions.GET_LANDINGS, getLandingsSaga);

  yield takeEvery(landingActions.UPDATE_LANDINGS_CACHE, updateCacheSaga);
}
