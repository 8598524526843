export const INITIATE_WEBSOCKET = 'socket/INITIATE_WEBSOCKET';
export const DISCONNECT_WEBSOCKET = 'socket/DISCONNECT_WEBSOCKET';

export const GET_NOTIFICATIONS_COUNTER = 'socket/GET_NOTIFICATIONS_COUNTER';
export const SET_NOTIFICATIONS_COUNTER = 'socket/SET_NOTIFICATIONS_COUNTER';

export const UPDATE_PAGE_TODO_COUNTER = 'socket/UPDATE_PAGE_TODO_COUNTER';
export const UPDATE_COMMUNITY_REQUEST_FOR_COACH_COUNTERS =
  'socket/UPDATE_COMMUNITY_REQUEST_FOR_COACH_COUNTERS';

export const UPDATE_TOTAL_NEW_USERS_FOR_COACH = 'socket/UPDATE_TOTAL_NEW_USERS_FOR_COACH';

export function initiateWebsocket(payload) {
  return { type: INITIATE_WEBSOCKET, payload };
}

export function disconnectWebsocket(payload) {
  return { type: DISCONNECT_WEBSOCKET, payload };
}

export function getNotificationsCounter(payload) {
  return { type: GET_NOTIFICATIONS_COUNTER, payload };
}

export function updatePageTodoCounter(payload) {
  return { type: UPDATE_PAGE_TODO_COUNTER, payload };
}

export function updateCommunityRequestForCoachCounters(payload) {
  return { type: UPDATE_COMMUNITY_REQUEST_FOR_COACH_COUNTERS, payload };
}

export function updateTotalNewUsersForCoach(payload) {
  return { type: UPDATE_TOTAL_NEW_USERS_FOR_COACH, payload };
}
