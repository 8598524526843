// @flow

import React from 'react';

import clsx from 'clsx';
import { privateTheme } from '@layout/theme';

import { makeStyles } from '@material-ui/core/styles';

import { MAIN_TEXT_COLOR, NEUTRAL_COLOR } from '@shared/constants';

type GroupLessonsCardsItemProps = {
  name: string,
  place: string,
  coach: string,
  imgSrc: string,
  imgAlt: string,
  className?: string,
};

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: NEUTRAL_COLOR,
    borderRadius: '10px',
    boxShadow: '8px 8px 4px rgba(0, 0, 0, 0.25)',
    justifyContent: 'space-between',
    marginTop: '2vh',
  },
  textColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    padding: '20px',
  },
  name: {
    fontFamily: 'Roboto, Georgia, serif',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '1.1rem',
    lineHeight: '1.5rem',
    color: MAIN_TEXT_COLOR,
    paddingBottom: '1vh',
  },
  place: {
    fontFamily: 'Roboto, Georgia, serif',
    fontStyle: 'italic',
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '1.5rem',
    color: MAIN_TEXT_COLOR,
    paddingBottom: '2vh',
  },
  coach: {
    fontFamily: 'Roboto, Georgia, serif',
    fontWeight: 400,
    fontSize: '0.85rem',
    lineHeight: '1.2rem',
    color: MAIN_TEXT_COLOR,
    '& span': {
      textDecoration: 'underline',
    },
  },
  thumbnail: {
    width: '230px',
    height: '172px',
    borderRadius: '10px 10px 0 0 ',
    [privateTheme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: '230px',
      height: 'auto',
    },
  },
}));

const GroupLessonsCardsItem = ({
  name,
  place,
  coach,
  imgSrc,
  imgAlt,
  className,
}: GroupLessonsCardsItemProps) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, className)}>
      <div>
        <img className={clsx(classes.thumbnail)} src={imgSrc} alt={imgAlt} />
      </div>
      <div className={clsx(classes.textColumn)}>
        <p className={clsx(classes.name)}>{name}</p>
        <p className={clsx(classes.place)}>{place}</p>
        <p className={clsx(classes.coach)}>
          Avec <span>{coach}</span>
        </p>
      </div>
    </div>
  );
};

export default GroupLessonsCardsItem;
