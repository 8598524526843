import { createSelector } from 'reselect';

import * as groupClassConstants from '@groupClasses/constants';

export const groupClassStateSelector = (state) => state[groupClassConstants.NAME];

export const currentGroupClassSelector = createSelector(
  groupClassStateSelector,
  (groupClassState) => groupClassState.current
);

export const participantGroupClassSelector = createSelector(
  groupClassStateSelector,
  (groupClassState) => groupClassState.participants,
);

export const joinRequestGroupClassSelector = createSelector(
  groupClassStateSelector,
  (groupClassState) => groupClassState.joinRequests
);
