/* eslint-disable camelcase */
export const COACH_REQUEST = 'coach/COACH_REQUEST';
export const COACH_SEARCH_RESULTS = 'coach/COACH_SEARCH_RESULTS';

export const GET_ALL_COACHES = 'coach/GET_ALL_COACHES';
export const ASSIGN_COACH_TO_USER = 'coach/ASSIGN_COACH_TO_USER';

export const GET_ADHERENTS = 'coach/GET_ADHERENTS';
export const MY_ADHERENTS_SET = 'coach/MY_ADHERENTS_SET';

export const COACH_CALENDAR_SET = 'coach/COACH_CALENDAR_SET';

export const UPDATE_CACHE = 'coach/UPDATE_CACHE';
export const UPDATE_CACHE_SET = 'coach/UPDATE_CACHE_SET';

export const GET_COACHES_USERS = 'coach/GET_COACHES_USERS';
export const COACHES_USERS_SET = 'coach/COACHES_USERS_SET';

export const GET_COACHES_USERS_MIN = 'coach/GET_COACHES_USERS_MIN';
export const COACHES_USERS_MIN_SET = 'coach/COACHES_USERS_MIN_SET';

export const GET_COACHES_UPCOMING_TASK = 'coach/GET_COACHES_UPCOMING_TASK';
export const COACHES_UPCOMING_TASK_SET = 'coach/COACHES_UPCOMING_TASK_SET';

export const GET_COACH_TABLE_TASK = 'coach/GET_COACH_TABLE_TASK';
export const COACH_TABLE_TASK_SET = 'coach/COACH_TABLE_TASK_SET';

export const GET_COACH_DETAIL = 'coach/GET_COACH_DETAIL';
export const COACH_DETAIL_SET = 'coach/COACH_DETAIL_SET';

export const GET_COACH_ASSESMENT_TASK = 'coach/GET_COACH_ASSESMENT_TASK';
export const COACH_ASSESMENT_TASK_SET = 'coach/COACH_ASSESMENT_TASK_SET';
export const CLEAR_ASSESMENT_TASK = 'coach/CLEAR_ASSESMENT_TASK';

export const GET_COACH_ASSESMENT_CALLBACK = 'coach/GET_COACH_ASSESMENT_CALLBACK';
export const COACH_ASSESMENT_CALLBACK_SET = 'coach/COACH_ASSESMENT_CALLBACK_SET';
export const CLEAR_ASSESMENT_CALLBACK = 'coach/CLEAR_ASSESMENT_CALLBACK';

export const UPDATE_COACH_ASSESMENT_TASK = 'coach/UPDATE_COACH_ASSESMENT_TASK';
export const SET_SUCCESS_UPDATE_COACH_ASSESMENT_TASK =
  'coach/SET_SUCCESS_UPDATE_COACH_ASSESMENT_TASK';
export const CLEAR_RESULT_UPDATE_COACH_ASSESMENT_TASK =
  'coach/CLEAR_RESULT_UPDATE_COACH_ASSESMENT_TASK';

export const GET_COACH_CALENDAR_DATA = 'coach/GET_COACH_CALENDAR_DATA';
export const SET_CAOCH_CALENDAR_DATA = 'coach/SET_CAOCH_CALENDAR_DATA';

export const GET_COACH_SESSION_USER = 'coach/GET_COACH_SESSION_USER';
export const SET_COACH_SESSION_USER = 'coach/SET_COACH_SESSION_USER';

export const CLEAR_TRAINES_BENEFICIARY = 'coach/CLEAR_TRAINES_BENEFICIARY';

export const CHANGE_COACH_ASSESMENT_TASK_DATE = 'coach/CHANGE_COACH_ASSESMENT_TASK_DATE';
export const SET_SUCCESS_CHANGE_COACH_ASSESMENT_TASK_DATE =
  'coach/SET_SUCCESS_CHANGE_COACH_ASSESMENT_TASK_DATE';

export const GET_COACH_NOTES = 'coach/GET_COACH_NOTES';
export const SET_COACH_NOTES = 'coach/SET_COACH_NOTES';

export const GET_PHYSICAL_EVOLUTION_TEST = 'coach/GET_PHYSICAL_EVOLUTION_TEST';
export const SET_PHYSICAL_EVOLUTION_TEST = 'coach/SET_PYSICAL_EVOLUTION_TEST';

export const PUT_PHYSICAL_EVOLUTION_TEST = 'coach/PUT_PHYSICAL_EVOLUTION_TEST';
export const UPDATE_PHYSICAL_EVOLUTION_TEST = 'coach/UPDATE_PHYSICAL_EVOLUTION_TEST';

export const ADD_USER_SESSION = 'coach/ADD_USER_SESSION';
export const RESULT_ADD_USER_SESSION = 'coach/RESULT_ADD_USER_SESSION';
export const CLEAR_USER_ADD_SESSION = 'coach/CLEAR_USER_ADD_SESSION';

export const ADD_USER_PROGRAM = 'coach/ADD_USER_PROGRAM';
export const RESULT_ADD_USER_PROGRAM = 'coach/RESULT_ADD_USER_PROGRAM';
export const CLEAR_USER_ADD_PROGRAM = 'coach/CLEAR_USER_ADD_PROGRAM';

export const GET_PROGRAM_USER = 'coach/GET_PROGRAM_USER';
export const SET_PROGRAM_USER = 'coach/SET_PROGRAM_USER';

export const GET_SESSIONS_USER = 'coach/GET_SESSIONS_USER';
export const SET_SESSIONS_USER = 'coach/SET_SESSIONS_USER';

export function coachRequestCreator(payload) {
  return { type: COACH_REQUEST, payload };
}
export function getAllCoachesCreator(payload) {
  return { type: GET_ALL_COACHES, payload: payload || {} };
}
export function assignCoachToUserCreator(payload) {
  return { type: ASSIGN_COACH_TO_USER, payload: payload || {} };
}
export function getAherentsCreator(payload) {
  return { type: GET_ADHERENTS, payload: payload || {} };
}
export function getCoachesUsers(payload) {
  return { type: GET_COACHES_USERS, payload };
}
export function getCoachesUsersMin(payload) {
  return { type: GET_COACHES_USERS_MIN, payload };
}
export function getCoachUpcomingTask(payload) {
  return { type: GET_COACHES_UPCOMING_TASK, payload };
}
export function getCoachTableTask(payload) {
  return { type: GET_COACH_TABLE_TASK, payload };
}

export function getCoachDetail(userId) {
  return { type: GET_COACH_DETAIL, payload: userId };
}

export function getCoachAssesmentTask(taskId) {
  return { type: GET_COACH_ASSESMENT_TASK, payload: taskId };
}

export function clearAssesmentTask() {
  return { type: CLEAR_ASSESMENT_TASK };
}

export function updateCoachAssesmentTask(taskId, data) {
  return { type: UPDATE_COACH_ASSESMENT_TASK, payload: data, taskId };
}

export function getCoachAssesmentCallback(taskId) {
  return { type: GET_COACH_ASSESMENT_CALLBACK, payload: taskId };
}

export function clearAssesmentCallback() {
  return { type: CLEAR_ASSESMENT_CALLBACK };
}

export function getCoachCalendarData(userId, data) {
  return { type: GET_COACH_CALENDAR_DATA, payload: userId, data };
}

export function getCoachSessionUser(userId) {
  return { type: GET_COACH_SESSION_USER, payload: userId };
}

export function clearTrainesBeneficiary() {
  return { type: CLEAR_TRAINES_BENEFICIARY };
}

export function getCoachNotes(userId) {
  return { type: GET_COACH_NOTES, payload: userId };
}

export function changeCoachAssesmentTaskDate(taskId, date) {
  return { type: CHANGE_COACH_ASSESMENT_TASK_DATE, taskId, payload: date };
}

export function getPhysicalEvolutionTest(taskId) {
  return { type: GET_PHYSICAL_EVOLUTION_TEST, payload: taskId };
}

export function setPhysicalEvolutionTest(data) {
  return { type: SET_PHYSICAL_EVOLUTION_TEST, payload: data };
}

export function putPhysicalEvolutionTest(taskId, answers) {
  return { type: PUT_PHYSICAL_EVOLUTION_TEST, payload: taskId, answers };
}

export function addUserSession(userId, sessionId, start_date) {
  return { type: ADD_USER_SESSION, userId, sessionId, start_date };
}

export function addUserProgram(userId, programId, start_date) {
  return { type: ADD_USER_PROGRAM, userId, programId, start_date };
}

export function clearUserAddSession() {
  return { type: CLEAR_USER_ADD_SESSION };
}

export function clearUserAddProgram() {
  return { type: CLEAR_USER_ADD_PROGRAM };
}

export function getProgramData(value = '') {
  return { type: GET_PROGRAM_USER, payload: value };
}

export function getSessionData(value = '') {
  return { type: GET_SESSIONS_USER, payload: value };
}
