import * as chatActions from 'store/modules/Chat/actions';

const initialState = {
  unreads: 0,
  userDiscussions: {},
  communityDiscussions: {},
  latestMessages: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case chatActions.CACHE_UPDATE_USER_UNREADS_SET:
      return {
        ...state,
        unreads: action.payload,
      };

    case chatActions.CACHE_UPDATE_USER_DISCUSSIONS_SET:
      return {
        ...state,
        userDiscussions: action.payload,
      };

    case chatActions.CACHE_UPDATE_COMMUNITY_DISCUSSIONS_SET:
      return {
        ...state,
        communityDiscussions: action.payload,
      };

    case chatActions.SET_LATEST_MESSAGES:
      return {
        ...state,
        latestMessages: action.payload,
      };

    default:
      return state;
  }
};
