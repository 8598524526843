import Echo from 'laravel-echo';
import { getToken } from 'services/token';

// const host = 'localhost:6001';
const host = process.env.REACT_APP_SOCKETIO;

let echo = null;
const joinedChannels = [];

function addJoinedChannel(channelId) {
  joinedChannels.push(channelId);
}

export function socketConnect(userId, emitter) {
  // eslint-disable-next-line global-require
  window.io = require('socket.io-client');

  const token = getToken();

  echo = new Echo({
    broadcaster: 'socket.io',
    host: window.location.hostname + host,
    auth: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  });

  echo.join(`App.User.${userId}`).here(() => {
    // Called after channel joined
    addJoinedChannel(`App.User.${userId}`);
  });

  echo.private(`App.User.${userId}`).listen('MessageSent', (e) => {
    emitter({
      emitType: 'MessageSent',
      data: e,
    });
  });

  echo.private(`App.User.${userId}`).listen('FriendUpdate', (e) => {
    emitter({
      emitType: 'FriendUpdate',
      data: e,
    });
  });

  echo.private(`App.User.${userId}`).listen('PageToDoCounter', (e) => {
    emitter({
      emitType: 'PageToDoCounter',
      data: e,
    });
  });

  echo.private(`App.User.${userId}`).listen('CommunityRequestForCoachCounters', (e) => {
    emitter({
      emitType: 'CommunityRequestForCoachCounters',
      data: e,
    });
  });

  echo.private(`App.User.${userId}`).listen('CoachAssignToUser', (e) => {
    emitter({
      emitType: 'CoachAssignToUser',
      data: e,
    });
  });

  return echo;
}

export function socketDisconnect() {
  if (echo) echo.disconnect();
}
