import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import MediaQuery from 'react-responsive';
import { SCREEN_MOBILE_MAX } from 'constants/constants';
import { itContains } from 'services/utils';

import DropdownMenu from './DropdownMenu';

import './style.css';

const baseClass = 'header-link-dropdown';

class HeaderLinkDropdown extends Component {
  constructor() {
    super();

    this.state = {
      dropdownActive: false,
    };

    this.timer = 0;

    this.handleDropdownClick = this.handleDropdownClick.bind(this);

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('dragstart', this.handleClickOutside);
    document.addEventListener('dblclick', this.handleClickOutside);
    document.addEventListener('mousedown', this.handleClickOutside);
    document.addEventListener('touchstart', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('dragstart', this.handleClickOutside);
    document.removeEventListener('dblclick', this.handleClickOutside);
    document.removeEventListener('mousedown', this.handleClickOutside);
    document.removeEventListener('touchstart', this.handleClickOutside);
    clearTimeout(this.timer);
  }

  handleClickOutside(event) {
    if (this.state.dropdownActive && this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.timer = setTimeout(
        () =>
          this.setState({
            dropdownActive: false,
          }),
        100
      );
    }
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleDropdownClick(e) {
    e.stopPropagation();

    this.setState((prevState) => ({
      dropdownActive: !prevState.dropdownActive,
    }));
  }

  render() {
    return (
      <div
        className={`${baseClass}-button ${this.props.isTabLink ? baseClass + '-tab-link' : ''}`}
        onClick={this.handleDropdownClick}
        ref={this.setWrapperRef}
      >
        <div
          className={`${baseClass}-tab ${
            this.state.dropdownActive || itContains(window.location.href, this.props.to)
              ? 'active'
              : ''
          }`}
        >
          {this.props.text}
          <MediaQuery maxWidth={SCREEN_MOBILE_MAX}>
            <span className={`${baseClass}-tab-arrow ${this.state.dropdownActive ? 'active' : ''}`}>
              &or;
            </span>
          </MediaQuery>
        </div>

        <DropdownMenu
          dropdownButtons={this.props.dropdownButtons}
          isActive={this.state.dropdownActive}
        />
      </div>
    );
  }
}

export default withTranslation()(HeaderLinkDropdown);
