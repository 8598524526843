// @flow

import React, { useMemo } from 'react';

import { Link } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import MUIAppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';

import Icon from '@components/Icon';

import { publicTheme } from '@layout/theme';
import { getLocale } from '@services/localisation';

import Avatar from './Avatar';
import LoginButton from './LoginButton';
import SignUpButton from './SignUpButton';
import AppBarNavItems from './AppBarNavItems';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    [publicTheme.breakpoints.down('md')]: {
      width: '100vw',
    },
    [publicTheme.breakpoints.up('lg')]: {
      width: '1280px',
      margin: '0 auto',
    },
  },
  logoGetphy: {
    width: '110px',
    height: '56px',
  },
  sectionDesktop: {
    display: 'none',
    [publicTheme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [publicTheme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  loginButton: {
    marginRight: publicTheme.spacing(2),
  },
}));

const AppBar = ({
  toggleMobileMenu,
  handleLogin,
  handleSignUp,
  handleProfile,
  handleLogout,
  mobileMenuActive,
  isLogoLink = true,
  isOnLandingPage,
  isOnIPFPage = false,
  showMenu,
}) => {
  const classes = useStyles();
  const { isLoggedIn } = useSelector((state) => state.Account);

  const hideLogin = useMemo(
    (): boolean => isOnIPFPage && ['fr', 'es-AR', 'es', 'en', 'it'].includes(getLocale()),
    [isOnIPFPage]
  );

  const sectionDesktop = isLoggedIn ? (
    <>
      <AppBarNavItems handleProfile={handleProfile} handleLogout={handleLogout} />
      <Avatar />
    </>
  ) : (
    !isOnLandingPage &&
    !hideLogin && (
      <>
        <div className={classes.loginButton}>
          <LoginButton handleLogin={handleLogin} />
        </div>
        <SignUpButton handleSignUp={handleSignUp} />
      </>
    )
  );

  return (
    <MUIAppBar position="static" color="primary">
      <Toolbar className={classes.root}>
        <div className={classes.logoGetphy}>
          {isLogoLink && !isOnLandingPage ? (
            <Link to="/">
              <Icon src="getphy-logo-2.svg" className={classes.logoGetphy} />
            </Link>
          ) : (
            <Icon src="getphy-logo-2.svg" className={classes.logoGetphy} />
          )}
        </div>
        <div className={classes.sectionDesktop}>{sectionDesktop}</div>
        {showMenu && (
          <div className={classes.sectionMobile}>
            <IconButton color="inherit" aria-label="menu" onClick={toggleMobileMenu}>
              {mobileMenuActive ? <CloseIcon /> : <MenuIcon />}
            </IconButton>
          </div>
        )}
      </Toolbar>
    </MUIAppBar>
  );
};

export default AppBar;
