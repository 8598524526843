import React from 'react';

import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import { privateTheme } from '@layout/theme';
import { BLACK } from '@shared/constants';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    [privateTheme.breakpoints.down('md')]: {
      marginTop: '3vh',
      width: '100vw',
    },
    [privateTheme.breakpoints.up('lg')]: {
      width: '960px',
      margin: '3vh auto 0',
    },
  },
  text: {
    color: BLACK,
    fontFamily: 'Roboto, Georgia, serif',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '2.25rem',
    lineHeight: '3.25rem',
  },
}));

const TitleBanner = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <p className={classes.text}>{t('GroupClasses.title')}</p>
    </div>
  );
};

export default TitleBanner;
