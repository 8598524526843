export const GET_QUESTIONNAIRE_RESOURCES = 'resource/GET_QUESTIONNAIRE_RESOURCES';
export const GET_COMMUNITY_RESOURCES = 'resource/GET_COMMUNITY_RESOURCES';
export const GET_COUNTRIES = 'resource/GET_COUNTRIES';
export const GET_PROGRAMS_RESOURCES = 'resource/GET_PROGRAMS_RESOURCES';
export const GET_ARTICLES_RESOURCES = 'resource/GET_ARTICLES_RESOURCES';
export const GET_COACHES_RESOURCES = 'resource/GET_COACHES_RESOURCES';
export const GET_EXERCISES_RESOURCES = 'resource/GET_EXERCISES_RESOURCES';
export const GET_SESSIONS_RESOURCES = 'resource/GET_SESSIONS_RESOURCES';

export const UPDATE_RESOURCE_CACHE_SET = 'resource/UPDATE_RESOURCE_CACHE_SET';

export function getQuestionnaireResources(role) {
  return { type: GET_QUESTIONNAIRE_RESOURCES, payload: role };
}

export function getCommunityResources(role) {
  return { type: GET_COMMUNITY_RESOURCES, payload: role };
}

export function getCountries(role) {
  return { type: GET_COUNTRIES, payload: role };
}

export function getProgramsResources(role) {
  return { type: GET_PROGRAMS_RESOURCES, payload: role };
}

export function getArticlesResources(role) {
  return { type: GET_ARTICLES_RESOURCES, payload: role };
}

export function getCoachesResources(role) {
  return { type: GET_COACHES_RESOURCES, payload: role };
}

export function getExercisesResources() {
  return { type: GET_EXERCISES_RESOURCES };
}

export function getSessionsResources() {
  return { type: GET_SESSIONS_RESOURCES };
}
