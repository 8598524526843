import * as csvActions from 'store/modules/Csv/actions';

const initialState = {
  community_parsed: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case csvActions.SET_COMMUNITY_PARSED:
      return {
        ...state,
        community_parsed: action.payload,
      };

    case csvActions.SET_COMMUNITY_IMPORTED:
      return {
        ...state,
        community_imported: action.payload.communities,
        community_failures: action.payload.community_failures,
      };

    default:
      return state;
  }
};
