// @flow
import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';

import ModalLogin from '@containers/modals/ModalLogin';
import ModalRegister from '@containers/modals/ModalRegister';
import ModalProfile from '@containers/modals/ModalProfile';

import { logoutRequestCreator } from '@store/modules/Account/actions';

import type { Tabs } from '@header/types';

import {
  LOGIN_MODAL_WORD_OPENER_FROM_URL,
  SIGNUP_MODAL_WORD_OPENER_FROM_URL,
} from 'constants/constants';
import getQueryParam from 'services/location';
import Menu from './Menu';
import MobileMenu from './MobileMenu';
import AppBar from './AppBar';

type Props = {
  tabs?: Tabs,
  activeTab?: string,
  showMenu?: boolean,
  signUpPage?: string,
  isLogoLink?: boolean,
  isOnLandingPage?: boolean,
  isOnIPFPage?: boolean,
};

const Header = ({
  tabs,
  activeTab,
  showMenu = true,
  signUpPage,
  isLogoLink,
  isOnLandingPage,
  isOnIPFPage,
}: Props) => {
  const [loginModalActive, setLoginModalActive] = useState(false);
  const [signupModalActive, setSignupModalActive] = useState(false);
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const [profileModalActive, setProfileModalActive] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (getQueryParam('open') === SIGNUP_MODAL_WORD_OPENER_FROM_URL) {
      setLoginModalActive(true);
      setSignupModalActive(true);
    }

    if (getQueryParam('open') === LOGIN_MODAL_WORD_OPENER_FROM_URL) {
      setLoginModalActive(true);
    }
  }, []);

  const handleLogin = useCallback(() => {
    setLoginModalActive(true);
  }, [setLoginModalActive]);

  const handleSignUp = useCallback(() => {
    if (signUpPage) {
      history.push({
        pathname: signUpPage,
        search: '?scrollTopPreSignUpChecklist=true',
        state: { scrollTopPreSignUpChecklist: true },
      });
    } else {
      const preSignUpChecklist = document.querySelector('.pre-sign-up-checklist');
      if (preSignUpChecklist) {
        preSignUpChecklist.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        });
      } else {
        setSignupModalActive(true);
      }
    }
  }, [setSignupModalActive]);

  const handleProfile = useCallback(() => {
    setProfileModalActive(true);
  }, [setProfileModalActive]);

  const handleCloseLogin = useCallback(() => {
    setLoginModalActive(false);
  }, [setLoginModalActive]);

  const handleCloseSignup = useCallback(() => {
    setSignupModalActive(false);
  }, [setSignupModalActive]);

  const handleCloseProfile = useCallback(() => {
    setProfileModalActive(false);
  }, [setProfileModalActive]);

  const toggleMobileMenu = useCallback(() => {
    setMobileMenuActive(!mobileMenuActive);
  }, [mobileMenuActive, setMobileMenuActive]);

  const handleMenuClose = useCallback(() => {
    setMobileMenuActive(false);
  }, [setMobileMenuActive]);

  const handleLogout = useCallback(() => {
    dispatch(logoutRequestCreator());
  });

  return (
    <>
      <AppBar
        mobileMenuActive={mobileMenuActive}
        toggleMobileMenu={toggleMobileMenu}
        handleLogin={handleLogin}
        handleSignUp={handleSignUp}
        handleProfile={handleProfile}
        handleLogout={handleLogout}
        isLogoLink={isLogoLink}
        isOnLandingPage={isOnLandingPage}
        isOnIPFPage={isOnIPFPage}
        showMenu={showMenu}
      />
      {showMenu && <Menu tabs={tabs} active={activeTab} />}
      {mobileMenuActive && showMenu && (
        <MobileMenu
          tabs={tabs}
          active={activeTab}
          handleLogin={handleLogin}
          handleSignUp={handleSignUp}
          handleMenuClose={handleMenuClose}
          handleProfile={handleProfile}
          handleLogout={handleLogout}
        />
      )}
      {loginModalActive && <ModalLogin handleClose={handleCloseLogin} />}
      {signupModalActive && (
        <ModalRegister handleOpenLogin={handleLogin} handleClose={handleCloseSignup} />
      )}
      {profileModalActive && <ModalProfile handleClose={handleCloseProfile} />}
    </>
  );
};

export default Header;
