import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './style.css';

class Button extends Component {
  constructor() {
    super();
    this.state = {};
  }

  isSuccess() {
    if (!this.props.status) return this.props.isSuccess;

    return this.props.status.state === 'finished' && !this.props.hideSuccess;
  }

  isDisabled() {
    if (!this.props.status) return this.props.isDisabled;

    return this.props.status.count > 0;
  }

  isError() {
    if (!this.props.status) return this.props.isError;

    return this.props.status.state === 'error' && !this.props.hideError;
  }

  isLoading() {
    if (!this.props.status) return this.props.isLoading;

    return this.props.status.count > 0;
  }

  statusClass() {
    let toReturn = '';

    if (this.isLoading()) toReturn = 'isLoading';
    if (this.isSuccess()) toReturn = 'isSuccess';
    if (this.isError()) toReturn = 'isError';

    return toReturn;
  }

  render() {
    return (
      <button
        id={this.props.id}
        className={`button ${this.props.className}`}
        onClick={this.props.onClick}
        type={this.props.type}
        disabled={this.isDisabled() || this.props.disabled}
        data-cy={this.props['data-cy']}
      >
        {this.props.children}

        <div className={`status ${this.statusClass()}`}>
          <div className="statusIcon"></div>
        </div>
      </button>
    );
  }
}

Button.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  type: PropTypes.string,

  isLoading: PropTypes.bool,
  isSuccess: PropTypes.bool,
  isError: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

Button.defaultProps = {
  type: 'button',
  isLoading: false,
  isSuccess: false,
  isError: false,
  isDisabled: false,
};

export default Button;
