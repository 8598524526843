export const UPLOAD_COMMUNITIES_CSV = 'csv/UPLOAD_COMMUNITIES_CSV';
export const PARSE_COMMUNITIES_CSV = 'csv/PARSE_COMMUNITIES_CSV';
export const SET_COMMUNITY_PARSED = 'csv/SET_COMMUNITY_PARSED';
export const SET_COMMUNITY_IMPORTED = 'csv/SET_COMMUNITY_IMPORTED';

export function uploadCommunitiesCsv(payload) {
  return { type: UPLOAD_COMMUNITIES_CSV, payload };
}

export function parseCommunitiesCsv(payload) {
  return { type: PARSE_COMMUNITIES_CSV, payload };
}
