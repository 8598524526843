import * as notificationActions from 'store/modules/Notification/actions';

const initialState = {
  notification: {
    message: '',
    open: false,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case notificationActions.NEW_VERSION_AVAILABLE:
      return {
        ...state,
        notification: {
          message: 'NewVersionAvailable',
          open: true,
        },
      };
    case notificationActions.CLOSE_NOTIFICATION_MODAL: {
      return {
        ...state,
        notification: {
          message: '',
          open: false,
        },
      };
    }
    default:
      return state;
  }
};
