// @flow

import React, { Component } from 'react';
import ReactModal from 'react-modal';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import moment from 'moment';

import { getLocale, setLocale } from 'services/localisation';
import { GROUPS_EMAILS, INDIVIDUAL_EMAILS, FRIENDS_EMAILS } from 'services/notification';

import FormModal from 'components/FormModal';
import CloseButton from 'components/CloseButton';
import Accordion from 'components/Accordion';
import { setUserAvatar, sendEmailInviteCreator, getUserPayments } from 'store/modules/User/actions';
import {
  submitProfileUpdate,
  refetchProfile,
  unsubscribe,
  updateUserLanguage,
  submitPasswordUpdate,
} from 'store/modules/Account/actions';
import {
  linkUserToPrescriberWithCode,
  unlinkUserToPrescriber,
} from 'store/modules/Prescriber/actions';
import { clearStatus } from 'store/modules/Status/actions';
import { switchLanguage } from 'store/modules/App/actions';
import TopSection from './components/TopSection';

import ProfileInfo from './components/ProfileInfo';
import Prescriptor from './components/Prescriptor';
import ConfidentialInfo from './components/ConfidentialInfo';
import NotificationInfo from './components/NotificationInfo';
import PaymentsInfo from './components/PaymentsInfo';
import UnsubscribeInfo from './components/UnsubscribeInfo';

import './style.css';

if (process.env.NODE_ENV !== 'test') ReactModal.setAppElement('#root');

const statusRefPrescriptorCode = 'ModalProfile-PrescriptorCode';
const statusRefUnlinkPrescriber = 'ModalProfile-UnlinkPrescriber';
const statusRefEmailInvite = 'ModalProfile-EmailInvite';
const statusRefAvatarChange = 'ModalProfile-AvatarChange';
const statusRefSaveProfile = 'ModalProfile-SaveProfile';
const statusRefUnsubscribe = 'ModalProfile-unsubscribe';
const statusRefSaveConfidential = 'ModalProfle-ConfidentialInfo';
const statusRefChangeLanguage = 'ModalProfile-changeLanguage';

class ModalProfile extends Component {
  constructor() {
    super();

    this.state = {
      userData: {},
      changedData: {},
      validationError: null,
      errorMessage: '',
      notificationsPanelOpen: false,
      disabled_emails: {
        groupEmails: '',
        individualEmails: '',
        friendsEmails: '',
      },
    };

    this.init = this.init.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.handleChangePhone = this.handleChangePhone.bind(this);
    this.handleChangeLandline = this.handleChangeLandline.bind(this);
    this.handleLanguageChange = this.handleLanguageChange.bind(this);
    this.handleLocationChange = this.handleLocationChange.bind(this);
    this.handleSubmitClick = this.handleSubmitClick.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.handleSubmitClickPrescriptor = this.handleSubmitClickPrescriptor.bind(this);
    this.handleSubmitInviteClick = this.handleSubmitInviteClick.bind(this);
    this.handleCancelClickPrescriptor = this.handleCancelClickPrescriptor.bind(this);
    this.handleSubmitClickConfidential = this.handleSubmitClickConfidential.bind(this);
    this.handleAvatarChange = this.handleAvatarChange.bind(this);
    this.handleUnsubcribe = this.handleUnsubcribe.bind(this);
    this.handleClearStatus = this.handleClearStatus.bind(this);
    this.handleCloseNotificationPanel = this.handleCloseNotificationPanel.bind(this);
    this.handleOpenNotificationPanel = this.handleOpenNotificationPanel.bind(this);
  }

  componentDidMount() {
    if (this.props.userInfo) {
      this.setInitialNotificationOptions();
      this.init();
    }

    if (this.props.unsubscribeEmail) {
      this.setState({
        notificationsPanelOpen: true,
      });
    }

    this.props.dispatch(getUserPayments({ user_id: 'me' }));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.userInfo !== this.props.userInfo) {
      this.setInitialNotificationOptions();
      this.init();
    }

    if (
      this.props.statuses[statusRefAvatarChange] &&
      (this.props.statuses[statusRefAvatarChange] || {}).state === 'finished'
    ) {
      if (
        !prevProps.statuses[statusRefAvatarChange] ||
        (prevProps.statuses[statusRefAvatarChange] || {}).state !== 'finished'
      ) {
        this.props.dispatch(
          refetchProfile({
            user_id: 'me',
          })
        );
      }
    }
    if (
      this.props.statuses[statusRefSaveProfile] &&
      (this.props.statuses[statusRefSaveProfile] || {}).state === 'finished'
    ) {
      if (
        !prevProps.statuses[statusRefSaveProfile] ||
        (prevProps.statuses[statusRefSaveProfile] || {}).state !== 'finished'
      ) {
        this.props.dispatch(
          refetchProfile({
            user_id: 'me',
          })
        );
      }
    }
    if (
      this.props.statuses[statusRefSaveConfidential] &&
      (this.props.statuses[statusRefSaveConfidential] || {}).state === 'finished'
    ) {
      if (
        !prevProps.statuses[statusRefSaveConfidential] ||
        (prevProps.statuses[statusRefSaveConfidential] || {}).state !== 'finished'
      ) {
        this.setState({
          changedData: {},
        });
      }
    }

    if (
      this.props.statuses[statusRefSaveProfile] &&
      (this.props.statuses[statusRefSaveProfile] || {}).state === 'finished'
    ) {
      if (
        !prevProps.statuses[statusRefSaveProfile] ||
        (prevProps.statuses[statusRefSaveProfile] || {}).state !== 'finished'
      ) {
        this.props.dispatch(
          refetchProfile({
            user_id: 'me',
          })
        );
      }
    }

    if (
      this.props.statuses[statusRefPrescriptorCode] &&
      (this.props.statuses[statusRefPrescriptorCode] || {}).state === 'finished'
    ) {
      if (
        !prevProps.statuses[statusRefPrescriptorCode] ||
        (prevProps.statuses[statusRefPrescriptorCode] || {}).state !== 'finished'
      ) {
        this.props.dispatch(
          refetchProfile({
            user_id: 'me',
          })
        );
      }
    }

    if (
      this.props.statuses[statusRefUnlinkPrescriber] &&
      (this.props.statuses[statusRefUnlinkPrescriber] || {}).state === 'finished'
    ) {
      if (
        !prevProps.statuses[statusRefUnlinkPrescriber] ||
        (prevProps.statuses[statusRefUnlinkPrescriber] || {}).state !== 'finished'
      ) {
        this.props.dispatch(
          refetchProfile({
            user_id: 'me',
          })
        );
      }
    }
    if (
      this.props.statuses[statusRefUnsubscribe] &&
      (this.props.statuses[statusRefUnsubscribe] || {}).state === 'finished'
    ) {
      if (
        !prevProps.statuses[statusRefUnsubscribe] ||
        (prevProps.statuses[statusRefUnsubscribe] || {}).state !== 'finished'
      ) {
        this.props.dispatch(unsubscribe());
      }
    }

    if (this.props.unsubscribeEmail && this.props.unsubscribeEmail !== prevProps.unsubscribeEmail) {
      this.setState({
        notificationsPanelOpen: true,
      });
    }
  }

  setInitialNotificationOptions() {
    if (!this.props.userInfo) return;

    if (this.props.userInfo.user_settings.disabled_emails) {
      let groupEmails = '';
      let individualEmails = '';
      let friendsEmails = '';

      if (this.props.userInfo.user_settings.disabled_emails.value.includes(GROUPS_EMAILS)) {
        groupEmails = GROUPS_EMAILS;
      }
      if (this.props.userInfo.user_settings.disabled_emails.value.includes(INDIVIDUAL_EMAILS)) {
        individualEmails = INDIVIDUAL_EMAILS;
      }
      if (this.props.userInfo.user_settings.disabled_emails.value.includes(FRIENDS_EMAILS)) {
        friendsEmails = FRIENDS_EMAILS;
      }

      this.setState(
        {
          disabled_emails: {
            groupEmails,
            individualEmails,
            friendsEmails,
          },
        },
        () => {}
      );
    }
  }

  getInitialState = (userData) => {
    return {
      userData: {
        ...userData,
        country_id: (userData.country || {}).id,
        postcode_localities: userData.city ? [userData.city] : [],
        birthdate:
          userData.birthdate && userData.birthdate.length > 0
            ? moment(userData.birthdate).format('YYYY-MM-DD')
            : null,
      },
      changedData: {},
    };
  };

  init() {
    const { userInfo: userData } = this.props;
    this.setState(this.getInitialState(userData));
  }

  handleLanguageChange(languageId) {
    this.props.dispatch(switchLanguage(this.props.getLanguageById(languageId).code || getLocale()));

    this.props.dispatch(
      updateUserLanguage({
        language_id: languageId,
        statusRef: statusRefChangeLanguage,
      })
    );
  }

  handleChange(e) {
    this.handleClearStatus();

    let { name, value, type, checked } = e.target;

    if (type === 'checkbox' && name !== 'display_city') {
      value = checked;
    }

    if (type === 'checkbox' && name === 'display_city') {
      value = !checked;
    }

    let data = { [name]: value };
    if (type === 'checkbox' && name === 'display_pseudo' && value) {
      data.username = this.state.userData.username;
    }

    this.setState({
      userData: {
        ...this.state.userData,
        [name]: value,
      },
      changedData: {
        ...this.state.changedData,
        ...data,
      },
    });
  }

  handleChangePhone(phoneNumber, phoneNumberCountry) {
    this.handleClearStatus();

    this.setState({
      userData: {
        ...this.state.userData,
        phone_number: phoneNumber || '',
        phone_number_country: phoneNumberCountry || '',
      },
      changedData: {
        ...this.state.changedData,
        phone_number: phoneNumber || '',
        phone_number_country: phoneNumberCountry || '',
      },
    });
  }

  handleChangeLandline(landlineNumber, landlineCountry) {
    this.handleClearStatus();

    this.setState({
      userData: {
        ...this.state.userData,
        landline: landlineNumber || '',
        landline_country: landlineCountry || '',
      },
      changedData: {
        ...this.state.changedData,
        landline: landlineNumber || '',
        landline_country: landlineCountry || '',
      },
    });
  }

  handleNotificationSettingsChange = (e) => {
    const target = e.target;

    const name = target.name;
    const value = target.checked ? '' : parseInt(target.value, 10);
    const checked = target.checked;

    if (name === 'disabled_emails' && !checked) {
      this.setState(
        {
          disabled_emails: {
            groupEmails: GROUPS_EMAILS,
            individualEmails: INDIVIDUAL_EMAILS,
            friendsEmails: FRIENDS_EMAILS,
          },
        },
        () => {
          this.updateChangedDataNotification(this.state.disabled_emails);
        }
      );
    } else if (name === 'disabled_emails' && checked) {
      this.setState(
        {
          disabled_emails: {
            groupEmails: '',
            individualEmails: '',
            friendsEmails: '',
          },
        },
        () => {
          this.updateChangedDataNotification(this.state.disabled_emails);
        }
      );
    } else {
      this.setState(
        {
          disabled_emails: {
            ...this.state.disabled_emails,
            [name]: value,
          },
        },
        () => {
          this.updateChangedDataNotification(this.state.disabled_emails);
        }
      );
    }
  };

  updateChangedDataNotification(notificationOptions) {
    let emailsNotificationsOptions = [];

    if (notificationOptions.groupEmails !== '') {
      emailsNotificationsOptions.push(GROUPS_EMAILS);
    }
    if (notificationOptions.individualEmails !== '') {
      emailsNotificationsOptions.push(INDIVIDUAL_EMAILS);
    }
    if (notificationOptions.friendsEmails !== '') {
      emailsNotificationsOptions.push(FRIENDS_EMAILS);
    }

    this.setState({
      changedData: {
        ...this.state.changedData,
        user_settings: {
          ...(this.state.changedData.user_settings || {}),
          disabled_emails: {
            value: emailsNotificationsOptions,
          },
        },
      },
    });
  }

  handleLocationChange(data) {
    const { userData, changedData } = this.state;
    this.setState({
      userData: {
        ...userData,
        address: data.address || userData.address || '',
        address_details: data.address_details || userData.address_details || '',
        city: data.city || userData.city || '',
        zip_code: data.zip_code || userData.zip_code || '',
        latitude: data.latitude || userData.latitude || '',
        longitude: data.longitude || userData.longitude || '',
        country_id: data.country_id || userData.country_id || '',
      },
      changedData: {
        ...changedData,
        address: data.address || changedData.address || '',
        address_details: data.address_details || changedData.address_details || '',
        city: data.city || changedData.city || '',
        zip_code: data.zip_code || changedData.zip_code || '',
        latitude: data.latitude || changedData.latitude || '',
        longitude: data.longitude || changedData.longitude || '',
        country_id: data.country_id || changedData.country_id || '',
        errorZipCode: data.errorZipCode || {},
      },
      errorMessage: null,
    });
  }

  handleSubmitClick() {
    const { t } = this.props;
    const { changedData } = this.state;

    if (Object.keys(changedData).length === 0) return;

    changedData.city = this.state.userData.city;
    changedData.departmentsString = this.state.userData.departmentsString;
    changedData.zip_code = this.state.userData.zip_code;

    const { birthdate } = changedData;
    let errorMessage = null;

    if (changedData.errorZipCode !== undefined && changedData.errorZipCode.state === 'error') {
      errorMessage = t('Location.ZipcodeAutocomplete.error');
    }

    if (birthdate && moment().diff(birthdate, 'years') < 16) {
      errorMessage = t('ModalProfile.ProfileInfo.error-message');
    }

    if (
      this.props.role === 'user' &&
      !this.state.userData.country &&
      !this.state.changedData.country_id
    ) {
      errorMessage = t('ModalProfile.ProfileInfo.country-mandatory');
    }

    if (errorMessage) {
      this.setState({ errorMessage });
    } else {
      const dataToUpload = { ...this.state.changedData };
      if (dataToUpload.phone_number === null || dataToUpload.landline === null) {
        dataToUpload.phone_number = undefined;
        dataToUpload.landline = undefined;
      }

      if (Object.keys(dataToUpload).length > 0) {
        dataToUpload.statusRef = statusRefSaveProfile;
        this.props.dispatch(submitProfileUpdate(dataToUpload));
        this.setState({
          changedData: { ...this.state.changedData, statusRef: dataToUpload.statusRef },
        });
      }
    }
  }

  handleUnsubcribe() {
    if (!this.state.changedData) return;

    this.props.dispatch(
      unsubscribe({
        ...this.state.changedData,
        statusRef: statusRefUnsubscribe,
      })
    );
  }

  handleCancelClick() {
    this.setState({
      userData: this.props.userInfo,
      changedData: {},
    });

    setLocale((this.props.userInfo.language || {}).code);
  }

  handleSubmitClickPrescriptor() {
    const { t } = this.props;

    this.props.dispatch(
      clearStatus({
        statusRef: statusRefUnlinkPrescriber,
      })
    );

    let errorMessage = '';
    if (this.state.userData.prescribers.length > 0) {
      errorMessage = t('ModalProfile.Prescriptor.error-linked-only-to-one');
    }

    this.setState(
      {
        errorMessage,
      },
      () => {
        if (!errorMessage) {
          this.props.dispatch(
            linkUserToPrescriberWithCode({
              code: this.state.changedData.prescriptor_code,
              statusRef: statusRefPrescriptorCode,
            })
          );
        }
      }
    );
  }

  handleSubmitInviteClick() {
    if (!this.state.changedData) return;

    let emails = this.state.changedData.emails.split(',');
    emails.map((item) => {
      this.props.dispatch(
        sendEmailInviteCreator({
          user_id: this.state.userData.id,
          email: item,
          statusRef: statusRefEmailInvite,
        })
      );

      return null;
    });
  }

  handleCancelClickPrescriptor(prescriberId) {
    this.props.dispatch(
      clearStatus({
        statusRef: statusRefPrescriptorCode,
      })
    );

    this.props.dispatch(
      unlinkUserToPrescriber({
        userId: this.state.userData.id,
        prescriber_id: prescriberId,
        statusRef: statusRefUnlinkPrescriber,
      })
    );
  }

  handleSubmitClickConfidential() {
    const { t } = this.props;

    if (!this.state.changedData) return;

    let validationError;
    let password = this.state.changedData.password;
    let passwordConfirmation = this.state.changedData.password_confirmation;

    if ((password && !passwordConfirmation) || (!password && passwordConfirmation)) {
      validationError = t('ModalProfile.ConfidentialInfo.error-confirm');
    }

    if (password && passwordConfirmation) {
      if (password !== passwordConfirmation) {
        validationError = t('ModalProfile.ConfidentialInfo.error-password');
      } else if (password.length < 6) {
        validationError = t('ModalProfile.ConfidentialInfo.error-password-too-short');
      } else {
        validationError = null;
      }
    }

    this.setState(
      {
        validationError,
      },
      () => {
        let data = {
          id: this.state.userData.id,
          password: password,
          statusRef: statusRefSaveConfidential,
        };
        if (!validationError) {
          this.props.dispatch(submitPasswordUpdate(data));
        }
      }
    );
  }

  handleAvatarChange(dataUrl) {
    this.props.dispatch(
      setUserAvatar({
        userId: this.props.userInfo.id,
        dataUrl: dataUrl,
        statusRef: statusRefAvatarChange,
      })
    );
  }

  handleClearStatus() {
    this.props.dispatch(
      clearStatus({
        statusRef: statusRefSaveProfile,
      })
    );

    this.props.dispatch(
      clearStatus({
        statusRef: statusRefEmailInvite,
      })
    );
  }

  handleCloseNotificationPanel() {
    this.setState({
      notificationsPanelOpen: false,
    });

    this.props.handleCloseUnsubscribeEmail();
  }

  handleOpenNotificationPanel() {
    this.setState(
      {
        notificationsPanelOpen: true,
      },
      () => {
        document.querySelector('.section-notification').scrollIntoView({ behavior: 'smooth' });
      }
    );
  }

  handleBirthdateChange = (field: string, value: string): void => {
    this.setState({
      userData: {
        ...this.state.userData,
        [field]: value,
      },
      changedData: {
        ...this.state.changedData,
        [field]: value,
      },
    });
  };

  render() {
    const { t } = this.props;

    return (
      <ReactModal
        isOpen
        onRequestClose={this.props.handleClose}
        shouldCloseOnOverlayClick
        className="modal-content modal-profile"
        overlayClassName="modal-overlay"
      >
        <CloseButton onClick={this.props.handleClose} />

        <FormModal id="profile-form" className="profile-form" onSubmit={this.handleSubmitClick}>
          <TopSection
            userData={this.state.userData}
            handleAvatarChange={this.handleAvatarChange}
            inputLanguageChange={this.handleLanguageChange}
            statusChangeLanguage={this.props.statuses[statusRefChangeLanguage]}
          />

          <Accordion
            handleClearStatus={this.handleClearStatus}
            handleCloseNotificationPanel={this.handleCloseNotificationPanel}
            sections={[
              {
                title: t('ModalProfile.title-1'),
                content: (
                  <ProfileInfo
                    userData={this.state.userData}
                    user={this.props.userInfo}
                    countries={this.props.countries}
                    role={this.props.role}
                    inputChange={this.handleChange}
                    inputChangePhone={this.handleChangePhone}
                    inputChangeLandline={this.handleChangeLandline}
                    handleLocationChange={this.handleLocationChange}
                    onBirthdateChange={this.handleBirthdateChange}
                    cancelClick={this.handleCancelClick}
                    submitClick={this.handleSubmitClick}
                    errorMessage={this.state.errorMessage}
                    status={this.props.statuses[statusRefSaveProfile]}
                  />
                ),
              },
              this.props.role === 'user' && {
                title: t('ModalProfile.title-3'),
                content: (
                  <Prescriptor
                    userData={this.state.userData}
                    inputChange={this.handleChange}
                    cancelClick={this.handleCancelClickPrescriptor}
                    submitClick={this.handleSubmitClickPrescriptor}
                    errorMessage={this.state.errorMessage}
                    status={this.props.statuses[statusRefPrescriptorCode]}
                    statusRefUnlinkPrescriber={this.props.statuses[statusRefUnlinkPrescriber]}
                    isLinkedToPrescriber={
                      ((this.state.userData || {}).prescribers || []).length > 0
                    }
                  />
                ),
              },
              {
                title: t('ModalProfile.title-4'),
                content: (
                  <ConfidentialInfo
                    userData={this.state.userData}
                    inputChange={this.handleChange}
                    cancelClick={this.handleCancelClick}
                    submitClick={this.handleSubmitClickConfidential}
                    validationError={this.state.validationError}
                    status={this.props.statuses[statusRefSaveConfidential]}
                  />
                ),
              },
              {
                title: t('ModalProfile.title-5'),
                content: (
                  <NotificationInfo
                    userData={this.state.userData}
                    disabledEmails={this.state.disabled_emails}
                    inputCheckboxChange={this.handleNotificationSettingsChange}
                    inputChange={this.handleChange}
                    cancelClick={this.handleCancelClick}
                    submitClick={this.handleSubmitClick}
                    status={this.props.statuses[statusRefSaveProfile]}
                  />
                ),
                isOpen: this.state.notificationsPanelOpen,
                className: 'section-notification',
              },
              this.props.payments !== null &&
                this.props.payments.length > 0 && {
                  title: t('ModalProfile.title-7'),
                  content: <PaymentsInfo userData={this.state.userData} />,
                },
              {
                title: t('ModalProfile.title-8'),
                content: (
                  <UnsubscribeInfo
                    userData={this.state.userData}
                    inputChange={this.handleChange}
                    cancelClick={this.handleCancelClick}
                    submitClick={this.handleUnsubcribe}
                    status={this.props.statuses[statusRefUnsubscribe]}
                    notificationPanelOpen={this.handleOpenNotificationPanel}
                  />
                ),
              },
            ].filter(Boolean)}
          />

          <div>{this.props.updateRequestStatus}</div>
          <div>{this.props.updateError}</div>
        </FormModal>
      </ReactModal>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.Account.user,
  role: state.Account.role,
  statuses: state.Status.statuses,
  countries: state.Resource.countries,
  payments: state.User.userPaymentsResults && state.User.userPaymentsResults.payments,
  getLanguageById: (id) => state.Account.languages.find((item) => item.id === id),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ModalProfile));
