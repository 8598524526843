import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import moment from 'moment';

import Button from 'components/Button';
import Location from 'components/Location';
import ErrorMessage from 'components/ErrorMessage';
import InputInternationalPhoneNumber from 'components/InputInternationalPhoneNumber';
import InputBirthdate from 'components/InputBirthdate';

class ProfileInfo extends Component {
  render() {
    const { t, userData, onBirthdateChange } = this.props;
    const { birthdate } = userData;

    return (
      <form>
        <div className="profile-modal-profile-info form-profile">
          <div className="data-row data-row-half">
            <label>{t('ModalProfile.ProfileInfo.label-1')}</label>
            <input
              autoComplete="given-name"
              name="first_name"
              type="text"
              value={this.props.userData.first_name || ''}
              required
              onChange={this.props.inputChange}
              maxLength={191}
            />
          </div>

          <div className="data-row data-row-half">
            <label>{t('ModalProfile.ProfileInfo.label-2')}</label>
            <input
              autoComplete="family-name"
              name="last_name"
              type="text"
              value={this.props.userData.last_name || ''}
              required
              onChange={this.props.inputChange}
              maxLength={191}
            />
          </div>

          {this.props.role === 'prescriber' && (
            <div className="data-row">
              <label>{t('ModalProfile.ProfileInfo.label-3')}</label>
              <input
                autoComplete="company-name"
                name="company_name"
                type="text"
                value={this.props.userData.company_name || ''}
                onChange={this.props.inputChange}
                maxLength={191}
              />
            </div>
          )}

          <div className="data-row width-100">
            <label>{t('ModalProfile.ProfileInfo.label-4')}</label>
            <input
              autoComplete="email"
              name="email"
              type="email"
              value={this.props.userData.email || ''}
              required
              onChange={this.props.inputChange}
            />
          </div>

          <div>
            <InputBirthdate
              isRequired
              field="birthdate"
              label={t('ModalProfile.ProfileInfo.label-6')}
              birthdate={birthdate && birthdate.length > 0 ? moment(birthdate) : null}
              onChange={onBirthdateChange}
            />
          </div>

          <label>{t('ModalProfile.ProfileInfo.label-7')}</label>
          <div className="data-row radio-group padding-1 transparent no-border">
            <div className="radio-field padding-1">
              <input
                id="sexe_1"
                name="sexe"
                type="radio"
                value="1"
                onChange={this.props.inputChange}
                checked={parseInt(this.props.userData.sexe, 10) === 1}
              />
              <label htmlFor="sexe_1">{t('ModalProfile.ProfileInfo.label-8')}</label>
            </div>

            <div className="radio-field padding-1">
              <input
                id="sexe_2"
                name="sexe"
                type="radio"
                value="2"
                onChange={this.props.inputChange}
                checked={parseInt(this.props.userData.sexe, 10) === 2}
              />
              <label htmlFor="sexe_2">{t('ModalProfile.ProfileInfo.label-9')}</label>
            </div>
          </div>

          <div className="data-row data-row-half">
            <label>{t('ModalProfile.ProfileInfo.label-10-1')}</label>
            <InputInternationalPhoneNumber
              phoneNumber={this.props.userData.phone_number}
              phoneNumberCountry={this.props.userData.phone_number_country ?? 'FR'}
              handlePhoneNumberChange={this.props.inputChangePhone}
            />
          </div>
          <div className="data-row data-row-half">
            <label>{t('ModalProfile.ProfileInfo.label-10-2')}</label>
            <InputInternationalPhoneNumber
              phoneNumber={this.props.userData.landline}
              phoneNumberCountry={this.props.userData.landline_country ?? 'FR'}
              handlePhoneNumberChange={this.props.inputChangeLandline}
            />
          </div>

          <Location
            data={this.props.userData}
            user={this.props.user}
            countries={this.props.countries}
            onChange={this.props.handleLocationChange}
            zipcodeAutocomplete
          />

          <div>
            <Button onClick={this.props.cancelClick}>{t('GLOBAL.cancel')}</Button>

            <Button type="submit" onClick={null} status={this.props.status}>
              {t('GLOBAL.submit')}
            </Button>

            <ErrorMessage status={this.props.status} />

            {this.props.errorMessage && (
              <div className={`alert danger`}>{this.props.errorMessage}</div>
            )}
          </div>
        </div>
      </form>
    );
  }
}

export default withTranslation()(ProfileInfo);
