export const GET_ALL_SEO_PAGES = 'seo/GET_ALL_SEO_PAGES';
export const CREATE_SEO_PAGE = 'seo/CREATE_SEO_PAGE';
export const UPDATE_SEO_PAGE = 'seo/UPDATE_SEO_PAGE';
export const DELETE_SEO_PAGE = 'seo/DELETE_SEO_PAGE';

export const CACHE_SEO_PAGES = 'seo/CACHE_SEO_PAGES';
export const CACHE_SEO_PAGES_SET = 'seo/CACHE_SEO_PAGES_SET';
export const CACHE_SEO_PAGES_SEARCH_RESULTS = 'seo/CACHE_SEO_PAGES_SEARCH_RESULTS';

export function getSeoPages(payload) {
  return { type: GET_ALL_SEO_PAGES, payload };
}

export function createSeoPage(payload) {
  return { type: CREATE_SEO_PAGE, payload };
}

export function updateSeoPage(payload) {
  return { type: UPDATE_SEO_PAGE, payload };
}

export function deleteSeoPage(payload) {
  return { type: DELETE_SEO_PAGE, payload };
}
