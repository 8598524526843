export const GET_ADMIN_STATS = 'stats/GET_ADMIN_STATS';
export const SET_ADMIN_STATS = 'stats/SET_ADMIN_STATS';

export const GET_PAYMENTS = 'stats/GET_PAYMENTS';
export const SET_PAYMENTS_CACHE = 'stats/SET_PAYMENTS_CACHE';

export const RECOVER_PAYMENT = 'stats/RECOVER_PAYMENT';
export const CANCEL_PAYMENT = 'stats/CANCEL_PAYMENT';

export const GET_PRESCRIBER_DASHBOARD_OVERVIEW = 'stats/GET_PRESCRIBER_DASHBOARD_OVERVIEW';
export const GET_DASHBOARD_OVERVIEW = 'stats/GET_DASHBOARD_OVERVIEW';
export const GET_PRESCRIBER_DASHBOARD_ACTIVITIES = 'stats/GET_PRESCRIBER_DASHBOARD_ACTIVITIES';
export const GET_DASHBOARD_ACTIVITIES = 'stats/GET_DASHBOARD_ACTIVITIES';
export const SET_PRESCRIBER_DASHBOARD = 'stats/SET_PRESCRIBER_DASHBOARD';

export const EXPORT_PAYMENTS = 'stats/EXPORT_PAYMENTS';
export const EXPORT_PRESCRIBER_BILLING = 'stats/EXPORT_PRESCRIBER_BILLING';
export const EXPORT_USER_SESSION_FEEDBACKS = 'stats/EXPORT_USER_SESSION_FEEDBACKS';

export function getAdminStats(payload) {
  return { type: GET_ADMIN_STATS, payload };
}

export function getPayments(payload) {
  return { type: GET_PAYMENTS, payload };
}

export function recoverPayment(payload) {
  return { type: RECOVER_PAYMENT, payload };
}

export function cancelPayment(payload) {
  return { type: CANCEL_PAYMENT, payload };
}

export function exportPayments(payload) {
  return { type: EXPORT_PAYMENTS, payload };
}

export function exportPrescriberBilling(payload) {
  return { type: EXPORT_PRESCRIBER_BILLING, payload };
}

export function exportUserSessionFeedbacks(payload) {
  return { type: EXPORT_USER_SESSION_FEEDBACKS, payload };
}

export function getPrescriberDashboardOverview(payload) {
  return { type: GET_PRESCRIBER_DASHBOARD_OVERVIEW, payload };
}

export function getDashboardOverview(payload) {
  return { type: GET_DASHBOARD_OVERVIEW, payload };
}

export function getDashboardActivities(payload) {
  return { type: GET_DASHBOARD_ACTIVITIES, payload };
}

export function getPrescriberDashboardActivities(payload) {
  return { type: GET_PRESCRIBER_DASHBOARD_ACTIVITIES, payload };
}
