import React, { Component } from 'react';
import ReactModal from 'react-modal';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import FormModal from 'components/FormModal';
import Button from 'components/Button';
import CloseButton from 'components/CloseButton';
import ErrorMessage from 'components/ErrorMessage';

import { clearStatus } from 'store/modules/Status/actions';
import { submitForgottenPasswordRequest } from 'store/modules/Account/actions';

import './style.css';

const statusRefForgotPwd = 'ModalForgotPassword';

if (process.env.NODE_ENV !== 'test') ReactModal.setAppElement('#root');

class ModalForgotPassword extends Component {
  constructor() {
    super();

    this.state = {
      email: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(clearStatus({ statusRef: statusRefForgotPwd }));
  }

  handleChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  handleSubmit() {
    this.props.dispatch(
      submitForgottenPasswordRequest({
        email: this.state.email,
        statusRef: statusRefForgotPwd,
      })
    );
  }

  render() {
    const { t } = this.props;

    return (
      <ReactModal
        isOpen
        onRequestClose={this.props.handleCloseForgotPassword}
        shouldCloseOnOverlayClick
        className="modal-content modal-forgot-password"
        overlayClassName="modal-overlay"
      >
        <CloseButton onClick={this.props.handleCloseForgotPassword} />

        <FormModal className="forgot-password-form transparent" onSubmit={this.handleSubmit}>
          <h1>{t('ModalForgotPassword.title')}</h1>

          <div className="row-container">
            <input
              type="email"
              name="email"
              value={this.state.email}
              placeholder="Email"
              autoComplete="false"
              required
              onChange={this.handleChange}
              onBlur={this.handleChange}
            />
          </div>

          <div className="row-container">
            <Button className="button" onClick={this.props.handleCloseForgotPassword}>
              {t('GLOBAL.cancel')}
            </Button>

            <Button type="submit" name="button" status={this.props.statuses[statusRefForgotPwd]}>
              {t('GLOBAL.submit')}
            </Button>

            <ErrorMessage status={this.props.statuses[statusRefForgotPwd]} />
          </div>
        </FormModal>
      </ReactModal>
    );
  }
}

const mapStateToProps = (state) => ({
  theState: state,
  statuses: state.Status.statuses,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});

ModalForgotPassword.defaultProps = {};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ModalForgotPassword));
