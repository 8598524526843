import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import { MAIN_TEXT_COLOR, PRIMARY_COLOR } from '@shared/constants';
import { ONLINE_PROGRAMS_CARDS_FR } from 'app/landing-page/constants';
import { privateTheme, publicTheme } from '@layout/theme';

import Button from '@material-ui/core/Button';
import OnlineProgramsCardItemFr from 'app/landing-page/components/OnlineProgramsCardItemFr';

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: '3vh',
    paddingBottom: '2vh',
    width: '100%',
    color: MAIN_TEXT_COLOR,
    [publicTheme.breakpoints.down('xs')]: {
      paddingTop: '0',
    },
  },
  headingMain: {
    width: '100%',
    textAlign: 'center',
    fontSize: '2.5rem',
    fontFamily: 'Chevin Demi Bold, sans-serif',
  },
  blocksContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: '4vh',
    [privateTheme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  block: {
    width: '45%',
    maxWidth: '700px',
    fontSize: '1.6rem',
    lineHeight: '2rem',
    padding: '30px 30px',
    boxShadow: '2px 2px 2px 2px rgba(0, 0, 0, 0.5)',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [privateTheme.breakpoints.down('sm')]: {
      width: '90%',
      margin: 'auto',
      marginBottom: '20px',
    },
  },
  blockHeading: {
    color: PRIMARY_COLOR,
    fontSize: '2.2rem',
    fontFamily: 'Chevin Demi Bold, sans-serif',
    marginTop: 0,
    marginBottom: '1.5em',
    whiteSpace: 'nowrap',
    '& span': {
      display: 'block',
    },
    [privateTheme.breakpoints.down('md')]: {
      whiteSpace: 'unset',
      '& span': {
        display: 'inline-block',
      },
    },
  },
  programs: {
    display: 'flex',
    justifyContent: 'space-around',
    [privateTheme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  buttonRoot: {
    margin: 'auto',
    marginTop: '6em',
  },
  buttonLabel: {
    fontFamily: 'Roboto, Georgia, serif',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '1.25rem',
    lineHeight: '1.75rem',
  },
}));

const ContentFrSection = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const redirectToCourses = () => {
    window.location.assign('https://mescours.sielbleu.org/group-classes');
  };

  return (
    <section className={clsx(classes.root)}>
      <h1 className={clsx(classes.headingMain)}>Votre plateforme GetphY évolue…</h1>
      <div className={clsx(classes.blocksContainer)}>
        <div className={clsx(classes.block)}>
          <h1 className={clsx(classes.blockHeading)}>
            Nouvelle plateforme pour nos cours <span>collectifs !</span>
          </h1>
          <p>
            La plateforme GetphY des cours collectifs évolue et devient “Mes cours Siel Bleu” ! Pour
            nous rejoindre sur cette nouvelle plateforme, nous vous demandons de bien vouloir vous
            re-créer un compte (gratuit) en cliquant sur le bouton suivant :
          </p>
          <Button
            variant="contained"
            style={publicTheme.palette.accentuated}
            classes={{ root: classes.buttonRoot, label: classes.buttonLabel }}
            size="large"
            onClick={redirectToCourses}
          >
            Accès à Mes cours Siel Bleu
          </Button>
        </div>
        <div className={clsx(classes.block)}>
          <h1 className={clsx(classes.blockHeading)}>Vos programmes d’entrainement en ligne</h1>
          <div className={clsx(classes.programs)}>
            {ONLINE_PROGRAMS_CARDS_FR.map((program) => (
              <OnlineProgramsCardItemFr
                key={program.id}
                title={t(program.title)}
                imgSrc={program.imgSrc}
                imgAlt={program.imgAlt}
                redirect={program.redirect}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContentFrSection;
