import * as statusActions from 'store/modules/Status/actions';

const initialState = {
  statuses: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case statusActions.SET_STATUSES:
      return {
        ...state,
        statuses: action.payload,
      };

    default:
      return state;
  }
};
