import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import Icon from 'components/Icon';
import './style.css';

const baseClass = 'nav-bar-nav-item';

class NavItem extends Component {
  constructor() {
    super();

    this.handleClick = this.handleClick.bind(this);
  }
  handleClick(e) {
    if (this.props.item.link.length === 0) e.preventDefault();
  }
  render() {
    return (
      <div className={baseClass}>
        <NavLink
          to={this.props.item.link}
          className={`${baseClass}-link ${this.props.item.className || ''}`}
          onClick={this.handleClick}
        >
          <Icon src={this.props.item.icon} className="nav-icon" />

          {this.props.totalRequests > 0 && (
            <div className="nav-notification">{this.props.totalRequests}</div>
          )}

          <div className="nav-dropdown">
            {this.props.item.dropdown.map((item, index) => {
              if (item.link) {
                return (
                  <NavLink
                    className={`nav-dropdown-row ${this.props.role}`}
                    // eslint-disable-next-line react/no-array-index-key
                    key={`navDropdownRow_${index}`}
                    to={`${item.link}`}
                  >
                    {item.text}
                  </NavLink>
                );
              } else {
                return (
                  <div
                    className={`nav-dropdown-row ${this.props.role}`}
                    // eslint-disable-next-line react/no-array-index-key
                    key={`navDropdownRow_${index}`}
                    onClick={item.onClick}
                  >
                    {item.text}
                  </div>
                );
              }
            })}
          </div>
        </NavLink>
        <div className={`${baseClass}-nav-text`}>{this.props.item.text}</div>
      </div>
    );
  }
}

export default NavItem;
