import React from 'react';

import clsx from 'clsx';

import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { uppercaseFirst, getUrlFromPages } from '@shared/helpers';

import { Link } from 'react-router-dom';

import { privateTheme } from '@layout/theme';
import { WHITE, MAIN_TEXT_COLOR } from '@shared/constants';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    [privateTheme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  item: {
    marginTop: '1.5rem',
    [privateTheme.breakpoints.up('sm')]: {
      marginRight: '1rem',
      marginTop: '0',
    },
  },
  itemContent: {
    fontFamily: 'Roboto, Georgia, serif',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '1.25rem',
    lineHeight: '1.75rem',
    color: MAIN_TEXT_COLOR,
    textDecoration: 'none',
    [privateTheme.breakpoints.up('sm')]: {
      color: WHITE,
    },
  },
  pointer: {
    cursor: 'pointer',
  },
}));

const AppBarNavItem = ({ handleProfile, handleLogout }) => {
  const { t, i18n } = useTranslation();
  const { searchResults } = useSelector((state) => state.Seo);
  const { language } = i18n;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.item}>
        <Link to={getUrlFromPages(searchResults, language, 'blog')} className={classes.itemContent}>
          {uppercaseFirst(t('NavItems.blog'))}
        </Link>
      </div>
      <div className={clsx(classes.item, classes.pointer)}>
        <span className={classes.itemContent} onClick={handleProfile}>
          {uppercaseFirst(t('NavItems.myAccount'))}
        </span>
      </div>
      <div className={clsx(classes.item, classes.pointer)}>
        <span className={classes.itemContent} onClick={handleLogout}>
          {uppercaseFirst(t('NavItems.logout'))}
        </span>
      </div>
    </div>
  );
};

export default AppBarNavItem;
