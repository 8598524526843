import * as articleActions from 'store/modules/Article/actions';

const initialState = {
  public: [],
  blogSearchResults: [],
  articles: {
    blog: [],
  },
  article: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case articleActions.UPDATE_CACHE_SET:
      return {
        ...state,
        [action.articleType]: action.payload,
      };

    case articleActions.UPDATE_CACHE_ARTICLES_SET:
      return {
        ...state,
        articles: action.payload,
      };

    case articleActions.UPDATE_CACHE_ARTICLE_SET:
      return {
        ...state,
        article: action.payload,
      };

    default:
      return state;
  }
};
