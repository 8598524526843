import * as programActions from 'store/modules/Program/actions';

const initialState = {
  programs: {},
  searchResults: {
    programs: [],
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case programActions.CACHE_UPDATE_PROGRAMS_SET:
      return {
        ...state,
        programs: action.payload,
      };

    case programActions.UPDATE_PROGRAMS_SEARCH_RESULTS:
      return {
        ...state,
        searchResults: action.payload,
      };

    default:
      return state;
  }
};
