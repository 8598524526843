// @flow
import React from 'react';

import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';

import { MAIN_TEXT_COLOR } from '@shared/constants';

import { publicTheme } from '@layout/theme';

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: '10vh',
    paddingLeft: '24px',
    paddingRight: '24px',
    [publicTheme.breakpoints.down('md')]: {
      width: '100vw',
    },
    [publicTheme.breakpoints.up('lg')]: {
      width: `${publicTheme.breakpoints.values.lg}px`,
      margin: '0 auto',
    },
    [publicTheme.breakpoints.down('xs')]: {
      paddingBottom: '5vh',
    },
  },
  container: {
    width: '550px',
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    padding: '5px',
    [publicTheme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  text: {
    fontFamily: 'Roboto, Georgia, serif',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '2.3rem',
    lineHeight: '3.25rem',
    color: MAIN_TEXT_COLOR,
  },
}));

const TextBanner = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={clsx(classes.root)}>
      <div className={clsx(classes.container)}>
        <p className={clsx(classes.text)}>{t('LandingPage.textBanner')}</p>
      </div>
    </div>
  );
};

export default TextBanner;
