import moment from 'moment';
import t from './translate';

export function normalizeTime(value, format) {
  if (value === undefined || value === null || value === '') return null;

  switch (format) {
    case 'seconds':
      return convertSeconds(value);
    case 'secondsRounded':
      return convertSeconds(value, true);
    case 'minutes':
      return convertMinutes(value);
    case 'hours_minutes':
      return convertHoursMinutes(value);
    case 'date':
      return formatDate(value);
    case 'dateEn':
      return formatDateEn(value);
    case 'datetime':
      return formatDatetime(value);
    case 'date_or_time':
      return formatDateOrTime(value);
    case 'time':
      return formatTime(value);
    case 'formattedDifference':
      return formattedDifference(value);
    default:
      console.log('Error : format not allowed');
      break;
  }

  return null;
}

export function formatDate(date) {
  return moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY');
}

export function formatDateEn(date) {
  return moment(date, 'YYYY-MM-DD').format('MM-DD-YYYY');
}

export function formatDatetime(datetime) {
  return moment(datetime, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY HH:mm');
}

export function formatTime(time) {
  return moment(time, 'HH:mm:ss').format('HH:mm');
}

export function formatDateOrTime(datetime) {
  let datetimeMoment = moment(datetime, 'YYYY-MM-DD HH:mm:ss');

  if (moment().isSame(datetimeMoment, 'day'))
    datetimeMoment = formatTime(moment(datetime).format('HH:mm:ss'));
  else datetimeMoment = formatDatetime(datetime);

  return datetimeMoment;
}

export function convertSeconds(time, secondRounded) {
  time = Number(time);
  const h = Math.floor(time / 3600);
  let m = Math.floor((time % 3600) / 60);
  const s = Math.floor((time % 3600) % 60);

  if (secondRounded && s > 30) {
    m += 1;
  }

  const hDisplay = h > 0 ? `${h}H` : '';

  const sDisplay = s > 0 ? `${s} sec` : '';

  let minSuffix = ' min';
  if (!secondRounded && sDisplay.length > 0) {
    minSuffix = ' min, ';
  }
  const mDisplay = m > 0 ? m + minSuffix : '';

  return secondRounded ? hDisplay + mDisplay : hDisplay + mDisplay + sDisplay;
}

export function convertMinutes(time) {
  time = Number(time);
  const h = Math.floor((time % 3600) / 60);
  const m = Math.floor((time % 3600) % 60);

  const hDisplay = h > 0 ? `${h}H` : '';
  const mDisplay = m > 0 ? `${m} min` : '';
  return hDisplay + mDisplay;
}

export function convertHoursMinutes(time) {
  time = Number(time);
  const h = Math.floor((time % 3600) / 60);
  const m = Math.floor((time % 3600) % 60);

  const formattedH = `0${h}`.slice(-2);
  const formattedM = `0${m}`.slice(-2);

  return `${formattedH}H${formattedM}`;
}

export function formattedDifference(datetime) {
  const today = moment();
  const messageDateTime = moment(datetime);
  const isCurrentDay = messageDateTime.isSame(today, 'day');

  if (isCurrentDay) {
    return messageDateTime.format('HH:mm');
  }

  return `${t('Services.time.ago')} ${today.diff(messageDateTime, 'days')} ${t(
    'Services.time.days'
  )}`;
}
