import React, { Component } from 'react';

import Icon from 'components/Icon';
import DropdownMenu from './DropdownMenu';

import './style.css';

// TODO: handle file paths
const imagePath = process.env.REACT_APP_IMAGE_URL;

class AvatarButton extends Component {
  constructor() {
    super();

    this.state = {
      dropdownActive: false,
    };

    this.handleDropdownClick = this.handleDropdownClick.bind(this);

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('dragstart', this.handleClickOutside);
    document.addEventListener('dblclick', this.handleClickOutside);
    document.addEventListener('mousedown', this.handleClickOutside);
    document.addEventListener('touchstart', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('dragstart', this.handleClickOutside);
    document.removeEventListener('dblclick', this.handleClickOutside);
    document.removeEventListener('mousedown', this.handleClickOutside);
    document.removeEventListener('touchstart', this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.state.dropdownActive && this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({
        dropdownActive: false,
      });
    }
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleDropdownClick(e) {
    e.stopPropagation();

    this.setState((prevState) => ({
      dropdownActive: !prevState.dropdownActive,
    }));
  }

  render() {
    return (
      <div
        className="header-avatar-button"
        onClick={this.handleDropdownClick}
        ref={this.setWrapperRef}
      >
        {this.props.avatarUrl && <img src={imagePath + this.props.avatarUrl} alt="avatar" />}
        {!this.props.avatarUrl && <Icon src="default-avatar.svg" />}

        <DropdownMenu
          dropdownButtons={this.props.dropdownButtons}
          isActive={this.state.dropdownActive}
        />
      </div>
    );
  }
}

export default AvatarButton;
