import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import Button from 'components/Button';
import ErrorMessage from 'components/ErrorMessage';

import { GROUPS_EMAILS, INDIVIDUAL_EMAILS, FRIENDS_EMAILS } from 'services/notification';
import { ROLE_USER } from 'services/roles';

import './style.css';

class NotificationInfo extends Component {
  render() {
    const { t, userData, inputCheckboxChange, cancelClick, submitClick, status } = this.props;

    return (
      <div className="profile-modal-personal-info form-profile">
        <div className="data-row">
          <label>{t('ModalProfile.NotificationInfo.label-1')}</label>
        </div>

        <div className="data-row">
          <div className="data-row data-row-half radio-group transparent no-border padding-min width-100 notifications-all">
            <div className="radio-field padding-min width-100">
              <input
                id="notifications"
                className="notifications"
                name="disabled_emails"
                type="checkbox"
                checked={
                  this.props.disabledEmails.groupEmails !== GROUPS_EMAILS &&
                  this.props.disabledEmails.individualEmails !== INDIVIDUAL_EMAILS &&
                  this.props.disabledEmails.friendsEmails !== FRIENDS_EMAILS
                }
                onChange={(event) => inputCheckboxChange(event)}
              />
              <label htmlFor="notifications">{t('ModalProfile.NotificationInfo.label-4')}</label>
            </div>
          </div>

          <div className="notifications">
            <div className="radio-group transparent no-border notifications-1">
              <div className="radio-field width-100">
                <input
                  id="disabled_emails_1"
                  className="notifications"
                  name="groupEmails"
                  type="checkbox"
                  value={GROUPS_EMAILS}
                  checked={this.props.disabledEmails.groupEmails !== GROUPS_EMAILS}
                  onChange={(event) => inputCheckboxChange(event)}
                />
                {userData.roles_ids.includes(ROLE_USER) ? (
                  <label htmlFor="disabled_emails_1">
                    {t('ModalProfile.NotificationInfo.label-41-beneficiary')}
                  </label>
                ) : (
                  <label htmlFor="disabled_emails_1">
                    {t('ModalProfile.NotificationInfo.label-41')}
                  </label>
                )}
              </div>
            </div>

            <div className="radio-group transparent no-border">
              <div className="radio-field width-100">
                <input
                  id="disabled_emails_2"
                  className="notifications"
                  name="individualEmails"
                  type="checkbox"
                  value={INDIVIDUAL_EMAILS}
                  checked={this.props.disabledEmails.individualEmails !== INDIVIDUAL_EMAILS}
                  onChange={(event) => inputCheckboxChange(event)}
                />
                {userData.roles_ids.includes(ROLE_USER) ? (
                  <label htmlFor="disabled_emails_2">
                    {t('ModalProfile.NotificationInfo.label-42-beneficiary')}
                  </label>
                ) : (
                  <label htmlFor="disabled_emails_2">
                    {t('ModalProfile.NotificationInfo.label-42')}
                  </label>
                )}
              </div>
            </div>

            <div className="radio-group transparent no-border">
              <div className="radio-field width-100">
                <input
                  id="disabled_emails_3"
                  className="notifications"
                  name="friendsEmails"
                  type="checkbox"
                  value={FRIENDS_EMAILS}
                  checked={this.props.disabledEmails.friendsEmails !== FRIENDS_EMAILS}
                  onChange={(event) => inputCheckboxChange(event)}
                />
                {userData.roles_ids.includes(ROLE_USER) ? (
                  <label htmlFor="disabled_emails_3">
                    {t('ModalProfile.NotificationInfo.label-43-beneficiary')}
                  </label>
                ) : (
                  <label htmlFor="disabled_emails_3">
                    {t('ModalProfile.NotificationInfo.label-43')}
                  </label>
                )}
              </div>
            </div>
          </div>
        </div>

        <div>
          <Button onClick={cancelClick}>{t('GLOBAL.cancel')}</Button>

          <Button onClick={submitClick} status={status}>
            {t('GLOBAL.submit')}
          </Button>

          <ErrorMessage status={status} />
        </div>
      </div>
    );
  }
}

export default withTranslation()(NotificationInfo);
