// @flow

import React, { Component } from 'react';
import ReactModal from 'react-modal';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import moment from 'moment';

import { getLocale } from 'services/localisation';
import getQueryParam from 'services/location';
import { itContains } from '@services/utils';

import FormModal from 'components/FormModal';
import Button from 'components/Button';
import CloseButton from 'components/CloseButton';
import ModalRegisterSuccess from 'containers/modals/ModalRegisterSuccess';
import InputBirthdate from 'components/InputBirthdate';

import { registerRequestCreator } from 'store/modules/Account/actions';
import { clearStatus } from 'store/modules/Status/actions';
import withSlugs from 'layouts/withSlugs';
import {
  SIGNUP_MODAL_WORD_OPENER_FROM_URL,
  prescriberCode,
  prescriberCode2,
  prescriberCode3,
  prescriberCode3bis,
  prescriberCode4,
  prescriberCodeEcosante,
  prescriberCodeIPF,
} from 'constants/constants';
import { PRESCRIBER_CODE_ALLIANZ, URL_PATH_ALLIANZ } from 'containers/PageAllianz/constants';
import { COMMUNITY_COUNTRIES } from 'app/core/constants';
import ModalDatasConsent from './components/ModalDatasConsent';

import './style.css';

const statusRef = 'ModalRegister';

class ModalRegister extends Component {
  constructor() {
    super();

    const isUserFromArgentinaIPF =
      getLocale() === 'es-AR' && itContains(window.location.pathname.toLowerCase(), 'fp');

    this.state = {
      form: {
        first_name: '',
        last_name: '',
        birthdate: '',
        email: '',
        password: '',
        password_confirmation: '',
        cgu: false,
        authorize_data: true,
        country_id: isUserFromArgentinaIPF ? '18' : null,
      },
      registerSuccessModalActive: false,
      validationError: null,
      registredGroupClass: null,
      userSignUpComeFromEmail: false,
      uid: null,
      displayInputCodeField: false,
      isUserFromArgentinaIPF: isUserFromArgentinaIPF,
    };

    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.handleRegisterSuccess = this.handleRegisterSuccess.bind(this);
    this.handleCloseRegisterSuccess = this.handleCloseRegisterSuccess.bind(this);

    this.handleOpenModalDatasConsent = this.handleOpenModalDatasConsent.bind(this);
    this.handleCloseModalDatasConsent = this.handleCloseModalDatasConsent.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(clearStatus({ statusRef: statusRef }));
    if (getQueryParam('open') === SIGNUP_MODAL_WORD_OPENER_FROM_URL) {
      this.setState({
        ...this.state,
        form: {
          ...this.state.form,
          email: getQueryParam('email'),
          first_name: getQueryParam('first_name'),
          last_name: getQueryParam('last_name'),
          country_id: getQueryParam('country_id'),
          birthdate: getQueryParam('birthdate') || '', // we fallback to a string  because bellow it cannot parse a null with .length
        },
        registredGroupClass: getQueryParam('class'),
        userSignUpComeFromEmail: true,
        uid: getQueryParam('uid'),
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      !this.state.registerSuccessModalActive &&
      this.props.statuses[statusRef] &&
      this.props.statuses[statusRef].state === 'finished' &&
      prevProps.statuses[statusRef].state !== 'finshed'
    ) {
      this.handleRegisterSuccess();
    }
  }

  handleCloseModal() {
    this.setState(
      {
        form: {
          first_name: '',
          last_name: '',
          birthdate: '',
          email: '',
          password: '',
          password_confirmation: '',
          cgu: false,
          authorize_data: true,
        },
        validationError: null,
      },
      () => this.props.handleClose()
    );
  }

  handleInputChange(event) {
    const { countries } = this.props;
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    const isFromIpf =
      itContains(window.location.pathname.toLowerCase(), 'pf') ||
      itContains(window.location.pathname.toLowerCase(), 'fp') ||
      itContains(window.location.pathname.toLowerCase(), 'lf');

    let { displayInputCodeField } = this.state;

    if (name === 'country_id' && isFromIpf) {
      const country = countries.find((country) => country.id === parseInt(value, 10));

      if (country !== undefined && country.code === 'AR') {
        displayInputCodeField = true;
      } else {
        displayInputCodeField = false;
      }
    }

    this.setState({
      form: {
        ...this.state.form,
        [name]: value,
      },
      displayInputCodeField,
    });
  }

  handleBirthdateChange = (field: string, value: string): void => {
    this.setState({
      form: {
        ...this.state.form,
        [field]: value,
      },
    });
  };

  handleSubmit(datas) {
    let form = this.state.form;
    let pathname = window.location.pathname;

    if (form.code === undefined || form.code === '') {
      if (itContains(pathname.toLowerCase(), 'bvs')) {
        form.code = this.getPrescriberCode(pathname);
      } else if (itContains(pathname.toLowerCase(), '/ecosante')) {
        form.code = prescriberCodeEcosante;
      } else if (itContains(pathname.toLowerCase(), URL_PATH_ALLIANZ)) {
        form.code = PRESCRIBER_CODE_ALLIANZ;
      } else if (
        itContains(pathname.toLowerCase(), 'pf') ||
        itContains(pathname.toLowerCase(), 'fp') ||
        itContains(pathname.toLowerCase(), 'lf')
      ) {
        form.code = prescriberCodeIPF;
        form.is_from_ipf = true;
      }
    }

    if (this.props.inviteCode) {
      form.code = this.props.inviteCode;
    }

    form.language_code = getLocale();

    this.props.dispatch(
      registerRequestCreator({
        ...form,
        authorize_data: datas.authorize_data,
        statusRef: statusRef,
        registredGroupClass: this.state.registredGroupClass,
        userSignUpComeFromEmail: this.state.userSignUpComeFromEmail,
        uid: this.state.uid,
      })
    );
  }

  handleRegisterSuccess() {
    this.handleCloseModalDatasConsent();

    this.setState({
      registerSuccessModalActive: true,
    });
  }

  handleCloseRegisterSuccess() {
    this.setState({
      registerSuccessModalActive: false,
      validationError: null,
    });

    this.handleCloseModalDatasConsent();
    this.handleCloseModal();
  }

  handleOpenModalDatasConsent() {
    const { t } = this.props;

    let form = this.state.form;
    let validationError;

    if (form.password !== form.password_confirmation) {
      validationError = t('PagePasswordReset.error');
    } else if (form.password.length < 6) {
      validationError = t('PagePasswordReset.error-password-too-short');
    } else {
      validationError = null;
    }

    if (moment().diff(this.state.form.birthdate, 'years') < 18) {
      validationError = `${t('ModalRegister.18Required')}`;
    }

    if (!validationError) {
      this.setState({
        datasConsentModalActive: true,
        validationError: null,
      });
    } else {
      this.setState({
        validationError,
      });
    }
  }

  handleCloseModalDatasConsent() {
    this.props.dispatch(clearStatus({ statusRef: statusRef }));
    this.setState({
      datasConsentModalActive: false,
    });
  }

  getPrescriberCode(pathname) {
    let step = pathname.split('bvs/')[1] ? pathname.split('bvs/')[1] : ' ';
    step = itContains(step, '/') ? step.split('/')[0] : step;

    if (step) {
      switch (step) {
        case '2':
          return prescriberCode2;

        case '3':
          return prescriberCode3;

        case '3bis':
          return prescriberCode3bis;

        case '4':
          return prescriberCode4;

        default:
          return prescriberCode;
      }
    }
    return null;
  }

  isConnectedToPresscriber() {
    let pathname = window.location.pathname;

    return (
      itContains(pathname.toLowerCase(), 'bvs') ||
      itContains(pathname.toLowerCase(), '/ecosante') ||
      itContains(pathname.toLowerCase(), URL_PATH_ALLIANZ) ||
      itContains(pathname.toLowerCase(), 'pf') ||
      itContains(pathname.toLowerCase(), 'fp') ||
      itContains(pathname.toLowerCase(), 'lf')
    );
  }

  render() {
    const { t, getSlugByName, statuses } = this.props;
    const {
      displayInputCodeField,
      isUserFromArgentinaIPF,
      registerSuccessModalActive,
      datasConsentModalActive,
    } = this.state;

    const countryId =
      this.state.form.country_id ||
      (this.props.data || {}).country_id ||
      ((this.props.data || {}).country || {}).id ||
      '';

    const countriesFiltered = this.isConnectedToPresscriber()
      ? this.props.countries
      : this.props.countries.filter((item) => COMMUNITY_COUNTRIES.includes(item.code));

    return (
      <ReactModal
        className="modal-content modal-register"
        overlayClassName="modal-overlay"
        isOpen
        onRequestClose={this.handleCloseModal}
        shouldCloseOnOverlayClick
      >
        <CloseButton onClick={this.handleCloseModal} />

        {registerSuccessModalActive && (
          <ModalRegisterSuccess handleClose={this.handleCloseRegisterSuccess} />
        )}

        {datasConsentModalActive && (
          <ModalDatasConsent
            handleCloseModal={this.handleCloseModalDatasConsent}
            status={statuses[statusRef]}
            handleSubmit={this.handleSubmit}
          />
        )}

        <FormModal
          id="register-form"
          className="register-form transparent"
          onSubmit={this.handleOpenModalDatasConsent}
        >
          <h1>{t('ModalRegister.title')}</h1>

          <div className="row-container">
            <div className="inline-block width-50">
              <input
                type="text"
                name="first_name"
                value={this.state.form.first_name}
                onChange={this.handleInputChange}
                placeholder={t('ModalRegister.placeholder-1')}
                autoComplete="given-name"
                required
                maxLength={191}
              />
            </div>

            <div className="inline-block width-50">
              <input
                type="text"
                name="last_name"
                value={this.state.form.last_name}
                onChange={this.handleInputChange}
                placeholder={t('ModalRegister.placeholder-2')}
                autoComplete="family-name"
                required
                maxLength={191}
              />
            </div>
          </div>

          <div className="row-container">
            <InputBirthdate
              isRequired
              birthdate={
                this.state.form.birthdate.length > 0 ? moment(this.state.form.birthdate) : null
              }
              field="birthdate"
              label={t('ModalProfile.ProfileInfo.label-6')}
              onChange={this.handleBirthdateChange}
            />
          </div>

          <div className="row-container">
            <input
              className={`width-98`}
              id="email"
              type="email"
              name="email"
              value={this.state.form.email}
              onChange={this.handleInputChange}
              placeholder={t('ModalRegister.placeholder-3')}
              autoComplete="email"
              required
            />
          </div>

          <div className="row-container">
            <input
              className={`width-98`}
              type="password"
              name="password"
              value={this.state.form.password}
              onChange={this.handleInputChange}
              placeholder={t('ModalRegister.placeholder-4')}
              autoComplete="new-password"
              required
            />
          </div>

          <div className="row-container">
            <input
              className={`width-98`}
              type="password"
              name="password_confirmation"
              value={this.state.form.password_confirmation}
              onChange={this.handleInputChange}
              placeholder={t('ModalRegister.placeholder-5')}
              autoComplete="new-password"
              required
            />
          </div>

          <div className="row-container">
            <select
              className="width-98 country-select"
              autoComplete="country"
              name="country_id"
              value={countryId}
              onChange={this.handleInputChange}
              required
              disabled={isUserFromArgentinaIPF}
            >
              <option value="" selected disabled>
                {t('ModalRegister.placeholder-6')}
              </option>
              {countriesFiltered.map((item) => {
                return (
                  <option value={item.id} key={item.id}>
                    {item.name}
                  </option>
                );
              })}
            </select>
          </div>

          {(displayInputCodeField || isUserFromArgentinaIPF) && (
            <div className="row-container">
              <input
                className={`width-98`}
                type="text"
                name="code"
                value={this.state.form.code}
                onChange={this.handleInputChange}
                onInput={(e) => e.target.setCustomValidity('')}
                title={t('ModalRegister.codeRequired')}
                onInvalid={(e) => e.target.setCustomValidity(t('ModalRegister.codeRequired'))}
                placeholder={t('ModalRegister.placeholder-code')}
                required
              />
            </div>
          )}

          <div className="row-container check-row">
            <div className="radio-group transparent no-border">
              <div className="radio-field wide no-height">
                <input
                  type="checkbox"
                  id="cgu"
                  name="cgu"
                  value={this.state.form.cgu}
                  onChange={this.handleInputChange}
                  required
                />

                <label htmlFor="cgu">
                  {t('ModalRegister.text-1.part1')}
                  <a href={getSlugByName('terms')} target={`_blank`}>
                    <strong>{t('ModalRegister.text-1.part2')}</strong>
                  </a>
                  {t('ModalRegister.text-1.part3')}
                </label>
              </div>
            </div>
          </div>

          {this.state.validationError && (
            <div className="validation-error alert danger">{this.state.validationError}</div>
          )}

          <div className="row-container row-button">
            <Button className="cancel-button" onClick={this.handleCloseModal}>
              {t('GLOBAL.cancel')}
            </Button>

            <Button type="submit" className="signup-button" onClick={() => {}}>
              {t('ModalRegister.submission')}
            </Button>
          </div>
          {itContains(window.location.href.toLowerCase(), '/ipf-and-me') && (
            <div className="row-container row-ipf">
              <Button className="button--link-large" onClick={this.props.handleOpenLogin}>
                {t('ModalRegister.return-to-login')}
              </Button>
            </div>
          )}
        </FormModal>
      </ReactModal>
    );
  }
}

const mapStateToProps = (state) => ({
  theState: state,
  statuses: state.Status.statuses,
  countries: state.Resource.countries,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withSlugs(ModalRegister))
);
