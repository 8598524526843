/* eslint-disable import/no-cycle */
import { combineReducers } from 'redux';

import App from 'store/modules/App';
import Auth from 'store/modules/Auth';
import Account from 'store/modules/Account';
import Community from 'store/modules/Community';
import User from 'store/modules/User';
import Request from 'store/modules/Request';
import Calendar from 'store/modules/Calendar';
import Session from 'store/modules/Session';
import Prescriber from 'store/modules/Prescriber';
import Status from 'store/modules/Status';
import Csv from 'store/modules/Csv';
import Coach from 'store/modules/Coach';
import Program from 'store/modules/Program';
import Goal from 'store/modules/Goal';
import Chat from 'store/modules/Chat';
import Resource from 'store/modules/Resource';
import Article from 'store/modules/Article';
import Stats from 'store/modules/Stats';
import GetphyExpress from 'store/modules/GetphyExpress';
import Contact from 'store/modules/Contact';
import Assessment from 'store/modules/Assessment';
import Landing from 'store/modules/Landing';
import Notification from 'store/modules/Notification';
import SysAdmin from 'store/modules/SysAdmin';
import Help from 'store/modules/Help';
import Socket from 'store/modules/Socket';
import Seo from 'store/modules/Seo';
import Promote from 'store/modules/Promote';

import { LOGAS_REQUEST, SET_LOGGEDOUT_STATE_SET } from 'store/modules/Account/actions';

const appReducer = combineReducers({
  App,
  Auth,
  Account,
  Community,
  User,
  Request,
  Calendar,
  Session,
  Prescriber,
  Status,
  Csv,
  Coach,
  Program,
  Goal,
  Chat,
  Resource,
  Article,
  Stats,
  GetphyExpress,
  Contact,
  Assessment,
  Landing,
  Notification,
  SysAdmin,
  Help,
  Socket,
  Seo,
  Promote,
});

export default (state, action) => {
  if (action.type === LOGAS_REQUEST || action.type === SET_LOGGEDOUT_STATE_SET) {
    state = undefined;
  }

  return appReducer(state, action);
};
