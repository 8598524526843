export const GET_BLOG_ARTICLES = 'article/GET_BLOG_ARTICLES';
export const GET_ARTICLES_IN_BLOG = 'article/GET_ARTICLES_IN_BLOG';
export const GET_FAQ_ARTICLES = 'article/GET_FAQ_ARTICLES';
export const GET_ARTICLE_TO_EDIT = 'article/GET_ARTICLE_TO_EDIT';
export const GET_ARTICLE_TO_SHOW = 'article/GET_ARTICLE_TO_SHOW';

export const CREATE_ARTICLE = 'article/CREATE_ARTICLE';
export const UPDATE_ARTICLE = 'article/UPDATE_ARTICLE';
export const TOGGLE_PUBLISHED_ARTICLE = 'article/TOGGLE_PUBLISHED_ARTICLE';
export const DELETE_ARTICLE = 'article/DELETE_ARTICLE';

export const LIKE = 'article/LIKE';
export const DISLIKE = 'article/DISLIKE';

export const UPDATE_CACHE_SET = 'article/UPDATE_CACHE_SET';

export const UPDATE_CACHE_ARTICLES = 'article/UPDATE_CACHE_ARTICLES';
export const UPDATE_CACHE_ARTICLE_LIKE = 'article/UPDATE_CACHE_ARTICLE_LIKE';
export const UPDATE_CACHE_ARTICLE_SET = 'article/UPDATE_CACHE_ARTICLE_SET';
export const UPDATE_CACHE_ARTICLES_SET = 'article/UPDATE_CACHE_ARTICLES_SET';

export function getBlogArticles(payload) {
  return { type: GET_BLOG_ARTICLES, payload: { ...payload, type: 'blog' } };
}

export function getArticlesInBlog(payload) {
  return { type: GET_ARTICLES_IN_BLOG, payload: { ...payload, type: 'blog' } };
}

export function getFaqArticles(payload) {
  return { type: GET_FAQ_ARTICLES, payload: { ...payload, type: 'faq' } };
}

export function getArticleToEdit(payload) {
  return { type: GET_ARTICLE_TO_EDIT, payload };
}

export function getArticleToShow(payload) {
  return { type: GET_ARTICLE_TO_SHOW, payload };
}

export function createArticle(payload) {
  return { type: CREATE_ARTICLE, payload };
}

export function updateArticle(payload) {
  return { type: UPDATE_ARTICLE, payload };
}

export function togglePublishedArticle(payload) {
  return { type: TOGGLE_PUBLISHED_ARTICLE, payload };
}

export function deleteArticle(payload) {
  return { type: DELETE_ARTICLE, payload };
}

export function likeArticle(payload) {
  return { type: LIKE, payload };
}

export function dislikeArticle(payload) {
  return { type: DISLIKE, payload };
}
