import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import values from 'lodash/values';
import { getLocale } from '../services/localisation';

const withSlugs = (Component) => {
  return (props) => {
    // eslint-disable-next-line consistent-return
    useEffect(() => {
      const { location, language } = props;
      const currentLangSlug = location ? location.pathname.split('/')[1] : '';
      const currentPath = location ? location.pathname.split('/')[2] : '';
      if (isLanguageUpdated(currentLangSlug, language)) {
        const slug = props.slugs.find(
          (slug) => slug.translations[currentLangSlug].url === `/${currentPath}`
        );
        if (slug) {
          const searchParams = window.location.search;

          const urlForRedirection = `/${getLocale()}${
            slug.translations[getLocale()].url
          }${searchParams}`;
          return props.history.replace(urlForRedirection);
        }

        return props.history.replace(location.pathname.replace(currentLangSlug, language));
      }
    }, [props.language]);

    const isLanguageUpdated = (currentLangSlug, language) => {
      return (
        currentLangSlug &&
        props.languages.find((lang) => lang.code === currentLangSlug) &&
        currentLangSlug !== language
      );
    };

    const getSlugByName = (name) => {
      const slug = props.slugs.find((slug) => slug.name === name);
      if (slug) {
        return `/${getLocale()}${slug.translations[getLocale()]?.url}`;
      }
      return '/not-found';
    };

    const getSlugRoutes = (routes) => {
      const mappedRoutes = [];
      const defaultRoutes = [];
      let queryString = '';
      if (props.location?.search) {
        queryString = props.location?.search;
      }
      routes.forEach((route) => {
        const routeName = route.name?.toLowerCase();
        const seoPages = props.slugs.filter(
          (seo) =>
            seo.name?.toLowerCase() === routeName || seo.name?.toLowerCase().includes(routeName)
        );
        if (!seoPages.length) {
          defaultRoutes.push(route);
        } else {
          seoPages.forEach((seoPage) => {
            Object.keys(seoPage.translations).map((key) => {
              if (key === getLocale()) {
                defaultRoutes.push({
                  path: `${seoPage.translations[key].url}`,
                  component: () => (
                    <Redirect to={`/${key}${seoPage.translations[key].url}${queryString}`} />
                  ),
                });
              }
              mappedRoutes.push({
                path: `/${key}${seoPage.translations[key].url}`,
                component: route.component,
                lang: key,
              });

              return null;
            });
          });
        }
      });
      const commonRoutes = defaultRoutes.map((item) => (
        <Route
          exact
          path={item.path}
          component={item.component}
          key={`default-user-routes_${item.path}`}
        />
      ));
      const localizedRoutes = mappedRoutes.map((item) => (
        <Route
          exact
          path={item.path}
          render={(props) => {
            // eslint-disable-next-line react/jsx-props-no-spreading
            return <item.component {...props} languageSlug={item.lang} />;
          }}
          key={`localized-user-routes_${item.path}`}
        />
      ));
      return [...commonRoutes, ...localizedRoutes];
    };
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Component {...props} getSlugByName={getSlugByName} getSlugRoutes={getSlugRoutes} />;
  };
};
const mapStateToProps = (state) => ({
  slugs: values(state.Seo.seoPages),
  language: state.App.language,
  languages: state.Account.languages,
});
export default (Component) => connect(mapStateToProps)(withSlugs(Component));
