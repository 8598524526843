import { takeLatest, call, put } from 'redux-saga/effects';

import * as ApiService from 'services/api';

import { incrementStatusCreator, decrementStatusCreator } from 'store/modules/Status/actions';
import * as requestActions from 'store/modules/Request/actions';

/** ********************************************** */
/** Selectors * */

/** ********************************************** */
/** Sagas * */
function* fetchAllRequestsSearchSaga(data) {
  yield put({ type: requestActions.FETCH_COMMUNITIES_REQUESTS_SEARCH, payload: data });
  yield put(requestActions.getCallRequests(data));
}

function* fetchCommunitiesRequestsSearchSaga(data) {
  const result = yield call(ApiService.FetchCommunitiesRequestsSearch, data.payload);
  yield put({ type: requestActions.SET_REQUESTS_SEARCH_RESULTS, payload: result.data.data });
}

function* getCallRequestsSaga(data) {
  let result = yield call(ApiService.CallsGet, data.payload);

  if (result && result.data && result.data.data && result.data.data.calls) {
    result = { calls: result.data.data };
  }

  yield put({ type: requestActions.SET_REQUESTS_SEARCH_RESULTS, payload: result });
}

function* callRequestDoneSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Setting call request to done',
      })
    );

    // TODO: update store?
    const params = {};

    if (data.payload.result_outcome) {
      params.result_outcome = data.payload.result_outcome;
    }

    yield call(ApiService.CallDone, data.payload.call_id, params);

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Setting call request to done finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Setting call request to done error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* acceptCommunityRequestSaga(data) {
  yield call(ApiService.AcceptCommunityRequest, data.payload);
}

function* refuseCommunityRequestSaga(data) {
  yield call(ApiService.RefuseCommunityRequest, data.payload);
}

export default function* sagaWatcher() {
  yield takeLatest(requestActions.FETCH_REQUESTS_SEARCH, fetchAllRequestsSearchSaga);
  yield takeLatest(
    requestActions.FETCH_COMMUNITIES_REQUESTS_SEARCH,
    fetchCommunitiesRequestsSearchSaga
  );

  yield takeLatest(requestActions.GET_CALL_REQUESTS_SEARCH, getCallRequestsSaga);
  yield takeLatest(requestActions.SET_CALL_REQUEST_DONE__SEARCH, callRequestDoneSaga);

  yield takeLatest(requestActions.ACCEPT_COMMUNITY_REQUEST, acceptCommunityRequestSaga);
  yield takeLatest(requestActions.REFUSE_COMMUNITY_REQUEST, refuseCommunityRequestSaga);
}
