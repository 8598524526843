export const NAME = 'App';

export const PUBLIC_LANGUAGES = ['en', 'es', 'fr'];

export const ALL_LANGUAGES = ['en', 'es', 'es-AR', 'fr', 'it', 'de', 'pt', 'nl'];

export const COMMUNITY_COUNTRIES = ['BE', 'FR', 'ES', 'IE'];

export const PATH_NAMES = {
  HOME: 'home',
  ABOUT: 'about',
  BLOG: 'blog',
  CONTACT: 'contact',
  FAQ: 'FAQ',
  LEGAL: 'legal',
  PARTNERS: 'partners',
  TERMS: 'terms',
};

export const PATHS_TWITTER = {
  de: 'https://twitter.com/SielBleuIreland',
  en: 'https://twitter.com/SielBleuIreland',
  es: 'https://twitter.com/SielBleuEspana',
  fr: 'https://twitter.com/assosielbleu',
  it: 'https://twitter.com/SielBleuIreland',
  nl: 'https://twitter.com/SielBleuIreland',
  pt: 'https://twitter.com/SielBleuIreland',
};

export const PATHS_YOUTUBE = {
  de: 'https://www.youtube.com/user/SielBleuIreland',
  en: 'https://www.youtube.com/user/SielBleuIreland',
  es: 'https://www.youtube.com/channel/UCX5H7dfE-r3ApMFijGfJkCQ',
  fr: 'https://www.youtube.com/channel/UCArIRkz5G6rVAFUQtrMfxuA',
  it: 'https://www.youtube.com/user/SielBleuIreland',
  nl: 'https://www.youtube.com/user/SielBleuIreland',
  pt: 'https://www.youtube.com/user/SielBleuIreland',
};

export const PATHS_FACEBOOK = {
  de: 'https://www.facebook.com/SielBleuIreland',
  en: 'https://www.facebook.com/SielBleuIreland',
  es: 'https://www.facebook.com/SielBleuEspana',
  fr: 'https://www.facebook.com/assosielbleu',
  it: 'https://www.facebook.com/SielBleuIreland',
  nl: 'https://www.facebook.com/SielBleuIreland',
  pt: 'https://www.facebook.com/SielBleuIreland',
};

export const PATHS_SIEL_BLEU = {
  de: 'https://www.sielbleu.ie/',
  en: 'https://www.sielbleu.ie/',
  es: 'https://sielbleu.es/',
  fr: 'https://www.sielbleu.org/',
  it: 'https://www.sielbleu.ie/',
  nl: 'https://www.sielbleu.ie/',
  pt: 'https://www.sielbleu.ie/',
};
