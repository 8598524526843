// @flow

import FPIAndMeFrProgramImage from './assets/FPI-and-me-fr-program.png';
import FPIAndMeEnProgramImage from './assets/FPI-and-me-en-program.png';
import FPIAndMeEsProgramImage from './assets/FPI-and-me-es-program.png';
import FPIAndMeDeProgramImage from './assets/FPI-and-me-de-program.png';
import EcoSanteProgramImage from './assets/eco-sante-program.png';

import AquaGymGroupLessonImage from './assets/aquagym-group-lesson.jpg';
import BalanceGroupLessonImage from './assets/balance-group-lesson.jpg';
import GymGroupLessonImage from './assets/gym-group-lesson.jpg';
import NordicGymGroupLessonImage from './assets/nordic-gym-group-lesson.jpg';

import type { OnlineCards, GroupLessonsCards } from './types';

export const ONLINE_PROGRAMS_CARDS_FR: OnlineCards = [
  {
    id: 2,
    title: 'LandingPage.onlineProgramsFPICardTitle',
    text: 'LandingPage.onlineProgramsFPICardText',
    imgSrc: FPIAndMeFrProgramImage,
    imgAlt: 'LandingPage.onlineProgramsFPICardTitle',
    redirect: '/fp-et-moi',
  },
  {
    id: 1,
    title: 'LandingPage.onlineProgramsEcoSanteCardTitle',
    text: 'LandingPage.onlineProgramsEcoSanteCardText',
    imgSrc: EcoSanteProgramImage,
    imgAlt: 'LandingPage.onlineProgramsEcoSanteCardTitle',
    redirect: '/ecosante',
  },
];

export const ONLINE_PROGRAMS_CARDS_EN: OnlineCards = [
  {
    id: 0,
    title: 'LandingPage.onlineProgramsFPICardTitle',
    text: 'LandingPage.onlineProgramsFPICardText',
    imgSrc: FPIAndMeEnProgramImage,
    imgAlt: 'LandingPage.onlineProgramsFPICardTitle',
    redirect: '/pf-and-me',
  },
];

export const ONLINE_PROGRAMS_CARDS_ES: OnlineCards = [
  {
    id: 0,
    title: 'LandingPage.onlineProgramsFPICardTitle',
    text: 'LandingPage.onlineProgramsFPICardText',
    imgSrc: FPIAndMeEsProgramImage,
    imgAlt: 'LandingPage.onlineProgramsFPICardTitle',
    redirect: '/fp-y-yo',
  },
];

export const ONLINE_PROGRAMS_CARDS_DE: OnlineCards = [
  {
    id: 0,
    title: 'LandingPage.onlineProgramsFPICardTitle',
    text: 'LandingPage.onlineProgramsFPICardText',
    imgSrc: FPIAndMeDeProgramImage,
    imgAlt: 'LandingPage.onlineProgramsFPICardTitle',
    redirect: '/lf-und-ich',
  },
];

export const ONLINE_PROGRAMS_CARDS_NL: OnlineCards = [
  {
    id: 0,
    title: 'LandingPage.onlineProgramsFPICardTitle',
    text: 'LandingPage.onlineProgramsFPICardText',
    imgSrc: FPIAndMeEnProgramImage,
    imgAlt: 'LandingPage.onlineProgramsFPICardTitle',
    redirect: '/lf-en-ik',
  },
];

export const ONLINE_PROGRAMS_CARDS_PT: OnlineCards = [
  {
    id: 0,
    title: 'LandingPage.onlineProgramsFPICardTitle',
    text: 'LandingPage.onlineProgramsFPICardText',
    imgSrc: FPIAndMeEnProgramImage,
    imgAlt: 'LandingPage.onlineProgramsFPICardTitle',
    redirect: '/fp-e-eu',
  },
];

export const ONLINE_PROGRAMS_CARDS_IT: OnlineCards = [
  {
    id: 0,
    title: 'LandingPage.onlineProgramsFPICardTitle',
    text: 'LandingPage.onlineProgramsFPICardText',
    imgSrc: FPIAndMeEnProgramImage,
    imgAlt: 'LandingPage.onlineProgramsFPICardTitle',
    redirect: '/io-e-l-fp',
  },
];

export const GROUP_LESSONS_CARDS: GroupLessonsCards = [
  {
    id: 0,
    name: 'Gymnastique douce',
    place: 'Allenwiller',
    coach: 'Katia',
    imgSrc: GymGroupLessonImage,
    imgAlt: 'Gymnastique douce',
  },
  {
    id: 1,
    name: 'Équilibre en bleu',
    place: 'Agen',
    coach: 'Mélanie',
    imgSrc: BalanceGroupLessonImage,
    imgAlt: 'Équilibre en bleu',
  },
  {
    id: 2,
    name: 'Aquagym',
    place: 'Furdenheim',
    coach: 'Laetitia',
    imgSrc: AquaGymGroupLessonImage,
    imgAlt: 'Aquagym',
  },
  {
    id: 3,
    name: 'Marche nordique',
    place: 'Olonne-sur-mer',
    coach: 'Margot',
    imgSrc: NordicGymGroupLessonImage,
    imgAlt: 'Marche nordique',
  },
];
