import React, { Component } from 'react';
import './style.css';

class Img extends Component {
  render() {
    // eslint-disable-next-line global-require
    const imgSrc = require('./assets/' + this.props.src);
    return <img src={imgSrc} className={this.props.class} alt={this.props.src} />;
  }
}

export default Img;
