import * as socketActions from 'store/modules/Socket/actions';

const initialState = {
  notifications_counter: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case socketActions.SET_NOTIFICATIONS_COUNTER:
      return {
        ...state,
        notifications_counter: action.payload,
      };

    default:
      return state;
  }
};
