import { takeLatest, put, select, takeEvery, all } from 'redux-saga/effects';
import moment from 'moment';

import * as ApiService from 'services/api';
import ProtectedCall from 'services/protected.api';

import { incrementStatusCreator, decrementStatusCreator } from 'store/modules/Status/actions';
import * as calendarActions from 'store/modules/Calendar/actions';

/** ********************************************** */
/** Selectors * */
const allCalendarsSelector = (state) => {
  return JSON.parse(JSON.stringify(state.Calendar.calendars));
};

const getNextTwelveMonths = (pqDeclarationDate) => {
  const monthsArray = [moment(pqDeclarationDate).format('YYYY-MM')];
  for (let index = 1; index <= 12; index++) {
    monthsArray.push(moment(pqDeclarationDate).add(index, 'months').format('YYYY-MM'));
  }
  return monthsArray;
};

const removeDuplicates = (array, key = 'id') => {
  return array.reduce((arr, item) => {
    const removed = arr.filter((i) => i[key] !== item[key]);
    return [...removed, item];
  }, []);
};

const extractSessions = (result) => {
  return result.reduce(
    (acc, curr) => {
      if (curr.statusText === 'OK') {
        return {
          communities: removeDuplicates([...acc.communities, ...curr.data.data.communities]),
          session_users: removeDuplicates([...acc.session_users, ...curr.data.data.session_users]),
          tasks: removeDuplicates([...acc.tasks, ...curr.data.data.tasks]),
        };
      } else {
        return acc;
      }
    },
    {
      communities: [],
      session_users: [],
      tasks: [],
    }
  );
};

/** ********************************************** */
function* calendarSearchSaga(data) {
  let result;

  if (data.payload.monthDate) {
    result = yield ProtectedCall(ApiService.CalendarSearch, data.payload.userId || 'me', {
      month: data.payload.monthDate,
    });
  } else {
    const user = yield select((state) => state.Account.user); // <-- get the project
    result = yield all(
      getNextTwelveMonths(user.pq_declaration_accepted).reduce((acc, curr) => {
        return [
          ...acc,
          ProtectedCall(ApiService.CalendarSearch, data.payload.userId || 'me', {
            month: curr,
          }),
        ];
      }, [])
    );

    result = extractSessions(result);
  }

  yield put({
    type: calendarActions.UPDATE_CACHE,
    payload: [
      {
        id: data.payload.monthDate || 'allDates',
        data: data.payload.monthDate ? result.data.data : result,
      },
    ],
  });
}

function* addSessionToCalendarSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Start add session to calendar',
      })
    );

    yield ProtectedCall(ApiService.AddSessionToCalendar, data.payload.userId, data.payload);

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Finish add session to calendar',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Finish add session to calendar',
        data: e.response.data,
        state: 'error',
      })
    );
  }
}
function* updateSessionInCalendarSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Start update session in calendar',
      })
    );

    yield ProtectedCall(
      ApiService.UpdateSessionInCalendar,
      data.payload.userId,
      data.payload.session_user_id,
      data.payload
    );

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Finish update session in calendar',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Finish update session in calendar',
        data: e.response.data,
        state: 'error',
      })
    );
  }
}

function* deleteSessionFromCalendarSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Start delete session from calendar',
      })
    );

    yield ProtectedCall(
      ApiService.DeleteSessionFromCalendar,
      data.payload.userId,
      data.payload.session_user_id,
      data.payload
    );

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Finish delete session from calendar',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Finish delete session from calendar',
        data: e.response.data,
        state: 'error',
      })
    );
  }
}

function* addProgramToCalendarSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Start add program to calendar',
      })
    );

    yield ProtectedCall(ApiService.AddProgramToCalendar, data.payload.userId, data.payload);

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Finish add program to calendar',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Finish add program to calendar',
        data: e.response.data,
        state: 'error',
      })
    );
  }
}

function* makeInactivityChoiceSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Start submit inactivity choice',
      })
    );

    yield ProtectedCall(ApiService.MakeInactivityChoice, data.payload.userId, data.payload);

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Finish submit inactivity choice',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Finish submit inactivity choice',
        data: e.response.data,
        state: 'error',
      })
    );
  }
}

function* updateCacheSaga(data) {
  if (!data || !data.payload || data.payload.length === 0) {
    return;
  }

  const calendars = yield select(allCalendarsSelector) || {};

  data.payload.map((item) => {
    calendars[item.id] = item.data;

    return null;
  });
  yield put({ type: calendarActions.UPDATE_CACHE_SET, payload: calendars });
}

/** Sagas * */
export default function* sagaWatcher() {
  yield takeLatest(calendarActions.CALENDAR_SEARCH_REQUEST, calendarSearchSaga);
  yield takeLatest(calendarActions.ADD_SESSION_TO_CALENDAR_REQUEST, addSessionToCalendarSaga);
  yield takeLatest(calendarActions.UPDATE_SESSION_IN_CALENDAR_REQUEST, updateSessionInCalendarSaga);
  yield takeLatest(
    calendarActions.DELETE_SESSION_FROM_CALENDAR_REQUEST,
    deleteSessionFromCalendarSaga
  );
  yield takeLatest(calendarActions.ADD_PROGRAM_TO_CALENDAR_REQUEST, addProgramToCalendarSaga);
  yield takeLatest(calendarActions.MAKE_INACTIVITY_CHOICE, makeInactivityChoiceSaga);

  yield takeEvery(calendarActions.UPDATE_CACHE, updateCacheSaga);
}
