/** Tutorials * */
export const GET_TUTORIALS_TREE = 'help/GET_TUTORIALS_TREE';
export const GET_TUTORIALS = 'help/GET_TUTORIALS';
export const CREATE_TUTORIAL = 'help/CREATE_TUTORIAL';
export const UPDATE_TUTORIAL = 'help/UPDATE_TUTORIAL';
export const DELETE_TUTORIAL = 'help/DELETE_TUTORIAL';
export const DELETE_TUTORIAL_PREVIEW = 'help/DELETE_TUTORIAL_PREVIEW';

export const UPDATE_CACHE_TUTORIALS_SET = 'help/UPDATE_CACHE_TUTORIALS_SET';

export const GET_TUTORIALS_CATEGORIES = 'help/GET_TUTORIALS_CATEGORIES';
export const CREATE_TUTORIAL_CATEGORY = 'help/CREATE_TUTORIAL_CATEGORY';
export const UPDATE_TUTORIAL_CATEGORY = 'help/UPDATE_TUTORIAL_CATEGORY';
export const DELETE_TUTORIAL_CATEGORY = 'help/DELETE_TUTORIAL_CATEGORY';

export const UPDATE_CACHE_TUTORIALS_CATEGORIES_SET = 'help/UPDATE_CACHE_TUTORIALS_CATEGORIES_SET';

/** Faq * */
export const GET_FAQS_TREE = 'help/GET_FAQS_TREE';
export const GET_FAQS = 'help/GET_FAQS';
export const CREATE_FAQ = 'help/CREATE_FAQ';
export const UPDATE_FAQ = 'help/UPDATE_FAQ';
export const DELETE_FAQ = 'help/DELETE_FAQ';

export const UPDATE_CACHE_FAQ_SET = 'help/UPDATE_CACHE_FAQ_SET';

export const GET_FAQ_CATEGORIES = 'help/GET_FAQ_CATEGORIES';
export const CREATE_FAQ_CATEGORY = 'help/CREATE_FAQ_CATEGORY';
export const UPDATE_FAQ_CATEGORY = 'help/UPDATE_FAQ_CATEGORY';
export const DELETE_FAQ_CATEGORY = 'help/DELETE_FAQ_CATEGORY';

export const UPDATE_CACHE_FAQ_CATEGORIES_SET = 'help/UPDATE_CACHE_FAQ_CATEGORIES_SET';

/** Glossary * */
export const GET_GLOSSARIES_TREE = 'help/GET_GLOSSARIES_TREE';
export const GET_GLOSSARIES = 'help/GET_GLOSSARIES';
export const CREATE_GLOSSARY = 'help/CREATE_GLOSSARY';
export const UPDATE_GLOSSARY = 'help/UPDATE_GLOSSARY';
export const DELETE_GLOSSARY = 'help/DELETE_GLOSSARY';

export const UPDATE_CACHE_GLOSSARY_SET = 'help/UPDATE_CACHE_GLOSSARY_SET';

export const GET_GLOSSARY_CATEGORIES = 'help/GET_GLOSSARY_CATEGORIES';
export const CREATE_GLOSSARY_CATEGORY = 'help/CREATE_GLOSSARY_CATEGORY';
export const UPDATE_GLOSSARY_CATEGORY = 'help/UPDATE_GLOSSARY_CATEGORY';
export const DELETE_GLOSSARY_CATEGORY = 'help/DELETE_GLOSSARY_CATEGORY';

export const UPDATE_CACHE_GLOSSARY_CATEGORIES_SET = 'help/UPDATE_CACHE_GLOSSARY_CATEGORIES_SET';

/** Contact us * */
export const GET_CONTACT_US = 'help/GET_CONTACT_US';
export const CREATE_CONTACT_US = 'help/CREATE_CONTACT_US';
export const UPDATE_CONTACT_US = 'help/UPDATE_CONTACT_US';
export const DELETE_CONTACT_US = 'help/DELETE_CONTACT_US';

export const UPDATE_CACHE_CONTACT_US_SET = 'help/UPDATE_CACHE_CONTACT_US_SET';

export const GET_CONTACT_US_CATEGORIES = 'help/GET_CONTACT_US_CATEGORIES';
export const CREATE_CONTACT_US_CATEGORY = 'help/CREATE_CONTACT_US_CATEGORY';
export const UPDATE_CONTACT_US_CATEGORY = 'help/UPDATE_CONTACT_US_CATEGORY';
export const DELETE_CONTACT_US_CATEGORY = 'help/DELETE_CONTACT_US_CATEGORY';

export const UPDATE_CACHE_CONTACT_US_CATEGORIES_SET = 'help/UPDATE_CACHE_CONTACT_US_CATEGORIES_SET';

/** Procedures * */
export const GET_PROCEDURES_TREE = 'help/GET_PROCEDURES_TREE';
export const GET_PROCEDURES = 'help/GET_PROCEDURES';
export const CREATE_PROCEDURE = 'help/CREATE_PROCEDURE';
export const UPDATE_PROCEDURE = 'help/UPDATE_PROCEDURE';
export const DELETE_PROCEDURE = 'help/DELETE_PROCEDURE';
export const DELETE_PROCEDURE_DOCUMENT = 'help/DELETE_PROCEDURE_DOCUMENT';

export const UPDATE_CACHE_PROCEDURE_SET = 'help/UPDATE_CACHE_PROCEDURE_SET';

export const GET_PROCEDURE_CATEGORIES = 'help/GET_PROCEDURE_CATEGORIES';
export const CREATE_PROCEDURE_CATEGORY = 'help/CREATE_PROCEDURE_CATEGORY';
export const UPDATE_PROCEDURE_CATEGORY = 'help/UPDATE_PROCEDURE_CATEGORY';
export const DELETE_PROCEDURE_CATEGORY = 'help/DELETE_PROCEDURE_CATEGORY';

export const UPDATE_CACHE_PROCEDURE_CATEGORIES_SET = 'help/UPDATE_CACHE_PROCEDURE_CATEGORIES_SET';

/** Communication tools * */
export const GET_COMMUNICATION_TOOLS_TREE = 'help/GET_COMMUNICATION_TOOLS_TREE';
export const GET_COMMUNICATION_TOOLS = 'help/GET_COMMUNICATION_TOOLS';
export const CREATE_COMMUNICATION_TOOL = 'help/CREATE_COMMUNICATION_TOOL';
export const UPDATE_COMMUNICATION_TOOL = 'help/UPDATE_COMMUNICATION_TOOL';
export const DELETE_COMMUNICATION_TOOL = 'help/DELETE_COMMUNICATION_TOOL';
export const DELETE_COMMUNICATION_TOOL_PREVIEW = 'help/DELETE_COMMUNICATION_TOOL_PREVIEW';
export const DELETE_COMMUNICATION_TOOL_DOCUMENT = 'help/DELETE_COMMUNICATION_TOOL_DOCUMENT';

export const UPDATE_CACHE_COMMUNICATION_TOOL_SET = 'help/UPDATE_CACHE_COMMUNICATION_TOOL_SET';

export const GET_COMMUNICATION_TOOL_CATEGORIES = 'help/GET_COMMUNICATION_TOOL_CATEGORIES';
export const CREATE_COMMUNICATION_TOOL_CATEGORY = 'help/CREATE_COMMUNICATION_TOOL_CATEGORY';
export const UPDATE_COMMUNICATION_TOOL_CATEGORY = 'help/UPDATE_COMMUNICATION_TOOL_CATEGORY';
export const DELETE_COMMUNICATION_TOOL_CATEGORY = 'help/DELETE_COMMUNICATION_TOOL_CATEGORY';

export const UPDATE_CACHE_COMMUNICATION_TOOL_CATEGORIES_SET =
  'help/UPDATE_CACHE_COMMUNICATION_TOOL_CATEGORIES_SET';

/** Tutorials * */
export function getTutorialsTree(payload) {
  return { type: GET_TUTORIALS_TREE, payload: { ...payload } };
}

export function getTutorials(payload) {
  return { type: GET_TUTORIALS, payload: { ...payload } };
}

export function createTutorial(payload) {
  return { type: CREATE_TUTORIAL, payload };
}

export function updateTutorial(payload) {
  return { type: UPDATE_TUTORIAL, payload };
}

export function deleteTutorial(payload) {
  return { type: DELETE_TUTORIAL, payload };
}

export function deleteTutorialPreview(payload) {
  return { type: DELETE_TUTORIAL_PREVIEW, payload };
}

export function getTutorialsCategories(payload) {
  return { type: GET_TUTORIALS_CATEGORIES, payload: { ...payload } };
}

export function createTutorialCategory(payload) {
  return { type: CREATE_TUTORIAL_CATEGORY, payload };
}

export function updateTutorialCategory(payload) {
  return { type: UPDATE_TUTORIAL_CATEGORY, payload };
}

export function deleteTutorialCategory(payload) {
  return { type: DELETE_TUTORIAL_CATEGORY, payload };
}

/** Faqs * */
export function getFaqsTree(payload) {
  return { type: GET_FAQS_TREE, payload: { ...payload } };
}

export function getFaqs(payload) {
  return { type: GET_FAQS, payload: { ...payload } };
}

export function createFaq(payload) {
  return { type: CREATE_FAQ, payload };
}

export function updateFaq(payload) {
  return { type: UPDATE_FAQ, payload };
}

export function deleteFaq(payload) {
  return { type: DELETE_FAQ, payload };
}

export function getFaqCategories(payload) {
  return { type: GET_FAQ_CATEGORIES, payload: { ...payload } };
}

export function createFaqCategory(payload) {
  return { type: CREATE_FAQ_CATEGORY, payload };
}

export function updateFaqCategory(payload) {
  return { type: UPDATE_FAQ_CATEGORY, payload };
}

export function deleteFaqCategory(payload) {
  return { type: DELETE_FAQ_CATEGORY, payload };
}

/** Glossary * */
export function getGlossariesTree(payload) {
  return { type: GET_GLOSSARIES_TREE, payload: { ...payload } };
}

export function getGlossaries(payload) {
  return { type: GET_GLOSSARIES, payload: { ...payload } };
}

export function createGlossary(payload) {
  return { type: CREATE_GLOSSARY, payload };
}

export function updateGlossary(payload) {
  return { type: UPDATE_GLOSSARY, payload };
}

export function deleteGlossary(payload) {
  return { type: DELETE_GLOSSARY, payload };
}

export function getGlossaryCategories(payload) {
  return { type: GET_GLOSSARY_CATEGORIES, payload: { ...payload } };
}

export function createGlossaryCategory(payload) {
  return { type: CREATE_GLOSSARY_CATEGORY, payload };
}

export function updateGlossaryCategory(payload) {
  return { type: UPDATE_GLOSSARY_CATEGORY, payload };
}

export function deleteGlossaryCategory(payload) {
  return { type: DELETE_GLOSSARY_CATEGORY, payload };
}

/** Contact us * */
export function getContactUs(payload) {
  return { type: GET_CONTACT_US, payload: { ...payload } };
}

export function createContactUs(payload) {
  return { type: CREATE_CONTACT_US, payload };
}

export function updateContactUs(payload) {
  return { type: UPDATE_CONTACT_US, payload };
}

export function deleteContactUs(payload) {
  return { type: DELETE_CONTACT_US, payload };
}

export function getContactUsCategories(payload) {
  return { type: GET_CONTACT_US_CATEGORIES, payload: { ...payload } };
}

export function createContactUsCategory(payload) {
  return { type: CREATE_CONTACT_US_CATEGORY, payload };
}

export function updateContactUsCategory(payload) {
  return { type: UPDATE_CONTACT_US_CATEGORY, payload };
}

export function deleteContactUsCategory(payload) {
  return { type: DELETE_CONTACT_US_CATEGORY, payload };
}

/** Procedures * */
export function getProceduresTree(payload) {
  return { type: GET_PROCEDURES_TREE, payload: { ...payload } };
}

export function getProcedures(payload) {
  return { type: GET_PROCEDURES, payload: { ...payload } };
}

export function createProcedure(payload) {
  return { type: CREATE_PROCEDURE, payload };
}

export function updateProcedure(payload) {
  return { type: UPDATE_PROCEDURE, payload };
}

export function deleteProcedure(payload) {
  return { type: DELETE_PROCEDURE, payload };
}

export function deleteProcedureDocument(payload) {
  return { type: DELETE_PROCEDURE_DOCUMENT, payload };
}

export function getProcedureCategories(payload) {
  return { type: GET_PROCEDURE_CATEGORIES, payload: { ...payload } };
}

export function createProcedureCategory(payload) {
  return { type: CREATE_PROCEDURE_CATEGORY, payload };
}

export function updateProcedureCategory(payload) {
  return { type: UPDATE_PROCEDURE_CATEGORY, payload };
}

export function deleteProcedureCategory(payload) {
  return { type: DELETE_PROCEDURE_CATEGORY, payload };
}

/** Communication tools * */
export function getCommunicationToolsTree(payload) {
  return { type: GET_COMMUNICATION_TOOLS_TREE, payload: { ...payload } };
}

export function getCommunicationTools(payload) {
  return { type: GET_COMMUNICATION_TOOLS, payload: { ...payload } };
}

export function createCommunicationTool(payload) {
  return { type: CREATE_COMMUNICATION_TOOL, payload };
}

export function updateCommunicationTool(payload) {
  return { type: UPDATE_COMMUNICATION_TOOL, payload };
}

export function deleteCommunicationTool(payload) {
  return { type: DELETE_COMMUNICATION_TOOL, payload };
}

export function deleteCommunicationToolPreview(payload) {
  return { type: DELETE_COMMUNICATION_TOOL_PREVIEW, payload };
}

export function deleteCommunicationToolDocument(payload) {
  return { type: DELETE_COMMUNICATION_TOOL_DOCUMENT, payload };
}

export function getCommunicationToolCategories(payload) {
  return { type: GET_COMMUNICATION_TOOL_CATEGORIES, payload: { ...payload } };
}

export function createCommunicationToolCategory(payload) {
  return { type: CREATE_COMMUNICATION_TOOL_CATEGORY, payload };
}

export function updateCommunicationToolCategory(payload) {
  return { type: UPDATE_COMMUNICATION_TOOL_CATEGORY, payload };
}

export function deleteCommunicationToolCategory(payload) {
  return { type: DELETE_COMMUNICATION_TOOL_CATEGORY, payload };
}
