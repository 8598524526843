// @flow
import React from 'react';
import clsx from 'clsx';

import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import { publicTheme } from '@layout/theme';

import { MAIN_TEXT_COLOR, PRIMARY_COLOR, WHITE } from '@shared/constants';
import { PATHS_SIEL_BLEU } from '@core/constants';

import SielBleuFranceLogo from '@assets/siel-bleu-france.svg';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  titleBlock: {
    display: 'flex',
    [publicTheme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  container: {
    display: 'flex',
    [publicTheme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  textsBlock: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  title: {
    fontFamily: 'Roboto, Georgia, serif',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '2rem',
    lineHeight: '2.25rem',
    color: PRIMARY_COLOR,
    paddingBottom: '2vh',
  },
  text: {
    fontFamily: 'Roboto, Georgia, serif',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '1.25rem',
    lineHeight: '1.75rem',
    color: MAIN_TEXT_COLOR,
  },
  name: {
    fontFamily: 'Roboto, Georgia, serif',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '2rem',
    lineHeight: '2.25rem',
    color: PRIMARY_COLOR,
    textDecoration: 'underline',
  },
  beneficiaries: {
    fontFamily: 'Roboto, Georgia, serif',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '2.5rem',
    lineHeight: '3.8rem',
    color: WHITE,
    backgroundColor: PRIMARY_COLOR,
  },
  iconBlock: {
    width: '40vw',
    height: '20vh',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: '1vw',
    [publicTheme.breakpoints.down('xs')]: {
      width: '100%',
      justifyContent: 'center',
      order: 2,
    },
  },
  icon: {
    width: '20vw',
    height: '20vh',
    [publicTheme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}));

const SielBleuTexts = () => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const classes = useStyles();
  return (
    <div className={clsx(classes.root)}>
      <div className={clsx(classes.titleBlock)}>
        <p className={clsx(classes.title)}>{t('LandingPage.sielBleuSectionTextsTitle')}</p>
      </div>
      <div className={clsx(classes.container)}>
        <div className={clsx(classes.iconBlock)}>
          <a target="_blank" rel="noreferrer" href={PATHS_SIEL_BLEU[language]}>
            <img className={clsx(classes.icon)} src={SielBleuFranceLogo} alt="Siel Bleu logo" />
          </a>
        </div>
        <div className={clsx(classes.textsBlock)}>
          <p className={clsx(classes.text)}>
            {t('LandingPage.sielBleuSectionTextsText1Part1')}
            <a
              target="_blank"
              rel="noreferrer"
              href={PATHS_SIEL_BLEU[language]}
              className={clsx(classes.name)}
            >
              {t('LandingPage.sielBleuSectionTextsText1Part2')}
            </a>
            {t('LandingPage.sielBleuSectionTextsText1Part3')}
            <br />
            {t('LandingPage.sielBleuSectionTextsText1Part4')}
          </p>
          <p className={clsx(classes.text)}>
            {t('LandingPage.sielBleuSectionTextsText2Part1')}
            <br />
            <span className={clsx(classes.beneficiaries)}>
              {t('LandingPage.sielBleuSectionTextsText2Part2')}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SielBleuTexts;
