import { takeLatest, put } from 'redux-saga/effects';

import { getLocale } from 'services/localisation';

import { FORCE_USER_LANGUAGE_CHANGE } from 'store/modules/Account/actions';
import * as appActions from 'store/modules/App/actions';

/** ********************************************** */
/** Selectors * */

/** ********************************************** */

function* switchLanguageSaga(data) {
  yield put({ type: FORCE_USER_LANGUAGE_CHANGE, code: data.code || getLocale() });
  yield put({ type: appActions.SWITCH_LANGUAGE_SET, code: data.code });
}

/** Sagas * */
export default function* sagaWatcher() {
  yield takeLatest(appActions.SWITCH_LANGUAGE, switchLanguageSaga);
}
