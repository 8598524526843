import * as accountActions from 'store/modules/Account/actions';

const initialState = {
  user: {
    roles: [],
    language: '',
  },
  isLoggedIn: false,
  role: null,
  languages: [],
  redirectToAnUrlAfterUserLogin: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case accountActions.SET_LOGIN_REQUEST_STATUS:
      return {
        ...state,
        loginError: action.payload.error,
        loginRequestStatus: action.payload.status,
      };
    case accountActions.SET_LOGGEDIN_STATE_SET:
      return {
        ...state,
        isLoggedIn: true,
        user: action.payload.user,
      };

    case accountActions.FORCE_USER_LANGUAGE_CHANGE:
      return {
        ...state,
        user: { ...state.user, language: state.languages.find((l) => action.code === l.code) },
      };

    case accountActions.REDIRECT_AFTER_LOGIN:
      return {
        ...state,
        redirectToAnUrlAfterUserLogin: action.payload.url,
      };

    case accountActions.SET_LANGUAGES:
      return {
        ...state,
        languages: action.payload,
      };

    case accountActions.SET_LEVELS:
      return {
        ...state,
        levels: action.payload,
      };

    case accountActions.SET_CURRENT_ROLE_SET:
      return {
        ...state,
        role: action.payload,
      };

    case accountActions.SET_LOGGEDOUT_STATE_SET:
      return {
        ...initialState,
        loginError: state.loginError,
        loginRequestStatus: state.loginRequestStatus,
      };

    case accountActions.SET_REGISTER_REQUEST_STATUS:
      return {
        ...state,
        registerError: action.payload.error,
        registerRequestStatus: action.payload.status,
      };

    case accountActions.SET_REGISTERED_STATE:
      return {
        ...state,
        user: action.payload.user,
      };

    case accountActions.SET_REGISTER_CONFIRM_EMAIL_STATUS:
      return {
        ...state,
        confirmEmailError: action.payload.error,
        confirmEmailRequestStatus: action.payload.status,
      };

    case accountActions.SET_RESTORE_ACCOUNT_EMAIL_STATUS:
      return {
        ...state,
        restoreAccountError: action.payload.error,
        restoreAccountRequestStatus: action.payload.status,
      };

    case accountActions.UPDATE_PROFILE:
      return {
        ...state,
        user: action.payload,
      };

    default:
      return state;
  }
};
