import { put, call, takeEvery } from 'redux-saga/effects';

import * as ApiService from 'services/api';

import { incrementStatusCreator, decrementStatusCreator } from 'store/modules/Status/actions';
import * as contactActions from 'store/modules/Contact/actions';

/** ********************************************** */
/** Selectors * */

/** ********************************************** */
function* contactByEmailSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Contact by email' })
    );

    yield call(ApiService.Contact, data.payload);

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Contact by email complete',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: `Contact by email error - ${e.message}`,
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

/** Sagas * */
export default function* sagaWatcher() {
  yield takeEvery(contactActions.CONTACT_BY_EMAIL, contactByEmailSaga);
}
