import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import './style.css';

import Image from 'components/Image';

class Logo extends Component {
  render() {
    return (
      <div className="header-logo">
        <Link to={this.props.linkRoute}>
          <Image src="getphy-logo.svg" className="image-header-logo"></Image>
        </Link>
      </div>
    );
  }
}

export default Logo;
