// @flow
import React from 'react';

import clsx from 'clsx';
import { privateTheme } from '@layout/theme';

import { makeStyles } from '@material-ui/core/styles';

import { MAIN_TEXT_COLOR, WHITE } from '@shared/constants';

import SubscribeButton from './SubscribeButton';

type OnlineProgramsCardItemProps = {
  title: string,
  text: string,
  handleSubscribe: () => void,
  imgSrc: string,
  imgAlt: string,
};

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    backgroundColor: WHITE,
    borderRadius: '10px',
    boxShadow: '8px 8px 4px rgba(0, 0, 0, 0.25)',
    justifyContent: 'space-between',
    maxWidth: '420px',
    maxHeight: '220px',
    marginTop: '2vh',
  },
  textColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    padding: '0 15px 0 15px',
    width: '50%',
  },
  title: {
    fontFamily: 'Roboto, Georgia, serif',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '1.75rem',
    lineHeight: '2.5rem',
    color: MAIN_TEXT_COLOR,
    textAlign: 'center',
  },
  text: {
    fontFamily: 'Roboto, Georgia, serif',
    fontStyle: 'italic',
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '1.5rem',
    color: MAIN_TEXT_COLOR,
    textAlign: 'center',
  },
  button: { textAlign: 'center' },
  imageColumn: {
    display: 'flex',
    width: '50%',
  },
  thumbnail: {
    width: '210px',
    height: '220px',
    borderRadius: '0 10px 10px 0',
    [privateTheme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: '210px',
      height: 'auto',
    },
  },
}));

const OnlineProgramsCardItem = ({
  title,
  text,
  handleSubscribe,
  imgSrc,
  imgAlt,
}: OnlineProgramsCardItemProps) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root)}>
      <div className={clsx(classes.textColumn)}>
        <p className={clsx(classes.title)}>{title}</p>
        <p className={clsx(classes.text)}>{text}</p>
        <p className={clsx(classes.button)}>
          <SubscribeButton handleSubscribe={handleSubscribe} fullWidth={false} />
        </p>
      </div>
      <div className={clsx(classes.imageColumn)}>
        <img className={clsx(classes.thumbnail)} src={imgSrc} alt={imgAlt} />
      </div>
    </div>
  );
};

export default OnlineProgramsCardItem;
