import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import ReactModal from 'react-modal';

import FormModal from 'components/FormModal';
import Button from 'components/Button';
import CloseButton from 'components/CloseButton';
import ErrorMessage from 'components/ErrorMessage';

import './style.css';

const baseClass = 'confirm-message';

class ConfirmMessage extends Component {
  constructor() {
    super();

    this.state = {};
  }

  render() {
    const { t } = this.props;
    let confirmText = this.props.confirmText ? this.props.confirmText : t('GLOBAL.yes');
    let cancelText = this.props.cancelText ? this.props.cancelText : t('GLOBAL.no');

    return (
      <div className={`${baseClass}`}>
        <ReactModal
          className={`${baseClass}-modal modal-content`}
          isOpen
          onRequestClose={this.props.handleCancel}
          shouldCloseOnOverlayClick
          overlayClassName="modal-overlay"
        >
          <CloseButton onClick={this.props.handleCancel} />

          <FormModal className={`${baseClass}-modal-form`} onSubmit={() => {}}>
            <h1>{this.props.title}</h1>

            {this.props.description && <p>{this.props.description}</p>}

            <Button type="button" onClick={this.props.handleCancel}>
              {cancelText}
            </Button>
            <Button type="button" onClick={this.props.handleConfirm} status={this.props.status}>
              {confirmText}
            </Button>

            <ErrorMessage status={this.props.status} />
          </FormModal>
        </ReactModal>
      </div>
    );
  }
}

ConfirmMessage.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  status: PropTypes.object,
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
};

ConfirmMessage.defaultProps = {};

export default withTranslation()(ConfirmMessage);
