import * as assessmentActions from 'store/modules/Assessment/actions';

const initialState = {
  assessments: {},
  searchResults: {
    assessments: [],
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case assessmentActions.UPDATE_ASSESSMENTS_CACHE_SET:
      return {
        ...state,
        assessments: action.payload,
      };

    case assessmentActions.UPDATE_ASSESSMENT_SEARCH_RESULTS:
      return {
        ...state,
        searchResults: action.payload,
      };

    default:
      return state;
  }
};
