import { takeEvery, call, put } from 'redux-saga/effects';

import * as ApiService from 'services/api';

import * as resourceActions from 'store/modules/Resource/actions';

/** ********************************************** */
/** Selectors * */

/** ********************************************** */
/** Sagas * */
function* getSessionsResourcesSaga() {
  const result = yield call(ApiService.GetSessionsResources);

  yield put({
    type: resourceActions.UPDATE_RESOURCE_CACHE_SET,
    resource: 'sessions',
    payload: result.data.data,
  });
}

function* getExercisesResourcesSaga() {
  const result = yield call(ApiService.GetExercisesResources);

  yield put({
    type: resourceActions.UPDATE_RESOURCE_CACHE_SET,
    resource: 'exercises',
    payload: result.data.data,
  });
}

function* getQuestionannireResourcesSaga(payload) {
  const result = yield call(ApiService.GetQuestionannireResources, payload);

  yield put({
    type: resourceActions.UPDATE_RESOURCE_CACHE_SET,
    resource: 'questionnaires',
    payload: result.data.data,
  });
}

function* getCommunityResourcesSaga(data) {
  const result = yield call(ApiService.GetCommunityResources, data.payload);

  yield put({
    type: resourceActions.UPDATE_RESOURCE_CACHE_SET,
    resource: 'communities',
    payload: result.data.data,
  });
}

function* getCountriesSaga() {
  const result = yield call(ApiService.GetCountries);

  yield put({
    type: resourceActions.UPDATE_RESOURCE_CACHE_SET,
    resource: 'countries',
    payload: result.data.data.countries,
  });
}

function* getProgramsResourcesSaga() {
  const result = yield call(ApiService.GetProgramsResources);

  yield put({
    type: resourceActions.UPDATE_RESOURCE_CACHE_SET,
    resource: 'programs',
    payload: result.data.data,
  });
}

function* getArticlesResourcesSaga() {
  const result = yield call(ApiService.GetArticlesResources);

  yield put({
    type: resourceActions.UPDATE_RESOURCE_CACHE_SET,
    resource: 'articles',
    payload: result.data.data,
  });
}

function* getCoachesResourcesSaga() {
  const result = yield call(ApiService.GetCoachesResources);

  yield put({
    type: resourceActions.UPDATE_RESOURCE_CACHE_SET,
    resource: 'coaches',
    payload: result.data.data,
  });
}

export default function* sagaWatcher() {
  yield takeEvery(resourceActions.GET_QUESTIONNAIRE_RESOURCES, getQuestionannireResourcesSaga);
  yield takeEvery(resourceActions.GET_COMMUNITY_RESOURCES, getCommunityResourcesSaga);
  yield takeEvery(resourceActions.GET_COUNTRIES, getCountriesSaga);
  yield takeEvery(resourceActions.GET_PROGRAMS_RESOURCES, getProgramsResourcesSaga);
  yield takeEvery(resourceActions.GET_ARTICLES_RESOURCES, getArticlesResourcesSaga);
  yield takeEvery(resourceActions.GET_COACHES_RESOURCES, getCoachesResourcesSaga);
  yield takeEvery(resourceActions.GET_EXERCISES_RESOURCES, getExercisesResourcesSaga);
  yield takeEvery(resourceActions.GET_SESSIONS_RESOURCES, getSessionsResourcesSaga);
}
