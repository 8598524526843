export const INCREMENT = 'status/INCREMENT_STATUS';
export const DECREMENT = 'status/DECREMENT_STATUS';

export const CLEAR = 'status/CLEAR_STATUS';

export const SET_STATUSES = 'status/SET_STATUSES';

export function incrementStatusCreator(payload) {
  return { type: INCREMENT, payload };
}

export function decrementStatusCreator(payload) {
  return { type: DECREMENT, payload };
}

export function clearStatus(payload) {
  return { type: CLEAR, payload };
}
