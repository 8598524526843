import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import MediaQuery from 'react-responsive';

import HeaderLinkDropdown from 'containers/HeaderContainer/components/HeaderLinkDropdown/index';

import ModalLogin from 'containers/modals/ModalLogin';
import ModalProfile from 'containers/modals/ModalProfile';

import { ROLE_USER, ROLE_PRESCRIBER, getCurrentRole } from 'services/roles';
import { SCREEN_MOBILE_MAX } from 'constants/constants';
import {
  getAdminTabs,
  getCoachTabs,
  getManagerTabs,
  getUserTabs,
  isUserGeneralAssessment,
} from 'app/user/helpers';
import { itContains } from 'services/utils';

import { logoutRequestCreator, setCurrentRole } from 'store/modules/Account/actions';
import { getAllRolesCreator } from 'store/modules/User/actions';
import AvatarButton from './components/AvatarButton';
import HeaderLink from './components/HeaderLink';
import Hamburger from './components/Hamburger';
import Logo from './components/Logo';
import RoleSwap from './components/RoleSwap';

import withSlugs from '../../layouts/withSlugs';

import './style.css';

class HeaderContainer extends Component {
  constructor() {
    super();

    this.handleLogin = this.handleLogin.bind(this);
    this.handleCloseLogin = this.handleCloseLogin.bind(this);

    this.handleProfile = this.handleProfile.bind(this);
    this.handleProfileClose = this.handleProfileClose.bind(this);

    this.handleLogoutClick = this.handleLogoutClick.bind(this);
    this.handleRoleChangeClick = this.handleRoleChangeClick.bind(this);

    this.state = {
      loginModalActive: false,
      loginProfileActive: false,
    };
  }

  componentDidMount() {
    this.props.dispatch(getAllRolesCreator({}));

    if (this.props.isLoggedIn && this.props.unsubscribeEmail) {
      this.setState({
        loginProfileActive: true,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.unsubscribeEmail &&
      this.props.isLoggedIn &&
      prevProps.isLoggedIn !== this.props.isLoggedIn
    ) {
      this.setState({
        loginProfileActive: true,
      });
    }
  }

  handleLogin() {
    this.setState({
      loginModalActive: true,
    });
  }

  handleCloseLogin() {
    this.setState({
      loginModalActive: false,
    });
  }

  handleProfile() {
    this.setState({
      loginProfileActive: true,
    });
  }

  handleProfileClose() {
    this.setState({
      loginProfileActive: false,
    });

    this.props.handleCloseUnsubscribeEmail();
  }

  handleLogoutClick() {
    this.props.dispatch(logoutRequestCreator());
  }

  handleRoleChangeClick(role) {
    this.props.dispatch(setCurrentRole(role));
  }

  links() {
    const { t, user, role, isLoggedIn, getSlugByName, getphyExpress } = this.props;

    let headersLink = [];

    switch (role) {
      case 'admin':
        headersLink.push(...getAdminTabs());
        break;
      case 'manager':
        headersLink.push(...getManagerTabs());
        break;
      case 'coach':
        headersLink.push(...getCoachTabs());
        break;
      case 'user':
        headersLink.push(...getUserTabs(user, isLoggedIn, getphyExpress));
        break;
      default:
        break;
    }

    return (
      <ul className="links-list">
        {headersLink.map((item) => {
          if (item.dropdown?.length) {
            return (
              <HeaderLinkDropdown
                key={item.text}
                text={item.text}
                to={item.link}
                dropdownButtons={item.dropdown}
                isTabLink={item.icon}
                linkExternal={item.external}
              />
            );
          } else {
            return (
              <HeaderLink
                to={item.link}
                key={item.text}
                text={item.text}
                isTabLink={item.icon}
                linkExternal={item.external}
              />
            );
          }
        })}

        {this.props.isLoggedIn && this.props?.role !== 'user' && this.props?.role !== 'prescriber' && (
          <HeaderLinkDropdown
            text={t('HeaderContainer.links.help')}
            to="/help"
            dropdownButtons={[
              { link: '/help/tutorial', text: t('HeaderContainer.Help.tutorials') },
              { link: '/help/faq', text: t('HeaderContainer.Help.faq') },
              { link: '/help/glossary', text: t('HeaderContainer.Help.glossary') },
              { link: '/help/procedure', text: t('HeaderContainer.Help.procedures') },
              {
                link: '/help/communication-tool',
                text: t('HeaderContainer.Help.communication-tools'),
              },
              { link: '/help/contact-us', text: t('HeaderContainer.Help.contact-us') },
            ]}
          />
        )}
        {!(
          itContains(window.location.href, getSlugByName('Landing Page - IPF')) ||
          itContains(window.location.href, getSlugByName('BVS - Step')) ||
          this.props.role === 'prescriber'
        ) && <HeaderLink to={getSlugByName('blog')} text={t('HeaderContainer.links.blog')} />}

        {this.props.isLoggedIn && (
          <>
            <MediaQuery maxWidth={SCREEN_MOBILE_MAX}>
              <HeaderLink
                text={t('HeaderContainer.dropdownButtons.account')}
                type="connectButton"
                onClick={this.handleProfile}
              />
              <HeaderLink
                text={t('HeaderContainer.dropdownButtons.disconnect')}
                type="connectButton"
                onClick={this.handleLogoutClick}
              />
            </MediaQuery>
            <MediaQuery minWidth={SCREEN_MOBILE_MAX}>
              <AvatarButton
                dropdownButtons={[
                  {
                    onClick: this.handleProfile,
                    text: t('HeaderContainer.dropdownButtons.account'),
                  },
                  {
                    onClick: this.handleLogoutClick,
                    text: t('HeaderContainer.dropdownButtons.disconnect'),
                  },
                ]}
                avatarUrl={this.props.user ? this.props.user.photo : null}
              />
            </MediaQuery>
          </>
        )}
      </ul>
    );
  }

  getLogoLink(user) {
    switch (getCurrentRole()) {
      case 'user':
        if (isUserGeneralAssessment(user)) {
          return '/communities';
        } else {
          return '/sessions';
        }
      case 'coach':
        return '/communities';
      case 'manager':
        return '/users';
      case 'admin':
        return '/users';
      case 'prescriber':
        return '/stats/dashboard';
      default:
        return '/communities';
    }
  }

  render() {
    return (
      <div className={`header-container ${this.props.className || ''}`}>
        <div className="header-container-inner">
          {this.state.loginModalActive && <ModalLogin handleClose={this.handleCloseLogin} />}

          {this.state.loginProfileActive && (
            <ModalProfile
              handleClose={this.handleProfileClose}
              notificationsPanelOpen={this.props.unsubscribeEmail ? true : false}
              unsubscribeEmail={this.props.unsubscribeEmail}
              handleCloseUnsubscribeEmail={this.props.handleCloseUnsubscribeEmail}
            />
          )}

          {!(
            this.props.roles_ids.includes(ROLE_USER) ||
            this.props.roles_ids.includes(ROLE_PRESCRIBER)
          ) && (
            <RoleSwap
              allRoles={this.props.allRoles}
              roles={this.props.roles}
              role={this.props.role}
              handleRoleChangeClick={this.handleRoleChangeClick}
            />
          )}

          <Logo linkRoute={this.getLogoLink(this.props.user)} />

          <Hamburger>{this.links()}</Hamburger>

          <div className="desktop-header">{this.links()}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.Account.isLoggedIn,
  roles: state.Account.user?.roles || [],
  allRoles: state.User.roles,
  role: state.Account.role,
  roles_ids: state.Account.user?.roles_ids || [],
  user: state.Account.user,
  getphyExpress: state.GetphyExpress.getphy_express,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withSlugs(HeaderContainer))
);
