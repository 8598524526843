import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { getUserPayments } from 'store/modules/User/actions';
import { pdfReceipt } from 'store/modules/Account/actions';

import './style.css';

const statusRefPdfReceipt = 'Account-pdfReceipt';

class PaymentsInfo extends Component {
  componentDidMount() {
    this.props.dispatch(getUserPayments({ user_id: 'me' }));
  }

  render() {
    const { t } = this.props;

    const { payments } = this.props;

    return (
      <div className="profile-modal-payment-info form-profile">
        <div className="data-row">
          <table>
            <thead>
              <tr>
                <th>{t('ModalProfile.PaymentsInfo.subtitle-1')}</th>
                <th>{t('ModalProfile.PaymentsInfo.subtitle-2')}</th>
                <th>{t('ModalProfile.PaymentsInfo.subtitle-3')}</th>
                <th>{t('ModalProfile.PaymentsInfo.subtitle-4')}</th>
                <th>{t('ModalProfile.PaymentsInfo.subtitle-5')}</th>
              </tr>
            </thead>

            <tbody>
              {payments &&
                payments.map((payment, index) => {
                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <tr key={index}>
                      <td data-label="Reçu de paiement pour la commande N°">{payment.reference}</td>
                      <td data-label="Cours Co : ">{payment.object}</td>

                      <td data-label="Date transaction : "> {payment.date.date.split(' ')[0]} </td>
                      <td data-label="Tarif :"> {payment.amount} Euro(s) </td>
                      <td data-label={t('ModalProfile.PaymentsInfo.subtitle-5')}>
                        <p
                          className="download-button"
                          onClick={() => {
                            this.props.dispatch(
                              pdfReceipt({
                                id: payment.id,
                                statusRef: statusRefPdfReceipt,
                              })
                            );
                          }}
                        >
                          {t('GLOBAL.download')}
                        </p>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.User.usersSearchResults,
  payments: state.User.userPaymentsResults && state.User.userPaymentsResults.payments,
  currentUser: state.Account && state.Account.user,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(PaymentsInfo));
