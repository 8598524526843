import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { getLocale } from 'services/localisation';

import fr from 'translations/fr.json';
import en from 'translations/en.json';
import es from 'translations/es.json';
import esAR from 'translations/es-AR.json';
import de from 'translations/de.json';
import it from 'translations/it.json';
import nl from 'translations/nl.json';
import pt from 'translations/pt.json';

// import Backend from 'i18next-xhr-backend';
// import LanguageDetector from 'i18next-browser-languagedetector';
// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  // .use(Backend)
  // // detect user language
  // // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: getLocale() || 'fr',
    defaultNS: 't',
    react: {
      useSuspense: false,
      wait: true,
    },

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: { t: en },
      fr: { t: fr },
      es: { t: es },
      'es-AR': { t: esAR },
      de: { t: de },
      it: { t: it },
      nl: { t: nl },
      pt: { t: pt },
    },
  });

export default i18n;
