export const GET_ASSESSMENTS = 'assessment/GET_ASSESSMENTS';

export const UPDATE_ASSESSMENTS_CACHE = 'assessment/UPDATE_ASSESSMENTS_CACHE';
export const UPDATE_ASSESSMENTS_CACHE_SET = 'assessment/UPDATE_ASSESSMENTS_CACHE_SET';
export const UPDATE_ASSESSMENT_SEARCH_RESULTS = 'assessment/UPDATE_ASSESSMENT_SEARCH_RESULTS';
export const DOWNLOAD_ASSESSMENT_DOCUMENT = 'assessment/DOWNLOAD_ASSESSMENT_DOCUMENT';

export function getAssessments(payload) {
  return { type: GET_ASSESSMENTS, payload };
}

export function downloadAssessmentDocument(payload) {
  return { type: DOWNLOAD_ASSESSMENT_DOCUMENT, payload };
}
