import * as helpActions from 'store/modules/Help/actions';

const initialState = {
  helpTutorialsSearchResults: [],
  helpTutorialsCategoriesSearchResults: [],
  helpFaqsSearchResults: [],
  helpFaqCategoriesSearchResults: [],
  helpGlossariesSearchResults: [],
  helpGlossaryCategoriesSearchResults: [],
  helpContactUsSearchResults: [],
  helpContactUsCategoriesSearchResults: [],
  helpProceduresSearchResults: [],
  helpProcedureCategoriesSearchResults: [],
  helpCommunicationToolsSearchResults: [],
  helpCommunicationToolCategoriesSearchResults: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case helpActions.UPDATE_CACHE_TUTORIALS_SET:
      return {
        ...state,
        helpTutorialsSearchResults: action.payload,
      };

    case helpActions.UPDATE_CACHE_TUTORIALS_CATEGORIES_SET:
      return {
        ...state,
        helpTutorialsCategoriesSearchResults: action.payload,
      };

    case helpActions.UPDATE_CACHE_FAQ_SET:
      return {
        ...state,
        helpFaqsSearchResults: action.payload,
      };

    case helpActions.UPDATE_CACHE_FAQ_CATEGORIES_SET:
      return {
        ...state,
        helpFaqCategoriesSearchResults: action.payload,
      };

    case helpActions.UPDATE_CACHE_GLOSSARY_SET:
      return {
        ...state,
        helpGlossariesSearchResults: action.payload,
      };

    case helpActions.UPDATE_CACHE_GLOSSARY_CATEGORIES_SET:
      return {
        ...state,
        helpGlossaryCategoriesSearchResults: action.payload,
      };

    case helpActions.UPDATE_CACHE_CONTACT_US_SET:
      return {
        ...state,
        helpContactUsSearchResults: action.payload,
      };

    case helpActions.UPDATE_CACHE_CONTACT_US_CATEGORIES_SET:
      return {
        ...state,
        helpContactUsCategoriesSearchResults: action.payload,
      };

    case helpActions.UPDATE_CACHE_PROCEDURE_SET:
      return {
        ...state,
        helpProceduresSearchResults: action.payload,
      };

    case helpActions.UPDATE_CACHE_PROCEDURE_CATEGORIES_SET:
      return {
        ...state,
        helpProcedureCategoriesSearchResults: action.payload,
      };

    case helpActions.UPDATE_CACHE_COMMUNICATION_TOOL_SET:
      return {
        ...state,
        helpCommunicationToolsSearchResults: action.payload,
      };

    case helpActions.UPDATE_CACHE_COMMUNICATION_TOOL_CATEGORIES_SET:
      return {
        ...state,
        helpCommunicationToolCategoriesSearchResults: action.payload,
      };

    default:
      return state;
  }
};
