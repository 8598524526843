import * as getphyExpressActions from 'store/modules/GetphyExpress/actions';

const initialState = {
  csv: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case getphyExpressActions.UPDATE_CACHE:
      return {
        ...state,
        getphy_express: action.payload,
      };

    default:
      return state;
  }
};
