import { takeLatest, call, put, select } from 'redux-saga/effects';

import * as ApiService from 'services/api';
import * as RolesService from 'services/roles';

import { getToken, setToken, clearToken } from 'services/token';
import { setLocale, getLocale } from 'services/localisation';
import ProtectedCall from 'services/protected.api';

import { incrementStatusCreator, decrementStatusCreator } from 'store/modules/Status/actions';
import { getUserByIdCreator, getAllRolesCreator } from 'store/modules/User/actions';
import { initiateWebsocket, disconnectWebsocket } from 'store/modules/Socket/actions';
import { getGetphyExpressVideos } from 'store/modules/GetphyExpress/actions';
import { getCountries } from 'store/modules/Resource/actions';
import { SWITCH_LANGUAGE_SET } from 'store/modules/App/actions';

import * as accountActions from 'store/modules/Account/actions';
import * as appActions from 'store/modules/App/actions';
import * as promoteActions from 'store/modules/Promote/actions';
import { getSeoPages } from 'store/modules/Seo/actions';

/** ********************************************** */
/** Selectors * */

/** ********************************************** */
const allSeoPagesSelector = (state) => {
  return JSON.parse(JSON.stringify(state.Seo.seoPages));
};

function* initSaga(data) {
  try {
    yield put(incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Init saga' }));

    const token = yield call(getToken);
    yield put({ type: SWITCH_LANGUAGE_SET, code: getLocale() });

    if (!token) {
      yield put({ type: accountActions.SET_LOGGEDOUT_STATE });
      yield put(
        decrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Init saga no token' })
      ); // TODO: Should be error message?
      return;
    }

    const me = yield ProtectedCall(ApiService.GetUser, 'me');
    yield put(accountActions.setLoggedInStateCreator(me.data.data));

    yield put(
      decrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Init saga success' })
    );
  } catch (e) {
    yield put({ type: accountActions.SET_LOGGEDOUT_STATE });
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Init saga error',
        state: 'error',
      })
    );
  }
}

function* loginSaga(data) {
  try {
    // yield put({ type: SET_LOGIN_REQUEST_STATUS, payload: { error:null, status:'sending' }});
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Start login' })
    );

    const response = yield call(ApiService.Login, data.payload);

    yield call(setToken, response.data.data);
    yield put(
      accountActions.setLoggedInStateCreator({
        ...response.data.data,
        statusRef: data.payload.statusRef,
        shouldRedirectToThisUrl: data.payload.shouldRedirectToThisUrl,
      })
    );
    yield put(
      decrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Start login success' })
    );

    // TODO: Remove these two lines when next_assesment will be added to login response
    const me = yield ProtectedCall(ApiService.GetUser, 'me');
    yield put(accountActions.setLoggedInStateCreator(me.data.data));

    // yield put({ type: SET_LOGIN_REQUEST_STATUS, payload: { error: null, status: 'success' }});
    // yield put( setCurrentRole( RolesService.getDefaultRole(response.data.data.user.roles) ) )
  } catch (error) {
    // yield put({type: SET_LOGIN_REQUEST_STATUS, payload: { error: JSON.stringify(error.response.data.error), status: 'error'}});
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Login error',
        state: 'error',
        data: error.response.data,
      })
    );
  }
}

function* logAsSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Start logAs' })
    );
    const { userId } = data.payload;

    const response = yield ProtectedCall(ApiService.LogAs, userId);
    yield put(disconnectWebsocket({}));
    yield call(RolesService.clearRole);
    yield call(setToken, response.data.data);
    yield put(
      accountActions.setLoggedInStateCreator({
        ...response.data.data,
        statusRef: data.payload.statusRef,
      })
    );
    yield put(initiateWebsocket({ userId }));
    yield put(getSeoPages({}));
    yield put(getCountries({})); // related to clearing state on rootReducer
    yield put(getAllRolesCreator({})); // related to clearing state on rootReducer
    yield put(
      decrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Start logAs success' })
    );

    // TODO: Remove these two lines when next_assesment will be added to login response
    const me = yield ProtectedCall(ApiService.GetUser, 'me');
    yield put(accountActions.setLoggedInStateCreator(me.data.data));
  } catch (error) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'LogAs error',
        state: 'error',
        data: error.response.data.error,
      })
    );
  }
}

function* setLoggedInState(data) {
  try {
    if (!data || !data.payload) return;
    if (!data.payload.user) return;
    const currentRole = RolesService.getDefaultRole(data.payload.user.roles);
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Setting login state' })
    );

    yield put(accountActions.getLanguages({}));
    if (data.payload.user.language && data.payload.user.language.code) {
      setLocale(data.payload.user.language.code);
      yield put({ type: SWITCH_LANGUAGE_SET, code: data.payload.user.language.code });
    }

    // yield put ( {type: user_UPDATE_CACHE, payload: [data.payload.user]});

    yield put(accountActions.setCurrentRole(currentRole));

    // yield put({ type: SET_LOGIN_REQUEST_STATUS, payload: { error: null, status: 'success' }});
    yield put({
      type: accountActions.SET_LOGGEDIN_STATE_SET,
      payload: { user: data.payload.user },
    });
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Setting login state success',
      })
    );

    yield put(
      getUserByIdCreator({
        user_id: 'me',
      })
    );

    yield put(initiateWebsocket({ userId: data.payload.user.id }));

    if (
      data.payload.user.language.code === 'fr' &&
      data.payload.user.prescribers.length === 0 &&
      currentRole === 'user'
    ) {
      yield put(promoteActions.addEcoSantePromotion());
    }
    // redirection here we limit redirection only to users
    if (currentRole === 'user' && data.payload.shouldRedirectToThisUrl) {
      yield put(accountActions.redirectionToAnUrl(data.payload.shouldRedirectToThisUrl));
    }
  } catch (error) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Setting login state error',
        state: 'error',
        data: error.response.data.error,
      })
    );
  }
}

function* getLanguagesSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Getting languages' })
    );

    const result = yield call(ApiService.GetLanguages, data.payload);
    yield put({ type: accountActions.SET_LANGUAGES, payload: result.data.data.languages });
    yield put({ type: appActions.SET_LANGUAGES, payload: result.data.data.languages });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting languages success',
      })
    );
  } catch (error) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting languages error',
        state: 'error',
        data: error.response.data.error,
      })
    );
  }
}

function* getLevelsSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Getting levels' })
    );

    const result = yield call(ApiService.GetLevels, data.payload);
    yield put({ type: accountActions.SET_LEVELS, payload: result.data.data.levels });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting levels success',
      })
    );
  } catch (error) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting levels error',
        state: 'error',
        data: error.response.data.error,
      })
    );
  }
}

function* submitForgottenPasswordRequestSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Submitting forgotten password request',
      })
    );

    const result = yield call(ApiService.ForgotPasswordRequest, data.payload);

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Submitting forgotten password request finished',
        data: result.data.message,
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Submitting forgotten password request error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* submitNewPasswordSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Submitting new password request',
      })
    );

    const result = yield call(ApiService.SubmitNewPassword, data.payload);

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Submitting new password requestfinished',
        data: result.data.message,
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Submitting new password request error',
        state: 'error',
        data: e.response.data.error,
      })
    );
  }
}

function* setCurrentRoleSaga(data) {
  if (!data || !data.payload) {
    data = { payload: null };
  }

  yield call(RolesService.saveToLocalStorage, data.payload);
  yield put({ type: accountActions.SET_CURRENT_ROLE_SET, payload: data.payload });
}

function* logoutSaga() {
  try {
    const token = yield call(getToken);
    let landing = null;
    let seoPages = {};
    let newLanding = '';

    if (token) {
      landing = yield call(ApiService.Logout);
      seoPages = yield select(allSeoPagesSelector) || {};
      newLanding = `/${landing?.data?.data}`;
      if (newLanding[newLanding.length - 1] === '/') {
        newLanding = newLanding.slice(0, -1);
      }
    }

    yield put(disconnectWebsocket({}));
    yield call(clearToken);
    yield call(RolesService.clearRole);

    if (landing) {
      const language = getLocale();
      if (Object.keys(seoPages).length > 0 && language) {
        const findIndex = Object.values(seoPages).findIndex(
          (seoPage) => seoPage.translations.en.url === newLanding
        );
        if (findIndex > -1) {
          newLanding = Object.values(seoPages)[findIndex].url;
        }
      }

      const newLocation = language ? `/${language}${newLanding}` : `/${landing?.data?.data}`;
      window.location.pathname = newLocation;
    }
  } catch (error) {
    // when it's impossible to call the API || when you don't have the autorization (code 401)
    if (
      (JSON.stringify(error) || '').includes('Network Error') ||
      (JSON.stringify(error) || '').includes('Request failed with status code 401')
    ) {
      yield call(clearToken);
      yield call(RolesService.clearRole);
      window.location.pathname = `/`;
    }
  } finally {
    yield put(accountActions.getLanguages({}));
    setLocale(getLocale());
  }
}

function* registrationSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'start register' })
    );

    yield call(ApiService.Register, data.payload);

    yield put(
      decrementStatusCreator({ statusRef: data.payload.statusRef, message: 'finish register' })
    );
  } catch (error) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Register error',
        state: 'error',
        data: error.response.data,
      })
    );
  }
}

function* updateUserLanguageSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'start updating user language',
      })
    );

    yield put(promoteActions.hideEcoSantePromotion());
    const response = yield call(ApiService.UpdateUserLanguage, data.payload);
    const { user } = response.data.data;
    const currentRole = RolesService.getDefaultRole(user.roles);

    if (user.language.code === 'fr' && user.prescribers.length === 0 && currentRole === 'user') {
      yield put(promoteActions.addEcoSantePromotion());
    }

    yield put(getGetphyExpressVideos({}));

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'finish updating user language',
      })
    );
  } catch (error) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'update user language error',
        state: 'error',
        data: error.response.data,
      })
    );
  }
}

function* submitConfirmEmailSaga(data) {
  try {
    yield call(ApiService.ConfirmEmail, data.payload);

    yield put({
      type: accountActions.SET_REGISTER_CONFIRM_EMAIL_STATUS,
      payload: {
        error: null,
        status: 'success',
      },
    });
  } catch (error) {
    let errorString = '';

    if (error.response) {
      errorString = {
        message: error.response.data.message,
        errors: error.response.data.errors || error.response.data.error,
      };
    } else if (error.request) {
      errorString = error.request;
    } else {
      errorString = error.message;
    }

    yield put({
      type: accountActions.SET_REGISTER_CONFIRM_EMAIL_STATUS,
      payload: {
        error: errorString,
        status: '',
      },
    });
  }
}

function* submitRestoreAccountSaga(data) {
  try {
    yield call(ApiService.RestoreAccount, data.payload);

    yield put({
      type: accountActions.SET_RESTORE_ACCOUNT_EMAIL_STATUS,
      payload: {
        error: null,
        status: 'success',
      },
    });
  } catch (error) {
    let errorString = '';

    if (error.response) {
      errorString = {
        message: error.response.data.message,
        errors: error.response.data.errors || error.response.data.error,
      };
    } else if (error.request) {
      errorString = error.request;
    } else {
      errorString = error.message;
    }

    yield put({
      type: accountActions.SET_RESTORE_ACCOUNT_EMAIL_STATUS,
      payload: {
        error: errorString,
        status: '',
      },
    });
  }
}

function* updateProfileSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.payload.statusRef,
        message: 'Start save account',
      })
    );
    const response = yield ProtectedCall(ApiService.UpdateProfile, data.payload.payload);

    yield put({
      type: accountActions.SET_LOGGEDIN_STATE_SET,
      payload: { user: response.data.data.user },
    });
    yield put({
      type: accountActions.SET_PROFILE_UDPDATE_STATUS,
      payload: {
        error: null,
        status: 'success',
      },
    });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.payload.statusRef,
        message: 'End save account',
      })
    );
  } catch (error) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.payload.statusRef,
        message: 'Save account error',
        state: 'error',
        data: error.response.data,
      })
    );
    let errorString = '';

    if (error.response) {
      errorString = {
        message: error.response.data.message,
        errors: error.response.data.errors || error.response.data.error,
      };
    } else if (error.request) {
      errorString = error.request;
    } else {
      errorString = error.message;
    }

    yield put({
      type: accountActions.SET_PROFILE_UDPDATE_STATUS,
      payload: {
        error: errorString,
        status: '',
      },
    });
  }
}

function* updatePasswordSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Start save password' })
    );

    yield ProtectedCall(ApiService.UpdatePassword, data.payload);
    yield put({
      type: accountActions.SET_PASSWORD_UDPDATE_STATUS,
      payload: {
        error: null,
        status: 'success',
      },
    });

    yield put(
      decrementStatusCreator({ statusRef: data.payload.statusRef, message: 'End save password' })
    );
  } catch (error) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Save password error',
        state: 'error',
        data: error.response.data,
      })
    );
    let errorString = '';

    if (error.response) {
      errorString = {
        message: error.response.data.message,
        errors: error.response.data.errors || error.response.data.error,
      };
    } else if (error.request) {
      errorString = error.request;
    } else {
      errorString = error.message;
    }

    yield put({
      type: accountActions.SET_PASSWORD_UDPDATE_STATUS,
      payload: {
        error: errorString,
        status: '',
      },
    });
  }
}

function* refetchProfileSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Start refetch account data',
      })
    );

    const user = yield ProtectedCall(ApiService.GetUser, data.payload.user_id);
    const currentRole = RolesService.getDefaultRole(user.data.data.user.roles);
    yield put({ type: accountActions.UPDATE_PROFILE, payload: user.data.data.user });

    if (
      user.data.data.user.language.code === 'fr' &&
      user.data.data.user.prescribers.length === 0 &&
      currentRole === 'user'
    ) {
      yield put(promoteActions.addEcoSantePromotion());
    }

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'End refetch account data',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Refetch account data error',
        state: 'error',
      })
    );
  }
}

function* unsubscribeSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Start unsubscribe' })
    );

    yield ProtectedCall(ApiService.Unsubscribe, data.payload);

    yield put(
      decrementStatusCreator({ statusRef: data.payload.statusRef, message: 'End unsubscribe' })
    );

    window.location.reload();
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Unsubscribe error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* resendConfirmationEmailSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'resend confirmation email',
      })
    );

    yield call(ApiService.ResendConfirmEmail, data.payload);

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'resend confirmation email finish',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'resend confirmation email error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* resendRestoreAccountEmailSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'resend restore account email',
      })
    );

    yield call(ApiService.ResendRestoreAccountEmail, data.payload);

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'resend restore account email finish',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'resend restore account email error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* pdfReceiptSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Download PDF receipt',
      })
    );

    yield call(ApiService.PdfReceipt, data.payload.id);

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Download PDF receipt finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Download PDF receipt',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

/** Sagas * */

export default function* sagaWatcher() {
  yield takeLatest(accountActions.APP_INIT, initSaga);

  yield takeLatest(accountActions.LOGIN_REQUEST, loginSaga);
  yield takeLatest(accountActions.LOGAS_REQUEST, logAsSaga);
  yield takeLatest(accountActions.SET_LOGGEDIN_STATE, setLoggedInState);

  yield takeLatest(accountActions.GET_LANGUAGES, getLanguagesSaga);
  yield takeLatest(accountActions.GET_LEVELS, getLevelsSaga);

  yield takeLatest(
    accountActions.SUBMIT_FORGOTTEN_PASSWORD_REQUEST,
    submitForgottenPasswordRequestSaga
  );
  yield takeLatest(accountActions.SUBMIT_NEW_PASSWORD, submitNewPasswordSaga);

  yield takeLatest(accountActions.SET_CURRENT_ROLE, setCurrentRoleSaga);
  yield takeLatest(accountActions.SET_LOGGEDOUT_STATE, logoutSaga);

  yield takeLatest(accountActions.REGISTER_REQUEST, registrationSaga);
  yield takeLatest(accountActions.EMAIL_CONFIRM_SUBMIT_REQUEST, submitConfirmEmailSaga);
  yield takeLatest(accountActions.RESTORE_ACCOUNT_SUBMIT_REQUEST, submitRestoreAccountSaga);
  yield takeLatest(accountActions.UPDATE_PROFILE_REQUEST, updateProfileSaga);
  yield takeLatest(accountActions.UPDATE_PASSWORD_REQUEST, updatePasswordSaga);
  yield takeLatest(accountActions.REFETCH_PROFILE_REQUEST, refetchProfileSaga);

  yield takeLatest(accountActions.RESEND_CONFIRM_EMAIL, resendConfirmationEmailSaga);
  yield takeLatest(accountActions.RESEND_RESTORE_ACCOUNT_EMAIL, resendRestoreAccountEmailSaga);

  yield takeLatest(accountActions.UNSUBSCRIBE, unsubscribeSaga);

  yield takeLatest(accountActions.UPDATE_USER_LANGUAGE, updateUserLanguageSaga);

  yield takeLatest(accountActions.DOWNLOAD_PDF_RECEIPT, pdfReceiptSaga);
}
