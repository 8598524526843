import React, { Component } from 'react';
import ReactModal from 'react-modal';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import FormModal from 'components/FormModal';
import Button from 'components/Button';
import CloseButton from 'components/CloseButton';

import './style.css';

const baseClass = 'modal-register-success';

class ModalRegisterSuccess extends Component {
  constructor() {
    super();

    this.state = {};
  }

  render() {
    const { t } = this.props;

    return (
      <ReactModal
        isOpen
        onRequestClose={this.props.handleClose}
        shouldCloseOnOverlayClick
        className={`modal-content ${baseClass}`}
        overlayClassName="modal-overlay"
      >
        <CloseButton onClick={this.props.handleClose} />

        <FormModal id="login-form" className="login-form transparent">
          <h1>{t('ModalRegisterSuccess.title')}</h1>
          <div className={`${baseClass}-text`}>{t('ModalRegisterSuccess.text')}</div>

          <div>
            <Button onClick={this.props.handleClose}>{t('GLOBAL.close')}</Button>
          </div>
        </FormModal>
      </ReactModal>
    );
  }
}

const mapStateToProps = (state) => ({
  theState: state,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ModalRegisterSuccess)
);
