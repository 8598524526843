import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import usePrevious from '@shared/hooks/use-previous/usePrevious';

import { CommunitySearch } from '@services/api';

const useGetGroupClasses = ({ archivedCourses }) => {
  const { i18n } = useTranslation();
  const [groupClasses, setGroupClasses] = useState(undefined);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [requestState, setRequestState] = useState('idle');
  const { id } = useSelector((state) => state.Account.user);

  const limit = 6;
  const { language } = i18n;
  const prevLanguage = usePrevious(language);
  const prevPage = usePrevious(page);
  const prevArchivedCourses = usePrevious(archivedCourses);

  useEffect(() => {
    if (prevLanguage !== language) {
      setRequestState('idle');
    }
  }, [language, prevLanguage]);

  useEffect(() => {
    if (prevPage !== page || prevArchivedCourses !== archivedCourses) {
      setRequestState('idle');
    }
  }, [page, prevPage, archivedCourses, prevArchivedCourses]);

  useEffect(() => {
    if (id && requestState === 'idle') {
      setRequestState('loading');
      CommunitySearch({
        limit,
        offset: page,
        archive: archivedCourses,
      }).then((payload) => {
        if (payload.data) {
          const { communities, total_count: totalCount } = payload.data.data;
          setGroupClasses(communities);
          setTotalPages(Math.ceil(totalCount / limit));
          setRequestState('success');
        }
      });
    }
  }, [groupClasses, requestState, id, page, archivedCourses]);

  return { groupClasses, requestState, totalPages, page, setPage };
};

export default useGetGroupClasses;
