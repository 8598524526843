import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import Button from 'components/Button';
import ErrorMessage from 'components/ErrorMessage';

class ConfidentialInfo extends Component {
  render() {
    const { t } = this.props;

    return (
      <div className="profile-modal-personal-info form-profile">
        <div className="data-row data-row-half">
          <label>{t('ModalProfile.ConfidentialInfo.label-2')}</label>
          <input
            autoComplete="new-password"
            name="password"
            type="password"
            value={this.props.userData.password || ''}
            onChange={this.props.inputChange}
          />
        </div>

        <div className="data-row data-row-half">
          <label>{t('ModalProfile.ConfidentialInfo.label-3')}</label>
          <input
            autoComplete="new-password"
            name="password_confirmation"
            type="password"
            value={this.props.userData.password_confirmation || ''}
            onChange={this.props.inputChange}
          />
        </div>

        <div>
          <Button onClick={this.props.cancelClick}>{t('GLOBAL.cancel')}</Button>

          <Button onClick={this.props.submitClick} status={this.props.status}>
            {t('GLOBAL.submit')}
          </Button>

          <ErrorMessage status={this.props.status} />

          {this.props.validationError && (
            <div className="validation-error alert danger">{this.props.validationError}</div>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(ConfidentialInfo);
