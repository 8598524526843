export const CREATE_EVENT_REQUEST = 'community/CREATE_EVENT_REQUEST';
export const CREATE_GROUP_REQUEST = 'community/CREATE_GROUP_REQUEST';
export const GET_COMMUNITY = 'community/GET_COMMUNITY';
export const GET_COMMUNITIES_USER = 'community/GET_COMMUNITIES_USER';
export const GET_EDIT_COMMUNITY = 'community/GET_EDIT_COMMUNITY';
export const COMMUNITY_SEARCH_SUBMIT = 'community/COMMUNITY_SEARCH_SUBMIT';
export const COMMUNITY_MAP_SEARCH_SUBMIT = 'community/COMMUNITY_MAP_SEARCH_SUBMIT';

export const REQUEST_MY_COMMUNITIES_FETCH = 'community/REQUEST_MY_COMMUNITIES_FETCH';
export const SET_MY_COMMUNITIES = 'community/SET_MY_COMMUNITIES';
export const SET_COMMUNITIES_SEARCH_RESULTS = 'community/SET_COMMUNITIES_SEARCH_RESULTS';

export const GET_COMMUNITY_JOIN_REQUESTS = 'community/GET_COMMUNITY_JOIN_REQUESTS';
export const REQUEST_JOIN_COMMUNITY = 'community/REQUEST_JOIN_COMMUNITY';
export const REQUEST_LEAVE_COMMUNITY = 'community/REQUEST_LEAVE_COMMUNITY';
export const REFUSE_COMMUNITY_JOIN_REQUEST = 'community/REFUSE_COMMUNITY_JOIN_REQUEST';
export const ACCEPT_COMMUNITY_JOIN_REQUEST = 'community/ACCEPT_COMMUNITY_JOIN_REQUEST';

export const UPDATE_COMMUNITY = 'community/UPDATE_COMMUNITY';
export const UPDATE_GROUP = 'community/UPDATE_GROUP';
export const UPDATE_EVENT = 'community/UPDATE_EVENT';

export const GET_COMMUNITY_PAYMENT_STATUS = 'community/GET_COMMUNITY_PAYMENT_STATUS';
export const SET_COMMUNITY_PAYMENT_STATUS = 'community/SET_COMMUNITY_PAYMENT_STATUS';

export const SET_COMMUNITY_REQUEST = 'community/SET_COMMUNITY_REQUEST';

export const DELETE_PENDING_PAYMENT = 'community/DELETE_PENDING_PAYMENT';

export const VALIDATE_PERIOD = 'community/VALIDATE_PERIOD';

export const ADD_CUSTOM_PRICE = 'community/ADD_CUSTOM_PRICE';
export const UPDATE_CUSTOM_PRICE = 'community/UPDATE_CUSTOM_PRICE';

export const TRANSFER_PAYMENT = 'community/TRANSFER_PAYMENT';
export const ADD_PAYMENTS = 'community/ADD_PAYMENTS';
export const UPDATE_PAYMENTS = 'community/UPDATE_PAYMENTS';
export const SUBMIT_FAILED_PAYMENT = 'community/SUBMIT_FAILED_PAYMENT';
export const SUBMIT_SUCCESS_PAYMENT = 'community/SUBMIT_SUCCESS_PAYMENT';

export const REMOVE_EXPIRED_INITIATE_PAYMENTS = 'community/REMOVE_EXPIRED_INITIATE_PAYMENTS';

export const UPDATE_USER_ONLINE_PAYMENT = 'community/UPDATE_USER_ONLINE_PAYMENT';
export const INITIATE_COMMUNITY_PAYMENT = 'community/INITIATE_COMMUNITY_PAYMENT';

// export const SET_CODE = 'community/SET_CODE'

export const ARCHIVE_COMMUNITY = 'community/ARCHIVE_COMMUNITY';
export const DEARCHIVE_COMMUNITY = 'community/DEARCHIVE_COMMUNITY';

export const SHARE_COMMUNITY_BY_MAIL = 'community/SHARE_COMMUNITY_BY_MAIL';

export const GET_COMMUNITY_REQUEST = 'community/GET_COMMUNITY_REQUEST';
export const ACCEPT_COMMUNITY_REQUEST = 'community/ACCEPT_COMMUNITY_REQUEST';
export const REFUSE_COMMUNITY_REQUEST = 'community/REFUSE_COMMUNITY_REQUEST';

export const GET_COMMUNITY_PARTICIPANTS = 'community/GET_COMMUNITY_PARTICIPANTS';
export const ADD_INACTIVE = 'community/ADD_INACTIVE';
export const ADD_PARTICIPANT = 'community/ADD_PARTICIPANT';
export const EDIT_PARTICIPANT = 'community/EDIT_PARTICIPANT';
export const REMOVE_PARTICIPANT = 'community/REMOVE_PARTICIPANT';
export const SEND_SMS_TO_PARTICIPANT = 'community/SEND_SMS_TO_PARTICIPANT';
export const SEND_MAIL_TO_PARTICIPANT = 'community/SEND_MAIL_TO_PARTICIPANT';
export const SEND_CHAT_TO_PARTICIPANT = 'community/SEND_CHAT_TO_PARTICIPANT';

export const LIKE = 'community/LIKE';
export const DISLIKE = 'community/DISLIKE';

export const EXPORT_COMMUNITIES = 'community/EXPORT_COMMUNITIES';
export const EXPORT_COMMUNITY = 'community/EXPORT_COMMUNITY';

export const CACHE_UPDATE_COMMUNITY_LIKE = 'community/CACHE_UPDATE_COMMUNITY_LIKE';
export const CACHE_UPDATE_COMMUNITIES = 'community/CACHE_UPDATE_COMMUNITIES';
export const CACHE_UPDATE_COMMUNITIES_SET = 'community/CACHE_UPDATE_COMMUNITIES_SET';
export const CACHE_UPDATE_EVENTS = 'community/CACHE_UPDATE_EVENTS';
export const CACHE_UPDATE_EVENTS_SET = 'community/CACHE_UPDATE_EVENTS_SET';
export const CACHE_UPDATE_GROUPS = 'community/CACHE_UPDATE_GROUPS';
export const CACHE_UPDATE_GROUPS_SET = 'community/CACHE_UPDATE_GROUPS_SET';
export const CACHE_UPDATE_JOIN_REQUESTS = 'community/CACHE_UPDATE_JOIN_REQUESTS';
export const CACHE_UPDATE_JOIN_REQUESTS_SET = 'community/CACHE_UPDATE_JOIN_REQUESTS_SET';
export const CACHE_UPDATE_COMMUNITIES_USER = 'community/CACHE_UPDATE_COMMUNITIES_USER';

export const CACHE_UPDATE_PARTICIPANTS = 'community/CACHE_UPDATE_PARTICIPANTS';
export const CACHE_UPDATE_PARTICIPANTS_SET = 'community/CACHE_UPDATE_PARTICIPANTS_SET';

export const CACHE_UPDATE_MANAGERS = 'community/CACHE_UPDATE_MANAGERS';
export const CACHE_UPDATE_MANAGERS_SET = 'community/CACHE_UPDATE_MANAGERS_SET';

export function createRequestCreator(payload) {
  return { type: CREATE_EVENT_REQUEST, payload };
}

export function createGroupCreator(payload) {
  return { type: CREATE_GROUP_REQUEST, payload };
}

export function getCommunityCreator(payload) {
  return { type: GET_COMMUNITY, payload };
}

export function getCommunitiesUser(payload) {
  return { type: GET_COMMUNITIES_USER, payload };
}

export function getCommunitySearchCreator(payload) {
  return { type: COMMUNITY_SEARCH_SUBMIT, payload };
}

export function getEditCommunity(payload) {
  return { type: GET_EDIT_COMMUNITY, payload };
}

export function getCommunityMapSearchCreator(payload) {
  return { type: COMMUNITY_MAP_SEARCH_SUBMIT, payload };
}

export function setMyCommunities(payload) {
  return { type: SET_MY_COMMUNITIES, payload };
}

export function getCommunityJoinRequests(payload) {
  return { type: GET_COMMUNITY_JOIN_REQUESTS, payload };
}

export function joinCommunityCreator(payload) {
  return { type: REQUEST_JOIN_COMMUNITY, payload };
}

export function leaveCommunityCreator(payload) {
  return { type: REQUEST_LEAVE_COMMUNITY, payload };
}

export function refuseCommunityJoinRequestCreator(payload) {
  return { type: REFUSE_COMMUNITY_JOIN_REQUEST, payload };
}

export function acceptCommunityJoinRequest(payload) {
  return { type: ACCEPT_COMMUNITY_JOIN_REQUEST, payload };
}

export function getCommunityParticipants(payload) {
  return { type: GET_COMMUNITY_PARTICIPANTS, payload };
}

export function updateCommunity(payload) {
  return { type: UPDATE_COMMUNITY, payload };
}

export function updateGroup(payload) {
  return { type: UPDATE_GROUP, payload };
}

export function updateEvent(payload) {
  return { type: UPDATE_EVENT, payload };
}

export function getCommunityPaymentStatus(payload) {
  return { type: GET_COMMUNITY_PAYMENT_STATUS, payload };
}

export function deletePendingPayment(payload) {
  return { type: DELETE_PENDING_PAYMENT, payload };
}

export function addCustomPrice(payload) {
  return { type: ADD_CUSTOM_PRICE, payload };
}

export function updateCustomPrice(payload) {
  return { type: UPDATE_CUSTOM_PRICE, payload };
}

export function validatePaymentPeriod(payload) {
  return { type: VALIDATE_PERIOD, payload };
}

export function transferPayment(payload) {
  return { type: TRANSFER_PAYMENT, payload };
}

export function addPayments(payload) {
  return { type: ADD_PAYMENTS, payload };
}

export function updatePayments(payload) {
  return { type: UPDATE_PAYMENTS, payload };
}

export function updateUserOnlinePayment(payload) {
  return { type: UPDATE_USER_ONLINE_PAYMENT, payload };
}

export function initiateCommunityPayment(payload) {
  return { type: INITIATE_COMMUNITY_PAYMENT, payload };
}

export function submitFailedPayment(payload) {
  return { type: SUBMIT_FAILED_PAYMENT, payload };
}

export function submitSuccessPayment(payload) {
  return { type: SUBMIT_SUCCESS_PAYMENT, payload };
}

export function addInactive(payload) {
  return { type: ADD_INACTIVE, payload };
}

export function addParticipant(payload) {
  return { type: ADD_PARTICIPANT, payload };
}

export function editParticipant(payload) {
  return { type: EDIT_PARTICIPANT, payload };
}

export function removeParticipant(payload) {
  return { type: REMOVE_PARTICIPANT, payload };
}

export function sendSmsToParticipant(payload) {
  return { type: SEND_SMS_TO_PARTICIPANT, payload };
}

export function sendMailToParticipant(payload) {
  return { type: SEND_MAIL_TO_PARTICIPANT, payload };
}

export function sendChatToParticipant(payload) {
  return { type: SEND_CHAT_TO_PARTICIPANT, payload };
}

export function archiveCommunity(payload) {
  return { type: ARCHIVE_COMMUNITY, payload };
}

export function dearchiveCommunity(payload) {
  return { type: DEARCHIVE_COMMUNITY, payload };
}

export function getCommunityRequest(payload) {
  return { type: GET_COMMUNITY_REQUEST, payload };
}

export function acceptCommunityRequest(payload) {
  return { type: ACCEPT_COMMUNITY_REQUEST, payload };
}

export function refuseCommunityRequest(payload) {
  return { type: REFUSE_COMMUNITY_REQUEST, payload };
}

export function removeExpiredInitiatedPayments(payload) {
  return { type: REMOVE_EXPIRED_INITIATE_PAYMENTS, payload };
}

export function likeCommunity(payload) {
  return { type: LIKE, payload };
}

export function dislikeCommunity(payload) {
  return { type: DISLIKE, payload };
}

export function exportCommunities(payload) {
  return { type: EXPORT_COMMUNITIES, payload };
}

export function exportCommunity(payload) {
  return { type: EXPORT_COMMUNITY, payload };
}

export function shareCommunityByEmail(payload) {
  return { type: SHARE_COMMUNITY_BY_MAIL, payload };
}
