import * as sysadminActions from 'store/modules/SysAdmin/actions';

const initialState = {
  options: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case sysadminActions.GET_RESET_OPTIONS_RESULTS:
      return {
        ...state,
        options: action.payload,
      };

    default:
      return state;
  }
};
