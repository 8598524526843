export const GET_ALL_GOALS = 'goal/GET_ALL_GOALS';
export const ADD_COMMON_GOAL = 'goal/ADD_COMMON_GOAL';
export const ADD_GOAL_TO_USER = 'goal/ADD_GOAL_TO_USER';
export const DELETE_GOAL = 'goal/DELETE_GOAL';
export const REMOVE_USER_GOAL = 'goal/REMOVE_USER_GOAL';

export const GET_USER_GOALS = 'goal/GET_USER_GOALS';

export const UPDATE_GOAL_ANSWER = 'goal/UPDATE_GOAL_ANSWER';

export const CACHE_UPDATE_GOALS = 'goal/CACHE_UPDATE_GOALS';
export const CACHE_UPDATE_GOALS_SET = 'goal/CACHE_UPDATE_GOALS_SET';

export const CACHE_UPDATE_USERS_GOALS = 'goal/CACHE_UPDATE_USERS_GOALS';
export const CACHE_UPDATE_USERS_GOALS_SET = 'goal/CACHE_UPDATE_USERS_GOALS_SET';

export function getAllGoalsCreator(payload) {
  return { type: GET_ALL_GOALS, payload };
}

export function addCommonGoalCreator(payload) {
  return { type: ADD_COMMON_GOAL, payload };
}

export function addGoalToUserCreator(payload) {
  return { type: ADD_GOAL_TO_USER, payload };
}

export function getUserGoalsCreator(payload) {
  return { type: GET_USER_GOALS, payload };
}

export function updateGoalAnswerCreator(payload) {
  return { type: UPDATE_GOAL_ANSWER, payload };
}

export function deleteGoalCreator(payload) {
  return { type: DELETE_GOAL, payload };
}

export function RemoveUserGoal(payload) {
  return { type: REMOVE_USER_GOAL, payload };
}
