import { put, call, takeEvery } from 'redux-saga/effects';

import * as ApiService from 'services/api';

import { incrementStatusCreator, decrementStatusCreator } from 'store/modules/Status/actions';
import * as getphyExpressActions from 'store/modules/GetphyExpress/actions';

/** ********************************************** */
/** Selectors * */

/** ********************************************** */
function* getGetphyExpressVideosSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Start get getphy express videos',
      })
    );

    const result = yield call(ApiService.GetGetphyExpressVideos, data.payload);

    yield put({
      type: getphyExpressActions.UPDATE_CACHE,
      payload: result.data.data.getphy_express_videos,
    });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Get getphy express videos finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Get getphy express videos error',
        state: 'error',
      })
    );
  }
}

function* createGetphyExpressVideoSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Start create getphy express video',
      })
    );

    yield call(ApiService.CreateGetphyExpressVideo, data.payload);

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Create getphy express video finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Create getphy express video error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* updateGetphyExpressVideoSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Start update getphy express video',
      })
    );

    yield call(ApiService.UpdateGetphyExpressVideo, data.payload.id, data.payload);

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Update getphy express video finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Update getphy express video error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* deleteGetphyExpressVideoSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Start delete getphy express video',
      })
    );

    yield call(
      ApiService.DeleteGetphyExpressVideo,
      data.payload.getphyExpressVideoId,
      data.payload
    );

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Delete getphy express video finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Delete getphy express video error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

/** Sagas * */
export default function* sagaWatcher() {
  yield takeEvery(getphyExpressActions.GET_GETPHY_EXPRESS_VIDEOS, getGetphyExpressVideosSaga);
  yield takeEvery(getphyExpressActions.CREATE_GETPHY_EXPRESS_VIDEO, createGetphyExpressVideoSaga);
  yield takeEvery(getphyExpressActions.UPDATE_GETPHY_EXPRESS_VIDEO, updateGetphyExpressVideoSaga);
  yield takeEvery(getphyExpressActions.DELETE_GETPHY_EXPRESS_VIDEO, deleteGetphyExpressVideoSaga);
}
