import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { setLocale, getLocale } from 'services/localisation';
import { itContains } from '@services/utils';

import userHasStaffRole from 'helpers/user-has-staff-role';

import { staffLanguagesCode } from 'constants/constants';

const baseClass = 'language-picker';

class LanguagePicker extends Component {
  constructor() {
    super();

    this.state = {};

    this.handleChange = this.handleChange.bind(this);
    this.getLanguagesFromRole = this.getLanguagesFromRole.bind(this);
  }

  handleChange(e) {
    let { value } = e.target;

    value = JSON.parse(value);
    setLocale(value.code);
    this.props.handleOnChange(value.id);
  }

  getLanguagesFromRole() {
    const { roles } = this.props.account;
    const isStaff = userHasStaffRole(roles);

    if (isStaff) {
      const filteredLanguages = this.props.languages.filter((el) => {
        return itContains(staffLanguagesCode, el.code);
      });
      return filteredLanguages;
    }
    return this.props.languages;
  }

  render() {
    return (
      <div className={`${baseClass}`}>
        <select onChange={this.handleChange}>
          {(this.getLanguagesFromRole() || []).map((item) => {
            return (
              <option
                value={JSON.stringify(item)}
                key={`language_${item.code}`}
                selected={item.code === getLocale()}
              >
                {item.name}
              </option>
            );
          })}
        </select>
      </div>
    );
  }
}

LanguagePicker.propTypes = {
  handleOnChange: PropTypes.func,
};

LanguagePicker.defaultProps = {
  handleOnChange: () => {},
};

const mapStateToProps = (state) => ({
  account: state.Account.user,
  statuses: state.Status.statuses,
  languages: state.Account.languages,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(LanguagePicker);
