// @flow
import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import { GREY_TEXT_COLOR } from '@shared/constants';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
  label: {
    fontStyle: 'italic',
    color: GREY_TEXT_COLOR,
    paddingRight: '0.5rem',
    fontSize: '1.1rem',
  },
  value: {
    fontSize: '1.1rem',
  }
}));

type Props = {
  labelValue: string,
  value: string,
  xs: number,
  md: number,
  required: boolean,
};

const DisplayField = ({ labelValue, value, md, xs, required }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Grid container item xs={12} sm={5} md={3}>
        <Typography className={classes.label} variant="subtitle1">
          {t(`GroupClasses.communityInformation.${labelValue}`)} {required && ('*')} :
        </Typography>
      </Grid>

      <Grid container item xs={xs} sm={7} md={md}>
        <Typography className={classes.value} variant="body1">
          {value}
        </Typography>
      </Grid>
    </>
  );
};

DisplayField.defaultProps = {
  labelValue: '',
  value: '',
  xs: 12,
  md: 3,
  required: false,
};

export default DisplayField;
