import React from 'react';

import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { uppercaseFirst } from '@shared/helpers';

import Button from '@material-ui/core/Button';

import { privateTheme } from '@layout/theme';

const useStyles = makeStyles(() => ({
  label: {
    fontFamily: 'Roboto, Georgia, serif',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '1.25rem',
    lineHeight: '1.75rem',
  },
}));

const SearchButton = ({ handleSearch, fullWidth, size }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Button
      variant="contained"
      style={privateTheme.palette.accentuated}
      classes={{ label: classes.label }}
      onClick={handleSearch}
      fullWidth={fullWidth}
      size={size}
    >
      {uppercaseFirst(t('GLOBAL.search'))}
    </Button>
  );
};

export default SearchButton;
