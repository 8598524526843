import React from 'react';

import clsx from 'clsx';

import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { uppercaseFirst, getUrlFromPages } from '@shared/helpers';

import Divider from '@material-ui/core/Divider';
import CloseIcon from '@material-ui/icons/Close';
import { Link } from 'react-router-dom';

import { getTabs } from '@header/helpers';

import { publicTheme } from '@layout/theme';

import LoginButton from './LoginButton';
import SignUpButton from './SignUpButton';
import AppBarNavItems from './AppBarNavItems';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#fff',
    boxShadow: '0 0 6px 5px rgba($black, 0.04)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: 'calc(95vh - 56px);',
    marginTop: '5vh',
    width: '100%',
    [publicTheme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  top: {
    display: 'flex',
    flexDirection: 'column',
  },
  isActive: {
    backgroundColor: '#0273bb',
  },
  linkIsActive: {
    color: '#fff !important',
  },
  navItem: {
    padding: '20px 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  linkItem: {
    color: '#0273bb',
    fontFamily: 'Roboto, Georgia, serif',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    textDecoration: 'none',
  },
  button: {
    marginTop: '10px',
    padding: '0 10px',
  },
  bottom: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100vw',
  },
  close: {
    color: '#0273bb',
    fontSize: '4.5em',
  },
  divider: {
    margin: '10px',
  },
}));

const MobileMenu = ({
  tabs,
  active,
  handleMenuClose,
  handleLogin,
  handleSignUp,
  handleProfile,
  handleLogout,
}) => {
  const { t, i18n } = useTranslation();
  const { isLoggedIn } = useSelector((state) => state.Account);
  const { searchResults } = useSelector((state) => state.Seo);
  const { language } = i18n;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.top}>
        {getTabs(tabs, active).map((tab) => (
          <div key={tab.value} className={clsx(classes.navItem, tab.isActive && classes.isActive)}>
            <Link
              to={tab.link || getUrlFromPages(searchResults, language, tab.name)}
              className={clsx(classes.linkItem, tab.isActive && classes.linkIsActive)}
            >
              {uppercaseFirst(t(tab.translationKey))}
            </Link>
          </div>
        ))}
        {isLoggedIn ? (
          <>
            <Divider className={classes.divider} />
            <AppBarNavItems handleProfile={handleProfile} handleLogout={handleLogout} />
          </>
        ) : (
          <>
            <div className={classes.button}>
              <LoginButton handleLogin={handleLogin} fullWidth size="large" outlined />
            </div>
            <div className={classes.button}>
              <SignUpButton handleSignUp={handleSignUp} fullWidth size="large" />
            </div>
          </>
        )}
      </div>
      <div className={classes.bottom}>
        <CloseIcon className={classes.close} onClick={handleMenuClose} />
      </div>
    </div>
  );
};

export default MobileMenu;
