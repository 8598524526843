import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import './style.css';

const baseClass = 'header-navlink';

class HeaderLink extends Component {
  render() {
    return (
      <li className={`${baseClass} ${this.props.isTabLink ? baseClass + '-tab-link' : ''}`}>
        {this.props.to &&
          (this.props.linkExternal ? (
            <NavLink to={{ pathname: this.props.to }} target="_blank">
              {this.props.text}
            </NavLink>
          ) : (
            <NavLink to={this.props.to}>{this.props.text}</NavLink>
          ))}

        {this.props.type === 'connectButton' && (
          <div className="connect-button" onClick={this.props.onClick}>
            {this.props.text}
          </div>
        )}
      </li>
    );
  }
}

export default HeaderLink;
