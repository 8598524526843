// @flow
import * as React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import usePrevious from '@shared/hooks/use-previous/usePrevious';

import { ThemeProvider } from '@material-ui/core/styles';
import { privateTheme } from '@layout/theme';

import { Header } from '@header/components';
import Footer from 'components/Footer';

import type { Tabs } from '@header/types';

import PromotingBanner from '@components/PromotingBanner';
import { getSeoPages } from '@store/modules/Seo/actions';
import * as promoteAction from '@store/modules/Promote/actions';
import * as prescriberAction from '@store/modules/Prescriber/actions';
import { getCountries } from '@store/modules/Resource/actions';
import { updateUserLanguage } from '@store/modules/Account/actions';
import hasLanguageChanged from '@helpers/props-watcher';
import { sendGetphyInvitations } from '@store/modules/User/actions';

import { switchLanguage } from '@store/modules/App/actions';
import userHasStaffRole from '@helpers/user-has-staff-role';

import {
  statusRefChangeLanguage,
  statusRefPrescriptorCode,
  statusRefSeoPages,
} from '@layout/constants';

import { PUBLIC_LANGUAGES, ALL_LANGUAGES } from '@core/constants';

import type { Language } from '@core/models';

type Props = {
  children: React.Node,
  activeTab: string,
  tabs: Tabs,
};

const NextPrivateLayout = ({ tabs, activeTab, children }: Props) => {
  const [languageChanged, setLanguageChanged] = React.useState(false);

  const { role, user } = useSelector((state) => state.Account);
  const promotion = useSelector((state) => state.Promote);

  const prevUser = usePrevious(user);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getCountries({}));
  }, []);

  React.useEffect(() => {
    dispatch(
      getSeoPages({
        statusRef: statusRefSeoPages,
      })
    );
  }, []);

  React.useEffect(() => {
    if (hasLanguageChanged(user, prevUser) || languageChanged) {
      dispatch(getSeoPages({}));
      setLanguageChanged(false);
    }
  }, [user, prevUser, languageChanged]);

  const handleLanguageChange = (language: Language) => {
    dispatch(switchLanguage(language.code));
    if (role) {
      setLanguageChanged(true);
      dispatch(
        updateUserLanguage({
          language_id: language.id,
          statusRef: statusRefChangeLanguage,
        })
      );
    }
  };

  const handleSubmitInvitationEmail = (email: string) => {
    dispatch(
      sendGetphyInvitations({
        email: email,
      })
    );
  };

  const availableLanguages = React.useCallback(() => {
    if (userHasStaffRole(user.roles)) {
      return PUBLIC_LANGUAGES;
    }
    return ALL_LANGUAGES;
  }, [user]);

  const handleClosePromotingBanner = () => {
    dispatch(promoteAction.hideEcoSantePromotion());
  };

  const handleSubmitPromotingBanner = () => {
    dispatch(
      prescriberAction.linkUserToPrescriberWithCode({
        code: promotion.action.prescriptorProgramCode,
        statusRef: statusRefPrescriptorCode,
      })
    );
  };

  return (
    <ThemeProvider theme={privateTheme}>
      <Header tabs={tabs} activeTab={activeTab} />
      {children}
      <Footer
        onLanguageChange={handleLanguageChange}
        onSubmitInvitationEmail={handleSubmitInvitationEmail}
        availableLanguages={availableLanguages()}
      />
      {promotion.display && (
        <PromotingBanner
          promotionText={promotion.text}
          promotionActionText={promotion.action.text}
          onClose={() => handleClosePromotingBanner()}
          onSubmit={() => handleSubmitPromotingBanner()}
        />
      )}
    </ThemeProvider>
  );
};

export default NextPrivateLayout;
