// @flow
import * as React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import type { Tabs } from '@header/types';
import type { Language } from '@core/models';

import { statusRefChangeLanguage, statusRefSeoPages, statusRef } from '@layout/constants';
import { ALL_LANGUAGES } from '@core/constants';

import values from 'lodash/values';
import { Helmet } from 'react-helmet';
import { ThemeProvider } from '@material-ui/core/styles';
import { publicTheme } from '@layout/theme';
import { Header } from '@header/components';
import Footer from 'components/Footer';
import usePrevious from '@shared/hooks/use-previous/usePrevious';
import { getSeoPages } from '@store/modules/Seo/actions';
import { switchLanguage } from '@store/modules/App/actions';
import { init, updateUserLanguage } from '@store/modules/Account/actions';
import { getCountries } from '@store/modules/Resource/actions';
import { getLocale } from '@services/localisation';
import { getSlugByName } from '@layout/helpers';
import hasLanguageChanged from '@helpers/props-watcher';
import { sendGetphyInvitations } from '@store/modules/User/actions';
import { itContains } from '@services/utils';

type Props = {
  children: React.Node,
  activeTab: string,
  tabs: Tabs,
  availableLanguages: Array<string>,
  showMenu?: boolean,
  signUpPage?: string,
  isLogoLink?: boolean,
};

const PublicLayout = ({
  tabs,
  activeTab,
  showMenu = true,
  availableLanguages = ALL_LANGUAGES,
  children,
  signUpPage,
  isLogoLink,
  isOnLandingPage = false,
  isOnIPFPage = false,
}: Props) => {
  const [languageChanged, setLanguageChanged] = React.useState(false);
  const { searchResults, seoPagesLoaded } = useSelector((state) => state.Seo);
  const { role, user } = useSelector((state) => state.Account);
  const location = useLocation();
  const dispatch = useDispatch();

  const prevUser = usePrevious(user);

  React.useEffect(() => {
    dispatch(
      init({
        statusRef: statusRef,
      })
    );
  }, []);

  React.useEffect(() => {
    dispatch(getCountries({}));
  }, []);

  React.useEffect(() => {
    dispatch(
      getSeoPages({
        statusRef: statusRefSeoPages,
      })
    );
  }, []);

  React.useEffect(() => {
    if (hasLanguageChanged(user, prevUser) || languageChanged) {
      dispatch(getSeoPages({}));
      setLanguageChanged(false);
    }
  }, [user, prevUser, languageChanged, dispatch]);

  const getSeoPageInformations = (seoPages) => {
    const { pathname } = location;

    if (seoPages && seoPages.length) {
      const seoPage = seoPages.find((seo) => `/${getLocale()}${seo.url}` === pathname);
      if (seoPage) {
        const { meta_title: metaTitle, meta_description: metaDescription } = seoPage;
        return { metaTitle, metaDescription };
      }
    }

    return undefined;
  };

  const handleLanguageChange = (language: Language) => {
    dispatch(switchLanguage(language.code));
    if (role) {
      setLanguageChanged(true);
      dispatch(
        updateUserLanguage({
          language_id: language.id,
          statusRef: statusRefChangeLanguage,
        })
      );
    }
  };

  const handleSubmitInvitationEmail = (email: string) => {
    dispatch(
      sendGetphyInvitations({
        email: email,
      })
    );
  };

  const showFooterInvite = () => {
    return !(
      itContains(
        window.location.href,
        getSlugByName(values(searchResults), 'Landing Page - IPF')
      ) ||
      itContains(window.location.href, getSlugByName(values(searchResults), 'BVS - Step')) ||
      itContains(
        window.location.href,
        getSlugByName(values(searchResults), 'Landing Page - EcoSante')
      ) ||
      itContains(
        window.location.href,
        getSlugByName(values(searchResults), 'Details Page - EcoSante')
      ) ||
      itContains(
        window.location.href,
        getSlugByName(values(searchResults), 'Landing page - Allianz')
      ) ||
      (user.prescribers || []).length > 0
    );
  };

  const showPartnersLink = () => {
    return !itContains(
      window.location.href,
      getSlugByName(values(searchResults), 'Landing page - Allianz')
    );
  };

  const seoPageInformations = getSeoPageInformations(searchResults);

  return (
    <ThemeProvider theme={publicTheme}>
      {seoPageInformations && (
        <Helmet>
          <title>{seoPageInformations.metaTitle}</title>
          <meta name="description" content={seoPageInformations.metaDescription} />
        </Helmet>
      )}
      <Header
        tabs={tabs}
        activeTab={activeTab}
        showMenu={showMenu}
        signUpPage={signUpPage}
        isLogoLink={isLogoLink}
        isOnLandingPage={isOnLandingPage}
        isOnIPFPage={isOnIPFPage}
      />
      {children}
      {seoPagesLoaded && (
        <Footer
          onLanguageChange={handleLanguageChange}
          onSubmitInvitationEmail={handleSubmitInvitationEmail}
          showFooterInvite={showFooterInvite()}
          showPartnersLink={showPartnersLink()}
          availableLanguages={availableLanguages}
        />
      )}
    </ThemeProvider>
  );
};

export default PublicLayout;
