import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import getRoleTranslation from 'helpers/get-role-translation';

import './style.css';
import { ROLE_ADMIN_REFERENSIEL } from 'services/roles';

const baseClass = 'header-role-swap';

const RoleSwap = ({ allRoles, roles, role, handleRoleChangeClick }) => {
  const [open, setOpen] = React.useState(false);
  const roleDropdown = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (roleDropdown.current && !roleDropdown.current.contains(event.target)) {
        setOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [roleDropdown]);

  const handleOpen = () => {
    setOpen(!open);
  };

  const onRoleChange = (role) => {
    setOpen(false);
    handleRoleChangeClick(role);
  };

  return (
    <div className={`${baseClass} ${open ? 'open' : ''}`} ref={roleDropdown}>
      <div className={`${baseClass}-button selected`} onClick={handleOpen}>
        {(getRoleTranslation(role) || '').substring(0, 2)}
      </div>

      {(allRoles || [])
        .filter((r) => r.id !== ROLE_ADMIN_REFERENSIEL)
        .map((item) => {
          if ((roles || []).includes(item.name)) {
            return (
              <div
                key={item.id}
                className={`${baseClass}-button ${role === item.name ? 'active' : ''}`}
                onClick={() => onRoleChange(item.name)}
              >
                {(getRoleTranslation(item.name) || '').substring(0, 2)}
              </div>
            );
          }
          return null;
        })}
    </div>
  );
};

RoleSwap.propTypes = {
  allRoles: PropTypes.array,
  roles: PropTypes.array,
  role: PropTypes.string,
};

RoleSwap.defaultProps = {
  allRoles: [],
  roles: [],
  role: 'user',
};

export default withTranslation()(RoleSwap);
