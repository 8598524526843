import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { withTranslation } from 'react-i18next';

import Icon from 'components/Icon/index';
import LanguagePicker from 'containers/LanguagePicker/index';
import ErrorMessage from 'components/ErrorMessage/index';

import { isAG2RPrescriber } from 'helpers/is-ag2r-prescriber';
import { isUserProgramme } from 'app/user/helpers';
import withSlugs from '../../layouts/withSlugs';

import './style.css';

const baseClass = 'footer-private';

class FooterPrivate extends Component {
  linkList = () => {
    const { t, getSlugByName } = this.props;

    return [
      {
        path: getSlugByName('contact'),
        label: t('Footer.contact'),
      },
    ];
  };

  render() {
    const { availableLanguages } = this.props;

    return (
      <div className={`${baseClass}`}>
        <div className={`${baseClass}-logo`}>
          <Icon src="getphy-logo-2.svg" />
        </div>

        <div className={`${baseClass}-items-right`}>
          <div className={`${baseClass}-items-right-links`}>
            {this.linkList().map((item) => {
              return (
                <Link
                  to={item.path}
                  key={item.path}
                  className={`${baseClass}-items-right-links-link-container`}
                >
                  {item.label}
                </Link>
              );
            })}
          </div>

          {!isAG2RPrescriber(this.props.user) &&
            !isUserProgramme(this.props.user) &&
            availableLanguages.length > 0 && (
              <div className={`${baseClass}-items-right-language-picker-container`}>
                <LanguagePicker handleOnChange={(value) => this.props.inputLanguageChange(value)} />
                <ErrorMessage status={this.props.statusChangeLanguage} />
              </div>
            )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(withSlugs(FooterPrivate));
