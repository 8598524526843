import { takeLatest, put, select, takeEvery } from 'redux-saga/effects';

import * as ApiService from 'services/api';
import ProtectedCall from 'services/protected.api';

import { incrementStatusCreator, decrementStatusCreator } from 'store/modules/Status/actions';
import * as goalActions from 'store/modules/Goal/actions';

/** ********************************************** */
/** Selectors * */

const allGoalsSelector = (state) => {
  return JSON.parse(JSON.stringify(state.Goal.goals));
};

const allUsersGoalsSelector = (state) => {
  return JSON.parse(JSON.stringify(state.Goal.usersGoals));
};

/** ********************************************** */
function* getAllGoalsSaga() {
  const result = yield ProtectedCall(ApiService.GetAllGoals);

  yield put({
    type: goalActions.CACHE_UPDATE_GOALS_SET,
    payload: result.data.data.goals,
  });
}

function* addGoalToUserSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'add goal to user' })
    );

    yield ProtectedCall(ApiService.AddGoalToUser, data.payload.user_id, data.payload);

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'add goal to user finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'add goal to user error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* addCommonGoalSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Create common goal' })
    );

    yield ProtectedCall(ApiService.AddCommonGoal, data.payload);

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Create common goal finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Create common goal error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* deleteGoalSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Delete goal' })
    );

    yield ProtectedCall(ApiService.DeleteGoal, data.payload.goal_id, data.payload);

    yield put(
      decrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Delete goal finished' })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Delete goal error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* RemoveUserGoalSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Remove user goal' })
    );

    yield ProtectedCall(
      ApiService.RemoveUserGoal,
      data.payload.user_id,
      data.payload.goal_id,
      data.payload
    );

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Remove user goal finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Remove user goal error',
        state: 'error',
      })
    );
  }
}

function* getGoalsSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Getting user goals' })
    );

    const results = yield ProtectedCall(ApiService.GetUserGoals, data.payload.userId, data.payload);

    yield put({
      type: goalActions.CACHE_UPDATE_USERS_GOALS,
      payload: [{ id: data.payload.userId, userGoals: results.data.data.goal_users }],
    });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting user goals finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting user goals error',
        state: 'error',
      })
    );
  }
}

function* updateGoalAnswerSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Updating goal answer' })
    );

    yield ProtectedCall(
      ApiService.UpdateGoalAnswer,
      data.payload.userId,
      data.payload.userGoalId,
      data.payload
    );

    // yield put({type: CACHE_UPDATE_USERS_GOALS, payload: [{ id: data.payload.userId,  userGoals: results.data.data.user_goals }] });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Updating goal answer finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Updating goal answer error',
        state: 'error',
      })
    );
  }
}

function* updateCacheGoalsSaga(data) {
  if (!data || !data.payload || data.payload.length === 0) {
    return;
  }

  const goals = yield select(allGoalsSelector) || {};

  data.payload.map((item) => {
    goals[item.id] = item;

    return null;
  });

  yield put({ type: goalActions.CACHE_UPDATE_GOALS_SET, payload: goals });
}

function* updateCacheUsersGoalsSaga(data) {
  if (!data || !data.payload || data.payload.length === 0) {
    return;
  }

  const usersGoals = yield select(allUsersGoalsSelector) || {};

  data.payload.map((item) => {
    usersGoals[item.id] = item;

    return null;
  });

  yield put({ type: goalActions.CACHE_UPDATE_USERS_GOALS_SET, payload: usersGoals });
}

/** Sagas * */
export default function* sagaWatcher() {
  yield takeLatest(goalActions.GET_ALL_GOALS, getAllGoalsSaga);
  yield takeEvery(goalActions.ADD_COMMON_GOAL, addCommonGoalSaga);
  yield takeEvery(goalActions.DELETE_GOAL, deleteGoalSaga);
  yield takeEvery(goalActions.ADD_GOAL_TO_USER, addGoalToUserSaga);
  yield takeEvery(goalActions.REMOVE_USER_GOAL, RemoveUserGoalSaga);

  yield takeEvery(goalActions.GET_USER_GOALS, getGoalsSaga);
  yield takeEvery(goalActions.UPDATE_GOAL_ANSWER, updateGoalAnswerSaga);

  yield takeEvery(goalActions.CACHE_UPDATE_GOALS, updateCacheGoalsSaga);
  yield takeEvery(goalActions.CACHE_UPDATE_USERS_GOALS, updateCacheUsersGoalsSaga);
}
