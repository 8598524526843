export const GET_ALL_PROGRAMS = 'program/GET_ALL_PROGRAMS';
export const START_SUGGESTED_PROGRAMS = 'program/START_SUGGESTED_PROGRAMS';

export const UPDATE_PROGRAMS_SEARCH_RESULTS = 'program/UPDATE_PROGRAMS_SEARCH_RESULTS';
export const CACHE_UPDATE_PROGRAMS = 'program/CACHE_UPDATE_PROGRAMS';
export const CACHE_UPDATE_PROGRAMS_SET = 'program/CACHE_UPDATE_PROGRAMS_SET';

export function getAllProgramsCreator(payload) {
  return { type: GET_ALL_PROGRAMS, payload };
}

export function startSuggestedPrograms(payload) {
  return { type: START_SUGGESTED_PROGRAMS, payload };
}
