// @flow
import React from 'react';

import clsx from 'clsx';

import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import { publicTheme } from '@layout/theme';

import ReinsuranceItem from './ReinsuranceItem';

import AdaptedProgramIcon from '../assets/adapted-programs-icon.svg';
import TakeChargeNowIcon from '../assets/take-charge-now-icon.svg';
import ProfessionalsIcon from '../assets/professionals-icon.svg';
import GroupLessonsIcon from '../assets/group-lessons-icon.svg';

const useStyles = makeStyles(() => ({
  root: {
    paddingBottom: '5vh',
    paddingLeft: '24px',
    paddingRight: '24px',
    width: '100vw',
    [publicTheme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
}));

const ReinsuranceSection = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const items = [
    {
      id: 0,
      src: TakeChargeNowIcon,
      alt: t('LandingPage.reinsuranceTakeChargeNowTitle'),
      title: t('LandingPage.reinsuranceTakeChargeNowTitle'),
      text: t('LandingPage.reinsuranceTakeChargeNowText'),
    },
    {
      id: 1,
      src: AdaptedProgramIcon,
      alt: t('LandingPage.adaptedProgramsTitle'),
      title: t('LandingPage.adaptedProgramsTitle'),
      text: t('LandingPage.adaptedProgramsText'),
    },
    {
      id: 2,
      src: ProfessionalsIcon,
      alt: t('LandingPage.professionalsTitle'),
      title: t('LandingPage.professionalsTitle'),
      text: t('LandingPage.professionalsText'),
    },
    {
      id: 3,
      src: GroupLessonsIcon,
      alt: t('LandingPage.groupLessonsTitle'),
      title: t('LandingPage.groupLessonsTitle'),
      text: t('LandingPage.groupLessonsText'),
    },
  ];

  return (
    <section className={clsx(classes.root)}>
      <div className={clsx(classes.container)}>
        {items.map((item) => (
          <ReinsuranceItem
            key={item.id}
            id={item.id}
            imgSrc={item.src}
            imgAlt={item.alt}
            title={item.title}
            text={item.text}
          />
        ))}
      </div>
    </section>
  );
};

export default ReinsuranceSection;
