// @flow

import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';

import { publicTheme } from '@layout/theme';

import { WHITE, NEUTRAL_COLOR } from '@shared/constants';

import TextBanner from './TextBanner';
import DoubleCaret from './DoubleCaret';

import Background from '../assets/landing-background.png';
import BackgroundMobile from '../assets/landing-background-mobile.png';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: WHITE,
    height: '80vh',
    [publicTheme.breakpoints.down('xs')]: {
      backgroundColor: NEUTRAL_COLOR,
    },
  },
  content: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    overflow: 'hidden',
    backgroundImage: `url(${Background})`,
    width: '100%',
    height: '90%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    clipPath: 'ellipse(100% 75% at 50% 25%)',
    paddingBottom: '6vh',
    [publicTheme.breakpoints.down('xs')]: {
      backgroundImage: `url(${BackgroundMobile})`,
      justifyContent: 'flex-end',
      clipPath: 'polygon(0 0, 100% 0%, 100% 93%, 0% 100%)',
      paddingBottom: '0',
    },
  },
}));

const BannerSection = () => {
  const classes = useStyles();
  return (
    <section className={clsx(classes.root)}>
      <div className={clsx(classes.content)}>
        <TextBanner />
        <DoubleCaret />
      </div>
    </section>
  );
};

export default BannerSection;
