// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';

import GroupClassMenuParticipantItem from './GroupClassMenuParticipantItem';
import type { Participant } from '../models';

const useStyles = makeStyles(() => ({
  title: {
    padding: '1rem',
    paddingBottom: '0',
    marginTop: '2rem',
    fontWeight: 'bold',
  },
  noRequestText: {
    paddingLeft: '1rem',
    fontStyle: 'italic',
  },
}));

type Props = {
  title: string,
  participants: Array<Participant>,
};

const GroupClassMenuParticipantList = ({ title, participants }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="h6" className={classes.title}>
        {t(`GroupClasses.participantMenu.${title}`)}
      </Typography>

      {participants && participants.length > 0 && (
        <List>
          {participants.map((item) => {
            return <GroupClassMenuParticipantItem participant={item} key={item.id} />;
          })}
        </List>
      )}

      {!participants && (
        <Typography variant="body1" className={classes.noRequestText}>
          {t(`GroupClasses.participantMenu.noRequest`)}
        </Typography>
      )}
    </>
  );
};

export default GroupClassMenuParticipantList;
