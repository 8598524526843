import React, { Component } from 'react';
import ReactModal from 'react-modal';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import getQueryParam from 'services/location';
import {
  FORCE_REDIRECTION_AFTERLOGIN_WORD,
  prescriberCode,
  prescriberCode2,
  prescriberCode3,
  prescriberCode3bis,
  prescriberCode4,
  prescriberCodeEcosante,
  prescriberCodeIPF,
} from 'constants/constants';
import { PRESCRIBER_CODE_ALLIANZ, URL_PATH_ALLIANZ } from 'containers/PageAllianz/constants';

import FormModal from 'components/FormModal';
import CloseButton from 'components/CloseButton';
import Button from 'components/Button';
import ErrorMessage from 'components/ErrorMessage';

import ModalForgotPassword from 'containers/modals/ModalForgotPassword';
import ModalRegister from 'containers/modals/ModalRegister';
import { itContains } from '@services/utils';

import { loginRequestCreator } from 'store/modules/Account/actions';
import { clearStatus } from 'store/modules/Status/actions';

import './style.css';
import withSlugs from '../../../layouts/withSlugs';

const statusRef = 'ModalLogin-connect';

if (process.env.NODE_ENV !== 'test') ReactModal.setAppElement('#root');

class ModalLogin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        email: '',
        password: '',
      },
      submitted: false,
      forgotPasswordModalActive: false,
      registerModalActive: props.shouldOpenSignup || false,
      redirectionAferLogin: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.handleRegister = this.handleRegister.bind(this);
    this.handleCloseRegister = this.handleCloseRegister.bind(this);

    this.handleForgotPassword = this.handleForgotPassword.bind(this);
    this.handleCloseForgotPassword = this.handleCloseForgotPassword.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(clearStatus({ statusRef: statusRef }));

    if (getQueryParam('email')) {
      this.setState({
        form: {
          email: getQueryParam('email'),
        },
      });
    }
    if (getQueryParam(FORCE_REDIRECTION_AFTERLOGIN_WORD)) {
      this.setState({
        ...this.state,
        redirectionAferLogin: getQueryParam(FORCE_REDIRECTION_AFTERLOGIN_WORD),
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.statuses[statusRef] &&
      this.props.statuses[statusRef].state === 'finished' &&
      prevProps.statuses[statusRef] &&
      prevProps.statuses[statusRef].state !== 'finshed'
    ) {
      this.props.handleClose();
    }
  }

  handleChange(e) {
    const { name, value } = e.target;

    this.setState({
      form: {
        ...this.state.form,
        [name]: value,
      },
    });
  }

  handleSubmit() {
    let data = {
      ...this.state.form,
      statusRef: statusRef,
    };

    let pathname = window.location.pathname;

    if (itContains(pathname.toLowerCase(), 'bvs')) {
      data.code = this.getPrescriberCode(pathname);
    } else if (itContains(pathname.toLowerCase(), '/ecosante')) {
      data.code = prescriberCodeEcosante;
    } else if (itContains(pathname.toLowerCase(), URL_PATH_ALLIANZ)) {
      data.code = PRESCRIBER_CODE_ALLIANZ;
    } else if (
      itContains(pathname.toLowerCase(), 'pf') ||
      itContains(pathname.toLowerCase(), 'fp') ||
      itContains(pathname.toLowerCase(), 'lf')
    ) {
      data.code = prescriberCodeIPF;
    }
    if (this.state.redirectionAferLogin) {
      data.shouldRedirectToThisUrl = this.state.redirectionAferLogin;
    }
    this.props.dispatch(loginRequestCreator(data));
  }

  handleRegister() {
    const { getSlugByName } = this.props;
    if (itContains(window.location.href.toLowerCase(), getSlugByName('Landing Page - IPF'))) {
      setTimeout(() => {
        document.querySelector('.page-ipf-form').scrollIntoView({ behavior: 'smooth' });
      }, 300);
      this.props.handleClose();
    } else if (itContains(window.location.href.toLowerCase(), '/bvs')) {
      document.querySelector('.page-ag2r-form').scrollIntoView({ behavior: 'smooth' });
      this.props.handleClose();
    } else {
      this.setState({
        registerModalActive: true,
      });
    }
  }

  handleCloseRegister() {
    this.setState({
      registerModalActive: false,
    });
  }

  handleForgotPassword() {
    this.props.dispatch(clearStatus({ statusRef: statusRef }));

    this.setState({
      forgotPasswordModalActive: true,
    });
  }

  handleCloseForgotPassword() {
    this.setState({
      forgotPasswordModalActive: false,
    });
  }

  getPrescriberCode(pathname) {
    let step = pathname.split('bvs/')[1] ? pathname.split('bvs/')[1] : ' ';
    step = itContains(step, '/') ? step.split('/')[0] : step;

    if (step) {
      switch (step) {
        case '2':
          return prescriberCode2;

        case '3':
          return prescriberCode3;

        case '3bis':
          return prescriberCode3bis;

        case '4':
          return prescriberCode4;

        default:
          return prescriberCode;
      }
    }
    return null;
  }

  render() {
    const { t, informationMessage } = this.props;
    const { email, password } = this.state.form;

    return (
      <ReactModal
        isOpen
        onRequestClose={this.props.handleClose}
        shouldCloseOnOverlayClick
        className="modal-content modal-login"
        overlayClassName="modal-overlay"
      >
        <CloseButton onClick={this.props.handleClose} />

        {this.state.forgotPasswordModalActive && (
          <ModalForgotPassword handleCloseForgotPassword={this.handleCloseForgotPassword} />
        )}

        {this.state.registerModalActive && <ModalRegister handleClose={this.handleCloseRegister} />}

        <FormModal id="login-form" className="login-form transparent" onSubmit={this.handleSubmit}>
          <h1>{t('ModalLogin.title')}</h1>

          {(informationMessage || '').length > 0 && <p>{informationMessage}</p>}

          <div className="row-container">
            <input
              type="email"
              name="email"
              value={email}
              placeholder={t('ModalLogin.email')}
              autoComplete="email"
              required
              onChange={this.handleChange}
            />
          </div>

          <div className="row-container">
            <input
              type="password"
              name="password"
              value={password}
              placeholder={t('ModalLogin.password')}
              autoComplete="password"
              required
              onChange={this.handleChange}
            />
          </div>

          <span className="login-status"></span>

          <div className="row-buttons">
            <Button
              type="submit"
              name="button"
              onClick={() => {}}
              status={this.props.statuses[statusRef]}
            >
              {t('ModalLogin.connect')}
            </Button>

            <Button
              className="button--link-type button-forgot-password"
              onClick={this.handleForgotPassword}
              type="button"
            >
              {t('ModalLogin.forgot_password')}
            </Button>

            <ErrorMessage status={this.props.statuses[statusRef]} />
          </div>
        </FormModal>

        <Button
          type="button"
          className="register-link button--link-type"
          href="/register"
          onClick={this.handleRegister}
        >
          {t('ModalLogin.register')}
        </Button>
      </ReactModal>
    );
  }
}

const mapStateToProps = (state) => ({
  theState: state,
  statuses: state.Status.statuses,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withSlugs(ModalLogin))
);
