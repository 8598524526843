import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import ReactCropperJs from 'ddm-react-cropperjs';

import Button from 'components/Button';
import './style.css';

import { withTranslation } from 'react-i18next';

class ImagePicker extends Component {
  constructor() {
    super();

    this.state = {
      inputSrc: '',
      cropperProps: {
        guides: true,
        zoomable: false,
        viewMode: 1,
        toggleDragModeOnDblclick: false,
        dragMode: 'crop',
        autoCrop: true,
        imgType: 'image/png',
      },
      fileError: [],
      showSaveButton: true,
    };

    this.handleEditClick = this.handleEditClick.bind(this);
    this.inputChange = this.inputChange.bind(this);
    this.handleCrop = this.handleCrop.bind(this);
    this.saveButtonClick = this.saveButtonClick.bind(this);
    this.cancelButtonClick = this.cancelButtonClick.bind(this);
  }

  componentDidMount() {
    if (this.props.initialImage) {
      this.setState({
        inputSrc: this.props.initialImage,
        dataUrl: this.props.initialImage,
      });
    }

    if (this.props.aspectRatio) {
      this.setState({
        cropperProps: {
          ...this.state.cropperProps,
          aspectRatio: this.props.aspectRatio,
        },
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.refresh !== prevProps.refresh) {
      this.setState({
        inputSrc: this.props.initialImage,
        dataUrl: this.props.initialImage,
      });
    }

    if (prevProps.aspectRatio !== this.props.aspectRatio) {
      this.setState({
        cropperProps: {
          ...this.state.cropperProps,
          aspectRatio: this.props.aspectRatio,
        },
      });
    }
  }

  handleEditClick() {
    this.setState({ isEditing: true });
  }

  handleCrop = (event, dataUrl) => {
    if (!this.state.showSaveButton) return;
    // this.setState({eventDetails: event.detail});
    this.setState({
      dataUrl: dataUrl,
      showSaveButton: true,
    });
  };

  saveButtonClick() {
    this.props.handleSave(this.state.dataUrl);
    this.setState({ isEditing: false });
  }

  cancelButtonClick() {
    this.props.handleCancel();
    this.setState({
      isEditing: false,
      dataUrl: this.props.initialImage,
      inputSrc: this.props.initialImage,
      fileError: [],
      showSaveButton: true,
    });
  }

  inputChange(e) {
    const { t } = this.props;

    e.preventDefault();

    let fileError = [];

    // Make sure one file was selected
    if (!e.target.files || e.target.files.length !== 1) {
      console.error('[Uploader:listen] Select only one file.');
      fileError.push(t('ImagePicker.error-1'));
    }

    if ((e.target.files[0] || {}).size > 3000000) {
      fileError.push(t('ImagePicker.error-2'));
    }

    if (fileError.length > 0) {
      this.setState({
        fileError: fileError,
      });
    } else {
      this.setState({
        fileError: [],
      });
    }

    let file = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (elem) => {
      this.setState({
        inputSrc: elem.target.result,
        showSaveButton: fileError.length > 0 ? false : true,
      });
    };
  }

  render() {
    const { t } = this.props;

    return (
      <div className={`image-picker ${this.props.className}`}>
        <div className="cropperjs-wrapper">
          <div className="preview-container"></div>

          {!this.state.isEditing && (
            <div>
              {this.state.dataUrl && (
                <div className="edit-pic" onClick={this.handleEditClick}>
                  <img src={this.state.dataUrl} className="non-editing-image" alt={'picked'} />
                </div>
              )}
              {!this.props.disabled && (
                <div className="edit-text" onClick={this.handleEditClick}>
                  {this.props.editText}
                </div>
              )}
            </div>
          )}

          {this.state.isEditing && (
            <ReactModal
              isOpen
              onRequestClose={this.cancelButtonClick}
              shouldCloseOnOverlayClick
              className="modal-content modal-image-picker image-picker-modal"
              overlayClassName="modal-overlay"
            >
              <ReactCropperJs
                /* eslint-disable-next-line react/no-string-refs */
                ref="cropper"
                src={this.state.inputSrc}
                crop={this.handleCrop}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...this.state.cropperProps}
              />

              {this.state.fileError.length > 0 && (
                <div className={`error-message `}>
                  {this.state.fileError.map((message, index) => {
                    // eslint-disable-next-line react/no-array-index-key
                    return <div key={index}>{message}</div>;
                  })}
                </div>
              )}
              <div>
                <input
                  type="file"
                  name="cropped_photo"
                  onChange={this.inputChange}
                  accept="image/jpeg,image/png,image/gif"
                />

                <label htmlFor="cropped_photo">+</label>

                <Button onClick={this.cancelButtonClick}>{t('GLOBAL.cancel')}</Button>

                <Button onClick={this.saveButtonClick} disabled={!this.state.showSaveButton}>
                  {t('GLOBAL.save')}
                </Button>
              </div>
            </ReactModal>
          )}
        </div>
      </div>
    );
  }
}

ImagePicker.propTypes = {
  handleCancel: PropTypes.func,
};

ImagePicker.defaultProps = {
  handleCancel: () => {},
};

export default withTranslation()(ImagePicker);
