import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { privateTheme } from '@layout/theme';

import GroupClassesCardsItem from './GroupClassesCardsItem';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    paddingTop: '5vh',
    [privateTheme.breakpoints.down('md')]: {
      marginTop: '3vh',
      width: '100vw',
    },
    [privateTheme.breakpoints.up('lg')]: {
      width: '960px',
      margin: '3vh auto 0',
    },
  },
}));

const GroupClassesCards = ({ groupClasses }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {groupClasses.map((item) => {
        return (
          <GroupClassesCardsItem
            key={item.id}
            name={item.name}
            place={`${item.address}, ${item.zip_code} ${item.city}`}
            coach={item.managers.length > 0 && item.managers[0].first_name}
            imgSrc={item.photo}
            imgAlt={item.imgAlt}
            day={item.day?.name}
            startTime={item.start_time && item.start_time.slice(0, 2)}
            endTime={item.end_time && item.end_time.slice(0, 2)}
            themeId={item.theme?.id}
          />
        );
      })}
    </div>
  );
};

export default GroupClassesCards;
