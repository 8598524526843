import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import PlacesAutocomplete from './components/PlacesAutocomplete';
import ZipcodeAutocomplete from './components/ZipcodeAutocomplete';

import './style.css';

class Location extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      t,
      className,
      data,
      countries,
      placeholder,
      restrictToCountry,
      defaultCountryRestriction,
      displayAddressDetails,
      user,
      displayAddress,
      required,
      onChange,
      placesAutocomplete,
      zipcodeAutocomplete,
    } = this.props;

    return (
      <div className={`location ${className || ''}`}>
        {placesAutocomplete && (
          <PlacesAutocomplete
            data={data}
            user={user}
            countries={countries}
            restrictToCountry={restrictToCountry}
            placeholder={placeholder || t('Location.PlacesAutocomplete.default-placeholder')}
            required={required}
            onChange={onChange}
            displayAddressDetails={displayAddressDetails}
          />
        )}

        {zipcodeAutocomplete && (
          <ZipcodeAutocomplete
            data={data}
            user={user}
            countries={countries}
            required={required}
            displayAddress={displayAddress}
            defaultCountryRestriction={defaultCountryRestriction}
            onChange={onChange}
          />
        )}
      </div>
    );
  }
}

Location.propTypes = {
  zipcodeAutocomplete: PropTypes.bool,
  placesAutocomplete: PropTypes.bool,
  countries: PropTypes.array,
  data: PropTypes.object,
  user: PropTypes.object,
  required: PropTypes.bool,
  displayAddress: PropTypes.bool,
  displayAddressDetails: PropTypes.bool,
  restrictToCountry: PropTypes.bool,
  defaultCountryRestriction: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
};

Location.defaultProps = {
  zipcodeAutocomplete: false,
  placesAutocomplete: false,
  countries: [],
  data: {},
  user: {},
  required: false,
  displayAddress: false,
  displayAddressDetails: false,
  restrictToCountry: false,
  defaultCountryRestriction: null,
  placeholder: '',
  onChange: () => {},
};

export default withTranslation()(Location);
