// @flow
import React, { useState } from 'react';

import clsx from 'clsx';

import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import { publicTheme } from '@layout/theme';

import LeftCaret from '../assets/left-caret.svg';
import RightCaret from '../assets/right-caret.svg';

import { GROUP_LESSONS_CARDS } from '../constants';

import GroupLessonsCardsItem from './GroupLessonsCardsItem';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: '5vh',
    paddingBottom: '5vh',
    [publicTheme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  hide: {
    display: 'none',
  },
}));

const GroupLessonsCards = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [cardDisplayed, setCardDisplayed] = useState(0);

  const displayPreviousCard = () => {
    if (cardDisplayed > 0) {
      setCardDisplayed(cardDisplayed - 1);
    }
  };

  const displayNextCard = () => {
    if (cardDisplayed < GROUP_LESSONS_CARDS.length - 1) {
      setCardDisplayed(cardDisplayed + 1);
    }
  };

  return (
    <div className={clsx(classes.root)}>
      <div>
        <img src={LeftCaret} alt="left caret" onClick={displayPreviousCard} />
      </div>
      {GROUP_LESSONS_CARDS.map((item) => (
        <GroupLessonsCardsItem
          key={item.id}
          name={t(item.name)}
          place={t(item.place)}
          coach={t(item.coach)}
          imgSrc={item.imgSrc}
          imgAlt={t(item.imgAlt)}
          className={clsx(cardDisplayed !== item.id ? classes.hide : undefined)}
        />
      ))}
      <div>
        <img src={RightCaret} alt="right caret" onClick={displayNextCard} />
      </div>
    </div>
  );
};

export default GroupLessonsCards;
