import { takeLatest, put, select, takeEvery } from 'redux-saga/effects';

import * as ApiService from 'services/api';
import ProtectedCall from 'services/protected.api';

import { incrementStatusCreator, decrementStatusCreator } from 'store/modules/Status/actions';
import * as seoActions from 'store/modules/Seo/actions';

/** ********************************************** */
/** Selectors * */

const allSeoPagesSelector = (state) => {
  return JSON.parse(JSON.stringify(state.Seo.seoPages));
};

/** ********************************************** */
/** Sagas * */
function* getSeoPagesSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'start get seo pages' })
    );

    const result = yield ProtectedCall(ApiService.SeoPagesGet);
    const {
      data: {
        data: { seo },
      },
    } = result;
    yield put({ type: seoActions.CACHE_SEO_PAGES_SEARCH_RESULTS, payload: seo });

    yield put({ type: seoActions.CACHE_SEO_PAGES, payload: seo });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'get seo pages finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'seo pages finished error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* createSeoPageSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'start create seo page',
      })
    );

    const result = yield ProtectedCall(ApiService.SeoPageCreate, data.payload);

    yield put({ type: seoActions.CACHE_SEO_PAGES, payload: [result.data.data.seo] });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'create seo page finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'create seo page error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* updateSeoPageSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'start update seo page',
      })
    );

    const result = yield ProtectedCall(ApiService.SeoPageUpdate, data.payload, data.payload.id);

    yield put({ type: seoActions.CACHE_SEO_PAGES, payload: [result.data.data.seo] });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'update seo page finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'update seo page error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* deleteSeoPageSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Delete seo page' })
    );

    yield ProtectedCall(ApiService.SeoPageDelete, data.payload.seo_id);

    yield put({ type: seoActions.CACHE_SEO_PAGES, payload: [data.payload.seo_id], delete: true });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Delete seo page finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Delete seo page error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* updateCacheSeoPagesSaga(data) {
  if (!data || !data.payload || data.payload.length === 0) {
    return;
  }

  const seoPages = yield select(allSeoPagesSelector) || {};

  if (data.delete) {
    const idToDelete = data.payload[0];

    if (idToDelete) {
      delete seoPages[idToDelete];
    }
  } else {
    data.payload.map((item) => {
      seoPages[item.id] = item;

      return null;
    });
  }

  yield put({ type: seoActions.CACHE_SEO_PAGES_SET, payload: seoPages });
}

export default function* sagaWatcher() {
  yield takeLatest(seoActions.GET_ALL_SEO_PAGES, getSeoPagesSaga);
  yield takeEvery(seoActions.CREATE_SEO_PAGE, createSeoPageSaga);
  yield takeEvery(seoActions.UPDATE_SEO_PAGE, updateSeoPageSaga);
  yield takeEvery(seoActions.DELETE_SEO_PAGE, deleteSeoPageSaga);

  yield takeEvery(seoActions.CACHE_SEO_PAGES, updateCacheSeoPagesSaga);
}
