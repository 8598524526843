import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { geocodeLatLng, getGeocodeInformationsFormated } from 'services/geocode';
import { FRANCE_ID } from 'services/country';
import { COMMUNITY_COUNTRIES } from 'app/core/constants';

import { isNil, isEqual } from 'lodash';

import './style.css';

class PlacesAutocomplete extends Component {
  constructor() {
    super();

    this.state = {
      location: {},
    };

    this.autocomplete = null;
    this.autocompleteRef = React.createRef();
  }

  componentDidMount() {
    this.init(0);
  }

  componentDidUpdate(prevProps) {
    const { user, data, countries } = this.props;

    if (!isEqual(prevProps.user, user)) {
      this.handleChangeCountryRestriction({
        target: {
          value: user?.country?.id,
        },
      });
    }

    if (!isEqual(prevProps.data?.country_id, data?.country_id)) {
      const countryId = data?.country_id;
      this.setState(
        {
          location: {
            ...this.state.location,
            country_id: countryId,
          },
        },
        () => {
          this.setAutocompleteRestriction(countryId);
        }
      );
    }

    if (!isEqual(prevProps.countries, countries)) {
      this.init(0);
    }

    if (!isEqual(prevProps.countries, countries)) {
      this.init(0);
    }
  }

  // TODO: better handling of this for when places doesn't load correctly
  init = (limit) => {
    const { restrictToCountry, countries, user, data } = this.props;
    let countryId = data.country_id || user?.country?.id || FRANCE_ID;
    let defaultCountry =
      countries.find((country) => Number(country.id) === countryId) || user?.country || {};

    if (!window.google || !window.google.maps || !window.google.maps.places) {
      console.log("window google doesn't exist, retrying");

      limit++;

      if (limit < 20) {
        setTimeout(this.init(limit), 500);
      }
      return;
    }

    const input = this.autocompleteRef.current;

    if (!input) return;

    let defaultBounds = new window.google.maps.LatLngBounds();

    let options = {
      bounds: defaultBounds,
    };

    if (restrictToCountry) {
      this.setState({
        location: {
          ...this.state.location,
          country_id: defaultCountry.id,
          country_label: defaultCountry.name,
          country_short: defaultCountry.code,
        },
      });
    }

    this.autocomplete = new window.google.maps.places.Autocomplete(input, options);
    this.autocomplete.addListener('place_changed', () => this.handlePlaceChanged());
    this.setAutocompleteRestriction(defaultCountry.id);

    window.google.maps.event.addDomListener(input, 'keyup', (event) => {
      if (event.keyCode === 13) {
        event.preventDefault();
      }

      if (event.target.value === '') {
        this.handleChangePlace({ target: { name: 'search_place', value: '' } });
      }
    });
  };

  setAutocompleteRestriction = (countryId) => {
    if (countryId) {
      const { countries } = this.props;
      const countryObject =
        countries.find((country) => Number(country.id) === Number(countryId)) || {};

      this.autocomplete.setComponentRestrictions({ country: countryObject.code });
    }
  };

  handlePlaceChanged = () => {
    const { countries, restrictToCountry } = this.props;
    let place = this.autocomplete.getPlace();

    if (place.geometry && place.place_id) {
      let locationFormatted = getGeocodeInformationsFormated(place);

      if (!locationFormatted.country_id && locationFormatted.country_short) {
        const countryObject = countries.find(
          (country) => country.code === locationFormatted.country_short
        );
        locationFormatted.country_id = countryObject?.id;
      }

      if (!locationFormatted.zip_code && !restrictToCountry) {
        // Call google geocode to get a full address : city, zipcode, country
        geocodeLatLng(
          {
            lat: locationFormatted.lat,
            lng: locationFormatted.long,
          },
          (results, status) => {
            if (status === 'OK' && results.length > 0) {
              const result = results[0];
              const geocodedData = getGeocodeInformationsFormated(result);

              this.setState(
                {
                  location: {
                    ...this.state.location,
                    city: geocodedData.city,
                    lat: geocodedData.lat,
                    long: geocodedData.long,
                    zip_code: geocodedData.zip_code,
                    country: geocodedData.country,
                    country_short: geocodedData.country_short,
                    address: '',
                    search_place: place.formatted_address,
                  },
                },
                () => this.props.onChange(this.state.location)
              );
            }
          }
        );
      } else {
        this.setState(
          {
            location: {
              ...this.state.location,
              ...locationFormatted,
              search_place: place.formatted_address,
            },
          },
          () => this.props.onChange(this.state.location)
        );
      }
    }
  };

  onBlur = (e) => {
    const { value } = e.target;

    if (value === '') {
      this.setState(
        {
          location: {
            ...this.state.location,
            search_place: '',
            city: '',
            lat: null,
            long: null,
          },
        },
        () => this.props.onChange(this.state.location)
      );
    }
  };

  handleChangeCountryRestriction = (e) => {
    const { value } = e.target;

    this.setState(
      {
        location: {
          ...this.state.location,
          country_id: value,
          search_place: '',
        },
      },
      () => {
        this.props.onChange(this.state.location);
        this.setAutocompleteRestriction(value);
      }
    );
  };

  handleChangePlace = (e) => {
    const { value } = e.target;

    this.setState({
      location: {
        ...this.state.location,
        search_place: value,
      },
    });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;

    this.setState(
      {
        location: {
          ...this.state.location,
          [name]: value,
        },
      },
      () => {
        this.props.onChange(this.state.location);
      }
    );
  };

  render() {
    const {
      t,
      restrictToCountry,
      countries,
      placeholder,
      required,
      data,
      displayAddressDetails,
      user,
    } = this.props;
    const { location } = this.state;

    const searchPlace = !isNil(location.search_place)
      ? location.search_place
      : data.location || data.place || data.city;
    const addressDetails = location.address_details || data.address_details;
    const countryId = location.country_id || FRANCE_ID;

    const countriesFiltered =
      user?.prescribers?.length === 0
        ? countries?.filter((item) => COMMUNITY_COUNTRIES.includes(item.code))
        : countries;

    return (
      <div>
        {restrictToCountry && (
          <div className="search_place_country inline-block">
            <label>{t('Location.label-country')}</label>
            <select
              name="search_place_country"
              value={countryId}
              onChange={this.handleChangeCountryRestriction}
            >
              <option value="">{t('Location.placeholder-country')}</option>
              {countriesFiltered.map((item) => {
                return (
                  <option value={item.id} key={item.id}>
                    {item.name}
                  </option>
                );
              })}
            </select>
          </div>
        )}

        <input
          className={`places-autocomplete`}
          onChange={this.handleChangePlace}
          onBlur={this.onBlur}
          name={'search_place'}
          placeholder={placeholder}
          required={required}
          value={searchPlace}
          ref={this.autocompleteRef}
        />

        {displayAddressDetails && (
          <div className="data-row">
            <label>{t('Location.PlacesAutocomplete.label-address-details')}</label>
            <input
              autoComplete="street-address2"
              name="address_details"
              type="text"
              value={addressDetails}
              onChange={this.handleInputChange}
              placeholder={t('Location.PlacesAutocomplete.placeholder-address-details')}
              maxLength={60}
            />
          </div>
        )}
      </div>
    );
  }
}

PlacesAutocomplete.propTypes = {
  data: PropTypes.object,
  countries: PropTypes.array,
  restrictToCountry: PropTypes.bool,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  displayAddressDetails: PropTypes.bool,
};

PlacesAutocomplete.defaultProps = {
  data: {},
  countries: [],
  restrictToCountry: false,
  placeholder: '',
  required: false,
  onChange: () => {},
  displayAddressDetails: false,
};

export default withTranslation()(PlacesAutocomplete);
