import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AccordionSection from './components/AccordionSection';

class Accordion extends Component {
  constructor() {
    super();

    this.state = {};

    this.handleSectionTitleClick = this.handleSectionTitleClick.bind(this);
  }

  handleSectionTitleClick(key, item) {
    this.props.handleClearStatus();

    if (item.isOpen) {
      this.props.handleCloseNotificationPanel();
    }

    this.setState((prevState) => ({
      [key]: !prevState[key],
    }));
  }

  render() {
    return (
      <div className={`accordion ${this.props.className}`}>
        {this.props.sections.map((item, index) => {
          return (
            <AccordionSection
              item={item}
              title={item.title}
              content={item.content}
              className={item.className}
              titleClick={this.handleSectionTitleClick}
              // eslint-disable-next-line react/no-array-index-key
              key={`accordion_${index}`}
              isOpen={item.isOpen ? item.isOpen : this.state[item.title]}
            />
          );
        })}
      </div>
    );
  };
}

Accordion.propTypes = {
  sections: PropTypes.array,
  handleClearStatus: PropTypes.func,
};

Accordion.defaultProps = {
  sections: [],
  handleClearStatus: () => {},
};

export default Accordion;
