import * as sessionActions from 'store/modules/Session/actions';

const initialState = {
  sessions: {},
  user_sessions: {},
  sessionEndData: {},
  suggestedSessions: [],
  searchResults: {
    sessions: [],
  },
  sessionsSDS: [],
  session_user: {},
  user_session: {},
  favoriteSessions: {
    sessions: [],
  },
  historySessions: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case sessionActions.UPDATE_HISTORY_SESSIONS:
      return {
        ...state,
        historySessions: action.payload,
      };

    case sessionActions.UPDATE_CACHE_SET_USER_SESSION:
      return {
        ...state,
        user_session: action.payload,
      };

    case sessionActions.UPDATE_CACHE_SET_SESSION_END_DATA:
      return {
        ...state,
        sessionEndData: action.payload,
      };

    case sessionActions.CACHE_UPDATE_SESSIONS_SET:
      return {
        ...state,
        sessions: action.payload,
      };

    case sessionActions.CACHE_UPDATE_SESSION_USER:
      return {
        ...state,
        session_user: action.payload,
      };

    case sessionActions.CACHE_UPDATE_SUGGESTED_SESSIONS:
      return {
        ...state,
        suggestedSessions: action.payload,
      };

    case sessionActions.UPDATE_SESSION_SEARCH_RESULTS:
      return {
        ...state,
        searchResults: action.payload,
      };

    case sessionActions.UPDATE_FAVORITE_SESSIONS:
      return {
        ...state,
        favoriteSessions: action.payload,
      };

    case sessionActions.CACHE_UPDATE_SDS_SESSIONS:
      return {
        ...state,
        sessionsSDS: action.payload,
      };

    case sessionActions.CACHE_UPDATE_PHYSICAL_TEST_LEVELS:
      return {
        ...state,
        levels: action.payload,
      };

    case sessionActions.CACHE_UPDATE_PHYSICAL_TEST_WELCOME_MESSAGE:
      return {
        ...state,
        welcomeMessage: action.payload,
      };

    case sessionActions.CACHE_UPDATE_SESSION_INTRO_MESSAGE:
      return {
        ...state,
        introMessage: action.payload.intro_message,
        introMessageEnabled: action.payload.intro_message_enabled,
      };

    default:
      return state;
  }
};
