import * as authActions from 'store/modules/Auth/actions';

const initialState = {
  isRefreshingToken: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case authActions.UPDATE_TOKEN:
      return {
        ...state,
        isRefreshingToken: true,
      };
    case authActions.UPDATE_TOKEN_SUCCESS: {
      return {
        ...state,
        isRefreshingToken: false,
      };
    }
    case authActions.UPDATE_TOKEN_ERROR: {
      return {
        ...state,
        isRefreshingToken: false,
      };
    }
    default:
      return state;
  }
};
