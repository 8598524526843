// @flow
import React from 'react';

import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';

import { isEven } from '@shared/helpers';

import { publicTheme } from '@layout/theme';

import { MAIN_TEXT_COLOR, PRIMARY_COLOR } from '@shared/constants';

type ReinsuranceItemProps = {
  id: number,
  imgSrc: string,
  imgAlt: string,
  title: string,
  text: string,
};

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [publicTheme.breakpoints.up('sm')]: {
      maxWidth: '200px',
    },
    [publicTheme.breakpoints.down('xs')]: {
      padding: '1rem',
      flexDirection: 'row',
      justifyContent: 'space-around',
      boxShadow: 'inset 2px 2px 4px rgba(0, 0, 0, 0.25)',
    },
  },
  icon: {
    height: '10vh',
    paddingBottom: '2vh',
    [publicTheme.breakpoints.down('xs')]: {
      paddingBottom: 0,
    },
  },
  even: {
    [publicTheme.breakpoints.down('xs')]: {
      order: 2,
    },
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
  },
  title: {
    fontFamily: 'Roboto, Georgia, serif',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '1.5rem',
    lineHeight: '2rem',
    color: PRIMARY_COLOR,
    textAlign: 'center',
    paddingBottom: '2vh',
    [publicTheme.breakpoints.down('xs')]: {
      textAlign: 'left',
    },
  },
  text: {
    fontFamily: 'Roboto, Georgia, serif',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '1.1rem',
    lineHeight: '1.5rem',
    color: MAIN_TEXT_COLOR,
    textAlign: 'center',
    [publicTheme.breakpoints.down('xs')]: {
      textAlign: 'left',
    },
  },
}));

const ReinsuranceItem = ({ imgSrc, imgAlt, title, text, id }: ReinsuranceItemProps) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root)}>
      <img
        className={clsx(classes.icon, isEven(parseInt(id, 10)) && classes.even)}
        src={imgSrc}
        alt={imgAlt}
      />
      <div className={clsx(classes.textContainer)}>
        <p className={clsx(classes.title)}>{title}</p>
        <p className={clsx(classes.text)}>{text}</p>
      </div>
    </div>
  );
};

export default ReinsuranceItem;
