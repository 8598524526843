// @flow
import * as coreConstants from '@core/constants';

export const SWITCH_LANGUAGE = `${coreConstants.NAME}/SWITCH_LANGUAGE`;
export const SWITCH_LANGUAGE_SET = `${coreConstants.NAME}/SWITCH_LANGUAGE_SET`;
export const SET_LANGUAGES = `${coreConstants.NAME}/SET_LANGUAGES`;

export function switchLanguage(code: string) {
  return { type: SWITCH_LANGUAGE, code: code };
}
