import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import withSlugs from 'layouts/withSlugs';

import ErrorMessage from 'components/ErrorMessage/index';

import { itContains } from '@services/utils';
import { getLocale } from 'services/localisation';
import { isAG2RPrescriber } from 'helpers/is-ag2r-prescriber';
import { isUserProgramme } from 'app/user/helpers';

import { PATHS_FACEBOOK, PATHS_SIEL_BLEU, PATHS_TWITTER, PATHS_YOUTUBE } from 'app/core/constants';
import {
  Facebook as FacebookIcon,
  Twitter as TwitterIcon,
  YouTube as YouTubeIcon,
} from '@material-ui/icons';

import './style.css';

import logo from '@assets/siel-bleu-france.svg';
import LanguagePicker from './LanguagePicker';

const baseClass = 'footer';

const filterLocale = (locale) => {
  return locale === 'es-AR' ? 'es' : locale;
};

class Footer extends Component {
  constructor() {
    super();

    this.state = {};
  }

  linkList() {
    const { t, getSlugByName } = this.props;
    const locale = getLocale();

    const links = [
      { path: PATHS_SIEL_BLEU[filterLocale(locale)], label: t('Footer.association_siel_bleu') },
      {
        path: require(`assets/docs/cgu/${filterLocale(locale)}.pdf`),
        label: t('Footer.legal_information'),
        file: true,
      },
      {
        path: require(`assets/docs/privacy_consent/${filterLocale(locale)}.pdf`),
        label: t('Footer.privacy_policy'),
        file: true,
      },
      {
        path: getSlugByName('contact'),
        label: t('Footer.contact'),
      },
    ];

    return links;
  }

  render() {
    const { onLanguageChange, availableLanguages, t } = this.props;
    const locale = getLocale();

    return (
      <div className={`${baseClass}`}>
        <div className={`${baseClass}-top`}>
          <div className={`${baseClass}-top-company`}>
            <img src={logo} alt="Logo" className={`${baseClass}-top-company-logo`} />
            <div className={`${baseClass}-top-company-social`}>
              <span>{t('Footer.follow_us_title')}:</span>
              <a href={PATHS_FACEBOOK[filterLocale(locale)]} target="_blank" rel="noreferrer">
                <FacebookIcon fontSize="large" color="inherit" />
              </a>
              <a href={PATHS_TWITTER[filterLocale(locale)]} target="_blank" rel="noreferrer">
                <TwitterIcon fontSize="large" />
              </a>
              <a href={PATHS_YOUTUBE[filterLocale(locale)]} target="_blank" rel="noreferrer">
                <YouTubeIcon fontSize="large" />
              </a>
            </div>
          </div>
          {!itContains(window.location.href, '/bvs') &&
            !itContains(window.location.href, '/ecosante') && (
              <div className={`${baseClass}-top-lang-picker`}>
                {!isAG2RPrescriber(this.props.user) &&
                  !isUserProgramme(this.props.user) &&
                  availableLanguages.length > 0 && (
                    <LanguagePicker
                      onLanguageChange={onLanguageChange}
                      availableLanguages={availableLanguages}
                    />
                  )}

                <ErrorMessage status={this.props.statusChangeLanguage} />
              </div>
            )}
        </div>
        <div className={`${baseClass}-divider`}></div>
        <div className={`${baseClass}-links`}>
          {this.linkList().map((item) => {
            return (
              <div
                className={`${baseClass}-links-link-container`}
                key={`footer-links-${item.label}`}
              >
                <a
                  href={item.path}
                  target="_blank"
                  rel="noreferrer"
                  download={item.file ? `${item.label}.pdf` : undefined}
                >
                  {item.label}
                </a>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default withTranslation()(withSlugs(Footer));
