import { allTabs } from './constants';

export function getTabs(tabs = allTabs, active = '') {
  return tabs.map((tab) => {
    const newTab = { ...tab };
    if (tab.name === active) {
      newTab.isActive = true;
    }
    return newTab;
  });
}

export default {};
