import { takeLatest, put, select, takeEvery, all } from 'redux-saga/effects';

import * as ApiService from 'services/api';
import ProtectedCall from 'services/protected.api';

import { incrementStatusCreator, decrementStatusCreator } from 'store/modules/Status/actions';
import { UPDATE_PROFILE as updateProfileCache } from 'store/modules/Account/actions';
import * as sessionActions from 'store/modules/Session/actions';

/** ********************************************** */
/** Selectors * */
const allSessionEndDataSelector = (state) => {
  return JSON.parse(JSON.stringify(state.Session.sessionEndData));
};

const allSessionsSelector = (state) => {
  return JSON.parse(JSON.stringify(state.Session.sessions));
};

const suggestedSessionsSelector = (state) => {
  return JSON.parse(JSON.stringify(state.Session.suggestedSessions));
};

const sessionUserSelector = (state) => {
  return JSON.parse(JSON.stringify(state.Session.session_user));
};

const userSessionSelector = (state) => {
  return JSON.parse(JSON.stringify(state.Session.user_session));
};

/** ********************************************** */
/** Sagas * */
function* initiateSessionFlowSaga(data) {
  yield ProtectedCall(ApiService.InitiateSessionFlow, {
    session_id: data.payload.session_user.id,
  });
}

function* sessionFlowExerciseSubmitSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Submitting session flow exercise',
      })
    );

    yield ProtectedCall(
      ApiService.SessionFlowExerciseSubmit,
      data.payload.session_user_id,
      data.payload.session_exercise_id,
      { repetitions: data.payload.repetitions, series: data.payload.series }
    );

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Submitting session flow exercise finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Submitting session flow exercise error',
        state: 'error',
      })
    );
  }
}

function* sessionFlowChallengeSubmitSaga(data) {
  yield ProtectedCall(
    ApiService.SessionFlowChallengeSubmit,
    data.payload.session_user_id,
    data.payload.session_challenge_id,
    { repetitions: data.payload.repetitions, series: data.payload.series }
  );
}

function* sessionFlowGetSessionDataSaga(data) {
  const result = yield ProtectedCall(ApiService.SessionFlowGetSessionData, data.payload.sessionId);

  yield put({
    type: sessionActions.CACHE_UPDATE_DONE_SESSION_DATA,
    payload: [{ ...result.data.data.session_user, id: data.payload.sessionId }],
  });
}

function* sessionFlowSessionSubmitSaga(data) {
  const sessions = yield select(allSessionEndDataSelector) || {};
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Submitting session flow data',
      })
    );
    const session = sessions[data.payload.session_user_id];

    if (session?.exercise_users) {
      session.exercise_users = session.exercise_users.map((ex) => {
        return {
          ...ex,
          difficulties: data.payload.exercises[String(ex.id)],
        };
      });
    }
    yield put({
      type: sessionActions.CACHE_UPDATE_DONE_SESSION_DATA,
      payload: [{ ...session, id: data.payload.session_user_id }],
    });

    const result = yield ProtectedCall(
      ApiService.SessionFlowSessionSubmit,
      data.payload.session_user_id
    );

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Submitting session flow data finished',
        data: { end_assessment_message: result.data.data.end_assessment_message },
      })
    );
  } catch (e) {
    const session = sessions[data.payload.session_user_id];
    yield put({
      type: sessionActions.CACHE_UPDATE_DONE_SESSION_DATA,
      payload: [{ ...session, id: data.payload.session_user_id }],
    });
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Submitting session flow data error',
        state: 'error',
      })
    );
  }
}

function* sessionFlowSessionFeedbackSaga(data) {
  const userSession = yield select(userSessionSelector) || {};
  try {
    const { session } = userSession;
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Submitting session flow data',
      })
    );

    if (session?.session_exercises) {
      session.session_exercises = session.session_exercises.map((ex) => {
        return {
          ...ex,
          difficulties: data.payload.exercises[String(ex.id)],
        };
      });
    }
    yield put({
      type: sessionActions.CACHE_UPDATE_DONE_SESSION_DATA,
      payload: [{ ...session, id: data.payload.session_user_id }],
    });

    const result = yield ProtectedCall(
      ApiService.SessionFlowFeedback,
      data.payload.session_user_id,
      data.payload
    );

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Submitting session flow data finished',
        data: { end_assessment_message: result.data.data.end_assessment_message },
      })
    );
  } catch (e) {
    const { session } = userSession;
    yield put({
      type: sessionActions.CACHE_UPDATE_DONE_SESSION_DATA,
      payload: [{ ...session, id: data.payload.session_user_id }],
    });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Submitting session flow data error',
        state: 'error',
      })
    );
  }
}

function* sessionFlowGetNewUserSessionSaga(data) {
  const { callback = { success: () => null, error: () => null } } = data.payload;
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Gettting new user_session',
      })
    );

    const result = yield ProtectedCall(ApiService.InitiateSessionFlow, data.payload);

    yield put({
      type: sessionActions.UPDATE_CACHE_SET_USER_SESSION,
      payload: { ...result.data.data.session_user },
    });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Gettting new user_session finished',
        data: { sessionUserId: result.data.data.session_user.id },
      })
    );
    callback.success();
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Gettting new user_session error',
        state: 'error',
      })
    );
  }
}

function* fetchPhysicalTestByLevelSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting physical test by level',
      })
    );

    const result = yield ProtectedCall(
      ApiService.FetchPhysicalTestByLevel,
      data.payload.levelId,
      data.payload
    );

    // yield put(
    //   {
    //     type:CACHE_UPDATE_SESSIONS,
    //     payload: result.data.data.sessions
    //   });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting physical test by level finished',
        data: { physicalTest: result.data.data.physical_test },
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting physical test by level error',
        state: 'error',
      })
    );
  }
}

function* getPhysicalTestToUserSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Get physical test to user',
      })
    );

    const result = yield ProtectedCall(
      ApiService.GetPhysicalTestToUser,
      data.payload.userId,
      data.payload
    );

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Get physical test to user finished',
        data: {
          physicalTestUser:
            result.data.data.physical_test_users.length > 0
              ? result.data.data.physical_test_users[0]
              : '',
        },
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Get physical test to user error',
        state: 'error',
      })
    );
  }
}

function* assignPhysicalTestToUserSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Assigning physical test to user',
      })
    );

    const result = yield ProtectedCall(
      ApiService.AssignPhysicalTestToUser,
      data.payload.userId,
      data.payload
    );

    // yield put(
    //   {
    //     type:CACHE_UPDATE_SESSIONS,
    //     payload: result.data.data.sessions
    //   });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Assigning physical test to user finished',
        data: { physicalTestUser: result.data.data.physical_test_user },
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Assigning physical test to user error',
        state: 'error',
      })
    );
  }
}

function* submitPhysicalTestForUserSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Submitting physical test for user',
      })
    );

    const result = yield ProtectedCall(
      ApiService.SubmitPhysicalTestForUser,
      data.payload.userId,
      data.payload.physicalTestUserId,
      data.payload
    );

    yield put({ type: updateProfileCache, payload: result.data.data.user });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Submitting physical test for user finished',
        data: { results: result.data.data.physical_test_user },
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Submitting physical test for user error',
        state: 'error',
      })
    );
  }
}

function* getSuggestedSessionsSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting suggested sessions',
      })
    );

    const result = yield ProtectedCall(ApiService.GetSuggestedSessions, data.payload);

    yield put({
      type: sessionActions.CACHE_UPDATE_SUGGESTED_SESSIONS,
      payload: result.data.data.suggestions,
    });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting suggested sessions finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting suggested sessions error',
        state: 'error',
      })
    );
  }
}

function* getAllSessionsSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Getting sessions' })
    );

    const result = yield ProtectedCall(ApiService.GetAllSessions, data.payload);

    yield put({
      type: sessionActions.CACHE_UPDATE_SESSIONS,
      payload: result.data.data.sessions,
    });

    yield put({
      type: sessionActions.UPDATE_SESSION_SEARCH_RESULTS,
      payload: result.data.data,
    });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting sessions finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting sessions error',
        state: 'error',
      })
    );
  }
}

function* getAllSessionsMinSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Getting sessions' })
    );

    const result = yield ProtectedCall(ApiService.GetAllSessionsMin, data.payload);

    yield put({
      type: sessionActions.UPDATE_SESSION_SEARCH_RESULTS,
      payload: result.data.data,
    });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting sessions finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting sessions error',
        state: 'error',
      })
    );
  }
}

function* getSingleSessionSaga(data) {
  const result = yield ProtectedCall(
    ApiService.GetSingleSession,
    data.payload.sessionId,
    data.payload
  );

  yield put({
    type: sessionActions.CACHE_UPDATE_SESSIONS,
    payload: [result.data.data.session],
  });
}

function* getSessionUserSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Getting session user' })
    );

    const result = yield ProtectedCall(ApiService.GetSessionUser, data.payload.id) || {};

    yield put({
      type: sessionActions.CACHE_UPDATE_SESSION_USER,
      payload: ((result.data || {}).data || {}).session_user,
    });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting session user finished',
      })
    );
    data.payload.onSuccess();
  } catch (e) {
    data.payload.onError();
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting session user error',
        state: 'error',
      })
    );
  }
}

function* getSessionsSDSSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting sac de sport sessions',
      })
    );

    // type 2 = session
    data.payload.type = 2;

    const toReturn = [];
    const results = yield ProtectedCall(ApiService.GetFolders, data.payload);

    const folders = yield all(
      results.data.data.folders.map((folder) => {
        return ProtectedCall(ApiService.GetFolder, folder.id);
      })
    );

    folders.map((folder) => {
      folder.data.data.folder.sessions.map((session) => {
        toReturn.push(session);
        return null;
      });

      return null;
    });

    yield put({ type: sessionActions.CACHE_UPDATE_SDS_SESSIONS, payload: toReturn });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting sac de sport sessions finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting sac de sport sessions error',
        state: 'error',
      })
    );
  }
}

function* getPhysicalTestLevelsSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'getting physical test levels',
      })
    );

    const results = yield ProtectedCall(ApiService.GetPhysicalTestLevels, data.payload);

    yield put({
      type: sessionActions.CACHE_UPDATE_PHYSICAL_TEST_LEVELS,
      payload: results.data.data.levels,
    });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'getting physical test levels finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'getting physical test levels error',
        state: 'error',
      })
    );
  }
}

function* getPhysicalTestWelcomeMessageSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'getting physical test welcome message',
      })
    );

    const results = yield ProtectedCall(ApiService.GetPhysicalTestWelcomeMessage);

    yield put({
      type: sessionActions.CACHE_UPDATE_PHYSICAL_TEST_WELCOME_MESSAGE,
      payload: results.data.data,
    });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'getting physical test welcome message finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'getting physical test welcome message error',
        state: 'error',
      })
    );
  }
}

function* getSessionIntroMessageSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'getting session intro message',
      })
    );

    const results = yield ProtectedCall(ApiService.GetSessionIntroMessage);

    // eslint-disable-next-line camelcase
    const { intro_message, intro_message_enabled } = results.data.data;
    const payload = { intro_message, intro_message_enabled };

    yield put({ type: sessionActions.CACHE_UPDATE_SESSION_INTRO_MESSAGE, payload });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'getting session intro message finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'getting session intro message error',
        state: 'error',
      })
    );
  }
}

function* likeSaga(data) {
  yield put(incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Like session' }));
  const { object_id: sessionId } = data.payload;
  const result = yield ProtectedCall(ApiService.Like, data.payload);

  const suggestedSessions = yield select(suggestedSessionsSelector) || [];
  const userSession = yield select(userSessionSelector);
  const sessionUser = yield select(sessionUserSelector);
  const sessionEndData = yield select(allSessionEndDataSelector) || {};

  suggestedSessions.forEach((item, index) => {
    if (item.id === sessionId) {
      suggestedSessions[index].liked = result.data.data.like_id;
    }
  });

  yield put({
    type: sessionActions.CACHE_UPDATE_SUGGESTED_SESSIONS,
    payload: suggestedSessions,
  });

  yield put({
    type: sessionActions.CACHE_UPDATE_SESSION_LIKE,
    payload: {
      session_id: sessionId,
      like_id: result.data.data.like_id,
    },
  });

  // Only if a session is started
  if (userSession.session?.id === sessionId) {
    yield put({
      type: sessionActions.UPDATE_CACHE_SET_USER_SESSION,
      payload: {
        ...userSession,
        session: { ...userSession.session, liked: result.data.data.like_id },
      },
    });
  }

  if (sessionUser.session?.id === sessionId) {
    yield put({
      type: sessionActions.CACHE_UPDATE_SESSION_USER,
      payload: {
        ...sessionUser,
        session: { ...sessionUser.session, liked: result.data.data.like_id },
      },
    });
  }

  if (sessionEndData[sessionId]) {
    sessionEndData[sessionId] = {
      ...sessionEndData[sessionId],
      liked: result.data.data.like_id,
    };
    yield put({
      type: sessionActions.UPDATE_CACHE_SET_SESSION_END_DATA,
      payload: sessionEndData,
    });
  }

  yield put(
    decrementStatusCreator({
      statusRef: data.payload.statusRef,
      message: 'Like session finished',
    })
  );
}

function* dislikeSaga(data) {
  yield put(
    incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Dislike session' })
  );
  const { object_id: sessionId, id } = data.payload;
  yield ProtectedCall(ApiService.Dislike, id, data.payload);

  const suggestedSessions = yield select(suggestedSessionsSelector) || [];
  const userSession = yield select(userSessionSelector);
  const sessionUser = yield select(sessionUserSelector);
  const sessionEndData = yield select(allSessionEndDataSelector) || {};

  suggestedSessions.forEach((item, index) => {
    if (item.id === sessionId) {
      suggestedSessions[index].liked = null;
    }
  });

  yield put({
    type: sessionActions.CACHE_UPDATE_SUGGESTED_SESSIONS,
    payload: suggestedSessions,
  });

  yield put({
    type: sessionActions.CACHE_UPDATE_SESSION_LIKE,
    payload: {
      session_id: sessionId,
      like_id: null,
    },
  });

  // Only if a session is started
  if (userSession.session?.id === sessionId) {
    yield put({
      type: sessionActions.UPDATE_CACHE_SET_USER_SESSION,
      payload: {
        ...userSession,
        session: { ...userSession.session, liked: null },
      },
    });
  }

  if (sessionUser.session?.id === sessionId) {
    yield put({
      type: sessionActions.CACHE_UPDATE_SESSION_USER,
      payload: {
        ...sessionUser,
        session: { ...sessionUser.session, liked: null },
      },
    });
  }

  if (sessionEndData[sessionId]) {
    sessionEndData[sessionId] = {
      ...sessionEndData[sessionId],
      liked: null,
    };
    yield put({
      type: sessionActions.UPDATE_CACHE_SET_SESSION_END_DATA,
      payload: sessionEndData,
    });
  }

  yield put(
    decrementStatusCreator({
      statusRef: data.payload.statusRef,
      message: 'Dislike session finished',
    })
  );
}

function* getUserFavoriteSessionsHistorySaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Get user favorite sessions history',
      })
    );

    const result = yield ProtectedCall(
      ApiService.GetUserFavoriteSessionsHistory,
      data.payload.userId,
      data.payload
    );

    yield put({ type: sessionActions.UPDATE_FAVORITE_SESSIONS, payload: result.data.data });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Get user favorite sessions history finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Get user favorite sessions history error',
        state: 'error',
      })
    );
  }
}

function* getUserSessionsHistorySaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Get user sessions history',
      })
    );

    const result = yield ProtectedCall(
      ApiService.GetUserSessionsHistory,
      data.payload.userId,
      data.payload
    );

    yield put({
      type: sessionActions.UPDATE_HISTORY_SESSIONS,
      payload: result.data.data.session_users,
    });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Get user sessions history finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Get user sessions history error',
        state: 'error',
      })
    );
  }
}

function* updateCacheSessionEndData(data) {
  if (!data || !data.payload || data.payload.length === 0) {
    return;
  }

  const sessions = yield select(allSessionEndDataSelector) || {};

  data.payload.map((item) => {
    sessions[item.id] = { ...sessions[item.id], ...item };

    return null;
  });

  yield put({ type: sessionActions.UPDATE_CACHE_SET_SESSION_END_DATA, payload: sessions });
}

function* updateCacheSessionsSaga(data) {
  if (!data || !data.payload || data.payload.length === 0) {
    return;
  }

  const sessions = yield select(allSessionsSelector) || {};

  data.payload.map((item) => {
    sessions[item.id] = item;

    return null;
  });

  yield put({ type: sessionActions.CACHE_UPDATE_SESSIONS_SET, payload: sessions });
}

function* updateCacheSessionLikeSaga(data) {
  const sessions = yield select(allSessionsSelector) || {};

  sessions[data.payload.session_id] = {
    ...sessions[data.payload.session_id],
    ...{ liked: data.payload.like_id },
  };

  yield put({ type: sessionActions.CACHE_UPDATE_SESSIONS_SET, payload: sessions });
}

export default function* sagaWatcher() {
  yield takeLatest(sessionActions.INITIATE_SESSION_FLOW, initiateSessionFlowSaga);
  yield takeEvery(sessionActions.SESSION_FLOW_EXERCISE_SUBMIT, sessionFlowExerciseSubmitSaga);
  yield takeEvery(sessionActions.SESSION_FLOW_CHALLENGE_SUBMIT, sessionFlowChallengeSubmitSaga);
  yield takeEvery(sessionActions.SESSION_FLOW_SESSION_SUBMIT, sessionFlowSessionSubmitSaga);
  yield takeEvery(sessionActions.SESSION_FLOW_GET_SESSION_DATA, sessionFlowGetSessionDataSaga);
  yield takeEvery(sessionActions.SESSION_FLOW_SESSION_FEEDBACK, sessionFlowSessionFeedbackSaga);

  yield takeEvery(
    sessionActions.SESSION_FLOW_GET_NEW_USER_SESSION,
    sessionFlowGetNewUserSessionSaga
  );

  yield takeEvery(sessionActions.FETCH_PHYSICAL_TEST_BY_LEVEL, fetchPhysicalTestByLevelSaga);
  yield takeEvery(sessionActions.GET_PHYSICAL_TEST_TO_USER, getPhysicalTestToUserSaga);
  yield takeEvery(sessionActions.ASSIGN_PHYSICAL_TEST_TO_USER, assignPhysicalTestToUserSaga);
  yield takeEvery(sessionActions.SUBMIT_PHYSICAL_TEST_FOR_USER, submitPhysicalTestForUserSaga);
  yield takeEvery(sessionActions.GET_SUGGESTED_SESSIONS, getSuggestedSessionsSaga);

  yield takeEvery(sessionActions.GET_SESSIONS_SDS, getSessionsSDSSaga);
  yield takeEvery(sessionActions.GET_PHYSICAL_TEST_LEVELS, getPhysicalTestLevelsSaga);
  yield takeEvery(
    sessionActions.GET_PHYSICAL_TEST_WELCOME_MESSAGE,
    getPhysicalTestWelcomeMessageSaga
  );
  yield takeEvery(sessionActions.GET_SESSION_INTRO_MESSAGE, getSessionIntroMessageSaga);

  yield takeEvery(sessionActions.GET_ALL_SESSIONS, getAllSessionsSaga);
  yield takeEvery(sessionActions.GET_ALL_SESSIONS_MIN, getAllSessionsMinSaga);
  yield takeEvery(sessionActions.GET_SINGLE_SESSION, getSingleSessionSaga);
  yield takeEvery(sessionActions.GET_SESSION_USER, getSessionUserSaga);
  yield takeEvery(sessionActions.CACHE_UPDATE_SESSIONS, updateCacheSessionsSaga);
  yield takeEvery(sessionActions.CACHE_UPDATE_SESSION_LIKE, updateCacheSessionLikeSaga);

  yield takeEvery(sessionActions.LIKE, likeSaga);
  yield takeEvery(sessionActions.DISLIKE, dislikeSaga);

  yield takeEvery(
    sessionActions.GET_USER_FAVORITE_SESSIONS_HISTORY,
    getUserFavoriteSessionsHistorySaga
  );
  yield takeEvery(sessionActions.GET_USER_SESSIONS_HISTORY, getUserSessionsHistorySaga);

  yield takeEvery(sessionActions.CACHE_UPDATE_DONE_SESSION_DATA, updateCacheSessionEndData);
}
