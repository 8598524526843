import i18n from '@root/src/i18n';
import { getLocale } from 'services/localisation';

export const isUserFromCountry = (user, countryCode) => {
  return user?.country?.code === countryCode;
};

export const isUserIPF = (user) => {
  return user?.prescribers?.[0]?.first_name.includes('Ipf & me');
};

export const isUserBVS = (user) => {
  return user?.prescribers?.[0]?.first_name.includes('Branchez-vous santé');
};

export const isUserProgramme = (user) => {
  return user?.prescribers?.[0]?.first_name.includes('Programme');
};

export const isUserGeneralAssessment = (user) => {
  return !user?.prescribers?.length;
};

/**
 * Note: needs refacto of Prescribers to have unique global code from DB
 */
export const isUserAllianz = (user) => {
  return user?.prescribers?.[0]?.first_name.includes('GetphY par Allianz') || false;
};

export const getUserTabs = (user, isLoggedIn, getphyExpress) => {
  let isSBCountry = user.country?.available_for_sielbleu;

  let tabs = [];

  const locale = getLocale();

  if (!isUserGeneralAssessment(user)) {
    const textSessions =
      isUserIPF(user) || isUserAllianz(user)
        ? i18n.t('NavBar.userOptions.sessionsPersonalised')
        : i18n.t('NavBar.userOptions.sessions');

    const showPrimaryQuestionnaire = user.primary_questionnaire || user.redirect_to_pq_result;
    const sessionsTabLink =
      user.physical_test ||
      user.display_combined_assessment_coach_request ||
      (!user.physical_test &&
        !user.display_combined_assessment_coach_request &&
        !showPrimaryQuestionnaire)
        ? '/sessions'
        : '/moi';

    tabs.push({
      value: 'sessions',
      text: textSessions,
      icon: 'computer-run.svg',
      link: sessionsTabLink,
      dropdown: [],
    });

    if (
      !user.physical_test &&
      !user.display_combined_assessment_coach_request &&
      !showPrimaryQuestionnaire
    ) {
      tabs.push({
        value: 'me',
        text: i18n.t('NavBar.userOptions.me'),
        icon: 'head-shoulders.svg',
        link: '/moi',
        dropdown: [],
      });
    }

    if (
      isLoggedIn &&
      !isUserFromCountry(user, 'CA') &&
      !isUserFromCountry(user, 'AR') &&
      !isUserIPF(user) &&
      !isUserAllianz(user) &&
      !isUserProgramme(user) &&
      !isUserBVS(user)
    ) {
      tabs.push({
        value: 'friends',
        text: i18n.t('NavBar.userOptions.friends'),
        icon: 'two-people-heart.svg',
        link: '/friends',
        dropdown: [],
      });
    }

    if (isLoggedIn && isUserProgramme(user)) {
      tabs.push({
        value: 'challanges',
        text: i18n.t('NavBar.userOptions.challanges'),
        icon: 'challanges.png',
        link: '/defis',
        dropdown: [],
      });
    }
  }

  if (
    locale === 'fr' &&
    isSBCountry &&
    (isUserProgramme(user) || isUserBVS(user) || isUserIPF(user))
  ) {
    tabs.push({
      value: 'courses',
      text: i18n.t('NavBar.userOptions.courses'),
      icon: 'star-rounded.svg',
      link: '/communities',
      dropdown: [],
    });
  }

  if (getphyExpress?.length > 0) {
    tabs.unshift({
      value: 'videos',
      text: i18n.t('NavBar.userOptions.videos'),
      icon: 'video-icon.svg',
      link: '/videos',
      dropdown: [],
    });
  }

  return tabs;
};

export const getCoachTabs = () => {
  return [
    {
      value: 'courses',
      text: i18n.t('NavBar.coachOptions.courses'),
      icon: 'star-rounded.svg',
      link: '/communities',
      dropdown: [],
    },
    {
      value: 'communities',
      text: i18n.t('NavBar.coachOptions.beneficiaries'),
      icon: 'two-people.svg',
      link: '/beneficiaries',
      dropdown: [],
    },
    {
      value: 'referensiel',
      text: i18n.t('NavBar.coachOptions.referensiel'),
      icon: 'referensiel-book.png',
      className: 'no-circle',
      link: process.env.REACT_APP_REFERENSIEL,
      dropdown: [],
      external: true,
    },
  ];
};

export const getManagerTabs = () => {
  return [
    {
      value: 'users',
      text: i18n.t('NavBar.managerOptions.users'),
      icon: 'two-people.svg',
      link: '/users',
      dropdown: [],
    },
    {
      value: null,
      text: i18n.t('NavBar.managerOptions.communities'),
      icon: 'star-rounded.svg',
      link: '/communities',
      dropdown: [
        {
          text: i18n.t('NavBar.adminOptions.communitiesDropdown.active_communities'),
          link: '/communities',
        },
        {
          text: i18n.t('NavBar.managerOptions.communitiesDropdown.archived_communities'),
          link: '/communities/archived',
        },
      ],
    },
    {
      value: 'todo',
      text: i18n.t('NavBar.managerOptions.request'),
      icon: 'papers.svg',
      link: '/todo',
      dropdown: [],
    },
    {
      value: 'statistics',
      text: i18n.t('NavBar.managerOptions.statistics'),
      icon: 'card-coins.svg',
      link: '/stats',
      dropdown: [],
    },
    {
      value: 'referensiel',
      text: i18n.t('NavBar.managerOptions.referensiel'),
      icon: 'referensiel-book.png',
      className: 'no-circle',
      link: process.env.REACT_APP_REFERENSIEL,
      dropdown: [],
      external: true,
    },
  ];
};

export const getAdminTabs = () => {
  return [
    {
      value: 'users',
      text: i18n.t('NavBar.adminOptions.users'),
      icon: 'two-people.svg',
      link: '/users',
      dropdown: [],
    },
    {
      value: null,
      text: i18n.t('NavBar.adminOptions.communities'),
      icon: 'star-rounded.svg',
      link: '/communities',
      dropdown: [
        {
          text: i18n.t('NavBar.adminOptions.communitiesDropdown.active_communities'),
          link: '/communities',
        },
        {
          text: i18n.t('NavBar.adminOptions.communitiesDropdown.archived_communities'),
          link: '/communities/archived',
        },
      ],
    },
    {
      value: 'todo',
      text: i18n.t('NavBar.adminOptions.todo'),
      icon: 'papers.svg',
      link: '/todo',
      dropdown: [],
    },
    {
      value: 'contents',
      text: i18n.t('NavBar.adminOptions.contents'),
      icon: 'pen.svg',
      link: '/content',
      dropdown: [
        {
          text: i18n.t('NavBar.adminOptions.contentsDropDown.objectives'),
          link: '/content/objectives',
        },
        {
          text: i18n.t('NavBar.adminOptions.contentsDropDown.public'),
          link: '/content/public',
        },
        {
          text: i18n.t('NavBar.adminOptions.contentsDropDown.prescriptor'),
          link: '/content/prescriptor',
        },
        {
          text: i18n.t('NavBar.adminOptions.contentsDropDown.getphyExpress'),
          link: '/content/getphy-express',
        },
        {
          text: i18n.t('NavBar.adminOptions.contentsDropDown.help'),
          link: '/content/content-help',
        },
        {
          text: i18n.t('NavBar.adminOptions.contentsDropDown.seo'),
          link: '/content/seo',
        },
      ],
    },
    {
      value: 'statistics',
      text: i18n.t('NavBar.adminOptions.statistics'),
      icon: 'card-coins.svg',
      link: '/stats',
      dropdown: [
        {
          text: i18n.t('NavBar.adminOptions.statsDropDown.general'),
          link: '/stats',
        },
        {
          text: i18n.t('NavBar.adminOptions.statsDropDown.dashboard'),
          link: '/stats/dashboard',
        },
      ],
    },
    {
      value: 'referensiel',
      text: i18n.t('NavBar.adminOptions.referensiel'),
      icon: 'referensiel-book.png',
      className: 'no-circle',
      link: process.env.REACT_APP_REFERENSIEL,
      dropdown: [],
      external: true,
    },
    {
      value: 'sysAdmin',
      text: i18n.t('NavBar.adminOptions.sysAdmin'),
      icon: 'pen.svg',
      link: '/sys-admin',
      dropdown: [],
    },
  ];
};

export default { isUserFromCountry };
