import { call, takeLatest, put } from 'redux-saga/effects';

import * as ApiService from 'services/api';
import { setToken, clearToken } from 'services/token';

import * as authActions from 'store/modules/Auth/actions';

/** ********************************************** */
/** Selectors * */

/** ********************************************** */
/** Sagas * */

function* updateToken() {
  try {
    const { data } = yield call(ApiService.RefreshToken);
    yield call(setToken, data.data);
    yield put({ type: authActions.UPDATE_TOKEN_SUCCESS });
  } catch (e) {
    yield call(clearToken);
    yield put({ type: authActions.UPDATE_TOKEN_ERROR });
  }
}

export default function* sagaWatcher() {
  yield takeLatest(authActions.UPDATE_TOKEN, updateToken);
}
