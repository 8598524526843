import React from 'react';

import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { privateTheme } from '@layout/theme';
import { WHITE, NEUTRAL_COLOR, MAIN_TEXT_COLOR } from '@shared/constants';

import SearchButton from './SearchButton';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: NEUTRAL_COLOR,
    borderRadius: '0.5rem',
    padding: '1rem',
    [privateTheme.breakpoints.down('md')]: {
      marginTop: '3vh',
      width: '100vw',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 0,
    },
    [privateTheme.breakpoints.up('lg')]: {
      width: '960px',
      margin: '3vh auto 0',
    },
  },
  checkboxes: {
    display: 'flex',
    flex: 1,
  },
  search: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    [privateTheme.breakpoints.down('md')]: {
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  searchField: {
    marginRight: '1rem',
    backgroundColor: WHITE,
  },
  label: {
    color: MAIN_TEXT_COLOR,
    fontFamily: 'Roboto, Georgia, serif',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '1.5rem',
    lineHeight: '2rem',
  },
}));

const SearchBanner = ({ archivedCourses, handleActiveCourses, handleArchivedCourses }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.checkboxes}>
        <FormControlLabel
          control={
            <Checkbox
              checked={!archivedCourses}
              onChange={handleActiveCourses}
              name="active"
              color="primary"
            />
          }
          label={
            <Typography component="span" className={classes.label}>
              {t('GroupClasses.activesClasses')}
            </Typography>
          }
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={archivedCourses}
              onChange={handleArchivedCourses}
              name="archived"
              color="primary"
            />
          }
          label={
            <Typography component="span" className={classes.label}>
              {t('GroupClasses.archivedClasses')}
            </Typography>
          }
        />
      </div>
      <div className={classes.search}>
        <TextField
          className={classes.searchField}
          id="search-field"
          variant="outlined"
          type="search"
          size="small"
          placeholder={t('GroupClasses.searchBarPlaceholder')}
        />
        <SearchButton size="small" />
      </div>
    </div>
  );
};

export default SearchBanner;
