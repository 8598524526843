// @flow
import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import { GREY_TEXT_COLOR } from '@shared/constants';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
  label: {
    fontStyle: 'italic',
    color: GREY_TEXT_COLOR,
    paddingRight: '0.5rem',
    fontSize: '1.1rem',
  },
  value: {
    fontSize: '1.1rem',
  }
}));

type Props = {
  index: number,
  startDate: string,
  endDate: string,
  price: string,
};

const DisplayPeriodField = ({ index, startDate, endDate, price }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Grid container item xs={12} sm={4} md={2}>
        <Typography className={classes.label} variant="body1">
          {t(`GroupClasses.communityInformation.periodTitle`)} {index + 1} :
        </Typography>
      </Grid>

      <Grid container item xs={6} sm={4} md={2}>
        <Typography className={classes.value} variant="body1">
          {startDate}
        </Typography>
      </Grid>

      <Grid container item xs={6} sm={4} md={2}>
        <Typography className={classes.value} variant="body1">
          {endDate}
        </Typography>
      </Grid>

      <Grid container item xs={4} md={2}>
        <Typography className={classes.label} variant="body1">
          {t(`GroupClasses.communityInformation.periodPrice`)} * :
        </Typography>
      </Grid>

      <Grid container item xs={12} sm={1}>
        <Typography className={classes.value} variant="body1">
          {price}€
        </Typography>
      </Grid>
    </>
  );
};

DisplayPeriodField.defaultProps = {
  index: 1,
  startDate: '',
  endDate: '',
  price: '',
};

export default DisplayPeriodField;
