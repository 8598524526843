// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';

import { normalizeTime } from '@services/time';
import { publicTheme } from '@layout/theme';
import { MAIN_TEXT_COLOR, WHITE, GREY_TEXT_COLOR } from '@shared/constants';
import { DisplayField, DisplayPeriodField } from '@input/components';

import type { Community } from '../models';

const useStyles = makeStyles(() => ({
  information: {
    backgroundColor: WHITE,
    borderRadius: '5px',
    padding: '1.5rem',
    margin: '0.5rem',
    width: 'auto',
    [publicTheme.breakpoints.up('md')]: {
      padding: '2rem 3rem',
    },
  },
  description: {
    color: MAIN_TEXT_COLOR,
    fontSize: '1.1rem',
    padding: '1.1rem',
  },
  labelDescription: {
    fontSize: '1.4rem',
    backgroundColor: WHITE,
    width: '8rem',
    fontStyle: 'italic',
    color: `${GREY_TEXT_COLOR} !important`,
  },
  labelGrid: {
    '&> .MuiGrid-item': {
      padding: '0.5rem 0',
      [publicTheme.breakpoints.up('sm')]: {
        padding: '1rem',
      },
    },
  },
  divider: {
    width: '100%',
  },
}));

type Props = {
  groupClass: Community,
};

const GroupClassInformationDisplay = ({ groupClass }: Props) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const { language } = i18n;

  let {
    theme,
    apo_identifier: apoIdentifier,
    type,
    day,
    end_time: endTime,
    city,
    address,
    address_details: addressDetails,
    zip_code: zipCode,
    start,
    start_time: startTime,
    end,
    description,
    participants,
    payment_periods: paymentPeriods,
    registration_contact: registrationContact,
    registration_terms: registrationTerms,
    except_dates: exceptDates,
  } = groupClass;

  const formatDate = (date) => {
    let formatedDate;

    if(language !== "en") {
      formatedDate = normalizeTime(date, 'date');
    } else {
      formatedDate = normalizeTime(date, 'dateEn');
    }

    return formatedDate;
  }

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  startTime = normalizeTime(startTime, 'time');
  endTime = normalizeTime(endTime, 'time');
  start = formatDate(start);
  end = formatDate(end);
  address = capitalizeFirstLetter(address.toLowerCase());
  city = capitalizeFirstLetter(city.toLowerCase());

  let fullAddress = `${address} ${zipCode} ${city}`;

  return (
    <Grid container spacing={7} className={classes.information}>
      <Grid container item spacing={3}>
        <Grid container item spacing={3} className={classes.labelGrid}>
          <DisplayField labelValue="activity" value={theme.name} required></DisplayField>
          <DisplayField labelValue="apoId" value={apoIdentifier} required></DisplayField>
        </Grid>

        <Grid container item spacing={3} className={classes.labelGrid}>
          <DisplayField labelValue="type" value={type.name} required></DisplayField>
          <DisplayField labelValue="day" value={day.name} required></DisplayField>
        </Grid>

        <Grid container item spacing={3} className={classes.labelGrid}>
          <DisplayField labelValue="address" value={fullAddress}></DisplayField>
          <DisplayField
            labelValue="hours"
            value={`${startTime} - ${endTime}`}
            required
          ></DisplayField>
        </Grid>

        <Grid container item spacing={3} className={classes.labelGrid}>
          <DisplayField labelValue="addressDetails" value={addressDetails} md={8}></DisplayField>
        </Grid>
      </Grid>

      <Grid container item spacing={2}>
        <Grid container item>
          <TextField
            id="outlined-multiline-static"
            label={t(`GroupClasses.communityInformation.description`)}
            multiline
            defaultValue={description}
            variant="outlined"
            fullWidth
            disabled
            InputProps={{
              classes: {
                disabled: classes.description,
              },
            }}
            InputLabelProps={{
              classes: {
                disabled: classes.labelDescription,
              }
            }}
          />
        </Grid>

        <Grid container item>
          <DisplayField
            labelValue="registrationTerms"
            value={registrationTerms}
            md={8}
          ></DisplayField>
        </Grid>
      </Grid>

      <Grid container item spacing={2}>
        {paymentPeriods &&
          paymentPeriods.map((item, index) => {
            return (
              <Grid container item spacing={2} key={item.id}>
                <DisplayPeriodField
                  index={index}
                  startDate={formatDate(item.start)}
                  endDate={formatDate(item.end)}
                  price={item.price}
                />
                <Divider className={classes.divider} />
              </Grid>
            );
          })}
      </Grid>

      <Grid container item spacing={3}>
        <Grid container item spacing={3} className={classes.labelGrid}>
          <DisplayField labelValue="seasonStartDate" value={start}></DisplayField>
          <DisplayField labelValue="seasonEndDate" value={end}></DisplayField>
        </Grid>

        <Grid container item spacing={3} className={classes.labelGrid}>
          <DisplayField labelValue="except" value={exceptDates} md={8}></DisplayField>
        </Grid>

        <Grid container item spacing={3} className={classes.labelGrid}>
          {participants.managers &&
            participants.managers.map((item) => {
              return (
                <Grid container item spacing={1} key={item.id}>
                  <DisplayField labelValue="coach" value={item.full_name}></DisplayField>
                  <DisplayField
                    labelValue="coachPhoneNumber"
                    value={item.coach_phone}
                  ></DisplayField>
                  <Divider className={classes.divider} key={item.id} />
                </Grid>
              );
            })}
        </Grid>

        <Grid container item spacing={3} className={classes.labelGrid}>
          <DisplayField
            labelValue="registrationContact"
            value={registrationContact}
            md={8}
          ></DisplayField>
        </Grid>
      </Grid>
    </Grid>
  );
};

GroupClassInformationDisplay.defaultProps = {
  groupClass: {},
};

export default GroupClassInformationDisplay;
