import * as userActions from 'store/modules/User/actions';

const initialState = {
  roles: [],
  users: {},
  friends: [],
  mesAmisSearchResults: {},
  usersSearchResults: [],
  userPaymentsResults: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case userActions.GET_USER_PAYMENTS_RESULTS:
      return {
        ...state,
        userPaymentsResults: action.payload,
      };
    case userActions.USER_SEARCH_RESULTS:
      return {
        ...state,
        usersSearchResults: action.payload,
      };

    case userActions.UPDATE_MES_AMIS_SEARCH_RESULTS:
      return {
        ...state,
        mesAmisSearchResults: {
          ...state.mesAmisSearchResults,
          ...action.payload,
        },
      };

    case userActions.MES_AMIS_SEARCH_CLEAR:
      return {
        ...state,
        mesAmisSearchResults: {},
      };

    case userActions.UPDATE_FRIENDS_RESULTS:
      return {
        ...state,
        friends: action.payload,
      };

    case userActions.UPDATE_USER_JOURNEY:
      return {
        ...state,
        user_journey: action.payload,
      };

    case userActions.UPDATE_USER_WEEKLY_REPORT:
      return {
        ...state,
        user_weekly_report: action.payload,
      };

    case userActions.UPDATE_CACHE_SET:
      return {
        ...state,
        users: action.payload,
      };

    case userActions.UPDATE_CACHE_ROLES:
      return {
        ...state,
        roles: action.payload,
      };
    case userActions.UPDATE_CACHE_SECTORS:
      return {
        ...state,
        sectors: action.payload,
      };
    default:
      return state;
  }
};
