import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import Button from 'components/Button';
import ConfirmMessage from 'components/ConfirmMessage';
import ErrorMessage from 'components/ErrorMessage';

import './style.css';

class Prescriptor extends Component {
  constructor() {
    super();

    this.state = {
      openConfirmation: false,
    };

    this.handleOpenModalConfirmation = this.handleOpenModalConfirmation.bind(this);
    this.handleCloseModalConfirmation = this.handleCloseModalConfirmation.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.statusRefUnlinkPrescriber &&
      (this.props.statusRefUnlinkPrescriber || {}).state === 'finished'
    ) {
      if (
        !prevProps.statusRefUnlinkPrescriber ||
        (prevProps.statusRefUnlinkPrescriber || {}).state !== 'finished'
      ) {
        setTimeout(() => {
          this.handleCloseModalConfirmation();
        }, 200);
      }
    }
  }

  handleOpenModalConfirmation() {
    this.setState({
      openConfirmation: true,
    });
  }

  handleCloseModalConfirmation() {
    this.setState({
      openConfirmation: false,
    });
  }

  render() {
    const { t } = this.props;

    return (
      <div className="profile-modal-personal-info form-profile">
        <div className="description">{t('ModalProfile.Prescriptor.description')}</div>
        <div className="data-row data-row-half">
          <label>{t('ModalProfile.Prescriptor.label')}</label>
          <input
            autoComplete="off"
            name="prescriptor_code"
            type="text"
            value={this.props.userData.prescriptor_code || ''}
            onChange={this.props.inputChange}
            maxLength={191}
            disabled={this.props.isLinkedToPrescriber}
          />
        </div>

        {((this.props.userData || {}).prescribers || []).length > 0 && (
          <div>
            {t('ModalProfile.Prescriptor.user-is-linked')}{' '}
            {this.props.userData.prescribers[0].full_name}
          </div>
        )}
        <div>
          <Button
            onClick={this.handleOpenModalConfirmation}
            disabled={!this.props.isLinkedToPrescriber}
          >
            {t('ModalProfile.Prescriptor.cancel')}
          </Button>

          <Button
            onClick={this.props.submitClick}
            status={this.props.status}
            disabled={this.props.isLinkedToPrescriber}
          >
            {t('ModalProfile.Prescriptor.submit')}
          </Button>

          <ErrorMessage status={this.props.status} />
        </div>

        {this.props.errorMessage && <div className={`alert danger`}>{this.props.errorMessage}</div>}

        {this.state.openConfirmation && (
          <ConfirmMessage
            title={`${t('ModalProfile.Prescriptor.ConfirmArchive.title-1')}${
              ((this.props.userData || {}).prescribers[0] || {}).full_name
            }${t('ModalProfile.Prescriptor.ConfirmArchive.title-2')}`}
            handleCancel={this.handleCloseModalConfirmation}
            handleConfirm={() => this.props.cancelClick(this.props.userData.prescribers[0].id)}
            status={this.props.statusRefUnlinkPrescriber}
          />
        )}
      </div>
    );
  }
}

export default withTranslation()(Prescriptor);
