import * as prescriberActions from 'store/modules/Prescriber/actions';

const initialState = {
  adherence: {},
  activities: {},
  health_questionnaires: {},
  bilans: {},
  content: {},
  satisfaction_questionnaires: {},
  prescribers: {},
  inviteCodeSearchResults: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case prescriberActions.UPDATE_CACHE_PHYSICAL_TEST:
      return {
        ...state,
        physical_tests: action.payload,
      };

    case prescriberActions.UPDATE_CACHE_SET:
      return action.payload;

    case prescriberActions.UPDATE_CACHE_PRESCRIBERS_SET:
      return {
        ...state,
        prescribers: action.payload,
      };

    case prescriberActions.UPDATE_CACHE_PRESCRIBERS_SEARCH_RESULTS_SET:
      return {
        ...state,
        prescriberSearchResults: action.payload,
      };

    case prescriberActions.UPDATE_CACHE_PRESCRIBERS_LIST_SET:
      return {
        ...state,
        prescribersList: action.payload,
      };

    case prescriberActions.SET_CODES_SEARCH_RESULTS:
      return {
        ...state,
        inviteCodeSearchResults: action.payload,
      };
    default:
      return state;
  }
};
