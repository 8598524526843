import * as statsActions from 'store/modules/Stats/actions';

const initialState = {
  adminStats: {
    levels: [],
    public_sessions: [],
  },
  payments: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case statsActions.SET_ADMIN_STATS:
      return {
        ...state,
        adminStats: action.payload,
      };

    case statsActions.SET_PAYMENTS_CACHE:
      return {
        ...state,
        payments: action.payload,
      };

    case statsActions.SET_PRESCRIBER_DASHBOARD:
      return {
        ...state,
        prescriber_dashboard: {
          ...state.prescriber_dashboard,
          ...action.payload,
        },
      };

    default:
      return state;
  }
};
