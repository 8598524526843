import * as goalActions from 'store/modules/Goal/actions';

const initialState = {
  goals: {},
  usersGoals: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case goalActions.CACHE_UPDATE_GOALS_SET:
      return {
        ...state,
        goals: action.payload,
      };

    case goalActions.CACHE_UPDATE_USERS_GOALS_SET:
      return {
        ...state,
        usersGoals: action.payload,
      };

    default:
      return state;
  }
};
