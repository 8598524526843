import { getLocale } from 'services/localisation';

import { getCountries } from 'react-phone-number-input';
// eslint-disable-next-line import/no-duplicates
import en from 'react-phone-number-input/locale/en.json';
import es from 'react-phone-number-input/locale/es.json';
import fr from 'react-phone-number-input/locale/fr.json';
import de from 'react-phone-number-input/locale/de.json';
import it from 'react-phone-number-input/locale/it.json';
// eslint-disable-next-line import/no-duplicates
import nl from 'react-phone-number-input/locale/en.json';
import pt from 'react-phone-number-input/locale/pt.json';

const COUNTRY_LABELS_TRANSLATIONS = {
  en,
  es,
  fr,
  de,
  it,
  nl,
  pt,
  'es-AR': es,
};

export const FRANCE_ID = 1;

export const FRANCE_SHORT = 'FR';

export function sortCountries() {
  const countryLabelsByLang = COUNTRY_LABELS_TRANSLATIONS[getLocale()];
  const countries = [];

  getCountries().map((country) => {
    countries.push({
      country,
      label: countryLabelsByLang[country],
    });
    return null;
  });
  countries.sort((a, b) => a.label.localeCompare(b.label));

  return countries;
}

export function getCountryIdFromPlace(countryShortCode, countries) {
  if (!countryShortCode || countries.length === 0) return null;

  let toReturn = null;

  countries.map((item) => {
    if (item.code.toLowerCase() === countryShortCode.toLowerCase()) {
      toReturn = item.id;
    }
    return null;
  });

  return toReturn;
}
