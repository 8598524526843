// @flow

import React from 'react';

import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';

import { MAIN_TEXT_COLOR, NEUTRAL_COLOR, PRIMARY_COLOR } from '@shared/constants';

import { getGroupClassesDefaultImageBasedOnActivity } from '../helpers/groupClassesCards';

type GroupClassesCardsItemProps = {
  name: string,
  place: string,
  coach: string,
  imgSrc: string,
  imgAlt: string,
  themeId: string,
  day: string,
  startTime: string,
  endTime: string,
  className?: string,
};

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: NEUTRAL_COLOR,
    borderRadius: '10px',
    boxShadow: '8px 8px 4px rgba(0, 0, 0, 0.25)',
    marginTop: '2vh',
    width: '260px',
  },
  textColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    padding: '20px',
  },
  name: {
    fontFamily: 'Roboto, Georgia, serif',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '1.1rem',
    lineHeight: '1.5rem',
    color: MAIN_TEXT_COLOR,
    paddingBottom: '1vh',
  },
  place: {
    fontFamily: 'Roboto, Georgia, serif',
    fontStyle: 'italic',
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '1.5rem',
    color: MAIN_TEXT_COLOR,
    paddingBottom: '1vh',
  },
  date: {
    fontFamily: 'Roboto, Georgia, serif',
    fontStyle: 'italic',
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '1.5rem',
    color: PRIMARY_COLOR,
    paddingBottom: '1vh',
  },
  coach: {
    fontFamily: 'Roboto, Georgia, serif',
    fontWeight: 400,
    fontSize: '0.85rem',
    lineHeight: '1.2rem',
    color: MAIN_TEXT_COLOR,
    '& span': {
      textDecoration: 'underline',
    },
  },
  thumbnail: {
    width: '260px',
    height: '200px',
    objectFit: 'fill',
    borderRadius: '10px 10px 0 0 ',
  },
}));

const GroupClassesCardsItem = ({
  name,
  place,
  coach,
  imgSrc,
  imgAlt,
  themeId,
  day,
  startTime,
  endTime,
  className,
}: GroupClassesCardsItemProps) => {
  const classes = useStyles();

  const img = imgSrc
    ? `${process.env.REACT_APP_STORAGE_URL || ''}${imgSrc}`
    : getGroupClassesDefaultImageBasedOnActivity(themeId);

  return (
    <div className={clsx(classes.root, className)}>
      <div>
        <img className={clsx(classes.thumbnail)} src={img} alt={imgAlt} />
      </div>
      <div className={clsx(classes.textColumn)}>
        <p className={clsx(classes.name)}>{name}</p>
        <p className={clsx(classes.place)}>{place}</p>
        {day && <p className={clsx(classes.date)}>{`${day} ${startTime}h - ${endTime}h`}</p>}
        {coach && (
          <p className={clsx(classes.coach)}>
            Avec <span>{coach}</span>
          </p>
        )}
      </div>
    </div>
  );
};

export default GroupClassesCardsItem;
