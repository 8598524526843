export const ADMIN_ROLE = 'admin';
export const USER_ROLE = 'user';
export const COACH_ROLE = 'coach';
export const MANAGER_ROLE = 'manager';
export const PRESCRIBER_ROLE = 'prescriber';

export const staffLanguagesCode = ['es', 'fr', 'en'];
export const SIGNUP_MODAL_WORD_OPENER_FROM_URL = 'signup';
export const LOGIN_MODAL_WORD_OPENER_FROM_URL = 'login';
export const FORCE_REDIRECTION_AFTERLOGIN_WORD = 'redirectionAferLogin';

export const prescriberCode = 'BVS749730';
export const prescriberCode2 = 'BVS369235';
export const prescriberCode3 = 'BVS195257';
export const prescriberCode3bis = 'BVS885795';
export const prescriberCode4 = 'BVS368293';
export const prescriberCodeEcosante = 'ECO552359';
export const prescriberCodeIPF = 'IPF691212';

export const SCREEN_MOBILE_MAX = 667;
