import store from 'store';

export const getLanguageById = (id) => {
  const state = store.getState();

  const languages = state.Account.languages;

  return languages.find((item) => item.id === id);
};

export default { getLanguageById };
