import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './style.css';

class Icon extends Component {
  render() {
    // eslint-disable-next-line global-require
    const imgSrc = require('assets/' + this.props.src);
    return (
      <img
        className={`icon ${this.props.className}`}
        src={imgSrc}
        onClick={this.props.onClick}
        alt={this.props.src}
      />
    );
  }
}

Icon.propTypes = {
  onClick: PropTypes.func,
};

Icon.defaultProps = {
  onClick: () => {},
};

export default Icon;
