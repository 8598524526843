import { takeEvery, takeLatest, call, put, select } from 'redux-saga/effects';

import * as ApiService from 'services/api';
import ProtectedCall from 'services/protected.api';
import { getLocale, getLocaleLanguageAndRegion, setLocale } from 'services/localisation';

import { incrementStatusCreator, decrementStatusCreator } from 'store/modules/Status/actions';
import * as articleActions from 'store/modules/Article/actions';

/** ********************************************** */
/** Selectors * */

const articleSelector = (state) => {
  return JSON.parse(JSON.stringify(state.Article.article));
};

const allArticlesSelector = (state) => {
  return JSON.parse(JSON.stringify(state.Article.articles));
};

const blogSearchResultsSelector = (state) => {
  return JSON.parse(JSON.stringify(state.Article.blogSearchResults));
};

/** ********************************************** */

function* getArticlesSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting blog articles',
      })
    );

    const result = yield call(ApiService.GetArticles, data.payload);

    result.data.data.articles = result.data.data.articles.map((a) => {
      a.slug = (a.translations[getLocale()] || {}).slug || '';
      return a;
    });

    yield put({
      type: articleActions.UPDATE_CACHE_SET,
      articleType: `${data.payload.type}SearchResults`,
      payload: result.data.data,
    });

    yield put({
      type: articleActions.UPDATE_CACHE_ARTICLES,
      articleType: data.payload.type,
      payload: result.data.data.articles,
    });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting blog articles finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting blog articles error',
        state: 'error',
        data: e.response.data.error,
      })
    );
  }
}

function* getBlogSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting blog',
      })
    );

    const result = yield call(ApiService.GetBlog, data.payload);

    result.data.data.articles = result.data.data.articles.map((a) => {
      a.slug = (a.translations[getLocaleLanguageAndRegion().language] || {}).slug || '';
      return a;
    });

    yield put({
      type: articleActions.UPDATE_CACHE_SET,
      articleType: `${data.payload.type}SearchResults`,
      payload: result.data.data,
    });

    yield put({
      type: articleActions.UPDATE_CACHE_ARTICLES,
      articleType: data.payload.type,
      payload: result.data.data.articles,
    });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting blog articles finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting blog articles error',
        state: 'error',
        data: e.response.data.error,
      })
    );
  }
}

function* getArticleToEditSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting details of one article',
      })
    );

    const result = yield call(ApiService.GetArticleToEdit, data.payload.id, data.payload);

    yield put({
      type: articleActions.UPDATE_CACHE_ARTICLE_SET,
      payload: result.data.data,
    });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting details of one article finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting details of one article error',
        state: 'error',
        data: e.response.data.error,
      })
    );
  }
}

function* getArticleToShowSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting details of one article to show to user',
      })
    );

    const result = yield call(ApiService.GetArticleToShow, data.payload.slug);

    if (
      !data.payload.keepOldLanguage &&
      getLocaleLanguageAndRegion().language !== result.data.data.locale
    ) {
      setLocale(result.data.data.locale);
    }

    result.data.data.slug =
      result.data.data.translations[getLocaleLanguageAndRegion().language].slug;

    yield put({
      type: articleActions.UPDATE_CACHE_ARTICLE_SET,
      payload: result.data.data,
    });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting details of one article to show to user finished',
      })
    );
    if (data.payload.onSuccess) {
      data.payload.onSuccess();
    }
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting details of one article to show to user error',
        state: 'error',
        data: e.response.data.error,
      })
    );
    if (data.payload.onFailure) {
      data.payload.onFailure();
    }
  }
}

function* updateArticleSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Updating article' })
    );

    yield ProtectedCall(ApiService.UpdateArticle, data.payload.id, data.payload);

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Updating article finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Updating article error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* togglePublishedArticleSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Updating article' })
    );

    yield ProtectedCall(ApiService.TogglePublishedArticle, data.payload.id, data.payload);

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Updating article finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Updating article error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* createArticleSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Creating article' })
    );

    yield ProtectedCall(ApiService.CreateArticle, data.payload);

    // yield put(
    //   {
    //     type: UPDATE_CACHE,
    //     articleType: 'searchResults', payload: result.data.data.articles
    //   });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Creating article finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Creating article error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* deleteArticleSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Delete article' })
    );

    yield ProtectedCall(ApiService.DeleteArticle, data.payload.id);

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Delete article finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Delete article error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* likeSaga(data) {
  const result = yield ProtectedCall(ApiService.Like, data.payload);
  const blogSearchResults = yield select(blogSearchResultsSelector) || { articles: [] };

  blogSearchResults.articles.map((item, index) => {
    if (item.id === data.payload.object_id) {
      blogSearchResults.articles[index].liked = result.data.data.like_id;
    }
    return null;
  });

  yield put({
    type: articleActions.UPDATE_CACHE_SET,
    articleType: 'blogSearchResults',
    payload: blogSearchResults,
  });

  yield put({
    type: articleActions.UPDATE_CACHE_ARTICLE_LIKE,
    articleType: 'blog',
    payload: {
      articleId: data.payload.object_id,
      likeId: result.data.data.like_id,
    },
  });
}

function* dislikeSaga(data) {
  yield ProtectedCall(ApiService.Dislike, data.payload.id, data.payload);

  const blogSearchResults = yield select(blogSearchResultsSelector) || { articles: [] };

  blogSearchResults.articles.map((item, index) => {
    if (item.id === data.payload.object_id) {
      blogSearchResults.articles[index].liked = null;
    }
    return null;
  });

  yield put({
    type: articleActions.UPDATE_CACHE_SET,
    articleType: 'blogSearchResults',
    payload: blogSearchResults,
  });

  yield put({
    type: articleActions.UPDATE_CACHE_ARTICLE_LIKE,
    articleType: 'blog',
    payload: {
      articleId: data.payload.object_id,
      likeId: null,
    },
  });
}

function* updateCacheArticlesSaga(data) {
  if (!data?.payload || !data.payload.length) {
    return;
  }

  const articles = yield select(allArticlesSelector) || {};

  if (!articles[data.articleType]) {
    articles[data.articleType] = {};
  }

  data.payload.map((item) => {
    articles[data.articleType][item.id] = item;
    return null;
  });

  yield put({ type: articleActions.UPDATE_CACHE_ARTICLES_SET, payload: articles });
}

function* updateCacheArticleLikeSaga(data) {
  const { articleType, payload } = data;
  const { likeId, articleId } = payload;

  const articles = yield select(allArticlesSelector) || {};

  articles[articleType][articleId] = {
    ...articles[articleType][articleId],
    ...{ liked: likeId },
  };

  yield put({ type: articleActions.UPDATE_CACHE_ARTICLES_SET, payload: articles });

  const article = yield select(articleSelector);
  const updatedArticle = { ...article, liked: likeId };

  yield put({ type: articleActions.UPDATE_CACHE_ARTICLE_SET, payload: updatedArticle });
}

/** Sagas * */
export default function* sagaWatcher() {
  yield takeLatest(articleActions.GET_BLOG_ARTICLES, getArticlesSaga);
  yield takeLatest(articleActions.GET_ARTICLES_IN_BLOG, getBlogSaga);
  yield takeLatest(articleActions.GET_FAQ_ARTICLES, getArticlesSaga);

  yield takeEvery(articleActions.GET_ARTICLE_TO_EDIT, getArticleToEditSaga);
  yield takeEvery(articleActions.GET_ARTICLE_TO_SHOW, getArticleToShowSaga);

  yield takeEvery(articleActions.CREATE_ARTICLE, createArticleSaga);
  yield takeEvery(articleActions.UPDATE_ARTICLE, updateArticleSaga);
  yield takeEvery(articleActions.TOGGLE_PUBLISHED_ARTICLE, togglePublishedArticleSaga);
  yield takeEvery(articleActions.DELETE_ARTICLE, deleteArticleSaga);

  yield takeEvery(articleActions.LIKE, likeSaga);
  yield takeEvery(articleActions.DISLIKE, dislikeSaga);

  yield takeEvery(articleActions.UPDATE_CACHE_ARTICLES, updateCacheArticlesSaga);
  yield takeEvery(articleActions.UPDATE_CACHE_ARTICLE_LIKE, updateCacheArticleLikeSaga);
}
