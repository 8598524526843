// @flow
import React from 'react';
import { useHistory } from 'react-router-dom';

import clsx from 'clsx';
import { privateTheme, publicTheme } from '@layout/theme';

import { makeStyles } from '@material-ui/core/styles';

import { MAIN_TEXT_COLOR, NEUTRAL_COLOR } from '@shared/constants';

import Button from '@material-ui/core/Button';

type OnlineProgramsCardItemFrProps = {
  title: string,
  imgSrc: string,
  redirect: string,
};

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: NEUTRAL_COLOR,
    borderRadius: '10px',
    boxShadow: '8px 8px 4px rgba(0, 0, 0, 0.25)',
    justifyContent: 'space-between',
    width: '195px',
    cursor: 'pointer',
    [privateTheme.breakpoints.down('sm')]: {
      marginBottom: '20px',
    },
  },
  textColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    padding: '20px',
    textAlign: 'center',
    height: '80px',
    fontFamily: 'Chevin Demi Bold, sans-serif',
  },
  name: {
    fontFamily: 'Roboto, Georgia, serif',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '1.1rem',
    lineHeight: '1.5rem',
    color: MAIN_TEXT_COLOR,
    paddingBottom: '1vh',
  },
  place: {
    fontFamily: 'Roboto, Georgia, serif',
    fontStyle: 'italic',
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '1.5rem',
    color: MAIN_TEXT_COLOR,
    paddingBottom: '2vh',
  },
  coach: {
    fontFamily: 'Roboto, Georgia, serif',
    fontWeight: 400,
    fontSize: '0.85rem',
    lineHeight: '1.2rem',
    color: MAIN_TEXT_COLOR,
    '& span': {
      textDecoration: 'underline',
    },
  },
  thumbnail: {
    width: '195px',
    height: '180px',
    borderRadius: '10px 10px 0 0 ',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 50%',
    [privateTheme.breakpoints.down('sm')]: {
      maxWidth: '195px',
    },
  },
  buttonRoot: {
    margin: '15px auto',
  },
  buttonLabel: {
    fontFamily: 'Roboto, Georgia, serif',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '1.25rem',
    lineHeight: '1.75rem',
  },
}));

const OnlineProgramsCardItemFr = ({
  title,
  imgSrc,
  imgAlt,
  redirect,
}: OnlineProgramsCardItemFrProps) => {
  const classes = useStyles();
  const history = useHistory();

  const handleRedirect = (redirectUrl) => {
    if (redirectUrl) history.push(redirectUrl);
  };

  return (
    <div className={clsx(classes.root)} onClick={() => handleRedirect(redirect)}>
      <div>
        <img className={clsx(classes.thumbnail)} src={imgSrc} alt={imgAlt} />
      </div>
      <div className={clsx(classes.textColumn)}>{title}</div>
      <Button
        variant="contained"
        style={publicTheme.palette.accentuated}
        classes={{ root: classes.buttonRoot, label: classes.buttonLabel }}
        size="large"
        onClick={() => handleRedirect(redirect)}
      >
        En savoir +
      </Button>
    </div>
  );
};

export default OnlineProgramsCardItemFr;
