// @flow
import React, { type Node } from 'react';

export default function getStatusErrors(sRef: string, statusStore: Object): Node | null {
  if (!statusStore[sRef]) return null;
  if (!statusStore[sRef].data) return null;
  if (!statusStore[sRef].data.errors) return null;

  const { errors } = statusStore[sRef].data;
  let toReturn = '';

  if (typeof errors === 'object') {
    Object.keys(errors).forEach((key) => {
      const item = errors[key];
      toReturn += `${item} <br />`;
    });
  }

  if (Array.isArray(errors)) {
    errors.forEach((key) => {
      const item = errors[key];
      toReturn += `${item} <br />`;
    });
  }

  return <div dangerouslySetInnerHTML={{ __html: toReturn }} />;
}

export function getResponseErrors(errors: Object): Node | null {
  if (!errors) return null;

  const toReturn = [];

  if (errors.error) {
    toReturn.push(<div>{errors.error}</div>);
  } else if (errors.errors && typeof errors.errors === 'object') {
    Object.keys(errors.errors).forEach((key) => {
      const item = errors.errors[key];
      toReturn.push(<div>{item}</div>);
    });
  } else if (errors.message) {
    toReturn.push(<div>{errors.message}</div>);
  }

  return toReturn;
}
