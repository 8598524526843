// @flow

import React, { Component } from 'react';
import moment from 'moment';

import { withTranslation } from 'react-i18next';
import { getDays, getYears } from 'helpers/dates';
import Box from '@material-ui/core/Box';

import './style.css';

const baseClass = 'input-birthdate';

type Props = {
  birthdate: moment | null,
  field: string,
  label: string,
  isRequired: boolean,
  onChange: (event: SyntheticInputEvent<EventTarget>) => void,
};

type State = {
  day: string,
  month: string,
  year: string,
};

class InputBirthdate extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      day: '',
      month: '',
      year: '',
    };
  }

  componentDidMount = (): void => {
    const { birthdate } = this.props;

    if (birthdate) {
      this.setState({
        ...this.initState(),
      });
    }
  };

  componentDidUpdate = (prevProps: Props): void => {
    const { birthdate: prevBirthdate } = prevProps;
    const { birthdate } = this.props;

    if (prevBirthdate !== birthdate) {
      this.setState({
        ...this.initState(),
      });
    }
  };

  initState = (): State => {
    const { birthdate } = this.props;

    return {
      day: birthdate ? birthdate.format('DD') : '',
      month: birthdate ? birthdate.format('MM') : '',
      year: birthdate ? birthdate.format('YYYY') : '',
    };
  };

  handleChange = (event: SyntheticInputEvent<EventTarget>): void => {
    event.preventDefault();
    const { value: newValue, name } = event.target;

    this.setState(
      {
        [name]: newValue,
      },
      () => {
        this.handleBirthdateChange();
      }
    );
  };

  handleBirthdateChange = (): void => {
    const { field, onChange } = this.props;
    const { day, month, year } = this.state;

    if (day.length > 0 && month.length > 0 && year.length > 0) {
      onChange(field, `${year}-${month}-${day}`);
    } else {
      onChange(field, '');
    }
  };

  render() {
    const { t, label, isRequired } = this.props;
    const { day, month, year } = this.state;

    return (
      <Box mb={1} className={baseClass} display="flex" flexDirection="row" alignItems="flex-end">
        <Box mr={2} className={`${baseClass}-column`}>
          <label>{label}</label>

          <select
            name="day"
            autoComplete="off"
            onChange={this.handleChange}
            value={day}
            required={isRequired}
          >
            <option value="" key="day_empty">
              {t('GLOBAL.day-label')}
            </option>
            {getDays().map((d) => (
              <option value={d.value} key={d.value}>
                {d.label}
              </option>
            ))}
          </select>
        </Box>

        <Box mr={2} className={`${baseClass}-column`}>
          <select name="month" onChange={this.handleChange} value={month} required={isRequired}>
            <option value="" key="month_empty">
              {t('GLOBAL.month-label')}
            </option>
            <option value="01" key="01">
              {t('GLOBAL.month.january')}
            </option>
            <option value="02" key="02">
              {t('GLOBAL.month.february')}
            </option>
            <option value="03" key="03">
              {t('GLOBAL.month.march')}
            </option>
            <option value="04" key="04">
              {t('GLOBAL.month.april')}
            </option>
            <option value="05" key="05">
              {t('GLOBAL.month.may')}
            </option>
            <option value="06" key="06">
              {t('GLOBAL.month.june')}
            </option>
            <option value="07" key="07">
              {t('GLOBAL.month.july')}
            </option>
            <option value="08" key="08">
              {t('GLOBAL.month.august')}
            </option>
            <option value="09" key="09">
              {t('GLOBAL.month.september')}
            </option>
            <option value="10" key="10">
              {t('GLOBAL.month.october')}
            </option>
            <option value="11" key="11">
              {t('GLOBAL.month.november')}
            </option>
            <option value="12" key="12">
              {t('GLOBAL.month.december')}
            </option>
          </select>
        </Box>

        <Box className={`${baseClass}-column`}>
          <select
            name="year"
            autoComplete="off"
            value={year}
            onChange={this.handleChange}
            required={isRequired}
          >
            <option value="" key="year_empty">
              {t('GLOBAL.year-label')}
            </option>
            {getYears().map((y) => (
              <option value={y.value} key={y.value}>
                {y.label}
              </option>
            ))}
          </select>
        </Box>
      </Box>
    );
  }
}

InputBirthdate.defaultProps = {
  birthdate: null,
  field: '',
  label: '',
  isRequired: false,
  onChange: () => {},
};

export default withTranslation()(InputBirthdate);
