export const ADD_ECOSANTE_PROMOTION = 'promote/ADD_ECOSANTE_PROMOTION';
export const DISPLAY_PROMOTION = 'promote/DISPLAY_PROMOTION';
export const HIDE_PROMOTION = 'promote/HIDE_PROMOTION';

export function addEcoSantePromotion() {
  return { type: ADD_ECOSANTE_PROMOTION };
}

export function displayEcoSantePromotion() {
  return { type: DISPLAY_PROMOTION };
}

export function hideEcoSantePromotion() {
  return { type: HIDE_PROMOTION };
}
