import React from 'react';
import clsx from 'clsx';

import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import Icon from '@components/Icon';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '46px',
    height: '46px',
    backgroundSize: 'cover',
    backgroundPosition: '50% 50%',
    borderRadius: '50%',
    cursor: 'pointer',
  },
  avatar: {
    borderRadius: '50%',
  },
}));

const Avatar = () => {
  const classes = useStyles();
  const imagePath = process.env.REACT_APP_IMAGE_URL;
  const { photo } = useSelector((state) => state.Account.user);
  return (
    <div className={clsx(classes.root)}>
      {photo ? (
        <img className={clsx(classes.avatar)} src={imagePath + photo} alt="avatar" />
      ) : (
        <Icon src="default-avatar.svg" />
      )}
    </div>
  );
};

export default Avatar;
