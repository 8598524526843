// @flow

export const uppercaseFirst = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const isEven = (number: number): boolean => {
  return number % 2 === 0;
};

export function getUrlFromPages(pages: any, language: string, name: string): string {
  const page = pages.find((page) => page.name === name);
  if (pages.length > 0 && page) {
    return `/${language}${page.translations[language].url}`;
  }
  return '/not-found';
}

export function enableSignUpByLanguage(language: string): boolean {
  switch (language) {
    case 'de':
    case 'pt':
    case 'nl':
    case 'it':
      return false;
    default:
      return true;
  }
}
