import { put, takeEvery } from 'redux-saga/effects';

import * as ApiService from 'services/api';
import ProtectedCall from 'services/protected.api';

import { incrementStatusCreator, decrementStatusCreator } from 'store/modules/Status/actions';
import * as csvActions from 'store/modules/Csv/actions';

/** ********************************************** */
/** Selectors * */

/** ********************************************** */
function* parseCommunitiesCSVSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Parse communities CSV',
      })
    );

    const result = yield ProtectedCall(ApiService.parseCommunityCSV, data.payload);

    yield put({ type: csvActions.SET_COMMUNITY_PARSED, payload: result.data.data.csv });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Parse communities CSV complete',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: `Parse communities CSV error - ${e.message}`,
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* uploadCommunitiesCSVSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Uploading communities CSV',
      })
    );

    const result = yield ProtectedCall(ApiService.uploadCommunityCSV, data.payload);

    yield put({ type: csvActions.SET_COMMUNITY_IMPORTED, payload: result.data.data });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Uploading communities CSV complete',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: `Uploading communities CSV error - ${e.message}`,
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

/** Sagas * */
export default function* sagaWatcher() {
  yield takeEvery(csvActions.UPLOAD_COMMUNITIES_CSV, uploadCommunitiesCSVSaga);
  yield takeEvery(csvActions.PARSE_COMMUNITIES_CSV, parseCommunitiesCSVSaga);
}
