import moment from 'moment';

export const getYears = () => {
  const currentYear = new Date().getFullYear();
  const results = [];
  for (let i = 1905; i <= currentYear; i++) {
    results.push({ value: i, label: i });
  }
  return results.reverse();
};

export const getDays = () => {
  const results = [];
  for (let i = 1; i <= 31; i++) {
    results.push({ value: i < 10 ? `0${i}` : `${i}`, label: i });
  }
  return results;
};

export const getNextDateOfTheWeekday = (dayINeed) => {
  if (moment().locale('fr').weekday() >= dayINeed) {
    return moment().locale('fr').add(1, 'week').weekday(dayINeed);
  } else {
    return moment().locale('fr').weekday(dayINeed);
  }
};
