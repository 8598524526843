import * as requestActions from 'store/modules/Request/actions';

const initialState = {
  searchResults: {
    communities: [],
    events: [],
    groups: [],
    requests: [],
    calls: [],
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case requestActions.SET_REQUESTS_SEARCH_RESULTS:
      return {
        ...state,
        searchResults: action.payload,
      };

    default:
      return state;
  }
};
