// @flow

function getQueryParam(name: string, _queryString: string): string | null {
  const url = new URL(window.location);
  const queryString = _queryString || url.search;
  const searchParams = new URLSearchParams(queryString);

  if (searchParams.has(name)) {
    return searchParams.get(name);
  }

  return null;
}
export default getQueryParam;
