import { put, select, takeEvery } from 'redux-saga/effects';

import * as ApiService from 'services/api';
import ProtectedCall from 'services/protected.api';

import { incrementStatusCreator, decrementStatusCreator } from 'store/modules/Status/actions';
import * as chatActions from 'store/modules/Chat/actions';

/** ********************************************** */
/** Selectors * */

const allUserDiscussionsSelector = (state) => {
  return JSON.parse(JSON.stringify(state.Chat.userDiscussions));
};

const allCommunityDiscussionsSelector = (state) => {
  return JSON.parse(JSON.stringify(state.Chat.communityDiscussions));
};

/** ********************************************** */

function* getUserUnreadsSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Gettting user unreads',
      })
    );

    const result = yield ProtectedCall(
      ApiService.GetUserUnreads,
      data.payload.userId,
      data.payload
    );

    yield put({
      type: chatActions.CACHE_UPDATE_USER_UNREADS,
      payload: result.data.data.unreads,
    });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Gettting user unreads finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Gettting user unreads error',
        state: 'error',
      })
    );
  }
}

function* getUserDiscussionSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Gettting user discussion',
      })
    );

    const result = yield ProtectedCall(
      ApiService.GetUserDiscussion,
      data.payload.userId,
      data.payload
    );

    yield put({
      type: chatActions.CACHE_UPDATE_USER_DISCUSSIONS,
      payload: [{ ...result.data.data.discussion }],
    });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Gettting user discussion finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Gettting user discussion error',
        state: 'error',
      })
    );
  }
}

function* getCommunityDiscussionSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Gettting community discussion',
      })
    );

    const result = yield ProtectedCall(
      ApiService.GetCommunityDiscussion,
      data.payload.communityId,
      data.payload
    );

    yield put({
      type: chatActions.CACHE_UPDATE_COMMUNITY_DISCUSSIONS,
      payload: [{ ...result.data.data.discussion }],
    });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Gettting community discussion finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Gettting community discussion error',
        state: 'error',
      })
    );
  }
}

function* sendUserMesssageSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Sending user discussion',
      })
    );

    const result = yield ProtectedCall(
      ApiService.SendUserMessage,
      data.payload.userId,
      data.payload
    );

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Sending user discussion finished',
        data: { messageId: result.data.data.message.id },
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Sending user discussion error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* sendCommunityMesssageSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Sending community discussion',
      })
    );

    const result = yield ProtectedCall(
      ApiService.SendCommunityMessage,
      data.payload.communityId,
      data.payload
    );

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Sending community discussion finished',
        data: { messageId: result.data.data.message.id },
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Sending community discussion error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* deleteUserMessageSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Deleting user message',
      })
    );

    const result = yield ProtectedCall(
      ApiService.DeleteUserMessage,
      data.payload.messageId,
      data.payload
    );

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Deleting user message finished',
        data: { message: result.data.message },
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Deleting user message error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* getDiscussionByIdSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Gettting user discussion',
      })
    );

    const result = yield ProtectedCall(
      ApiService.GetDiscussionById,
      data.payload.discussion_id,
      {}
    );

    yield put({
      type: chatActions.CACHE_UPDATE_USER_DISCUSSIONS,
      payload: [{ ...result.data.data.discussion }],
    });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Gettting user discussion finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Gettting user discussion error',
        state: 'error',
      })
    );
  }
}

function* messageReceivedSaga(data) {
  if (!data || !data.payload) return;

  data = data.payload;

  let discussions = [];
  let toUpdate = null;

  let cacheType = null;

  if (data.discussion_type === 'user') {
    discussions = yield select(allUserDiscussionsSelector) || {};
    cacheType = chatActions.CACHE_UPDATE_USER_DISCUSSIONS;
  }

  if (data.discussion_type === 'community') {
    discussions = yield select(allCommunityDiscussionsSelector) || {};
    cacheType = chatActions.CACHE_UPDATE_COMMUNITY_DISCUSSIONS;
  }

  Object.keys(discussions).map((key) => {
    const item = discussions[key];

    if (item.id === data.message.discussion_id) {
      item.messages.push(data.message);

      toUpdate = item;
    }

    return null;
  });

  // TODO: handle if discussion doesn't exist locally yet???

  if (toUpdate) {
    yield put({
      type: cacheType,
      payload: [toUpdate],
    });
  } else {
    yield put(
      chatActions.getDiscussionById({
        discussion_id: data.message.discussion_id,
      })
    );
  }
}

function* getLatestMessagesSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Gettting latest messages',
      })
    );

    const result = yield ProtectedCall(
      ApiService.GetLatestMessages,
      data.payload.user_id,
      data.payload
    );

    yield put({
      type: chatActions.SET_LATEST_MESSAGES,
      payload: result.data.data.latest_messages,
    });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Gettting latest messages finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Gettting latest messages error',
        state: 'error',
      })
    );
  }
}

function* setFileSaga(data) {
  try {
    yield put(incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Set file' }));

    yield ProtectedCall(ApiService.SetFileToMessage, data.payload.messageId, data.payload);

    yield put(
      decrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Set file finished' })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Set file error',
        state: 'error',
      })
    );
  }
}

function* updateUserUnreadsSaga(data) {
  if (!Number.isNaN(Number(data?.payload))) {
    yield put({ type: chatActions.CACHE_UPDATE_USER_UNREADS_SET, payload: data.payload });
  }
}

function* updateUserDiscussionsSaga(data) {
  if (!data || !data.payload || data.payload.length === 0) {
    return;
  }

  const userDiscussions = yield select(allUserDiscussionsSelector) || {};

  data.payload.map((item) => {
    userDiscussions[item.other_participant_id] = item;

    return null;
  });

  yield put({ type: chatActions.CACHE_UPDATE_USER_DISCUSSIONS_SET, payload: userDiscussions });
}

function* updateCommunityDiscussionsSaga(data) {
  if (!data || !data.payload || data.payload.length === 0) {
    return;
  }

  const communityDiscussions = yield select(allCommunityDiscussionsSelector) || {};

  data.payload.map((item) => {
    communityDiscussions[item.community_id] = item;

    return null;
  });

  yield put({
    type: chatActions.CACHE_UPDATE_COMMUNITY_DISCUSSIONS_SET,
    payload: communityDiscussions,
  });
}

/** Sagas * */
export default function* sagaWatcher() {
  yield takeEvery(chatActions.GET_USER_UNREADS, getUserUnreadsSaga);
  yield takeEvery(chatActions.GET_USER_DISCUSSION, getUserDiscussionSaga);
  yield takeEvery(chatActions.GET_COMMUNITY_DISCUSSION, getCommunityDiscussionSaga);

  yield takeEvery(chatActions.SEND_USER_MESSAGE, sendUserMesssageSaga);
  yield takeEvery(chatActions.SEND_COMMUNITY_MESSAGE, sendCommunityMesssageSaga);
  yield takeEvery(chatActions.DELETE_USER_MESSAGE, deleteUserMessageSaga);

  yield takeEvery(chatActions.SET_FILE, setFileSaga);

  yield takeEvery(chatActions.GET_DISCUSSION_BY_ID, getDiscussionByIdSaga);

  yield takeEvery(chatActions.MESSAGE_RECEIVED, messageReceivedSaga);

  yield takeEvery(chatActions.GET_LATEST_MESSAGES, getLatestMessagesSaga);

  yield takeEvery(chatActions.CACHE_UPDATE_USER_UNREADS, updateUserUnreadsSaga);
  yield takeEvery(chatActions.CACHE_UPDATE_USER_DISCUSSIONS, updateUserDiscussionsSaga);
  yield takeEvery(chatActions.CACHE_UPDATE_COMMUNITY_DISCUSSIONS, updateCommunityDiscussionsSaga);
}
