// @flow
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import { AlternateEmail, ChatBubble } from '@material-ui/icons';

import { PRIMARY_COLOR, NEUTRAL_COLOR } from '@shared/constants';
import ActiveIcon from '../../../assets/getphy-state-active.svg';
import type { Participant } from '../models';

const useStyles = makeStyles(() => ({
  item: {
    height: '4rem',
    backgroundColor: NEUTRAL_COLOR,
    '&:hover': {
      filter: 'brightness(90%)',
      cursor: 'pointer',
    },
  },
  name: {
    paddingLeft: '1rem',
  },
  hasContact: {
    color: PRIMARY_COLOR,
  },
  activeIcon: {
    height: '2rem',
    width: '1rem',
  },
  isActive: {
    filter:
      'brightness(0) saturate(100%) invert(76%) sepia(29%) saturate(5227%) hue-rotate(359deg) brightness(104%) contrast(106%)',
  },
}));

type Props = {
  participant: Participant,
};

const GroupClassMenuParticipantItem = ({ participant }: Props) => {
  const classes = useStyles();

  let {
    active,
    first_name: firstName,
    last_name: lastName,
    email,
    phone_number: phoneNumber,
  } = participant;

  let fullName;

  if (firstName && lastName) {
    fullName = `${firstName} ${lastName.toUpperCase()}`;
  }

  return (
    <ListItem className={classes.item} onClick={() => {}}>
      <img
        src={ActiveIcon}
        className={clsx(active === 1 && classes.isActive, classes.activeIcon)}
      />

      <ListItemText primary={fullName} className={classes.name} />

      <ListItemSecondaryAction>
        <IconButton aria-label="delete" className={email && classes.hasContact}>
          <AlternateEmail />
        </IconButton>
        <IconButton edge="end" aria-label="delete" className={phoneNumber && classes.hasContact}>
          <ChatBubble />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default GroupClassMenuParticipantItem;
