export const GET_GETPHY_EXPRESS_VIDEOS = 'csv/GET_GETPHY_EXPRESS_VIDEOS';
export const CREATE_GETPHY_EXPRESS_VIDEO = 'csv/CREATE_GETPHY_EXPRESS_VIDEO';
export const UPDATE_GETPHY_EXPRESS_VIDEO = 'csv/UPDATE_GETPHY_EXPRESS_VIDEO';
export const DELETE_GETPHY_EXPRESS_VIDEO = 'csv/DELETE_GETPHY_EXPRESS_VIDEO';

export const UPDATE_CACHE = 'csv/UPDATE_CACHE';

export function getGetphyExpressVideos(payload) {
  return { type: GET_GETPHY_EXPRESS_VIDEOS, payload };
}

export function createGetphyExpressVideo(payload) {
  return { type: CREATE_GETPHY_EXPRESS_VIDEO, payload };
}

export function updateGetphyExpressVideo(payload) {
  return { type: UPDATE_GETPHY_EXPRESS_VIDEO, payload };
}

export function deleteGetphyExpressVideo(payload) {
  return { type: DELETE_GETPHY_EXPRESS_VIDEO, payload };
}
