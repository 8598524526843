// @flow
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import type { Language } from '@core/models';
import Box from '@material-ui/core/Box';
import { Language as LanguageIcon } from '@material-ui/icons';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import * as appSelectors from '@core/selectors';

import { setLocale } from '@services/localisation';

type Props = {
  onLanguageChange: (language: Language) => void,
  availableLanguages: Array<string>,
};

const useStyles = makeStyles(() => ({
  select: {
    color: 'white',
    '&.Mui-focused, &:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'white',
        borderWidth: 'thin',
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'white',
    },
  },
  icon: {
    fill: 'white',
  },
}));

const LanguagePicker = (props: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { availableLanguages } = props;
  const language = useSelector((state) => appSelectors.selectedLanguageSelector(state));
  const languages = useSelector((state) => appSelectors.languagesCollectionSelector(state));

  const handleLanguageChange = (event: SyntheticInputEvent<EventTarget>): void => {
    const { onLanguageChange } = props;
    const { value } = event.target;
    const language = languages.find((l) => l.code === value);
    setLocale(language.code);
    onLanguageChange(language);
  };

  const getLanguages = (): Language[] => {
    return languages.filter((language) => availableLanguages.includes(language.code));
  };

  return (
    <Box display="flex" alignItems="center">
      <Box mr={1}>{t('Footer.languages')} :</Box>
      <Box>
        <Select
          id="language-select"
          className={classes.select}
          IconComponent={LanguageIcon}
          inputProps={{
            classes: {
              icon: classes.icon,
            },
          }}
          onChange={handleLanguageChange}
          value={language}
          variant="outlined"
        >
          {getLanguages().map((language) => (
            <MenuItem value={language.code} key={language.id}>
              {language.name}
            </MenuItem>
          ))}
        </Select>
      </Box>
    </Box>
  );
};

LanguagePicker.defaultProps = {
  onLanguageChange: () => {},
};

export default LanguagePicker;
