// @flow
import React, { useState, useCallback } from 'react';

import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';

import { WHITE } from '@shared/constants';

import ModalRegister from '@containers/modals/ModalRegister';

import SubscribeButton from './SubscribeButton';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    backgroundColor: WHITE,
    justifyContent: 'center',
  },

  button: { textAlign: 'center' },
}));

const GroupLessonSubscribe = () => {
  const classes = useStyles();
  const [signupModalActive, setSignupModalActive] = useState(false);

  const handleSubscribe = useCallback(() => {
    setSignupModalActive(true);
  }, [setSignupModalActive]);

  const handleCloseSignup = useCallback(() => {
    setSignupModalActive(false);
  }, [setSignupModalActive]);

  return (
    <>
      <div className={clsx(classes.root)}>
        <p className={clsx(classes.button)}>
          <SubscribeButton handleSubscribe={handleSubscribe} fullWidth={false} size="large" />
        </p>
      </div>
      {signupModalActive && <ModalRegister handleClose={handleCloseSignup} />}
    </>
  );
};

export default GroupLessonSubscribe;
