import { takeEvery, call, put, select } from 'redux-saga/effects';

import * as ApiService from 'services/api';

import { incrementStatusCreator, decrementStatusCreator } from 'store/modules/Status/actions';
import * as assessmentActions from 'store/modules/Assessment/actions';

/** ********************************************** */
/** Selectors * */
const allSelector = (state) => {
  return JSON.parse(JSON.stringify(state.Assessment.assessments));
};

/** ********************************************** */

function* getAssessmentsSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Get assessments start',
      })
    );

    const result = yield call(ApiService.AssessmentsGet, data.payload);

    yield put({
      type: assessmentActions.UPDATE_ASSESSMENTS_CACHE,
      payload: result.data.data.assessments,
    });

    yield put({
      type: assessmentActions.UPDATE_ASSESSMENT_SEARCH_RESULTS,
      payload: result.data.data,
    });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Get assessments finsihed',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Get assessments start error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* updateCacheSaga(data) {
  if (!data || !data.payload || data.payload.length === 0) {
    return;
  }

  const assessments = yield select(allSelector) || [];

  if (data.delete) {
    data.payload.map((item) => {
      let id = item;
      if (typeof id === 'object') {
        id = id.id;
      }

      delete assessments[id];

      return null;
    });
  } else {
    data.payload.map((item) => {
      if (!item.id) return null;

      assessments[item.id] = item;

      return null;
    });
  }

  yield put({ type: assessmentActions.UPDATE_ASSESSMENTS_CACHE_SET, payload: assessments });
}

function* assessmentDocumentSaga(data) {
  try {
    yield call(ApiService.AssessmentDocument, data.payload.id);
  } catch (e) {
    yield put(
      decrementStatusCreator({
        message: 'Download assessment document',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

/** Sagas * */
export default function* sagaWatcher() {
  yield takeEvery(assessmentActions.GET_ASSESSMENTS, getAssessmentsSaga);

  yield takeEvery(assessmentActions.UPDATE_ASSESSMENTS_CACHE, updateCacheSaga);
  yield takeEvery(assessmentActions.DOWNLOAD_ASSESSMENT_DOCUMENT, assessmentDocumentSaga);
}
