import { put, takeEvery } from 'redux-saga/effects';

import * as ApiService from 'services/api';
import ProtectedCall from 'services/protected.api';

import { incrementStatusCreator, decrementStatusCreator } from 'store/modules/Status/actions';
import * as statsActions from 'store/modules/Stats/actions';

/** ********************************************** */
/** Selectors * */

/** ********************************************** */
/** Sagas * */
function* getAdminStatsSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Getting admin stats' })
    );

    const result = yield ProtectedCall(ApiService.GetStatistics, data.payload);
    yield put({
      type: statsActions.SET_ADMIN_STATS,
      payload: {
        ...result.data.data,
      },
    });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting admin stats finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting admin stats error',
        state: 'error',
      })
    );
  }
}

function* getPaymentsSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Getting payments' })
    );

    const result = yield ProtectedCall(ApiService.GetPayments, data.payload);

    yield put({ type: statsActions.SET_PAYMENTS_CACHE, payload: result.data.data });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting payments finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting payments error',
        state: 'error',
      })
    );
  }
}

function* recoverPaymentSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Recovering payment' })
    );

    yield ProtectedCall(ApiService.RecoverPayment, data.payload.id, {
      ...data.payload,
      cancel: false,
    });

    yield put(statsActions.getPayments({}));

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Recovering payment finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Recovering payment error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* cancelPaymentSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Cancelling payment' })
    );

    yield ProtectedCall(ApiService.RecoverPayment, data.payload.id, {
      ...data.payload,
      cancel: true,
    });

    yield put(statsActions.getPayments({}));

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Cancelling payment finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Cancelling payment error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* exportPaymentsSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Exporting payments' })
    );

    yield ProtectedCall(ApiService.ExportPayments, data.payload);

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Exporting payments finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Exporting payments error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* exportPrescriberBillingSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Exporting prescriber billing',
      })
    );

    yield ProtectedCall(
      ApiService.ExportPrescriberBilling,
      data.payload,
      data.payload.prescriberId
    );

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Exporting prescriber billing finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Exporting prescriber billing error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* exportUserSessionFeedbacksSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Exporting user session feedbacks',
      })
    );

    yield ProtectedCall(ApiService.ExportUserSessionFeedbacks, data.payload);

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Exporting user session feedbacks finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Exporting user session feedbacks error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* getPrescriberDashboardOverviewSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'get prescriber dashboard',
      })
    );

    let result = {};

    if (data.payload.token) {
      result = yield ProtectedCall(
        ApiService.PrescriberDashboardOverviewGetWithToken,
        data.payload.token,
        data.payload
      );
    } else {
      result = yield ProtectedCall(ApiService.PrescriberDashboardOverviewGet, data.payload);
    }

    yield put({
      type: statsActions.SET_PRESCRIBER_DASHBOARD,
      payload: {
        overview: result.data.data,
      },
    });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'get prescriber dashboard finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'get prescriber dashboard error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* getDashboardOverviewSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'get admin dashboard' })
    );

    const result = yield ProtectedCall(ApiService.DashboardOverviewGet, data.payload);

    yield put({
      type: statsActions.SET_PRESCRIBER_DASHBOARD,
      payload: {
        overview: result.data.data,
      },
    });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'get admin dashboard finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'get admin dashboard error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* getPrescriberDashboardActivitiesSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'get prescriber dashboard activities',
      })
    );

    let result = {};

    if (data.payload.token) {
      result = yield ProtectedCall(
        ApiService.PrescriberDashboardActivitiesGetWithToken,
        data.payload.token
      );
    } else {
      result = yield ProtectedCall(ApiService.PrescriberDashboardActivitiesGet);
    }

    yield put({
      type: statsActions.SET_PRESCRIBER_DASHBOARD,
      payload: {
        activities: result.data.activities,
      },
    });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'get prescriber dashboard activities finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'get prescriber dashboard activities error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* getDashboardActivitiesSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'get dashboard activities',
      })
    );

    const result = yield ProtectedCall(
      ApiService.DashboardActivitiesGet,
      data.payload.prescriberId || undefined
    );

    yield put({
      type: statsActions.SET_PRESCRIBER_DASHBOARD,
      payload: {
        activities: result.data.activities,
      },
    });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'get dashboard activities finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'get dashboard activities error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

export default function* sagaWatcher() {
  yield takeEvery(statsActions.GET_ADMIN_STATS, getAdminStatsSaga);
  yield takeEvery(statsActions.GET_PAYMENTS, getPaymentsSaga);
  yield takeEvery(statsActions.RECOVER_PAYMENT, recoverPaymentSaga);
  yield takeEvery(statsActions.CANCEL_PAYMENT, cancelPaymentSaga);
  yield takeEvery(statsActions.EXPORT_PAYMENTS, exportPaymentsSaga);
  yield takeEvery(statsActions.EXPORT_PRESCRIBER_BILLING, exportPrescriberBillingSaga);
  yield takeEvery(statsActions.EXPORT_USER_SESSION_FEEDBACKS, exportUserSessionFeedbacksSaga);
  yield takeEvery(
    statsActions.GET_PRESCRIBER_DASHBOARD_OVERVIEW,
    getPrescriberDashboardOverviewSaga
  );
  yield takeEvery(statsActions.GET_DASHBOARD_OVERVIEW, getDashboardOverviewSaga);
  yield takeEvery(
    statsActions.GET_PRESCRIBER_DASHBOARD_ACTIVITIES,
    getPrescriberDashboardActivitiesSaga
  );
  yield takeEvery(statsActions.GET_DASHBOARD_ACTIVITIES, getDashboardActivitiesSaga);
}
