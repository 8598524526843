import parseJwt from './utils';

export function setToken(data) {
  const { iat, exp } = parseJwt(data.token);

  localStorage.setItem('getphy_token', data.token);
  localStorage.setItem('getphy_token_expiry', data.expire_in);
  localStorage.setItem('token_iat', iat);
  localStorage.setItem('token_exp', exp);
}

export function getToken() {
  return localStorage.getItem('getphy_token');
}

export function getTokenInfo() {
  return {
    iat: localStorage.getItem('token_iat'),
    exp: localStorage.getItem('token_exp'),
  };
}

export function clearToken() {
  localStorage.removeItem('getphy_token');
  localStorage.removeItem('getphy_token_expiry');
  localStorage.removeItem('token_iat');
  localStorage.removeItem('token_exp');
}
