export const APP_INIT = 'APP_INIT';

export const LOGIN_REQUEST = 'account/LOGIN_REQUEST';
export const LOGAS_REQUEST = 'account/LOGAS_REQUEST';
export const SET_LOGIN_REQUEST_STATUS = 'account/SET_LOGIN_REQUEST_STATUS';

export const REDIRECT_AFTER_LOGIN = 'account/REDIRECT_AFTER_LOGIN';

export const SET_LOGGEDIN_STATE = 'account/SET_LOGGEDIN_STATE';
export const SET_LOGGEDIN_STATE_SET = 'account/SET_LOGGEDIN_STATE_SET';

export const UPDATE_USER_LANGUAGE = 'account/UPDATE_USER_LANGUAGE';

export const GET_LANGUAGES = 'account/GET_LANGUAGES';
export const SET_LANGUAGES = 'account/SET_LANGUAGES';

export const GET_LEVELS = 'account/GET_LEVELS';
export const SET_LEVELS = 'account/SET_LEVELS';

export const SUBMIT_FORGOTTEN_PASSWORD_REQUEST = 'account/SUBMIT_FORGOTTEN_PASSWORD_REQUEST';
export const SUBMIT_NEW_PASSWORD = 'account/SUBMIT_NEW_PASSWORD';

export const SET_CURRENT_ROLE = 'account/SET_CURRENT_ROLE';
export const SET_CURRENT_ROLE_SET = 'account/SET_CURRENT_ROLE_SET';

export const SET_LOGGEDOUT_STATE = 'account/SET_LOGGEDOUT_STATE';
export const SET_LOGGEDOUT_STATE_SET = 'account/SET_LOGGEDOUT_STATE_SET';

export const REGISTER_REQUEST = 'account/REGISTER_REQUEST';
export const SET_REGISTER_REQUEST_STATUS = 'account/SET_REGISTER_REQUEST_STATUS';
export const SET_REGISTERED_STATE = 'account/SET_REGISTERED_STATE';

export const EMAIL_CONFIRM_SUBMIT_REQUEST = 'account/EMAIL_CONFIRM_SUBMIT_REQUEST';
export const SET_REGISTER_CONFIRM_EMAIL_STATUS = 'account/SET_REGISTER_CONFIRM_EMAIL_STATUS';

export const RESTORE_ACCOUNT_SUBMIT_REQUEST = 'account/RESTORE_ACCOUNT_SUBMIT_REQUEST';
export const SET_RESTORE_ACCOUNT_EMAIL_STATUS = 'account/SET_RESTORE_ACCOUNT_EMAIL_STATUS';

export const UPDATE_PROFILE_REQUEST = 'account/UPDATE_PROFILE_REQUEST';
export const UPDATE_PASSWORD_REQUEST = 'account/UPDATE_PASSWORD_REQUEST';
export const REFETCH_PROFILE_REQUEST = 'account/REFETCH_PROFILE_REQUEST';

export const UNSUBSCRIBE = 'account/UNSUBSCRIBE';

export const RESEND_CONFIRM_EMAIL = 'account/RESEND_CONFIRM_EMAIL';
export const RESEND_RESTORE_ACCOUNT_EMAIL = 'account/RESEND_RESTORE_ACCOUNT_EMAIL';

export const SET_PROFILE_UDPDATE_STATUS = 'account/SET_PROFILE_UDPDATE_STATUS';
export const SET_PASSWORD_UDPDATE_STATUS = 'account/SET_PASSWORD_UDPDATE_STATUS';

export const UPDATE_PROFILE = 'account/UPDATE_PROFILE';
export const FORCE_USER_LANGUAGE_CHANGE = 'account/FORCE_USER_LANGUAGE_CHANGE';

export const DOWNLOAD_PDF_RECEIPT = 'account/DOWNLOAD_PDF_RECEIPT';

export function init(payload) {
  return { type: APP_INIT, payload };
}

export function loginRequestCreator(data) {
  return { type: LOGIN_REQUEST, payload: data };
}

export function logoutRequestCreator(data) {
  return { type: SET_LOGGEDOUT_STATE, payload: data, redirectToIndex: true };
}

export function logAsRequest(data) {
  return { type: LOGAS_REQUEST, payload: data };
}

export function getLanguages(data) {
  return { type: GET_LANGUAGES, payload: data };
}

export function getLevels(data) {
  return { type: GET_LEVELS, payload: data };
}

export function registerRequestCreator(data) {
  return { type: REGISTER_REQUEST, payload: data };
}

export function setLoginRequestStatusCreator(status = null, error = null) {
  return { type: SET_LOGIN_REQUEST_STATUS, payload: { status, error } };
}

export function submitForgottenPasswordRequest(data) {
  return { type: SUBMIT_FORGOTTEN_PASSWORD_REQUEST, payload: data };
}

export function submitNewPassword(data) {
  return { type: SUBMIT_NEW_PASSWORD, payload: data };
}

export function setLoggedInStateCreator(payload) {
  return {
    type: SET_LOGGEDIN_STATE,
    payload: {
      ...payload,
      user: payload.user,
    },
  };
}
export function redirectionToAnUrl(data) {
  return {
    type: REDIRECT_AFTER_LOGIN,
    payload: {
      url: data,
    },
  };
}

export function setCurrentRole(role) {
  return { type: SET_CURRENT_ROLE, payload: role };
}

export function submitConfirmEmailCreator(payload) {
  return {
    type: EMAIL_CONFIRM_SUBMIT_REQUEST,
    payload: {
      payload,
    },
  };
}

export function submitRestoreAccountCreator(payload) {
  return {
    type: RESTORE_ACCOUNT_SUBMIT_REQUEST,
    payload: {
      payload,
    },
  };
}

export function submitProfileUpdate(payload) {
  return {
    type: UPDATE_PROFILE_REQUEST,
    payload: {
      payload,
    },
  };
}

export function submitPasswordUpdate(payload) {
  return { type: UPDATE_PASSWORD_REQUEST, payload };
}

export function refetchProfile(payload) {
  return { type: REFETCH_PROFILE_REQUEST, payload };
}

export function unsubscribe(payload) {
  return { type: UNSUBSCRIBE, payload };
}

export function resendConfirmationEmail(payload) {
  return { type: RESEND_CONFIRM_EMAIL, payload };
}
export function resendRestoreAccountEmail(payload) {
  return { type: RESEND_RESTORE_ACCOUNT_EMAIL, payload };
}

export function updateUserLanguage(payload) {
  return { type: UPDATE_USER_LANGUAGE, payload };
}

export function pdfReceipt(payload) {
  return { type: DOWNLOAD_PDF_RECEIPT, payload };
}
