/* eslint-disable import/no-cycle */
import { fork } from 'redux-saga/effects';

import App from 'store/saga/App';
import Account from 'store/saga/Account';
import Community from 'store/saga/Community';
import User from 'store/saga/User';
import Request from 'store/saga/Request';
import Calendar from 'store/saga/Calendar';
import Session from 'store/saga/Session';
import Prescriber from 'store/saga/Prescriber';
import Status from 'store/saga/Status';
import Csv from 'store/saga/Csv';
import Coach from 'store/saga/Coach';
import Program from 'store/saga/Program';
import Goal from 'store/saga/Goal';
import Chat from 'store/saga/Chat';
import Resource from 'store/saga/Resource';
import Article from 'store/saga/Article';
import Stats from 'store/saga/Stats';
import GetphyExpress from 'store/saga/GetphyExpress';
import Contact from 'store/saga/Contact';
import Assessment from 'store/saga/Assessment';
import Landing from 'store/saga/Landing';
import Auth from 'store/saga/Auth';
import Notification from 'store/saga/Notification';
import SysAdmin from 'store/saga/SysAdmin';
import Help from 'store/saga/Help';
import Socket from 'store/saga/Socket';
import Seo from 'store/saga/Seo';

export default function* rootSaga() {
  yield fork(App);
  yield fork(Account);
  yield fork(Community);
  yield fork(User);
  yield fork(Request);
  yield fork(Calendar);
  yield fork(Session);
  yield fork(Prescriber);
  yield fork(Status);
  yield fork(Csv);
  yield fork(Coach);
  yield fork(Program);
  yield fork(Goal);
  yield fork(Chat);
  yield fork(Resource);
  yield fork(Article);
  yield fork(Stats);
  yield fork(GetphyExpress);
  yield fork(Contact);
  yield fork(Assessment);
  yield fork(Landing);
  yield fork(Auth);
  yield fork(Notification);
  yield fork(SysAdmin);
  yield fork(Help);
  yield fork(Socket);
  yield fork(Seo);
}
