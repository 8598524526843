import React, { useCallback } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { NextPrivateLayout } from '@layout/components';
import { privateTabs } from '@header/constants';

import { privateTheme } from '@layout/theme';

import useGetGroupClasses from '../hooks/useGetGroupClasses';

import SearchBanner from './SearchBanner';
import TitleBanner from './TitleBanner';
import GroupClassesCards from './GroupClassesCards';
import GroupClassesPagination from './GroupClassesPagination';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  topPagination: {
    display: 'none',
    [privateTheme.breakpoints.down('md')]: {
      display: 'flex',
    },
  },
}));

const GroupClasses = () => {
  const classes = useStyles();
  const [archivedCourses, setArchivedCourses] = React.useState(false);
  const { groupClasses, totalPages, page, setPage } = useGetGroupClasses({ archivedCourses });

  const handleChangePage = useCallback(
    (e, value) => {
      setPage(value);
    },
    [page]
  );

  const handleActiveCourses = React.useCallback((event) => {
    setArchivedCourses(!event.target.checked);
  }, []);

  const handleArchivedCourses = React.useCallback((event) => {
    setArchivedCourses(event.target.checked);
  }, []);

  return (
    <NextPrivateLayout tabs={privateTabs} activeTab={'myGroupClasses'}>
      <div className={classes.root}>
        <TitleBanner />
        <SearchBanner
          archivedCourses={archivedCourses}
          handleActiveCourses={handleActiveCourses}
          handleArchivedCourses={handleArchivedCourses}
        />
        {groupClasses && groupClasses.length > 0 && (
          <div className={classes.topPagination}>
            <GroupClassesPagination
              count={totalPages}
              page={page}
              changePage={handleChangePage}
              top
            />
          </div>
        )}
        {groupClasses && <GroupClassesCards groupClasses={groupClasses} />}
        {groupClasses && groupClasses.length > 0 && (
          <GroupClassesPagination count={totalPages} page={page} changePage={handleChangePage} />
        )}
      </div>
    </NextPrivateLayout>
  );
};

export default GroupClasses;
