import { getLocale } from 'services/localisation';
import getNestedObject from 'helpers/get-nested-object';

import fr from 'translations/fr.json';
import en from 'translations/en.json';
import es from 'translations/es.json';

const translations = {};

// TODO: move to the store?
export function init() {
  translations.fr = fr;
  translations.en = en;
  translations.es = es;

  return true;
}

export default function t(key) {
  if (Object.keys(translations).length <= 0) {
    init();
  }
  const locale = getLocale();
  let transKeys = translations[locale];
  if (!transKeys) {
    transKeys = translations.fr;
  }

  return getNestedObject(key, transKeys, '.') || key;
}
