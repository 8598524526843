export const GET_USER_UNREADS = 'chat/GET_USER_UNREADS';
export const GET_USER_DISCUSSION = 'chat/GET_USER_DISCUSSION';
export const GET_COMMUNITY_DISCUSSION = 'chat/GET_COMMUNITY_DISCUSSION';

export const SEND_USER_MESSAGE = 'chat/SEND_USER_MESSAGE';
export const DELETE_USER_MESSAGE = 'chat/DELETE_USER_MESSAGE';
export const SEND_COMMUNITY_MESSAGE = 'chat/SEND_COMMUNITY_MESSAGE';

export const SET_FILE = 'chat/SET_FILE';

export const GET_DISCUSSION_BY_ID = 'chat/GET_DISCUSSION_BY_ID';

export const MESSAGE_RECEIVED = 'chat/MESSAGE_RECEIVED';

export const GET_LATEST_MESSAGES = 'chat/GET_LATEST_MESSAGES';

export const CACHE_UPDATE_USER_UNREADS = 'chat/CACHE_UPDATE_USER_UNREADS';
export const CACHE_UPDATE_USER_UNREADS_SET = 'chat/CACHE_UPDATE_USER_UNREADS_SET';

export const CACHE_UPDATE_USER_DISCUSSIONS = 'chat/CACHE_UPDATE_USER_DISCUSSIONS';
export const CACHE_UPDATE_USER_DISCUSSIONS_SET = 'chat/CACHE_UPDATE_USER_DISCUSSIONS_SET';

export const CACHE_UPDATE_COMMUNITY_DISCUSSIONS = 'chat/CACHE_UPDATE_COMMUNITY_DISCUSSIONS';
export const CACHE_UPDATE_COMMUNITY_DISCUSSIONS_SET = 'chat/CACHE_UPDATE_COMMUNITY_DISCUSSIONS_SET';

export const SET_LATEST_MESSAGES = 'chat/SET_LATEST_MESSAGES';

export function getUserUnreads(payload) {
  return { type: GET_USER_UNREADS, payload };
}

export function setUserUnreads(payload) {
  return { type: CACHE_UPDATE_USER_UNREADS_SET, payload };
}

export function getUserDiscussion(payload) {
  return { type: GET_USER_DISCUSSION, payload };
}

export function getCommunityDiscussion(payload) {
  return { type: GET_COMMUNITY_DISCUSSION, payload };
}

export function sendUserMessage(payload) {
  return { type: SEND_USER_MESSAGE, payload };
}

export function deleteUserMessage(payload) {
  return { type: DELETE_USER_MESSAGE, payload };
}

export function sendCommunityMessage(payload) {
  return { type: SEND_COMMUNITY_MESSAGE, payload };
}

export function getDiscussionById(payload) {
  return { type: GET_DISCUSSION_BY_ID, payload };
}

export function messageReceived(payload) {
  return { type: MESSAGE_RECEIVED, payload };
}

export function getLatestMessages(payload) {
  return { type: GET_LATEST_MESSAGES, payload };
}

export function setFile(payload) {
  return { type: SET_FILE, payload };
}
