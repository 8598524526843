import React from 'react';

import clsx from 'clsx';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import { Link } from 'react-router-dom';

import { getTabs } from '@header/helpers';
import { uppercaseFirst, getUrlFromPages } from '@shared/helpers';

import { publicTheme } from '@layout/theme';

const useStyles = makeStyles(() => ({
  appBar: {
    backgroundColor: '#fff',
  },
  root: {
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'space-around',
    [publicTheme.breakpoints.down('xs')]: {
      display: 'none',
    },
    [publicTheme.breakpoints.up('md')]: {
      width: '960px',
      margin: '0 auto',
    },
  },
  isActive: {
    borderBottom: '5px solid #0273bb',
  },
  linkIsActive: {
    color: '#0273bb !important',
  },
  navItem: {
    padding: '0 30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  linkItem: {
    color: '#6f6f6f',
    fontFamily: 'Roboto, Georgia, serif',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    textDecoration: 'none',
  },
}));

const Menu = ({ tabs, active }) => {
  const { t, i18n } = useTranslation();
  const { searchResults } = useSelector((state) => state.Seo);
  const { language } = i18n;
  const classes = useStyles();

  return (
    <AppBar position="static" className={classes.appBar}>
      <Toolbar className={classes.root}>
        {getTabs(tabs, active).map((tab) => (
          <div key={tab.name} className={clsx(classes.navItem, tab.isActive && classes.isActive)}>
            <Link
              to={tab.link || getUrlFromPages(searchResults, language, tab.name)}
              className={clsx(classes.linkItem, tab.isActive && classes.linkIsActive)}
            >
              {uppercaseFirst(t(tab.translationKey))}
            </Link>
          </div>
        ))}
      </Toolbar>
    </AppBar>
  );
};

export default Menu;
