import { call, put, select, takeEvery } from 'redux-saga/effects';

import * as ApiService from 'services/api';
import ProtectedCall from 'services/protected.api';

import { incrementStatusCreator, decrementStatusCreator } from 'store/modules/Status/actions';
import * as prescriberActions from 'store/modules/Prescriber/actions';
import * as promoteActions from 'store/modules/Promote/actions';

/** ********************************************** */
/** Selectors * */
const allSelector = (state) => {
  return JSON.parse(JSON.stringify(state.Prescriber));
};

const allPrescribersSelector = (state) => {
  return JSON.parse(JSON.stringify(state.Prescriber.prescribers));
};

/** ********************************************** */
/** Sagas * */

function* getAllPrescribersSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Getting prescribers' })
    );

    const result = yield ProtectedCall(ApiService.GetAllPrescribers, data.payload);

    yield put({
      type: prescriberActions.UPDATE_CACHE_PRESCRIBERS_SEARCH_RESULTS_SET,
      payload: result.data.data,
    });

    yield put({
      type: prescriberActions.UPDATE_CACHE,
      payload: result.data.data.prescribers,
    });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting prescriber finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting prescriber error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* getListPrescribersSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting prescriber list',
      })
    );

    const result = yield ProtectedCall(ApiService.GetListPrescribers, data.payload);

    yield put({
      type: prescriberActions.UPDATE_CACHE_PRESCRIBERS_LIST_SET,
      payload: result.data.data.prescribers,
    });

    yield put({
      type: prescriberActions.UPDATE_CACHE,
      payload: result.data.data.prescribers,
    });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting prescriber list finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting prescriber list error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* getPhysicalTestByPrescriberSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting physical test by prescriber id',
      })
    );

    const result = yield ProtectedCall(
      ApiService.GetPhysicalTestByPrescriber,
      data.payload.prescriberId,
      data.payload
    );

    yield put({
      type: prescriberActions.UPDATE_CACHE_PHYSICAL_TEST,
      payload: result.data.data.physical_tests,
    });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting physical test by prescriber id finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting physical test by prescriber id error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* fetchDashboardDataSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Fetch dashboard data' })
    );

    const result = yield ProtectedCall(
      ApiService.PrescriberDashboardDataGet,
      data.payload.prescriberId,
      data.payload.dataType,
      data.payload.data
    );

    yield put({
      type: prescriberActions.UPDATE_CACHE,
      payload: {
        dataType: data.payload.dataType,
        data: result.data.data,
      },
    });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Fetch dashboard data finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Fetch dashboard data error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* getInviteCodesSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting prescriber invite codes',
      })
    );

    const result = yield ProtectedCall(ApiService.GetPrescriberInviteCodes, data.payload);

    yield put({
      type: prescriberActions.SET_CODES_SEARCH_RESULTS,
      payload: result.data.data,
    });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting prescriber invite codes finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting prescriber invite codes error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* exportInviteCodesSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Exporting prescriber invite codes',
      })
    );

    yield ProtectedCall(
      ApiService.ExportPrescriberInviteCode,
      data.payload.prescriber_id,
      data.payload
    );

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Exporting prescriber invite codes finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Exporting prescriber invite codes error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* createInviteCodesSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Creating prescriber invite codes',
      })
    );

    yield ProtectedCall(ApiService.CreatePrescriberInviteCodes, data.payload);

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Creating prescriber invite codes finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Creating prescriber invite codes error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* editInviteCodesSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Creating prescriber invite codes',
      })
    );

    yield call(ApiService.EditPrescriberInviteCodes, data.payload, data.payload.id);

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Creating prescriber invite codes finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Creating prescriber invite codes error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* checkCodeSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Checking invite code',
        data: null,
      })
    );

    const result = yield call(ApiService.CheckInviteCode, data.payload.code, data.payload);

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Checking invite code finished',
        data: result.data.data.code,
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Checking invite code error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* createNewPrescriberSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Creating new prescriber',
      })
    );

    yield ProtectedCall(ApiService.CreateNewPrescriber, data.payload);

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Creating new prescriber finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Creating new prescriber error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* editPrescriberSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Edit prescriber' })
    );

    yield ProtectedCall(ApiService.EditPrescriber, data.payload.id, data.payload);

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Edit prescriber finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Edit prescriber error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* linkUserToPrescriberWithCodeSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Linking user to prescriber',
      })
    );

    yield ProtectedCall(ApiService.LinkUserToPrescriber, data.payload);
    yield put(promoteActions.hideEcoSantePromotion());

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Linking user to prescriber finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'There was a problem with the submitted code',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* unlinkUserToPrescriberSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Unlinking user to prescriber',
      })
    );

    yield ProtectedCall(ApiService.UnlinkUserToPrescriber, data.payload.userId, data.payload);

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Unlinking user to prescriber finished',
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'UNlinking user to prescriber error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* updateCacheSaga(data) {
  if (!data || !data.payload || data.payload.length === 0) {
    return;
  }

  const all = yield select(allSelector) || {};
  all[data.payload.dataType] = data.payload.data;

  // data.payload.map((item) => {
  //   all[item.id] = item;
  // })

  yield put({ type: prescriberActions.UPDATE_CACHE_SET, payload: all });
}

function* updateCachePrescribersSaga(data) {
  if (!data || !data.payload || data.payload.length === 0) {
    return;
  }

  const all = yield select(allPrescribersSelector) || {};

  data.payload.map((item) => {
    all[item.id] = item;
    return null;
  });

  yield put({ type: prescriberActions.UPDATE_CACHE_PRESCRIBERS_SET, payload: all });
}

export default function* sagaWatcher() {
  yield takeEvery(prescriberActions.GET_ALL, getAllPrescribersSaga);
  yield takeEvery(prescriberActions.GET_LIST_PRESCRIBERS, getListPrescribersSaga);
  yield takeEvery(prescriberActions.FETCH_DASHBOARD_DATA, fetchDashboardDataSaga);

  yield takeEvery(
    prescriberActions.GET_PHYSICAL_TEST_BY_PRESCRIBER,
    getPhysicalTestByPrescriberSaga
  );

  yield takeEvery(prescriberActions.GET_INVITE_CODES, getInviteCodesSaga);
  yield takeEvery(prescriberActions.EXPORT_INVITE_CODES, exportInviteCodesSaga);
  yield takeEvery(prescriberActions.CREATE_INVITE_CODES, createInviteCodesSaga);
  yield takeEvery(prescriberActions.EDIT_INVITE_CODES, editInviteCodesSaga);
  yield takeEvery(prescriberActions.CHECK_CODE, checkCodeSaga);

  yield takeEvery(prescriberActions.CREATE_NEW_PRESCRIBER, createNewPrescriberSaga);
  yield takeEvery(prescriberActions.EDIT_PRESCRIBER, editPrescriberSaga);

  yield takeEvery(prescriberActions.LINK_USER, linkUserToPrescriberWithCodeSaga);
  yield takeEvery(prescriberActions.UNLINK_USER, unlinkUserToPrescriberSaga);

  yield takeEvery(prescriberActions.UPDATE_CACHE, updateCacheSaga);
  yield takeEvery(prescriberActions.UPDATE_CACHE_PRESCRIBERS, updateCachePrescribersSaga);
}
