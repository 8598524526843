import React from 'react';

import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';

import Pagination from '@material-ui/lab/Pagination';

import { privateTheme } from '@layout/theme';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '5vh',
    paddingBottom: '5vh',
    [privateTheme.breakpoints.down('md')]: {
      marginTop: '3vh',
      width: '100vw',
    },
    [privateTheme.breakpoints.up('lg')]: {
      width: '960px',
      margin: '3vh auto 0',
    },
  },
  top: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

const GroupClassesPagination = ({ count, page, changePage, top }) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, top && classes.top)}>
      <Pagination color="primary" count={count} page={page} onChange={changePage} />
    </div>
  );
};

export default GroupClassesPagination;
