import i18n from '../i18n';

/**
 *
 * Given a role id or the role string and return
 * the role translation depending the language (locale)
 *
 * @param {Object} role role id or role string
 * @returns {String} translation
 */

const getRoleTranslation = (role) => {
  return typeof role === 'string' && role.length > 0 ? i18n.t(`GLOBAL.roles.${role}`) : null;
};

export default getRoleTranslation;
