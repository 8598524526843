import * as coachActions from 'store/modules/Coach/actions';

const initialState = {
  coaches: {},
  myAdherents: [],
  calendar: {
    communities: [],
  },
  coachesUsersMin: [],
  coachesUsersTotalCount: 0,
  coachesUsers: [],
  upcomingTask: [],
  tableTask: [],
  detail: null,
  assesmentTask: {},
  assesmentCallback: {},
  resultUpdateAssesmentTask: null,
  coachCalendar: null,
  sessionUsers: null,
  notes: null,
  resultUpdateDateAssesmentTask: null,
  physical_test: null,
  physical_test_users: null,
  user_level: null,
  physical_test_user: null,
  sessionsOptions: null,
  programOptions: null,
  addUserSessionResult: null,
  addUserProgramResult: null,
  userId: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case coachActions.COACH_SEARCH_RESULTS:
      return {
        ...state,
        coachsSearchResults: action.payload,
      };
    case coachActions.UPDATE_CACHE_SET:
      return {
        ...state,
        coaches: action.payload,
      };
    case coachActions.MY_ADHERENTS_SET:
      return {
        ...state,
        myAdherents: action.payload,
      };
    case coachActions.COACH_CALENDAR_SET:
      return {
        ...state,
        calendar: action.payload,
      };
    case coachActions.COACHES_USERS_SET: {
      return {
        ...state,
        coachesUsersTotalCount: action.payload.total_count,
        coachesUsers: action.payload.users || [],
      };
    }
    case coachActions.COACHES_USERS_MIN_SET: {
      return {
        ...state,
        coachesUsersMin: action.payload,
      };
    }
    case coachActions.COACHES_UPCOMING_TASK_SET: {
      return {
        ...state,
        upcomingTask: action.payload,
      };
    }
    case coachActions.GET_COACH_TABLE_TASK: {
      return {
        ...state,
      };
    }
    case coachActions.COACH_TABLE_TASK_SET: {
      return {
        ...state,
        tableTask: action.payload,
      };
    }
    case coachActions.COACH_DETAIL_SET: {
      return {
        ...state,
        detail: action.payload,
        userId: action.payload.id,
      };
    }
    case coachActions.GET_COACH_ASSESMENT_TASK: {
      return {
        ...state,
      };
    }
    case coachActions.COACH_ASSESMENT_TASK_SET: {
      return {
        ...state,
        assesmentTask: action.payload,
      };
    }
    case coachActions.CLEAR_ASSESMENT_TASK: {
      return {
        ...state,
        assesmentTask: null,
        resultUpdateDateAssesmentTask: null,
        physical_test: null,
        physical_test_users: null,
        physical_test_user: null,
        user_level: null,
        sessionsOptions: null,
        programOptions: null,
        addUserSessionResult: null,
        addUserProgramResult: null,
      };
    }
    case coachActions.UPDATE_COACH_ASSESMENT_TASK: {
      return {
        ...state,
        resultUpdateAssesmentTask: null,
      };
    }
    case coachActions.SET_SUCCESS_UPDATE_COACH_ASSESMENT_TASK: {
      return {
        ...state,
        resultUpdateAssesmentTask: true,
      };
    }
    case coachActions.CLEAR_RESULT_UPDATE_COACH_ASSESMENT_TASK: {
      return {
        ...state,
        resultUpdateAssesmentTask: null,
        resultUpdateDateAssesmentTask: null,
      };
    }
    case coachActions.GET_COACH_ASSESMENT_CALLBACK: {
      return {
        ...state,
      };
    }
    case coachActions.COACH_ASSESMENT_CALLBACK_SET: {
      return {
        ...state,
        assesmentCallback: action.payload,
      };
    }
    case coachActions.CLEAR_ASSESMENT_CALLBACK: {
      return {
        ...state,
        days: null,
      };
    }

    case coachActions.SET_CAOCH_CALENDAR_DATA: {
      return {
        ...state,
        coachCalendar: action.payload,
      };
    }
    case coachActions.GET_COACH_CALENDAR_DATA: {
      return {
        ...state,
        coachCalendar: null,
      };
    }
    case coachActions.SET_COACH_SESSION_USER: {
      return {
        ...state,
        sessionUsers: action.payload,
      };
    }
    case coachActions.GET_COACH_SESSION_USER: {
      return {
        ...state,
      };
    }
    case coachActions.CLEAR_TRAINES_BENEFICIARY: {
      return {
        ...state,
        tableTask: [],
        coachCalendar: null,
        sessionUsers: null,
        detail: null,
        notes: null,
      };
    }
    case coachActions.SET_COACH_NOTES: {
      return {
        ...state,
        notes: action.payload,
      };
    }
    case coachActions.SET_SUCCESS_CHANGE_COACH_ASSESMENT_TASK_DATE: {
      return {
        ...state,
        resultUpdateDateAssesmentTask: true,
      };
    }
    case coachActions.SET_PHYSICAL_EVOLUTION_TEST: {
      return {
        ...state,
        physical_test: action.payload.physical_test,
        physical_test_users: action.payload.physical_test_users,
        user_level: action.payload.user_level,
      };
    }
    case coachActions.UPDATE_PHYSICAL_EVOLUTION_TEST: {
      return {
        ...state,
        physical_test_user: action.payload.physical_test_user.result_level,
        user_level: action.payload.user_level,
      };
    }
    case coachActions.SET_SESSIONS_USER: {
      return {
        ...state,
        sessionsOptions: action.payload,
      };
    }
    case coachActions.SET_PROGRAM_USER: {
      return {
        ...state,
        programOptions: action.payload,
      };
    }
    case coachActions.RESULT_ADD_USER_SESSION: {
      return {
        ...state,
        addUserSessionResult: action.payload,
      };
    }
    case coachActions.CLEAR_USER_ADD_PROGRAM: {
      return {
        ...state,
        addUserProgramResult: null,
      };
    }
    case coachActions.CLEAR_USER_ADD_SESSION: {
      return {
        ...state,
        addUserSessionResult: null,
      };
    }
    case coachActions.RESULT_ADD_USER_PROGRAM: {
      return {
        ...state,
        addUserProgramResult: action.payload,
      };
    }
    default:
      return state;
  }
};
