import { takeLatest, call, put, select } from 'redux-saga/effects';

import * as ApiService from 'services/api';
import ProtectedCall from 'services/protected.api';

import { incrementStatusCreator, decrementStatusCreator } from 'store/modules/Status/actions';
import { UPDATE_PROFILE as updateProfileCache } from 'store/modules/Account/actions';
import * as programActions from 'store/modules/Program/actions';

/** ********************************************** */
/** Selectors * */
const allProgramsSelector = (state) => {
  return JSON.parse(JSON.stringify(state.Program.programs));
};

/** ********************************************** */
/** Sagas * */
function* getAllProgramsSaga(data) {
  const result = yield ProtectedCall(ApiService.GetAllPrograms, data.payload);

  yield put({
    type: programActions.CACHE_UPDATE_PROGRAMS,
    payload: result.data.data.programs,
  });

  yield put({
    type: programActions.UPDATE_PROGRAMS_SEARCH_RESULTS,
    payload: result.data.data,
  });
}

function* startSuggestedProgramsSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Assign suggested programs',
      })
    );

    const result = yield call(ApiService.startSuggestedPrograms, data.payload) || {};

    const user = yield ProtectedCall(ApiService.GetUser, 'me');
    yield put({ type: updateProfileCache, payload: user.data.data.user });

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Assign suggested programs finished',
        data: (result.data || {}).data,
      })
    );
  } catch (e) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Assign suggested programs error',
        state: 'error',
        data: e.response.data,
      })
    );
  }
}

function* updateCacheProgramsSaga(data) {
  if (!data || !data.payload || data.payload.length === 0) {
    return;
  }

  const programs = yield select(allProgramsSelector) || {};

  data.payload.map((item) => {
    programs[item.id] = item;

    return null;
  });

  yield put({ type: programActions.CACHE_UPDATE_PROGRAMS_SET, payload: programs });
}

export default function* sagaWatcher() {
  yield takeLatest(programActions.GET_ALL_PROGRAMS, getAllProgramsSaga);
  yield takeLatest(programActions.START_SUGGESTED_PROGRAMS, startSuggestedProgramsSaga);
  yield takeLatest(programActions.CACHE_UPDATE_PROGRAMS, updateCacheProgramsSaga);
}
