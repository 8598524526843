import React from 'react';

import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { uppercaseFirst } from '@shared/helpers';

import Button from '@material-ui/core/Button';

const useStyles = makeStyles(() => ({
  contained: {
    border: '2px solid #fff',
  },
  outlinedPrimary: {
    border: '2px solid #0273bb',
  },
  label: {
    fontFamily: 'Roboto, Georgia, serif',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '1.25rem',
    lineHeight: '1.75rem',
  },
}));

const LoginButton = ({ handleLogin, fullWidth, size, outlined }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const variant = outlined ? 'outlined' : 'contained';

  return (
    <Button
      variant={variant}
      color="primary"
      onClick={handleLogin}
      fullWidth={fullWidth}
      classes={{
        contained: classes.contained,
        outlinedPrimary: classes.outlinedPrimary,
        label: classes.label,
      }}
      size={size}
      data-cy="login-button"
    >
      {uppercaseFirst(t('Login.login'))}
    </Button>
  );
};

export default LoginButton;
