import React, { Component } from 'react';

import './style.css';

class Hamburger extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hamburgerActive: false,
    };

    this.hamburgerClick = this.hamburgerClick.bind(this);
  }

  hamburgerClick(e) {
    e.stopPropagation();

    this.setState((prevState) => ({
      hamburgerActive: !prevState.hamburgerActive,
    }));
  }

  render() {
    return (
      <div
        className={`header-hamburger ${this.state.hamburgerActive ? 'active' : ''}`}
        onClick={this.hamburgerClick}
      >
        <div className="header-hamburger-icon">
          <div></div>
          <div></div>
          <div></div>
        </div>

        <div className="header-hamburger-inner">{this.props.children}</div>
      </div>
    );
  }
}

export default Hamburger;
