export const CALENDAR_SEARCH_REQUEST = 'calendar/CALENDAR_SEARCH_REQUEST';
export const SET_CALENDAR_SEARCH_RESULTS = 'calendar/SET_CALENDAR_SEARCH_RESULTS';

export const ADD_SESSION_TO_CALENDAR_REQUEST = 'calendar/ADD_SESSION_TO_CALENDAR_REQUEST';
export const UPDATE_SESSION_IN_CALENDAR_REQUEST = 'calendar/UPDATE_SESSION_IN_CALENDAR_REQUEST';
export const DELETE_SESSION_FROM_CALENDAR_REQUEST = 'calendar/DELETE_SESSION_FROM_CALENDAR_REQUEST';
export const ADD_PROGRAM_TO_CALENDAR_REQUEST = 'calendar/ADD_PROGRAM_TO_CALENDAR_REQUEST';

export const MAKE_INACTIVITY_CHOICE = 'calendar/MAKE_INACTIVITY_CHOICE';

export const UPDATE_CACHE = 'calendar/UPDATE_CACHE';
export const UPDATE_CACHE_SET = 'calendar/UPDATE_CACHE_SET';

export const CHANGE_SELECTED_DAY = 'calendar/CHANGE_SELECTED_DAY';

export function calendarSearchCreator(payload) {
  return { type: CALENDAR_SEARCH_REQUEST, payload };
}

export function addSessionToCalendarRequest(payload) {
  return { type: ADD_SESSION_TO_CALENDAR_REQUEST, payload };
}

export function updateSessionInCalendarRequest(payload) {
  return { type: UPDATE_SESSION_IN_CALENDAR_REQUEST, payload };
}

export function deleteSessionFromCalendarRequest(payload) {
  return { type: DELETE_SESSION_FROM_CALENDAR_REQUEST, payload };
}

export function addProgramToCalendarRequest(payload) {
  return { type: ADD_PROGRAM_TO_CALENDAR_REQUEST, payload };
}

export function makeInactivityChoice(payload) {
  return { type: MAKE_INACTIVITY_CHOICE, payload };
}

export function changeSelectedDay(payload) {
  return { type: CHANGE_SELECTED_DAY, payload };
}
