export const GET_ALL = 'prescriber/GET_ALL';
export const GET_LIST_PRESCRIBERS = 'prescriber/GET_LIST_PRESCRIBERS';

export const GET_PHYSICAL_TEST_BY_PRESCRIBER = 'session/GET_PHYSICAL_TEST_BY_PRESCRIBER';

export const FETCH_DASHBOARD_DATA = 'prescriber/FETCH_DASHBOARD_DATA';

export const GET_INVITE_CODES = 'prescriber/GET_INVITE_CODES';
export const EXPORT_INVITE_CODES = 'prescriber/EXPORT_INVITE_CODES';
export const CREATE_INVITE_CODES = 'prescriber/CREATE_INVITE_CODES';
export const EDIT_INVITE_CODES = 'prescriber/EDIT_INVITE_CODES';
export const SET_CODES_SEARCH_RESULTS = 'prescriber/SET_CODES_SEARCH_RESULTS';

export const CHECK_CODE = 'prescriber/CHECK_CODE';

export const CREATE_NEW_PRESCRIBER = 'prescriber/CREATE_NEW_PRESCRIBER';
export const EDIT_PRESCRIBER = 'prescriber/EDIT_PRESCRIBER';

export const LINK_USER = 'prescriber/LINK_USER';
export const UNLINK_USER = 'prescriber/UNLINK_USER';

export const UPDATE_CACHE = 'prescriber/UPDATE_CACHE';
export const UPDATE_CACHE_SET = 'prescriber/UPDATE_CACHE_SET';

export const UPDATE_CACHE_PHYSICAL_TEST = 'session/UPDATE_CACHE_PHYSICAL_TEST';
export const UPDATE_CACHE_PRESCRIBERS = 'prescriber/UPDATE_CACHE_PRESCRIBERS';
export const UPDATE_CACHE_PRESCRIBERS_SET = 'prescriber/UPDATE_CACHE_PRESCRIBERS_SET';
export const UPDATE_CACHE_PRESCRIBERS_SEARCH_RESULTS_SET =
  'prescriber/UPDATE_CACHE_PRESCRIBERS_SEARCH_RESULTS_SET';
export const UPDATE_CACHE_PRESCRIBERS_LIST_SET = 'prescriber/UPDATE_CACHE_PRESCRIBERS_LIST_SET';

export function getAllPrescribers(payload) {
  return { type: GET_ALL, payload };
}

export function getListPrescribers(payload) {
  return { type: GET_LIST_PRESCRIBERS, payload };
}

export function getPhysicalTestByPrescriber(payload) {
  return { type: GET_PHYSICAL_TEST_BY_PRESCRIBER, payload };
}

export function fetchDashboardData(payload) {
  return { type: FETCH_DASHBOARD_DATA, payload };
}

export function getInviteCodes(payload) {
  return { type: GET_INVITE_CODES, payload };
}

export function exportInviteCodes(payload) {
  return { type: EXPORT_INVITE_CODES, payload };
}

export function createInviteCodes(payload) {
  return { type: CREATE_INVITE_CODES, payload };
}

export function editInviteCodes(payload) {
  return { type: EDIT_INVITE_CODES, payload };
}

export function checkCode(payload) {
  return { type: CHECK_CODE, payload };
}

export function createNewPrescriber(payload) {
  return { type: CREATE_NEW_PRESCRIBER, payload };
}

export function editPrescriber(payload) {
  return { type: EDIT_PRESCRIBER, payload };
}

export function linkUserToPrescriberWithCode(payload) {
  return { type: LINK_USER, payload };
}

export function unlinkUserToPrescriber(payload) {
  return { type: UNLINK_USER, payload };
}
