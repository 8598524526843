// @flow
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import { publicTheme } from '@layout/theme';
import { normalizeTime } from '@services/time';
import { makeStyles } from '@material-ui/core/styles';
import { NextPrivateLayout } from '@layout/components';
import { privateTabs } from '@header/constants';
import { BLACK, MAIN_TEXT_COLOR } from '@shared/constants';
import * as groupClassSelectors from '@groupClasses/selectors';
import { getCommunityCreator } from '@store/modules/Community/actions';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import GroupClassesMenu from './GroupClassesMenu';
import GroupClassInformation from './GroupClassInformation';
import GroupClassMenuParticipants from './GroupClassMenuParticipants';

import type { Community } from '../models';
import { SECTIONS } from '../constants';

const useStyles = makeStyles(() => ({
  title: {
    color: MAIN_TEXT_COLOR,
    textAlign: 'center',
  },
  subtitle: {
    color: BLACK,
    textAlign: 'center',
  },
  container: {
    margin: '3rem 0',
  },
  containerDetail: {
    flexDirection: 'row',
    [publicTheme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
    },
  },
}));

type Props = {};

const GroupClassesDetails = ({ match }: Props) => {
  const classes = useStyles();
  const [activeSection, setActiveSection] = useState(SECTIONS[0]);
  const groupClass: Community | null = useSelector((state) =>
    groupClassSelectors.currentGroupClassSelector(state)
  );

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (match.params.id) {
      dispatch(
        getCommunityCreator({
          communityId: match.params.id,
        })
      );
    }
  }, []);

  const onActiveSection = (activeSection) => {
    setActiveSection(activeSection);
  };

  return (
    <NextPrivateLayout tabs={privateTabs} activeTab="myGroupClasses">
      {groupClass && (
        <Box sx={{ display: 'flex' }} className={classes.containerDetail}>
          {groupClass && activeSection === SECTIONS[1] && (
            <GroupClassMenuParticipants communityId={groupClass.id} />
          )}

          <Box sx={{ flexGrow: 1 }}>
            <div className={clsx('container', classes.container)}>
              <Typography variant="h3" className={classes.title} gutterBottom>
                {groupClass.name}
              </Typography>
              {groupClass.day && (
                <Typography variant="h5" className={classes.subtitle} gutterBottom>
                  {`${groupClass.day.name}
                    ${normalizeTime(groupClass.start_time, 'time')}
                    -
                    ${normalizeTime(groupClass.end_time, 'time')}`}
                </Typography>
              )}
              <div className="container">
                <GroupClassesMenu onActiveSection={onActiveSection} />
              </div>
              {groupClass && activeSection === SECTIONS[0] && (
                <GroupClassInformation groupClass={groupClass}></GroupClassInformation>
              )}
            </div>
          </Box>
        </Box>
      )}
    </NextPrivateLayout>
  );
};

GroupClassesDetails.defaultProps = {};

export default GroupClassesDetails;
