// @flow

import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';

import { publicTheme } from '@layout/theme';

import DoubleCaretSvg from '../assets/double-caret.svg';

const useStyles = makeStyles(() => ({
  root: {
    width: '8vw',
    height: '8vh',
    [publicTheme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}));

const DoubleCaret = () => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root)}>
      <img src={DoubleCaretSvg} alt="double caret" width={150} height={100} />
    </div>
  );
};

export default DoubleCaret;
