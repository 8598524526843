import { call, put, select, delay } from 'redux-saga/effects';
import moment from 'moment';
import { updateToken } from 'store/modules/Auth/actions';
import { getTokenInfo } from './token';

/**
 * Wrapped `call` effect
 * @description check token expiration before each `call`
 *  and update it if necessary.
 * @param {*} args
 */
function* ProtectedCall(...args) {
  const { exp } = yield call(getTokenInfo);
  const { isRefreshingToken } = yield select((state) => state.Auth);

  if (exp - moment().format('X') <= 0 && !!exp) {
    if (!isRefreshingToken) {
      yield put(updateToken());
    }
  }
  if (isRefreshingToken) {
    yield delay(300);
    return yield ProtectedCall(...args);
  }

  return yield call(...args);
}

export default ProtectedCall;
