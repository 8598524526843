import { getLocale } from 'services/localisation';

export function getAddressGeocoded(address, countryRestriction, callback) {
  const geocoder = new window.google.maps.Geocoder();

  let params = {
    language: getLocale(),
    address: address,
    region: countryRestriction,
  };

  if (countryRestriction && typeof countryRestriction === 'string') {
    params.componentRestrictions = {
      country: countryRestriction,
    };
  }

  geocoder.geocode(params, (results, status) => {
    callback(results, status);
  });
}

export function geocodeLatLng(latlng, callback) {
  const geocoder = new window.google.maps.Geocoder();

  geocoder.geocode({ location: latlng }, callback);
}

export function getGeocodeInformationsFormated(place) {
  const toReturn = {
    ...place,
    lat: ((place.geometry || {}).location || {}).lat() || null,
    long: ((place.geometry || {}).location || {}).lng() || null,
  };

  const cityTypes = [
    'administrative_area_level_1',
    'administrative_area_level_2',
    'administrative_area_level_3',
    'administrative_area_level_4',
    'administrative_area_level_5',
    'administrative_area_level_6',
    'administrative_area_level_7',
    'colloquial_area',
    'locality',
    'postal_town',
    'sublocality_level_1',
    'sublocality_level_2',
    'sublocality_level_3',
    'sublocality_level_4',
    'sublocality_level_5',
    'neighborhood',
    'premise',
    'subpremise',
  ];

  if (place.address_components) {
    place.address_components.map((item) => {
      // From top to bottom we are gonna select the most specific location name
      item.types.reverse().map((item2) => {
        if (cityTypes.includes(item2)) {
          if (!toReturn.city) {
            toReturn.city = item.long_name;
          }
        }

        if (item2 === 'country') {
          toReturn.country = item.long_name;
          toReturn.country_short = item.short_name;
        }

        if (item2 === 'postal_code') {
          toReturn.zip_code = item.long_name;
        }

        if (item2 === 'street_number') {
          toReturn.street_number = item.long_name;
        }

        if (item2 === 'route') {
          toReturn.route = item.long_name;
        }

        return null;
      });

      return null;
    });

    if (toReturn.street_number && toReturn.route)
      toReturn.address = `${toReturn.street_number} ${toReturn.route}`;
    else if (toReturn.route) toReturn.address = `${toReturn.route}`;
  }

  if (place.name) {
    toReturn.placeName = place.name;
  }

  return toReturn;
}

export function getLocationFormatted(data) {
  let dataToReturn = `${data.city}, ${(data.country || {}).name}`;

  if (data.address && data.zip_code) {
    dataToReturn = `${data.address}, ${data.zip_code} ${data.city}, ${(data.country || {}).name}`;
  } else if (data.zip_code) {
    dataToReturn = `${data.zip_code} ${data.city}, ${(data.country || {}).name}`;
  } else if (data.address) {
    dataToReturn = `${data.address}, ${data.city}, ${(data.country || {}).name}`;
  }

  return dataToReturn;
}
