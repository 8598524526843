import React, { Component } from 'react';
import { sortCountries } from 'services/country';
import { getLocale } from 'services/localisation';

import { getCountryCallingCode } from 'react-phone-number-input';
import Select, { components } from 'react-select';

import Flag from 'react-country-flags';
import Input from 'react-phone-number-input/input';

// eslint-disable-next-line import/no-duplicates
import en from 'react-phone-number-input/locale/en.json';
import es from 'react-phone-number-input/locale/es.json';
import fr from 'react-phone-number-input/locale/fr.json';
import de from 'react-phone-number-input/locale/de.json';
import it from 'react-phone-number-input/locale/it.json';
// eslint-disable-next-line import/no-duplicates
import nl from 'react-phone-number-input/locale/en.json';
import pt from 'react-phone-number-input/locale/pt.json';

import './style.css';
import 'react-phone-number-input/style.css';

const COUNTRY_LABELS_TRANSLATIONS = {
  en,
  es,
  fr,
  de,
  it,
  nl,
  pt,
  'es-AR': es,
};

const ValueContainer = ({ children, ...props }) => {
  let country = ((props.getValue() || [])[0] || {}).value || '';
  country = country === 'AC' ? 'GB' : country;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.ValueContainer {...props}>
      {/* eslint-disable-next-line react/jsx-boolean-value */}
      <Flag className="flag" country={country.toLowerCase()} asSquare={true} />
      {!props.hasValue && children}
    </components.ValueContainer>
  );
};

const baseClass = 'input-international-phone-number';

class InputInternationalPhoneNumber extends Component {
  constructor() {
    super();

    this.state = {
      lang: getLocale() || 'fr',
    };

    this.handleChangePhoneCountry = this.handleChangePhoneCountry.bind(this);
  }

  componentDidMount() {
    let countryLabelsByLang = COUNTRY_LABELS_TRANSLATIONS[getLocale()];
    let phoneNumberCountryOption = null;
    let { phoneNumber, phoneNumberCountry } = this.props;

    if (phoneNumberCountry) {
      phoneNumberCountryOption = {
        label: `${countryLabelsByLang[phoneNumberCountry]} +${getCountryCallingCode(
          phoneNumberCountry
        )} `,
        value: phoneNumberCountry,
      };
    }

    let countryCode = getCountryCallingCode(phoneNumberCountry);

    if (phoneNumber) {
      phoneNumber = this.formatPhoneNumber(phoneNumber);
    }

    this.setState({
      phoneNumber: phoneNumber,
      phoneNumberCountry: phoneNumberCountry,
      phoneNumberCountryOption,
      phoneNumberCountryCode: countryCode,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.phoneNumber !== this.props.phoneNumber) {
      let phoneNumber = this.props.phoneNumber;

      if (this.props.phoneNumber) {
        phoneNumber = this.formatPhoneNumber(this.props.phoneNumber);
      }

      this.setState({
        phoneNumber,
      });
    }
  }

  formatPhoneNumber(phoneNumber) {
    let countryCode = getCountryCallingCode(this.props.phoneNumberCountry);

    return this.props.phoneNumber.charAt(0) !== '+' ? `+${countryCode}${phoneNumber}` : phoneNumber;
  }

  handleChangePhoneCountry(countryOption) {
    if (!countryOption) return;

    let country = countryOption.value;
    let countryCode = getCountryCallingCode(country);

    this.setState(
      {
        phoneNumberCountryCode: countryCode,
        phoneNumberCountry: country,
        phoneNumberCountryOption: countryOption,
      },
      () => {
        this.props.handlePhoneNumberChange(this.state.phoneNumber, this.state.phoneNumberCountry);
      }
    );
  }

  render() {
    return (
      <div className={`${baseClass} ${this.props.className}`}>
        <Select
          onChange={(country) => {
            this.handleChangePhoneCountry(country);
          }}
          value={this.state.phoneNumberCountryOption}
          name="phone_number_country"
          options={sortCountries().map((country) => {
            return {
              label: country.label,
              value: country.country,
            };
          })}
          isSearchable
          components={{ ValueContainer }}
          className="country-select"
          classNamePrefix={'react-select'}
          placeholder="+00"
          isDisabled={this.props.readOnly}
        />

        <div className={`${baseClass}`}>
          <div className={`${baseClass}-country-code`}>+({this.state.phoneNumberCountryCode})</div>

          <div className={`${baseClass}-input`}>
            <Input
              country={this.state.phoneNumberCountry}
              value={this.state.phoneNumber}
              onChange={(phoneNumber) =>
                this.props.handlePhoneNumberChange(phoneNumber, this.state.phoneNumberCountry)
              }
              readOnly={this.props.readOnly}
              required={this.props.required}
            />
          </div>
        </div>
      </div>
    );
  }
}

InputInternationalPhoneNumber.propTypes = {};

InputInternationalPhoneNumber.defaultProps = {};

export default InputInternationalPhoneNumber;
