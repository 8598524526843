export const USER_SEARCH = 'user/USER_SEARCH';
export const USER_SEARCH_RESULTS = 'user/USER_SEARCH_RESULTS';
export const GET_USER_BY_ID = 'user/GET_USER_BY_ID';
export const DELETE_USER_BY_ID = 'user/DELETE_USER_BY_ID';
export const UPDATE_USER_BY_ID = 'user/UPDATE_USER_BY_ID';
export const UPDATE_USERS = 'user/UPDATE_USERS';

export const GET_USER_COACH = 'user/GET_USER_COACH';
export const SET_USER_AVATAR_REQUEST = 'user/SET_USER_AVATAR_REQUEST';
export const GET_ALL_ROLES = 'user/GET_ALL_ROLES';
export const ADD_USER_ROLE = 'user/ADD_USER_ROLE';
export const ADD_USER_ROLES = 'user/ADD_USER_ROLES';
export const GET_USER_JOURNEY = 'user/GET_USER_JOURNEY';
export const GET_USER_WEEKLY_REPORT = 'user/GET_USER_WEEKLY_REPORT';
export const FILL_USER_WEEKLY_REPORT = 'user/FILL_USER_WEEKLY_REPORT';

// TODO: belong ins separate search store maybe? Or should be split up into users/groups stores?
export const MES_AMIS_SEARCH = 'user/MES_AMIS_SEARCH';
export const MES_AMIS_SEARCH_CLEAR = 'user/MES_AMIS_SEARCH_CLEAR';
export const UPDATE_MES_AMIS_SEARCH_RESULTS = 'user/UPDATE_MES_AMIS_SEARCH_RESULTS';

export const SEND_FRIEND_INVITE = 'user/SEND_FRIEND_INVITE';
export const REFUSE_FRIEND_INVITE = 'user/REFUSE_FRIEND_INVITE';
export const ACCEPT_FRIEND_INVITE = 'user/ACCEPT_FRIEND_INVITE';
export const REMOVE_FRIEND = 'user/REMOVE_FRIEND';

export const GET_FRIENDS = 'user/GET_FRIENDS';
export const UPDATE_FRIENDS_RESULTS = 'user/UPDATE_FRIENDS_RESULTS';
export const UPDATE_USER_JOURNEY = 'user/UPDATE_USER_JOURNEY';
export const UPDATE_USER_WEEKLY_REPORT = 'user/UPDATE_USER_WEEKLY_REPORT';

export const SEND_EMAIL_INVITE = 'user/SEND_EMAIL_INVITE';

export const GET_FRIEND_SUGGESTIONS = 'user/GET_FRIEND_SUGGESTIONS';
export const GET_GROUP_SUGGESTIONS = 'user/GET_GROUP_SUGGESTIONS';

export const SUBMIT_PRIMARY_QUESTIONNAIRE = 'user/SUBMIT_PRIMARY_QUESTIONNAIRE';
export const GET_PRIMARY_QUESTIONNAIRE = 'user/GET_PRIMARY_QUESTIONNAIRE';
export const SAVE_DECLARATION_PRIMARY_QUESTIONNAIRE = 'user/SAVE_DECLARATION_PRIMARY_QUESTIONNAIRE';

export const CHANGE_QUESTIONNAIRE_STATE = 'user/CHANGE_QUESTIONNAIRE_STATE';
export const BILANS_INFO_SUBMIT = 'user/BILANS_INFO_SUBMIT';
export const BILANS_INFO_GET = 'user/BILANS_INFO_GET';

export const BILANS_INITIAL_SUBMIT = 'user/BILANS_INITIAL_SUBMIT';
export const BILANS_INITIAL_GET = 'user/BILANS_INITIAL_GET';
export const BILANS_INITIAL_SUBMIT_COMMENT = "'user/BILANS_INITIAL_SUBMIT_COMMENT";

export const BILANS_INTERMEDIATE_SUBMIT = 'user/BILANS_INTERMEDIATE_SUBMIT';
export const BILANS_INTERMEDIATE_GET = 'user/BILANS_INTERMEDIATE_GET';

export const BILANS_FINAL_SUBMIT = 'user/BILANS_FINAL_SUBMIT';
export const BILANS_FINAL_GET = 'user/BILANS_FINAL_GET';

export const BILANS_SATISFACTION_SUBMIT = 'user/BILANS_SATISFACTION_SUBMIT';
export const BILANS_SATISFACTION_GET = 'user/BILANS_SATISFACTION_GET';

export const BILANS_ANALYSIS_GET_RESOURCES = 'user/BILANS_ANALYSIS_GET_RESOURCES';
export const BILANS_ANALYSIS_GET = 'user/BILANS_ANALYSIS_GET';
export const BILANS_ANALYSIS_CREATE = 'user/BILANS_ANALYSIS_CREATE';

export const GET_MANAGER_SECTORS = 'user/GET_MANAGER_SECTORS';

export const UPDATE_CACHE = 'user/UPDATE_CACHE';
export const UPDATE_CACHE_SET = 'user/UPDATE_CACHE_SET';
export const UPDATE_CACHE_ROLES = 'user/UPDATE_CACHE_ROLES';
export const UPDATE_CACHE_SECTORS = 'user/UPDATE_CACHE_SECTORS';

export const GET_USER_PAYMENTS = 'user/GET_USER_PAYMENTS';
export const GET_USER_PAYMENTS_RESULTS = 'user/GET_USER_PAYMENTS_RESULTS';

export const CREATE_USER = 'user/CREATE_USER';

export const SEND_GETPHY_INVITATIONS = 'user/SEND_GETPHY_INVITATIONS';

export const EXPORT_USERS = 'user/EXPORT_USERS';

export function getUserPayments(payload) {
  return { type: GET_USER_PAYMENTS, payload };
}

export function userSearch(payload) {
  return { type: USER_SEARCH, payload };
}

export function getUserByIdCreator(payload) {
  return { type: GET_USER_BY_ID, payload };
}

export function getUserJourney(payload) {
  return { type: GET_USER_JOURNEY, payload };
}

export function getUserWeeklyReport(payload) {
  return { type: GET_USER_WEEKLY_REPORT, payload };
}

export function fillUserWeeklyReport(payload) {
  return { type: FILL_USER_WEEKLY_REPORT, payload };
}

export function deleteUserById(payload) {
  return { type: DELETE_USER_BY_ID, payload };
}

export function updateUserById(payload) {
  return { type: UPDATE_USER_BY_ID, payload };
}

export function updateUsers(payload) {
  return { type: UPDATE_USERS, payload };
}

export function getManagerSectors(payload) {
  return { type: GET_MANAGER_SECTORS, payload };
}

export function getUserCoachCreator(payload) {
  return { type: GET_USER_COACH, payload };
}

export function getAllRolesCreator(payload) {
  return { type: GET_ALL_ROLES, payload: payload || {} };
}

export function assignRoleToUser(payload) {
  return { type: ADD_USER_ROLE, payload };
}

export function assignRolesToUser(payload) {
  return { type: ADD_USER_ROLES, payload };
}

export function setUserAvatarCreator() {
  // return {type: USER_ADMIN_SEARCH, payload}
}

export function mesAmisSearchCreator(payload) {
  return { type: MES_AMIS_SEARCH, payload };
}

export function clearMesAmisSearch(payload) {
  return { type: MES_AMIS_SEARCH_CLEAR, payload };
}

export function sendFriendInviteCreator(payload) {
  return { type: SEND_FRIEND_INVITE, payload };
}

export function refuseFriendInviteCreator(payload) {
  return { type: REFUSE_FRIEND_INVITE, payload };
}

export function acceptFriendInviteCreator(payload) {
  return { type: ACCEPT_FRIEND_INVITE, payload };
}

export function removeFriendCreator(payload) {
  return { type: REMOVE_FRIEND, payload };
}

export function getFriendsCreator(payload) {
  return { type: GET_FRIENDS, payload };
}

export function getFriendSuggestions(payload) {
  return { type: GET_FRIEND_SUGGESTIONS, payload };
}

export function getGroupSuggestions(payload) {
  return { type: GET_GROUP_SUGGESTIONS, payload };
}

export function sendEmailInviteCreator(payload) {
  return { type: SEND_EMAIL_INVITE, payload };
}

export function submitPrimaryQuestionnaire(payload) {
  return { type: SUBMIT_PRIMARY_QUESTIONNAIRE, payload };
}

export function getPrimaryQuestionnaire(payload) {
  return { type: GET_PRIMARY_QUESTIONNAIRE, payload };
}

export function saveDeclarationPrimaryQuestionnaire(payload) {
  return { type: SAVE_DECLARATION_PRIMARY_QUESTIONNAIRE, payload };
}

export function changeQuestionnaireStageCreator(payload) {
  return { type: CHANGE_QUESTIONNAIRE_STATE, payload };
}

export function bilansInfoSubmitCreator(payload) {
  return { type: BILANS_INFO_SUBMIT, payload };
}

export function bilansInfoGet(payload) {
  return { type: BILANS_INFO_GET, payload };
}

export function bilansInitialSubmitCreator(payload) {
  return { type: BILANS_INITIAL_SUBMIT, payload };
}

export function bilansInitialGet(payload) {
  return { type: BILANS_INITIAL_GET, payload };
}

export function bilansInitialCommentSubmit(payload) {
  return { type: BILANS_INITIAL_SUBMIT_COMMENT, payload };
}

export function bilansIntermediateSubmitCreator(payload) {
  return { type: BILANS_INTERMEDIATE_SUBMIT, payload };
}

export function bilansIntermediateGet(payload) {
  return { type: BILANS_INTERMEDIATE_GET, payload };
}

export function bilansFinalSubmitCreator(payload) {
  return { type: BILANS_FINAL_SUBMIT, payload };
}

export function bilansFinalGet(payload) {
  return { type: BILANS_FINAL_GET, payload };
}

export function bilansSatisfactionSubmitCreator(payload) {
  return { type: BILANS_SATISFACTION_SUBMIT, payload };
}

export function bilansSatisafactionGet(payload) {
  return { type: BILANS_SATISFACTION_GET, payload };
}

export function bilansAnalysisGetResources(payload) {
  return { type: BILANS_ANALYSIS_GET_RESOURCES, payload };
}

export function bilansAnalysisGet(payload) {
  return { type: BILANS_ANALYSIS_GET, payload };
}

export function bilansAnalysisCreate(payload) {
  return { type: BILANS_ANALYSIS_CREATE, payload };
}

export function setUserAvatar(payload) {
  return { type: SET_USER_AVATAR_REQUEST, payload };
}

export function exportUsers(payload) {
  return { type: EXPORT_USERS, payload };
}

export function createUser(payload) {
  return { type: CREATE_USER, payload };
}

export function sendGetphyInvitations(payload) {
  return { type: SEND_GETPHY_INVITATIONS, payload };
}
