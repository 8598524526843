import React, { Component } from 'react';

import Icon from 'components/Icon';

const baseClass = 'nav-bar-nav-item';

class NavExternalItem extends Component {
  render() {
    return (
      <div className={`${baseClass}`}>
        <a
          href={this.props.item.link}
          className={`${baseClass}-link ${this.props.item.className || ''}`}
          target={`_blank`}
        >
          <Icon src={this.props.item.icon} className="nav-icon" />
        </a>
        <div className={`${baseClass}-nav-text`}>{this.props.item.text}</div>
      </div>
    );
  }
}

export default NavExternalItem;
