import { prescriberCodeEcosante } from 'constants/constants';
import * as promoteActions from 'store/modules/Promote/actions';

const initialState = {
  display: false,
  text: '',
  action: {
    text: '',
    prescriptorProgramCode: '',
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case promoteActions.ADD_ECOSANTE_PROMOTION:
      return {
        ...state,
        display: true,
        text: 'PromotingBanner.text',
        action: {
          text: 'PromotingBanner.action.text',
          prescriptorProgramCode: prescriberCodeEcosante,
        },
      };
    case promoteActions.DISPLAY_PROMOTION:
      return {
        ...state,
        display: true,
      };
    case promoteActions.HIDE_PROMOTION:
      return {
        ...state,
        display: false,
      };
    default:
      return state;
  }
};
