import * as calendarActions from 'store/modules/Calendar/actions';

const initialState = {
  calendars: {},
  selectedDay: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case calendarActions.UPDATE_CACHE_SET:
      return {
        ...state,
        calendars: action.payload,
      };
    case calendarActions.CHANGE_SELECTED_DAY:
      return {
        ...state,
        selectedDay: action.payload,
      };
    default:
      return state;
  }
};
