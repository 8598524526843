import { takeEvery, call, put } from 'redux-saga/effects';

import * as ApiService from 'services/api';

import * as sysadminActions from 'store/modules/SysAdmin/actions';

/** ********************************************** */
/** Selectors * */

/** ********************************************** */
/** Sagas * */
function* getResetOptionsSaga(data) {
  const result = yield call(ApiService.GetResetOptions, data.payload);

  yield put({ type: sysadminActions.GET_RESET_OPTIONS_RESULTS, payload: result.data.data });
}

export default function* sagaWatcher() {
  yield takeEvery(sysadminActions.GET_RESET_OPTIONS, getResetOptionsSaga);
}
