import { createSelector } from 'reselect';

import * as coreConstants from '@core/constants';

export const appStateSelector = (state) => state[coreConstants.NAME];

export const selectedLanguageSelector = createSelector(
  appStateSelector,
  (appState) => appState.language
);

export const languagesCollectionSelector = createSelector(
  appStateSelector,
  (appState) => appState.languages
);
