// @flow
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { publicTheme } from '@layout/theme';
import { getCommunityParticipants } from '@store/modules/Community/actions';
import * as groupClassSelectors from '@groupClasses/selectors';
import { MAIN_TEXT_COLOR, NEUTRAL_COLOR } from '@shared/constants';
import GroupClassMenuParticipantList from './GroupClassMenuParticipantList';
import type { Participant } from '../models';

const useStyles = makeStyles(() => ({
  container: {
    color: MAIN_TEXT_COLOR,
    backgroundColor: NEUTRAL_COLOR,
    height: '100%',
    width: '25rem',
    paddingTop: '1rem',
    [publicTheme.breakpoints.down('xs')]: {
      width: '100%',
      paddingTop: '0',
      marginBottom: '3rem',
    },
  },
  title: {
    padding: '1rem',
  },
  edit: {
    display: 'block',
    margin: 'auto',
    marginBottom: '1rem',
    width: '80%',
  },
  editText: {
    fontWeight: 'bold',
  },
  sectionTitle: {
    width: '100%',
    height: '6rem',
    alignItems: 'center',
    [publicTheme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'space-between',
      cursor: 'pointer',
    },
  },
  sectionData: {
    paddingBottom: '1rem 0 2rem 0',
    [publicTheme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  sectionIsOpen: {
    display: 'block',
    [publicTheme.breakpoints.down('xs')]: {
      padding: '2rem 0',
    },
  },
  arrowIcon: {
    display: 'none',
    [publicTheme.breakpoints.down('xs')]: {
      display: 'block',
      width: '4rem',
      height: '4rem',
      '&.makeStyles-isOpen-56': {
        transform: 'rotate(180deg)',
      },
    },
  },
}));

type Props = {
  communityId: number,
};

const GroupClassMenuParticipants = ({ communityId }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [activeSection, setActiveSection] = useState(false);
  const mql = window.matchMedia('(max-width: 600px)');

  const groupClassParticipant: Participant | null = useSelector((state) =>
    groupClassSelectors.participantGroupClassSelector(state)
  );

  const groupClassJoinRequest: Participant | null = useSelector((state) =>
    groupClassSelectors.joinRequestGroupClassSelector(state)
  );

  React.useEffect(() => {
    if (communityId) {
      dispatch(
        getCommunityParticipants({
          communityId: communityId,
        })
      );
    }
  }, []);

  const handleClick = () => {
    if (mql.matches) {
      setActiveSection(!activeSection);
    }
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.sectionTitle} onClick={() => handleClick()}>
        <Typography variant="h4" className={classes.title}>
          {t(`GroupClasses.participantMenu.title`)}
        </Typography>
        <KeyboardArrowDownIcon
          className={clsx(activeSection && classes.isOpen, classes.arrowIcon)}
        />
      </Box>

      <Box className={clsx(activeSection && classes.sectionIsOpen, classes.sectionData)}>
        <Button
          key={`group-class-information-edit`}
          variant="contained"
          lg="large"
          style={publicTheme.palette.accentuated}
          className={classes.edit}
          onClick={() => {}}
        >
          <Typography variant="h6" className={classes.editText}>
            {t(`GroupClasses.participantMenu.addParticipantButton`)}
          </Typography>
        </Button>

        <GroupClassMenuParticipantList
          title="participantRequests"
          participants={groupClassJoinRequest && groupClassJoinRequest[communityId]}
        />
        <GroupClassMenuParticipantList
          title="registeredParticipants"
          participants={groupClassParticipant && groupClassParticipant[communityId]}
        />
      </Box>
    </Box>
  );
};

export default GroupClassMenuParticipants;
