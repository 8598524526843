import * as communityActions from 'store/modules/Community/actions';

const initialState = {
  myCommunities: {
    communities: [],
    events: [],
    groups: [],
  },
  searchResults: [],
  communities: {},
  events: {},
  groups: {},
  joinRequests: {},
  participants: {},
  managers: {},
  communityPaymentStatus: [],
  current: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case communityActions.SET_MY_COMMUNITIES:
      return {
        ...state,
        myCommunities: action.payload,
      };

    case communityActions.SET_COMMUNITIES_SEARCH_RESULTS:
      return {
        ...state,
        searchResults: action.payload,
      };

    case communityActions.CACHE_UPDATE_COMMUNITIES_SET:
      return {
        ...state,
        communities: action.payload,
      };

    case communityActions.CACHE_UPDATE_JOIN_REQUESTS_SET:
      return {
        ...state,
        joinRequests: action.payload,
      };

    case communityActions.CACHE_UPDATE_PARTICIPANTS_SET:
      return {
        ...state,
        participants: action.payload,
      };

    case communityActions.SET_COMMUNITY_PAYMENT_STATUS:
      return {
        ...state,
        communityPaymentStatus: action.payload,
      };

    case communityActions.SET_COMMUNITY_REQUEST:
      return {
        ...state,
        communityRequests: action.payload,
      };

    case communityActions.CACHE_UPDATE_COMMUNITIES_USER:
      return {
        ...state,
        communitiesUser: action.payload,
      };
    case communityActions.CACHE_UPDATE_COMMUNITIES:
      return {
        ...state,
        current: action.payload[0] || null,
      };
    default:
      return state;
  }
};
