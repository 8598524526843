import React, { Component } from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import loadable from '@loadable/component';

import getQueryParam from 'services/location';
import { getCurrentRole } from 'services/roles';
import { getToken } from 'services/token';
import { init, refetchProfile, submitProfileUpdate } from 'store/modules/Account/actions';
import { getGetphyExpressVideos } from 'store/modules/GetphyExpress/actions';
import { getCountries } from 'store/modules/Resource/actions';
import { getSeoPages } from 'store/modules/Seo/actions';
import * as prescriberAction from 'store/modules/Prescriber/actions';
import * as promoteAction from 'store/modules/Promote/actions';
import hasLanguageChanged from 'helpers/props-watcher';
import { getLocale } from 'services/localisation';
import {
  FORCE_REDIRECTION_AFTERLOGIN_WORD,
  LOGIN_MODAL_WORD_OPENER_FROM_URL,
} from 'constants/constants';
import { isEqual } from 'lodash';

import { itContains } from '@services/utils';
import { isUserIPF } from '@user/helpers';
import { isUserGeneralAssessment } from 'app/user/helpers';

import withSlugs from '../../layouts/withSlugs';

import {
  commonRoutes,
  loggedOutRoutes,
  managerRoutes,
  adminRoutes,
  prescriberRoutes,
  coachRoutes,
  generalAssessmentUserRoutes,
  getUserRoutesFiltered,
} from '../../routes';

import './style.css';

const ModalSetBirthdate = loadable(() => import('app/modals/components/ModalSetBirthdate'));
const NotFound404 = loadable(() => import('containers/PageNotFound404'));

const statusRef = 'App-init';
const statusRefSaveProfile = 'ModalBirthdate-SetBirthdate';
const statusRefPrescriptorCode = 'PromotingBanner-PrescriptorCode';
const statusRefSeoPages = 'App-getSeoPages';

class App extends Component {
  constructor() {
    super();
    this.state = {
      routes: {
        routes: [],
        homeRoute: '/',
      },
      birthdateDataToSet: false,
      initialised: false,
      unsubscribeEmail: false,
      languageChanged: false,
    };

    this.hasNewDesign = this.hasNewDesign.bind(this);
  }

  componentDidMount() {
    // we force redirection if the param exists , for the case if user is already loged in
    if (getQueryParam(FORCE_REDIRECTION_AFTERLOGIN_WORD) && getToken()) {
      this.props.history.push(getQueryParam(FORCE_REDIRECTION_AFTERLOGIN_WORD));
    }
    this.props.dispatch(
      init({
        statusRef: statusRef,
      })
    );
    // Load static initial data which don't require authentication
    this.props.dispatch(getCountries({}));
    if (this.props.location.pathname.includes('/unsubscribe/email')) {
      this.setState({
        unsubscribeEmail: true,
      });
    }
    this.props.dispatch(
      getSeoPages({
        statusRef: statusRefSeoPages,
      })
    );
    if (this.props.isLoggedIn) {
      this.props.dispatch(getGetphyExpressVideos({}));
    }
  }

  componentDidUpdate(prevProps) {
    const { user, history, location, role, statuses, dispatch } = this.props;
    const { languageChanged, initialised } = this.state;
    const { pathname } = location;
    // Load static initial data which requires authentication
    // if (this.props.isLoggedIn && prevProps.isLoggedIn !== this.props.isLoggedIn) {
    // }
    if (hasLanguageChanged(user, prevProps.user) || languageChanged) {
      this.setState({
        languageChanged: false,
      });
      dispatch(getSeoPages({}));
    }
    if (role !== prevProps.role || !isEqual(user.prescribers, prevProps.user.prescribers)) {
      if (!role) {
        // Leave the current page if the user logout
        history.push('/');
      } else {
        setTimeout(() => {
          this.processRoleChange();
          if (this.props.role === 'user' && this.props.user.birthdate === null) {
            this.handleBirthdateData(true);
          }
        }, 100);
      }
    }
    if (
      statuses[statusRef] &&
      statuses[statusRef].state === 'finished' &&
      (prevProps.statuses[statusRef] || {}).state !== 'finished'
    ) {
      // run processRoleChange() only once.
      if (!initialised) {
        this.processRoleChange();
        this.setState({
          initialised: true,
        });
      }
    }
    if (prevProps.location.pathname !== pathname) {
      if (pathname !== '/moi/evaluation') {
        window.scrollTo({ top: 0 });
      }
    }
    if (this.pageExistInLoginArea() && !getToken()) {
      history.push(
        `/${getLocale()}?redirectUrl=${window.location.pathname}${
          window.location.search
        }&open=${LOGIN_MODAL_WORD_OPENER_FROM_URL}`
      );
    }

    if (
      statuses[statusRefPrescriptorCode] &&
      statuses[statusRefPrescriptorCode].state === 'finished' &&
      prevProps.statuses[statusRefPrescriptorCode]?.state !== 'finished'
    ) {
      dispatch(
        refetchProfile({
          user_id: 'me',
        })
      );
    }
  }

  // eslint-disable-next-line consistent-return
  skipRedirects = () => {
    // If being redirected back from Monetico afer a course payment
    const moneticoSuccessParam = getQueryParam('monetico_success');
    const { getSlugByName, location } = this.props;
    if (moneticoSuccessParam) return true;
    const { pathname } = location;
    if (itContains(pathname, '/sessions') && !itContains(pathname, '/sessions/evaluation'))
      return true;
    if (itContains(pathname, `/blog`)) return true;
    if (itContains(pathname, `/contact`)) return true;
    if (itContains(pathname, `/ecosante-details`)) return true;
    if (itContains(pathname, `/communities`)) return true;
    if (itContains(pathname, `/friends`)) return true;
    if (itContains(pathname, getSlugByName(`terms`))) return true;
    if (itContains(pathname, `/stats/dashboard`)) return true;
    if (itContains(pathname, `/test/gauges`)) return true;
    if (itContains(pathname, `/group-classes`)) return true;
  };

  handleBirthdateData(birthdateDataToSet) {
    this.setState({
      birthdateDataToSet: birthdateDataToSet,
    });
  }

  // TODO: Tidy and split into smaller chunks
  processRoleChange = () => {
    const role = this.props.role || getCurrentRole();
    let skipRedirect = this.skipRedirects();
    let pathToRedirect = '';
    let paramWhenRedirect = {};
    let routes;
    const { user } = this.props;
    switch (role) {
      case 'user':
        // we redirect if we have in the props the after login redirection dispatched action
        if (this.props.redirectToAnUrlAfterUserLogin) {
          pathToRedirect = this.props.redirectToAnUrlAfterUserLogin;
        } else if (user.prescribers && isUserIPF(user) && this.props.getphy_express?.length > 0) {
          pathToRedirect = '/videos';
        } else if (user.prescribers && user.prescribers.length > 0 && !skipRedirect) {
          pathToRedirect = '/sessions';
        } else if (user.prescribers && user.prescribers.length === 0 && !skipRedirect) {
          pathToRedirect = '/communities';
        }

        if (isUserGeneralAssessment(user)) {
          routes = generalAssessmentUserRoutes;
        } else {
          routes = getUserRoutesFiltered(this.props.isLoggedIn, user);
        }
        break;
      case 'coach':
        pathToRedirect = '/communities';
        routes = coachRoutes;
        break;
      case 'manager':
        pathToRedirect = '/todo';
        routes = managerRoutes;
        break;
      case 'admin':
        pathToRedirect = '/todo';
        routes = adminRoutes;
        break;
      case 'prescriber':
        pathToRedirect = '/stats/dashboard';
        routes = prescriberRoutes;
        break;
      default:
        routes = loggedOutRoutes;
        break;
    }
    // If we have to redirect after login
    const { search } = this.props.location;
    const queryParams = new URLSearchParams(search);
    const redirectUrl = queryParams.get('redirectUrl');
    if (redirectUrl) {
      pathToRedirect = redirectUrl;
    }

    if (role) {
      this.setState(
        {
          routes,
        },
        () => {
          if (!skipRedirect && pathToRedirect)
            this.props.history.push(pathToRedirect, paramWhenRedirect);
        }
      );
    } else {
      this.setState({
        routes,
      });
    }
  };

  pageExistInLoginArea = () => {
    if (this.props.role) return false;
    const { routes: defaultUserRoutes } = getUserRoutesFiltered(
      this.props.isLoggedIn,
      this.props.user
    );
    let pageExist = false;
    const { pathname: urlPath } = window.location;
    if (!pageExist) {
      pageExist = this.comparePathWithRoutes(defaultUserRoutes, urlPath);
    }
    if (!pageExist) {
      pageExist = this.comparePathWithRoutes(coachRoutes.routes, urlPath);
    }
    if (!pageExist) {
      pageExist = this.comparePathWithRoutes(managerRoutes.routes, urlPath);
    }
    if (!pageExist) {
      pageExist = this.comparePathWithRoutes(adminRoutes.routes, urlPath);
    }
    return pageExist;
  };

  comparePathWithRoutes = (routes, path) => {
    if (!path) return null;
    let isRouteFound = false;
    routes.forEach((route) => {
      if (route.regex && !isRouteFound) {
        // We have regex only for dynamic routes
        isRouteFound = route.regex.test(path);
      } else if (route.path === path) {
        isRouteFound = true;
      }
    });
    return isRouteFound;
  };

  handleCloseUnsubscribeEmail = () => {
    this.setState({
      unsubscribeEmail: false,
    });
  };

  handleClosePromotingBanner = () => {
    const { dispatch } = this.props;

    dispatch(promoteAction.hideEcoSantePromotion());
  };

  handleSubmitPromotingBanner = () => {
    const { dispatch, promotion } = this.props;

    dispatch(
      prescriberAction.linkUserToPrescriberWithCode({
        code: promotion.action.prescriptorProgramCode,
        statusRef: statusRefPrescriptorCode,
      })
    );
  };

  getSeoPageInformations = (seoPages) => {
    if (!(seoPages || []).length) return null;
    const { pathname } = this.props.history.location;
    const seoPage = seoPages.find((seo) => `/${getLocale()}${seo.url}` === pathname);
    if (!seoPage) return null;
    const { meta_title: metaTitle, meta_description: metaDescription } = seoPage;
    return { meta_title: metaTitle, meta_description: metaDescription };
  };

  showFooterInvite = () => {
    const { getSlugByName, user } = this.props;
    return !(
      itContains(window.location.href, getSlugByName('Landing Page - IPF')) ||
      itContains(window.location.href, getSlugByName('BVS - Step')) ||
      itContains(window.location.href, getSlugByName('Landing Page - EcoSante')) ||
      itContains(window.location.href, getSlugByName('Details Page - EcoSante')) ||
      (user.prescribers || []).length > 0
    );
  };

  setBirthdate = (data) => {
    const birthdate = data.birthdate;

    this.props.dispatch(
      submitProfileUpdate({
        birthdate,
        statusRef: statusRefSaveProfile,
      })
    );

    this.handleBirthdateData(false);
  };

  hasNewDesign = () => {
    const { pathname } = this.props.location;
    if (itContains(pathname, `/group-classes`)) return true;
    return false;
  };

  render() {
    let role = this.props.role || getCurrentRole();

    return (
      <div className={`App role-${role} ${this.hasNewDesign() ? 'new-design' : ''}`}>
        {role === 'user' && this.state.birthdateDataToSet && (
          <ModalSetBirthdate handleSubmit={this.setBirthdate} />
        )}

        <Switch>
          <Redirect exact from="/fpar" to="/es-AR/fp-y-yo" />

          {this.state.initialised && (this.props.getSlugRoutes(commonRoutes.routes) || [])}
          {this.props.getSlugRoutes((this.state.routes || {}).routes) || []}

          <Redirect exact from="/en" to="/en/pf-and-me" />
          <Redirect exact from="/es" to="/es/fp-y-yo" />
          <Redirect exact from="/es-AR" to="/es-AR/fp-y-yo" />
          <Redirect exact from="/de" to="/de/lf-und-ich" />
          <Redirect exact from="/it" to="/it/io-e-l-fp" />
          <Redirect exact from="/nl" to="/nl/lf-en-ik" />
          <Redirect exact from="/pt" to="/pt/fp-e-eu" />

          <Redirect exact from="/" to={`/${getLocale()}`} />
          <Redirect exact from="/en/ipf-and-me" to="/en/pf-and-me" />
          <Redirect exact from="/fr/ipf-and-me" to="/fr/fp-et-moi" />
          <Redirect exact from="/es/ipf-and-me" to="/es/fp-y-yo" />
          <Redirect exact from="/pt/ipf-and-me" to="/pt/fp-e-eu" />
          <Redirect exact from="/it/ipf-and-me" to="/it/io-e-l-fp" />
          <Redirect exact from="/it/io-e-l-IPF" to="/it/io-e-l-fp" />
          <Redirect exact from="/de/ipf-and-me" to="/de/lf-und-ich" />
          <Redirect exact from="/de/ipf-und-ich" to="/de/lf-und-ich" />
          <Redirect exact from="/nl/ipf-and-me" to="/nl/lf-en-ik" />
          <Redirect exact from="/nl/ipf-en-ik" to="/nl/lf-en-ik" />
          <Redirect exact from="/es-AR/ipf-and-me" to="/es-AR/fp-y-yo" />
          <Redirect exact from="/ipf-and-me" to="/fr/fp-et-moi" />

          <Redirect exact from="/fr/details-programme-fpi" to="/fr/details-programme-fp" />
          <Redirect exact from="/en/ipf-program-details" to="/en/pf-program-details" />
          <Redirect
            exact
            from="/es-AR/detalles-del-programa-fpi"
            to="/es-AR/detalles-del-programa-fp"
          />
          <Redirect exact from="/es/detalles-del-programa-fpi" to="/es/detalles-del-programa-fp" />
          <Redirect
            exact
            from="/pt/fpi-informações-do-programa"
            to="/pt/fp-informacoes-do-programa"
          />
          <Redirect
            exact
            from="/it/dettagli-sul-programma-dell-ipf"
            to="/it/dettagli-sul-programma-dell-fp"
          />
          <Redirect exact from="/de/ipf-programm-details" to="/de/lf-programm-details" />
          <Redirect exact from="/nl/ipf-programmagegevens" to="/nl/lf-programmagegevens" />

          <Redirect exact from="/es-ar/fp-y-yo" to="/es-AR/fp-y-yo" />
          <Redirect
            exact
            from="/es-ar/detalles-del-programa-fp"
            to="/es-AR/detalles-del-programa-fp"
          />

          {this.state.routes?.routes?.length > 0 && this.props.seoPages.length && (
            <Route component={NotFound404} />
          )}
        </Switch>
      </div>
    );
  }
}

App.defaultProps = {
  isLoggedIn: false,
  user: {},
};

const mapStateToProps = (state) => ({
  user: state.Account.user,
  isLoggedIn: state.Account.isLoggedIn,
  getphy_express: state.GetphyExpress.getphy_express,
  role: state.Account.role,
  statuses: state.Status.statuses,
  notificationsCounter: state.Socket.notifications_counter,
  promotion: state.Promote,
  seoPages: state.Seo.searchResults,
  seoPagesLoaded: state.Seo.seoPagesLoaded,
  getLanguageById: (id) => state.Account.languages.find((item) => item.id === id),
  getUserCountryCode: () => (state.Account.user.country || {}).code || null,
  userFromCountry: (countryCode) => {
    if (Array.isArray(countryCode)) {
      return countryCode.includes(state.Account.user?.country?.code);
    } else {
      return (state.Account.user.country?.code || null) === countryCode;
    }
  },
  redirectToAnUrlAfterUserLogin: state.Account.redirectToAnUrlAfterUserLogin,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withSlugs(App)));
