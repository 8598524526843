export const FETCH_REQUESTS_SEARCH = 'request/FETCH_REQUESTS_SEARCH';
export const FETCH_COMMUNITIES_REQUESTS_SEARCH = 'request/FETCH_COMMUNITIES_REQUESTS_SEARCH';

export const GET_CALL_REQUESTS_SEARCH = 'request/GET_CALL_REQUESTS_SEARCH';
export const SET_CALL_REQUEST_DONE__SEARCH = 'request/SET_CALL_REQUEST_DONE__SEARCH';

export const SET_REQUESTS_SEARCH_RESULTS = 'SET_REQUESTS_SEARCH_RESULTS';
export const ACCEPT_COMMUNITY_REQUEST = 'ACCEPT_COMMUNITY_REQUEST';
export const REFUSE_COMMUNITY_REQUEST = 'REFUSE_COMMUNITY_REQUEST';

export function fetchDemandsCreator(payload) {
  return { type: FETCH_REQUESTS_SEARCH, payload };
}

export function getCallRequests(payload) {
  return { type: GET_CALL_REQUESTS_SEARCH, payload };
}

export function setCallRequestDone(payload) {
  return { type: SET_CALL_REQUEST_DONE__SEARCH, payload };
}

export function acceptRequestCreator(payload) {
  return { type: ACCEPT_COMMUNITY_REQUEST, payload };
}

export function refuseRequestCreator(payload) {
  return { type: REFUSE_COMMUNITY_REQUEST, payload };
}
