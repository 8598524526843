import { isUserAllianz, isUserFromCountry, isUserIPF, isUserProgramme } from 'app/user/helpers';
import { getLocale } from 'services/localisation';
import loadable from '@loadable/component';

import { LandingPageContent } from '@landingPage/components';
import { GroupClasses, GroupClassesDetails } from '@groupClasses/components';

const PageBeneficiaries = loadable(() => import('./containers/PageBeneficiaries'));
const TrainesBeneficiaryPage = loadable(() => import('./containers/TrainesBeneficiaryPage'));
const PageTaskId = loadable(() => import('./containers/PageTaskId'));
const PageAbout = loadable(() => import('./containers/PageAbout'));
const PageAllianz = loadable(() => import('./containers/PageAllianz'));
const PageBlog = loadable(() => import('./containers/PageBlog'));
const PageUsers = loadable(() => import('./containers/PageUsers'));
const PageBlogArticle = loadable(() => import('./containers/PageBlogArticle'));
const PageConfirmEmail = loadable(() => import('./containers/PageConfirmEmail'));
const PageRestoreAccount = loadable(() => import('./containers/PageRestoreAccount'));
const PagePasswordReset = loadable(() => import('./containers/PagePasswordReset'));
const PageTerms = loadable(() => import('./containers/PageTerms'));
const PageContact = loadable(() => import('./containers/PageContact'));
const PageIPFProgramDetails = loadable(() => import('./containers/PageIPFProgramDetails'));
const PageAG2RProgramDetails = loadable(() => import('./containers/PageAG2RProgramDetails'));
const PageLegal = loadable(() => import('./containers/PageLegal'));
const PagePartners = loadable(() => import('./containers/PagePartners'));
const PageFaq = loadable(() => import('./containers/PageFaq'));
const PageTestGauges = loadable(() => import('./containers/PageTestGauges'));
const PageDashboard = loadable(() => import('./containers/PageDashboard'));
const PageInvitation = loadable(() => import('./containers/PageInvitation'));
const PageIPF = loadable(() => import('./containers/PageIPF'));
const PageAG2R = loadable(() => import('./containers/PageAG2R'));
const PageEcoSante = loadable(() => import('./containers/PageEcoSante'));
const PageEcoSanteDetails = loadable(() => import('./containers/PageEcoSanteDetails'));
const PageCourses = loadable(() => import('./containers/PageCourses'));
const PageCourseEdit = loadable(() => import('./containers/PageCourseEdit'));
const PageGroupEdit = loadable(() => import('./containers/PageGroupEdit'));
const PageEventEdit = loadable(() => import('./containers/PageEventEdit'));
const PageHelpFaq = loadable(() => import('./containers/PageHelpFaq'));
const PageHelpTutorial = loadable(() => import('./containers/PageHelpTutorial'));
const PageHelpGlossary = loadable(() => import('./containers/PageHelpGlossary'));
const PageHelpContactUs = loadable(() => import('./containers/PageHelpContactUs'));
const PageHelpCommunicationTool = loadable(() => import('./containers/PageHelpCommunicationTool'));
const PageHelpProcedure = loadable(() => import('./containers/PageHelpProcedure'));
const PageCoursesArchived = loadable(() => import('./containers/PageCoursesArchived'));
const PageTodo = loadable(() => import('./containers/PageTodo'));
const PageStats = loadable(() => import('./containers/PageStats'));
const PageContentObjectives = loadable(() => import('./containers/PageContentObjectives'));
const PageContentPublic = loadable(() => import('./containers/PageContentPublic'));
const PageContentProgram = loadable(() => import('./containers/PageContentProgram'));
const PageContentPrescriptor = loadable(() => import('./containers/PageContentPrescriptor'));
const PageContentGetphyExpress = loadable(() => import('./containers/PageContentGetphyExpress'));
const PageContentHelp = loadable(() => import('./containers/PageContentHelp'));
const PageContentSeo = loadable(() => import('./containers/PageContentSeo'));
const PageSysAdmin = loadable(() => import('./containers/PageSysAdmin'));
const PageSessions = loadable(() => import('./containers/PageSessions'));
const PageSessionsEvaluation = loadable(() => import('./containers/PageSessionsEvaluation'));
const PageMe = loadable(() => import('./containers/PageMe'));
const PageEvaluationMe = loadable(() => import('./containers/PageEvaluationMe'));
const PageVideos = loadable(() => import('./containers/PageVideos'));
const PageChallanges = loadable(() => import('./containers/PageChallanges'));
const PageFriends = loadable(() => import('./containers/PageFriends'));

export const commonRoutes = {
  routes: [
    { path: '/about', component: PageAbout, name: 'about' },
    { path: '/blog', component: PageBlog, name: 'blog' },
    { path: '/:languageSlug/blog/:articleSlug', component: PageBlogArticle },
    { path: '/confirm-email', component: PageConfirmEmail },
    { path: '/restore', component: PageRestoreAccount },
    { path: '/password-reset', component: PagePasswordReset },
    { path: '/contact', component: PageContact, name: 'contact' },
    { path: '/terms', component: PageTerms, name: 'terms' },
    { path: '/ipf-program-details', component: PageIPFProgramDetails, name: 'IPF Program details' },
    {
      path: '/bvs-program-details',
      component: PageAG2RProgramDetails,
      name: 'BVS Program details',
    },
    { path: '/beneficiaries', component: PageBeneficiaries, name: 'beneficiaries' },
    { path: '/legal', component: PageLegal, name: 'legal' },
    { path: '/partners', component: PagePartners, name: 'partners' },
    { path: '/faq', component: PageFaq, name: 'FAQ' },
    { path: '/test/gauges', component: PageTestGauges },
    { path: '/stats/dashboard', component: PageDashboard },
    { path: '/ecosante-details', component: PageEcoSanteDetails, name: 'Details Page - EcoSante' },
  ],
  onLoginRedirect: '',
};
export const loggedOutRoutes = {
  routes: [
    { path: '/', component: LandingPageContent, name: 'home' },
    { path: '/invitation', component: PageInvitation, name: 'invitation' },
    { path: '/ipf-and-me', component: PageIPF, name: 'Landing Page - IPF' },
    { path: '/:languageSlug/bvs/:step?', component: PageAG2R, name: 'BVS - Step' },
    { path: '/:languageSlug/ecosante', component: PageEcoSante, name: 'Landing Page - EcoSante' },
    { path: '/ecosante', component: PageEcoSante, name: 'Landing Page - EcoSante' },
    { path: '/allianz', component: PageAllianz, name: 'Landing page - Allianz' },
  ],
  homeRoute: '/',
  onLoginRedirect: '',
};
export const coachRoutes = {
  routes: [
    { path: '/beneficiaries/', component: PageBeneficiaries },
    {
      path: '/beneficiaries/:id',
      component: TrainesBeneficiaryPage,
      regex: /\/beneficiaries\/\d+/,
    },
    { path: '/task/:idTask', component: PageTaskId, regex: /\/task\/\d+/ },
    { path: '/communities', component: PageCourses },
    {
      path: '/communities/community/:communityId',
      component: PageCourses,
      regex: /\/communities\/community\/\d+/,
    },
    {
      path: '/communities/group/:groupId',
      component: PageCourses,
      regex: /\/communities\/group\/\d+/,
    },
    {
      path: '/communities/event/:eventId',
      component: PageCourses,
      regex: /\/communities\/event\/\d+/,
    },
    {
      path: '/communities/community/:communityId/admin',
      component: PageCourseEdit,
      regex: /\/communities\/community\/\d+\/admin/,
    },
    {
      path: '/communities/group/:groupId/admin',
      component: PageGroupEdit,
      regex: /\/communities\/group\/\d+\/admin/,
    },
    {
      path: '/communities/event/:eventId/admin',
      component: PageEventEdit,
      regex: /\/communities\/event\/\d+\/admin/,
    },
    { path: '/help/tutorial', component: PageHelpTutorial },
    { path: '/help/faq', component: PageHelpFaq },
    { path: '/help/glossary', component: PageHelpGlossary },
    { path: '/help/contact-us', component: PageHelpContactUs },
    { path: '/help/communication-tool', component: PageHelpCommunicationTool },
    { path: '/help/procedure', component: PageHelpProcedure },
    { path: '/unsubscribe/email' },
    { path: '/group-classes', component: GroupClasses },
    { path: '/group-classes/:id', component: GroupClassesDetails },
  ],
  homeRoute: '/communities',
  onLoginRedirect: '',
};
export const managerRoutes = {
  routes: [
    { path: '/users', component: PageUsers },
    { path: '/communities', component: PageCourses },
    {
      path: '/communities/community/:communityId',
      component: PageCourses,
      regex: /\/communities\/community\/\d+/,
    },
    {
      path: '/communities/group/:groupId',
      component: PageCourses,
      regex: /\/communities\/group\/\d+/,
    },
    {
      path: '/communities/event/:eventId',
      component: PageCourses,
      regex: /\/communities\/event\/\d+/,
    },
    { path: '/communities/archived', component: PageCoursesArchived },
    { path: '/todo', component: PageTodo },
    { path: '/stats', component: PageStats },
    {
      path: '/communities/community/:communityId/admin',
      component: PageCourseEdit,
      regex: /\/communities\/community\/\d+\/admin/,
    },
    {
      path: '/communities/group/:groupId/admin',
      component: PageGroupEdit,
      regex: /\/communities\/group\/\d+\/admin/,
    },
    {
      path: '/communities/event/:eventId/admin',
      component: PageEventEdit,
      regex: /\/communities\/event\/\d+\/admin/,
    },
    { path: '/help/tutorial', component: PageHelpTutorial },
    { path: '/help/faq', component: PageHelpFaq },
    { path: '/help/glossary', component: PageHelpGlossary },
    { path: '/help/contact-us', component: PageHelpContactUs },
    { path: '/help/communication-tool', component: PageHelpCommunicationTool },
    { path: '/help/procedure', component: PageHelpProcedure },
    { path: '/unsubscribe/email' },
    { path: '/group-classes', component: GroupClasses },
    { path: '/group-classes/:id', component: GroupClassesDetails },
  ],
  homeRoute: '/todo',
  onLoginRedirect: '',
};
export const adminRoutes = {
  routes: [
    { path: '/group-classes', component: GroupClasses },
    { path: '/group-classes/:id', component: GroupClassesDetails },
    { path: '/users', component: PageUsers },
    { path: '/communities', component: PageCourses },
    {
      path: '/communities/community/:communityId',
      component: PageCourses,
      regex: /\/communities\/community\/\d+/,
    },
    {
      path: '/communities/group/:groupId',
      component: PageCourses,
      regex: /\/communities\/group\/\d+/,
    },
    {
      path: '/communities/event/:eventId',
      component: PageCourses,
      regex: /\/communities\/event\/\d+/,
    },
    { path: '/communities/archived', component: PageCoursesArchived },
    { path: '/todo', component: PageTodo },
    { path: '/content/objectives', component: PageContentObjectives },
    { path: '/content/public', component: PageContentPublic },
    { path: '/content/program', component: PageContentProgram },
    { path: '/content/prescriptor', component: PageContentPrescriptor },
    { path: '/content/getphy-express', component: PageContentGetphyExpress },
    { path: '/content/content-help', component: PageContentHelp },
    { path: '/content', component: PageContentSeo },
    { path: '/content/seo', component: PageContentSeo },
    { path: '/stats', component: PageStats },
    {
      path: '/communities/community/:communityId/admin',
      component: PageCourseEdit,
      regex: /\/communities\/community\/\d+\/admin/,
    },
    {
      path: '/communities/group/:groupId/admin',
      component: PageGroupEdit,
      regex: /\/communities\/group\/\d+\/admin/,
    },
    {
      path: '/communities/event/:eventId/admin',
      component: PageEventEdit,
      regex: /\/communities\/event\/\d+\/admin/,
    },
    { path: '/sys-admin', component: PageSysAdmin },
    { path: '/help/tutorial', component: PageHelpTutorial },
    { path: '/help/faq', component: PageHelpFaq },
    { path: '/help/glossary', component: PageHelpGlossary },
    { path: '/help/contact-us', component: PageHelpContactUs },
    { path: '/help/communication-tool', component: PageHelpCommunicationTool },
    { path: '/help/procedure', component: PageHelpProcedure },
    { path: '/unsubscribe/email' },
  ],
  homeRoute: '/todo',
  onLoginRedirect: '',
};
export const prescriberRoutes = {
  routes: [],
  homeRoute: '/stats/dashboard',
  onLoginRedirect: '',
};
export const loggedInCommonRoutes = {
  routes: [],
  onLoginRedirect: '',
};

export const generalAssessmentUserRoutes = {
  routes: [
    { path: '/communities', component: PageCourses },
    {
      path: '/communities/community/:communityId',
      component: PageCourses,
      regex: /\/communities\/community\/\d+/,
    },
    {
      path: '/communities/group/:groupId',
      component: PageCourses,
      regex: /\/communities\/group\/\d+/,
    },
    {
      path: '/communities/event/:eventId',
      component: PageCourses,
      regex: /\/communities\/event\/\d+/,
    },
    { path: '/unsubscribe/email' },
    { path: '/videos', component: PageVideos },
  ],
  homeRoute: '/communities',
  onLoginRedirect: '/communities',
};

export const userRoutes = {
  routes: [
    { path: '/sessions', component: PageSessions },
    { path: '/sessions/evaluation', component: PageSessionsEvaluation },
    { path: '/moi', component: PageMe },
    { path: '/communities', component: PageCourses },
    { path: '/videos', component: PageVideos },
    {
      path: '/communities/community/:communityId',
      component: PageCourses,
      regex: /\/communities\/community\/\d+/,
    },
    {
      path: '/communities/group/:groupId',
      component: PageCourses,
      regex: /\/communities\/group\/\d+/,
    },
    {
      path: '/communities/event/:eventId',
      component: PageCourses,
      regex: /\/communities\/event\/\d+/,
    },
    { path: '/moi/evaluation', component: PageEvaluationMe },
    { path: '/unsubscribe/email' },
  ],
  homeRoute: '/sessions',
  onLoginRedirect: '',
};

export const getUserRoutesFiltered = (isLoggedIn, user) => {
  const locale = getLocale();
  if (
    isLoggedIn &&
    !isUserFromCountry(user, 'CA') &&
    !isUserFromCountry(user, 'AR') &&
    !isUserIPF(user) &&
    !isUserAllianz(user) &&
    !isUserProgramme(user)
  ) {
    return {
      ...userRoutes,
      routes: [...userRoutes.routes, { path: '/friends', component: PageFriends }],
    };
  }

  if (isLoggedIn && isUserProgramme(user) && locale === 'fr') {
    return {
      ...userRoutes,
      routes: [...userRoutes.routes, { path: '/defis', component: PageChallanges }],
    };
  }

  return userRoutes;
};
