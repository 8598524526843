import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import Button from 'components/Button';
import ConfirmMessage from 'components/ConfirmMessage';

import CONTACT_EMAIL_ADDRESS from 'services/contact';

import './style.css';

class UnsubscribeInfo extends Component {
  constructor() {
    super();

    this.state = {
      showOnlyOneButton: true,
    };

    this.handleUnsubscribeClick = this.handleUnsubscribeClick.bind(this);
    this.submitClick = this.submitClick.bind(this);
  }

  handleUnsubscribeClick() {
    this.setState({
      openConfirmModal: true,
    });
  }

  submitClick() {
    const { submitClick } = this.props;

    submitClick();
    this.setState({
      openConfirmModal: false,
    });
  }

  render() {
    const { t, notificationPanelOpen, status } = this.props;
    const { openConfirmModal } = this.state;

    return (
      <div className="panel">
        <div className="data-row">
          <p>{t('ModalProfile.UnsubscribeInfo.question-1')}</p>
          <br />
          <p>{t('ModalProfile.UnsubscribeInfo.text-no')}</p>
          <ul>
            <li>
              {t('ModalProfile.UnsubscribeInfo.list-1')}
              <a href={`mailto:${CONTACT_EMAIL_ADDRESS}`}>
                {t('ModalProfile.UnsubscribeInfo.link-to-bonjour')}
              </a>
              .
            </li>
            <li>
              {t('ModalProfile.UnsubscribeInfo.list-2')}
              <div className="button--link-type small" onClick={notificationPanelOpen}>
                {t('ModalProfile.UnsubscribeInfo.link-to-notifications')}
              </div>
            </li>
          </ul>
        </div>

        <div className="">
          <Button onClick={this.handleUnsubscribeClick}>
            {t('ModalProfile.UnsubscribeInfo.unsubscribe')}
          </Button>
        </div>

        {openConfirmModal && (
          <ConfirmMessage
            title={t('ModalProfile.UnsubscribeInfo.confirm')}
            handleCancel={() => this.setState({ openConfirmModal: false })}
            handleConfirm={this.submitClick}
            status={status}
          />
        )}
      </div>
    );
  }
}

export default withTranslation()(UnsubscribeInfo);
