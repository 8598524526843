import * as landingActions from 'store/modules/Landing/actions';

const initialState = {
  landings: {},
  searchResults: {
    landings: [],
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case landingActions.UPDATE_LANDINGS_CACHE_SET:
      return {
        ...state,
        landings: action.payload,
      };

    case landingActions.UPDATE_LANDING_SEARCH_RESULTS:
      return {
        ...state,
        searchResults: action.payload,
      };

    default:
      return state;
  }
};
